import { CodeName } from "@/assets/commontype/CodeName";

export const saijiKbnList = [
  { cd: '', name: '-' },
  { cd: '1', name: '1:一般' },
  { cd: '2', name: '2:催事' },
  { cd: '3', name: '3:全て' },
];
export const getSaijiKbnCD = (name: string): string => {
  const result = saijiKbnList.find(data => data.name == name);
  return result ? result.cd : saijiKbnList[0].cd;
}
export const getSaijiKbnName = (cd: string, nullIsDefault: boolean): string => {
  const result = saijiKbnList.find(data => data.cd == cd);
  return result ? result.name : nullIsDefault ? saijiKbnList[0].name : null;
}


export type ErrorInfoData = {
  itemCD?: string | null, //商品CD
  centerCD?: string | null, //商品CD
  aiteCenterCD?: string | null, //商品CD
  saijiKbn?: string | null, //商品CD
  fromDate?: string | null, //商品CD
  toDate?: string | null, //商品CD
}

//倉庫・商品・タグ
export type InfoData = {
  uuid?: string,  //データベースに登録済みのUUID。更新時にDB上の値とずれていれば登録エラーとする

  itemCD?: string | null, //商品CD
  itemInfo?: InfoItemData | null, //商品
  //子センター
  bumonCD?: string, //--部門
  centerCD?: string | null, //倉庫(4桁コード)
  centerCD2?: string | null, //倉庫(2桁コード)
  centerInfo?: InfoCenterData | null, //倉庫
  //親センター
  aiteBumonCD?: string, //--部門
  aiteCenterCD?: string | null, //倉庫(4桁コード)
  aiteCenterCD2?: string | null, //倉庫(2桁コード)
  aiteCenterInfo?: InfoCenterData | null, //倉庫

  saijiKbn?: '1' | '2' | '3' | string | null, //催事区分　'1：一般　2:催事　3:全て
  fromDate?: string | null, //開始日
  toDate?: string | null, //終了日

  editDate?: string | null, //編集日  //'YYYY-MM-DD HH24:mi:ss'
  editUser?: string | null, //編集者

  oldItemCD?: string | null, //商品CD
  oldCenterCD?: string | null, //倉庫(4桁コード)
  oldAiteCenterCD?: string | null, //倉庫(4桁コード)
  oldSaijiKbn?: '1' | '2' | '3' | string | null, //催事区分　'1：一般　2:催事　3:全て
  oldFromDate?: string | null, //開始日
  oldToDate?: string | null, //終了日

  errorInfo?: ErrorInfoData,
  warnInfo?: ErrorInfoData,
  edited?: boolean, //編集
  visible?: boolean, //表示状態

  requestItemInfo?: boolean, //商品情報取得希望対象
  requestCenterInfo?: boolean, //センター情報取得希望対象
  requestAiteCenterInfo?: boolean, //センター情報取得希望対象
}
//商品
export type InfoItemData = {
  itemCD?: string | null, //商品CD
  itemNM?: string | null, //商品名
  janCD?: string | null,   //JAN
  makerCD?: string | null, //メーカー
  makerNM?: string | null, //メーカー
  categoryCD?: string | null, //ｶﾃｺﾞﾘ
  categoryNM?: string | null, //ｶﾃｺﾞﾘ
  blIrisu?: number | null,  //容量1(BL入数) 
  csIrisu?: number | null,  //容量2(CS入数)
  lot?: number | null,  //発注単位
  csIrisu_lot?: number | null,  //CS入数×発注単位
  capacity?: string | null, //内容量
  bestBefore?: string | null, //賞味期間
  teika?: number | null, //定価
  tatene?: number | null, //建値
}
//倉庫
export type InfoCenterData = {
  centerCD?: string | null, //倉庫(4桁コード)
  centerNM?: string | null, //倉庫
  areaCD?: string | null, //地域
  areaNM?: string | null, //地域
  prefCD?: string | null, //都道府県
  prefNM?: string | null, //都道府県
}

//並び順
export const listSortOrders:CodeName[] = [
  new CodeName({group_code:"item", group_name:"商品→子センター", code: "itemCD", name: "商品コード順", }),
  new CodeName({group_code:"item", group_name:"商品→子センター", code: "itemNM", name: "商品名順", }),
  new CodeName({group_code:"item", group_name:"商品→子センター", code: "janCD", name: "JANコード順", }),
  new CodeName({group_code:"item", group_name:"商品→子センター", code: "maker", name: "メーカー順", }),
  new CodeName({group_code:"item", group_name:"商品→子センター", code: "category", name: "商品カテゴリ順", }),
  new CodeName({group_code:"item", group_name:"商品→子センター", code: "bestBefore", name: "賞味期間順", }),
  new CodeName({group_code:"item", group_name:"商品→子センター", code: "teika", name: "定価順", }),
  new CodeName({group_code:"item", group_name:"商品→子センター", code: "tatene", name: "建値順", }),

  new CodeName({group_code:"center", group_name:"子センター→商品", code: "center", name: "子センター", }),
  new CodeName({group_code:"center", group_name:"子センター→商品", code: "area", name: "子センター地域", }),
  new CodeName({group_code:"center", group_name:"子センター→商品", code: "pref", name: "子センター都道府県", }),

  new CodeName({group_code:"aiteCenter", group_name:"親センター→商品", code: "aiteCenter", name: "親センター", }),
  new CodeName({group_code:"aiteCenter", group_name:"親センター→商品", code: "aiteArea", name: "親センター地域", }),
  new CodeName({group_code:"aiteCenter", group_name:"親センター→商品", code: "aitePref", name: "親センター都道府県", }),
];