import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CodeName } from "@/assets/commontype/CodeName";
import { OptionItemTag } from "@/assets/commontype/ItemTag";
import * as arrayUtil from "@/util/arrayUtil";
import { MainPageColRowModel, mainPageColRowModelDefault } from "@/components/mainpage/MainPageTableModel";
import { centerItemAttributes, listSortOrders, AlertSettings, defaultVisibleColumnsKey, defaultVisibleRowsKey, defaultVisibleAttributes, mustVisibleColumnsKey, mustVisibleRowsKey } from "@/store/mainpage/mainPageCommon";
import { OptionTerm } from "@/components/custom/TermOptionSelecter";



export const optionsNameList = [
  'optionBumons',
  'optionMakers',
  'optionMakerCode',
  'optionAreas',
  'optionCenters',
  'optionCenterCode',
  'optionItemCode',
  'optionItemKeyword',
  'optionItemTags',
  'optionTermType',
  'optionTermDateFrom',
  'optionTermDateTo',
  'optionTermDiv',
  'optionTermShubai',
  'optionOrderUsers',
  'optionTermPast',
  'optionTermFuture',
  'optionTermPastDays',
  'optionTermShipping',
  'optionZaikoTanaban',
  'optionZaikoSuryo',
  'optionZaikoFresh',
  'optionTodayType',
  'optionNyukaYotei',
  'optionAlertStockMinusAfterNdays',
  'optionAlertStockMdaysOrMore',
  'optionAlertStoreOrderNover',
  'optionSejOdttl7Times',
  'optionTeiban',
  'optionTags',
  'optionFreshnessAlert',
];
export type MainPageOptions = {
  optionBumons?: CodeName[],    //部門
  optionMakers?: CodeName[],    //メーカー
  optionMakerCode?: string,            //メーカーCD
  optionAreas?: CodeName[],     //地域
  optionCenters?: CodeName[],   //倉庫
  optionCenterCode?: string,            //倉庫CD
  optionItemCode?: string,            //商品CD
  optionItemKeyword?: string,            //商品キーワード
  optionItemTags?: OptionItemTag[],            //商品タグ
  optionTermType?: 'relative' | 'past' | string,   //期間タイプ
  optionTermDateFrom?: string,
  optionTermDateTo?: string,
  optionTermDiv?: 'daily' | 'weekly' | string,   //過去在庫表示単位
  optionTermShubai?: OptionTerm | null,  //終売日
  optionOrderUsers?: CodeName[],   //担当メーカー
  optionTermPast?: number,            //過去表示週数
  optionTermFuture?: number,          //未来表示週数
  optionTermPastDays?: number,            //過去表示週数
  optionTermShipping?: number,            //過去表示週数
  optionZaikoTanaban?: '' | 'has' | 'none' | string,   //棚番
  optionZaikoSuryo?: '' | 'has' | 'none' | string,   //在庫数
  optionZaikoFresh?: '' | 'morning' | 'real' | string,   //在庫鮮度
  optionTodayType?: 'today' | 'now' | string,   //当日在庫表示内容
  optionNyukaYotei?: '' | 'has' | 'none' | string,   //入荷予定
  optionTeiban?: '' | 'has' | 'none' | string,   //定番フラグ
  optionTags?: CodeName[],   //タグ
  optionFreshnessAlert?: boolean,
};
export const displaySettingsNameList = [
  'visibleAttributes',
  'checkedRowKey',
  'checkedColKey',
];
export type DisplaySettings = {
  visibleAttributes?: string[][],    //表示属性
  checkedRowKey?: string[], //行項目選択
  checkedColKey?: string[],  //列項目選択
};

//Page State
export type MainPageSaveState = {
  //検索条件
  refreshOptions_ver: number,
  optionBumons:CodeName[],    //部門
  optionMakers:CodeName[],    //メーカー
  optionMakerCode:string,            //メーカーCD
  optionAreas:CodeName[],     //地域
  optionCenters:CodeName[],   //倉庫
  optionCenterCode:string,            //倉庫CD
  optionItemCode:string,            //商品CD
  optionItemKeyword:string,            //商品キーワード
  optionItemTags:OptionItemTag[],            //商品タグ
  optionTermType?: 'relative' | 'past' | string,   //期間タイプ
  optionTermDate?: OptionTerm | null,
  optionTermDiv:'daily'|'weekly'|string,   //在庫鮮度
  optionTermPast:number,            //過去表示週数
  optionTermFuture:number,          //未来表示週数
  optionTermPastDays:number,            //過去表示週数
  optionTermShipping:number,            //過去表示週数
  optionZaikoTanaban:''|'has'|'none'|string,   //棚番
  optionZaikoSuryo:''|'has'|'none'|string,   //在庫数
  optionZaikoFresh:''|'morning'|'real'|string,   //在庫鮮度
  optionTodayType: 'today' | 'now' | string,   //当日在庫表示内容
  optionNyukaYotei:''|'has'|'none'|string,   //入荷予定
  optionTermShubai: OptionTerm | null,  //終売日
  optionOrderUsers: CodeName[],   //担当メーカー
  optionTeiban: '' | 'has' | 'none' | string,   //定番フラグ

  optionAlertStockMinusAfterNdays?: number|"",  //N日後在庫マイナス
  optionAlertStockMdaysOrMore?: number|"",  //M日間以上の在庫量
  optionAlertStoreOrderNover?: number|"",    //当日受注が、前週同曜日の受注数よりXBL超過　★
  optionAlertStoreOrderNtimes?: number|"",    //当日受注が、前週同曜日の受注数よりN倍超過　★

  optionSejOdttl7Times?: number|"",  //7時時点暫定発注数の倍数
  optionTags: CodeName[],   //タグ
  optionFreshnessAlert: boolean,

  favoriteOptions: { title: CodeName, params: MainPageOptions }[] | null,
  favoriteDisplaySettings: { title: CodeName, params: DisplaySettings }[] | null,

  listSortOrder:CodeName,   //並び順
  listSortOrderDesc:boolean,   //昇順
  showSubTotal:boolean,   //小計表示
  showGroupRow:boolean,   //グループ行表示

  numUnit:'CS'|'BL'|'PS'|string,   //数量単位

  hiddenColumns: number[],
  hiddenColumnsKey: string[],
  visibleColumnsKey: string[],
  visibleRowsKey: string[],
  last_type: 'date'|'week'|string,
  result_type: 'general_teiban'|'general'|'general_yoyaku'|'general_tokubai'|'all'|string,

  colWidthsModified: {},

  visibleAttributes:string[][],    //表示属性
  alertSetting: AlertSettings,
  alertAfterSetting: AlertSettings,

  pagingUnit: 'group' | '50' | '100' | '200' | '300' | 'maker' | 'maker50' | 'maker100' | 'maker200' | 'maker300' | string,
};

export const initialMainPageState: MainPageSaveState = {
  refreshOptions_ver: 0,
  optionBumons:[],    //部門
  optionMakers:[],    //メーカー
  optionMakerCode:'',            //メーカーCD
  optionAreas:[],     //地域
  optionCenters:[],   //倉庫
  optionCenterCode:'',            //倉庫CD
  optionItemCode:'',            //商品CD
  optionItemKeyword:'',            //商品キーワード
  optionItemTags:[],            //商品タグ
  optionTermType: 'relative',   //期間タイプ
  optionTermDate: {
    termFrom: new Date(new Date().getTime() - 21*24*60*60*1000),
    termTo: new Date(new Date().getTime() - 1*24*60*60*1000),
  },
  optionTermDiv:'daily',   //在庫鮮度
  optionTermPast:3,   //過去表示週数
  optionTermFuture:3,   //未来表示週数
  optionTermPastDays:7,   //過去表示週数
  optionTermShipping:3,   //過去表示週数
  optionZaikoTanaban:'has',   //棚番
  optionZaikoSuryo:'has',   //在庫数
  optionZaikoFresh:'morning',   //在庫鮮度
  optionTodayType: 'today',   //当日在庫表示内容
  optionNyukaYotei:'',   //棚番
  optionTermShubai: null,   //終売日
  optionOrderUsers: [],   //担当メーカー
  optionTeiban: '',   //定番フラグ

  optionAlertStockMinusAfterNdays: "",  //N日後在庫マイナス
  optionAlertStockMdaysOrMore: "",  //M日間以上の在庫量
  optionAlertStoreOrderNover: "",    //当日受注が、前週同曜日の受注数よりXBL超過　★
  optionAlertStoreOrderNtimes: "",    //当日受注が、前週同曜日の受注数よりN倍超過　★

  optionSejOdttl7Times: 1.5,  //7時時点暫定発注数の倍数
  optionTags: [],   //タグ
  optionFreshnessAlert: false,

  favoriteOptions: [],
  favoriteDisplaySettings: [],

  listSortOrder: listSortOrders.find(listSortOrder => listSortOrder.code == 'itemCD'),   //並び順
  listSortOrderDesc: false,   //並び順
  showSubTotal:false,   //小計表示
  showGroupRow:true,   //グループ行表示

  numUnit:'BL',   //数量単位

  hiddenColumns: [],
  hiddenColumnsKey: [],
  visibleColumnsKey: defaultVisibleColumnsKey,
  visibleRowsKey: defaultVisibleRowsKey,
  last_type: 'date',
  result_type: 'general_teiban',
  colWidthsModified: {},

  visibleAttributes: defaultVisibleAttributes,    //表示属性
  alertSetting: {},
  alertAfterSetting: {},

  pagingUnit: 'maker50',

};

//Page Slice
export type MainPageSaveReducer = {
  initOnDidMount: (state:MainPageSaveState) => void,
  resetOnWillUnmount: (state:MainPageSaveState) => void,
  clearOption: (state: MainPageSaveState, action: PayloadAction<string>) => void,
  clearUnOrderableOption: (state: MainPageSaveState) => void,
  refreshOptions: (state: MainPageSaveState) => void,
  setOptionBumons: (state:MainPageSaveState, action: PayloadAction<CodeName[]>) => void,
  setOptionMakers: (state:MainPageSaveState, action: PayloadAction<CodeName[]>) => void,
  setOptionMakerCode: (state:MainPageSaveState, action: PayloadAction<string>) => void,
  setOptionAreas: (state:MainPageSaveState, action: PayloadAction<CodeName[]>) => void,
  setOptionCenters: (state:MainPageSaveState, action: PayloadAction<CodeName[]>) => void,
  setOptionCenterCode: (state:MainPageSaveState, action: PayloadAction<string>) => void,
  setOptionItemCode: (state:MainPageSaveState, action: PayloadAction<string>) => void,
  setOptionItemKeyword: (state:MainPageSaveState, action: PayloadAction<string>) => void,
  setOptionItemTags: (state:MainPageSaveState, action: PayloadAction<OptionItemTag[]>) => void,
  setOptionTermType: (state:MainPageSaveState, action: PayloadAction<string>) => void,
  setOptionTermDate: (state:MainPageSaveState, action: PayloadAction<OptionTerm>) => void,
  setOptionTermDiv: (state:MainPageSaveState, action: PayloadAction<string>) => void,
  setOptionTermPast: (state:MainPageSaveState, action: PayloadAction<number>) => void,
  setOptionTermFuture: (state:MainPageSaveState, action: PayloadAction<number>) => void,
  setOptionTermPastDays: (state:MainPageSaveState, action: PayloadAction<number>) => void,
  setOptionTermShipping: (state:MainPageSaveState, action: PayloadAction<number>) => void,
  setOptionZaikoTanaban: (state:MainPageSaveState, action: PayloadAction<string>) => void,
  setOptionZaikoSuryo: (state:MainPageSaveState, action: PayloadAction<string>) => void,
  setOptionZaikoFresh: (state:MainPageSaveState, action: PayloadAction<string>) => void,
  setOptionTodayType: (state:MainPageSaveState, action: PayloadAction<string>) => void,
  setOptionNyukaYotei: (state:MainPageSaveState, action: PayloadAction<string>) => void,
  setOptionTermShubai: (state: MainPageSaveState, action: PayloadAction<OptionTerm>) => void,
  setOptionOrderUsers: (state: MainPageSaveState, action: PayloadAction<CodeName[]>) => void,
  setOptionAlertStockMinusAfterNdays: (state:MainPageSaveState, action: PayloadAction<number|"">) => void,
  setOptionAlertStockMdaysOrMore: (state:MainPageSaveState, action: PayloadAction<number|"">) => void,
  setOptionAlertStoreOrderNover: (state:MainPageSaveState, action: PayloadAction<number|"">) => void,
  setOptionAlertStoreOrderNtimes: (state:MainPageSaveState, action: PayloadAction<number|"">) => void,
  setOptionSejOdttl7Times: (state:MainPageSaveState, action: PayloadAction<number|"">) => void,
  setOptionTeiban: (state:MainPageSaveState, action: PayloadAction<string>) => void,
  setOptionTags: (state: MainPageSaveState, action: PayloadAction<CodeName[]>) => void,
  setFavoriteOptions: (state:MainPageSaveState, action: PayloadAction<{ title: CodeName, params: MainPageOptions }[]>) => void,
  setFavoriteDisplaySettings: (state:MainPageSaveState, action: PayloadAction<{ title: CodeName, params: DisplaySettings }[]>) => void,
  setListSortOrder: (state:MainPageSaveState, action: PayloadAction<CodeName>) => void,
  setListSortOrderDesc: (state:MainPageSaveState, action: PayloadAction<boolean>) => void,
  setShowSubTotal: (state:MainPageSaveState, action: PayloadAction<boolean>) => void,
  setShowGroupRow: (state:MainPageSaveState, action: PayloadAction<boolean>) => void,
  setNumUnit: (state:MainPageSaveState, action: PayloadAction<'CS'|'BL'|'PS'|string>) => void,
  columnWidthChange: (state:MainPageSaveState, action: PayloadAction<{colKey:string, width:number}>) => void,
  hideColumnKeysChange: (state:MainPageSaveState, action: PayloadAction<string[]>) => void,
  visibleRowKeysChange: (state:MainPageSaveState, action: PayloadAction<string[]>) => void,
  last_typeChange: (state:MainPageSaveState, action: PayloadAction<'date'|'week'|string>) => void,
  result_typeChange: (state:MainPageSaveState, action: PayloadAction<'general_teiban'|'general'|'general_yoyaku'|'general_tokubai'|'all'|string>) => void,
  setOptionFreshnessAlert: (state:MainPageSaveState, action: PayloadAction<boolean>) => void,
  visibleAttributesChange: (state:MainPageSaveState, action: PayloadAction<string[][]>) => void,
  setAlertSetting: (state:MainPageSaveState, action: PayloadAction<AlertSettings>) => void,
  setAlertAfterSetting: (state:MainPageSaveState, action: PayloadAction<AlertSettings>) => void,
  setPagingUnit: (state:MainPageSaveState, action: PayloadAction<string>) => void,
}

const createReducerContent = (name:string, colRowModel:MainPageColRowModel, initialState: MainPageSaveState):MainPageSaveReducer => {return {
  //componentDidMount
  initOnDidMount(state:MainPageSaveState) {
    if(state.optionBumons === undefined) {
      state.optionBumons = initialMainPageState.optionBumons;
    }
    
    if(state.optionMakers === undefined) {
      state.optionMakers = initialMainPageState.optionMakers;
    }
    if(state.optionMakerCode === undefined) {
      state.optionMakerCode = initialMainPageState.optionMakerCode;
    }
    if(state.optionAreas === undefined) {
      state.optionAreas = initialMainPageState.optionAreas;
    }
    if(state.optionCenters === undefined) {
      state.optionCenters = initialMainPageState.optionCenters;
    }
    if(state.optionCenterCode === undefined) {
      state.optionCenterCode = initialMainPageState.optionCenterCode;
    }
    if(state.optionItemCode === undefined) {
      state.optionItemCode = initialMainPageState.optionItemCode;
    }
    if(state.optionItemKeyword === undefined) {
      state.optionItemKeyword = initialMainPageState.optionItemKeyword;
    }
    if(state.optionItemTags === undefined) {
      state.optionItemTags = initialMainPageState.optionItemTags;
    }
    if(state.optionTermType === undefined) {
      state.optionTermType = initialMainPageState.optionTermType;
    }
    if(state.optionTermDate === undefined) {
      state.optionTermDate = initialMainPageState.optionTermDate;
    }
    if(state.optionTermDiv === undefined) {
      state.optionTermDiv = initialMainPageState.optionTermDiv;
    }
    if(state.optionTermPast === undefined) {
      state.optionTermPast = initialMainPageState.optionTermPast;
    }
    if(state.optionTermFuture === undefined) {
      state.optionTermFuture = initialMainPageState.optionTermFuture;
    }
    if(state.optionTermPastDays === undefined) {
      state.optionTermPastDays = initialMainPageState.optionTermPastDays;
    }
    if(state.optionTermShipping === undefined) {
      state.optionTermShipping = initialMainPageState.optionTermShipping;
    }
    if(state.optionZaikoTanaban === undefined) {
      state.optionZaikoTanaban = initialMainPageState.optionZaikoTanaban;
    }
    if(state.optionZaikoFresh === undefined) {
      state.optionZaikoFresh = initialMainPageState.optionZaikoFresh;
    }
    if(state.optionZaikoSuryo === undefined) {
      state.optionZaikoSuryo = initialMainPageState.optionZaikoSuryo;
    }
    if(state.optionTodayType === undefined) {
      state.optionTodayType = initialMainPageState.optionTodayType;
    }
    if(state.optionNyukaYotei === undefined) {
      state.optionNyukaYotei = initialMainPageState.optionNyukaYotei;
    }
    if(state.optionTermShubai === undefined) {
      state.optionTermShubai = initialMainPageState.optionTermShubai;
    }
    if(state.optionTags === undefined) {
      state.optionTags = initialMainPageState.optionTags;
    }
    if (state.optionOrderUsers === undefined) {
      state.optionOrderUsers = initialMainPageState.optionOrderUsers;
    }
    if(state.optionAlertStockMinusAfterNdays === undefined) {
      state.optionAlertStockMinusAfterNdays = initialMainPageState.optionAlertStockMinusAfterNdays;
    }
    if(state.optionAlertStockMdaysOrMore === undefined) {
      state.optionAlertStockMdaysOrMore = initialMainPageState.optionAlertStockMdaysOrMore;
    }
    if(state.optionAlertStoreOrderNover === undefined) {
      state.optionAlertStoreOrderNover = initialMainPageState.optionAlertStoreOrderNover;
    }
    if(state.optionAlertStoreOrderNtimes === undefined) {
      state.optionAlertStoreOrderNtimes = initialMainPageState.optionAlertStoreOrderNtimes;
    }
    //常にデフォルトに戻す
    // if(state.optionSejOdttl7Times === undefined) {
      state.optionSejOdttl7Times = initialMainPageState.optionSejOdttl7Times;
    // }
    if(state.optionTeiban === undefined) {
      state.optionTeiban = initialMainPageState.optionTeiban;
    }
  
    if(state.favoriteOptions === undefined) {
      state.favoriteOptions = initialMainPageState.favoriteOptions;
    }
    if(state.favoriteDisplaySettings === undefined) {
      state.favoriteDisplaySettings = initialMainPageState.favoriteDisplaySettings;
    }
    if(!state.listSortOrder) {
      state.listSortOrder = initialState.listSortOrder;
    }
    else if(state.listSortOrder.code) { //属性値を再選択する
      const code = state.listSortOrder.code;
      state.listSortOrder = listSortOrders.find(listSortOrder => listSortOrder.code == code);   //並び順
    }
    
    if(state.listSortOrderDesc === undefined) {
      state.listSortOrderDesc = initialState.listSortOrderDesc;
    }
    if(state.showSubTotal === undefined) {
      state.showSubTotal = initialState.showSubTotal;
    }
    if(state.showGroupRow === undefined) {
      state.showGroupRow = initialState.showGroupRow;
    }
    if(state.numUnit === undefined) {
      state.numUnit = initialState.numUnit;
    }

    //初期表示列0配列は、常に全項目表示とする
    if(initialState.visibleColumnsKey.length == 0) {
      state.visibleColumnsKey = colRowModel.colKeys;
    }
    else if(!state.visibleColumnsKey) {
      state.visibleColumnsKey = initialState.visibleColumnsKey;
    }
    //強制選択列
    state.visibleColumnsKey = arrayUtil.union(arrayUtil.and(colRowModel.colKeys, mustVisibleColumnsKey), state.visibleColumnsKey);
    //非表示列
    state.hiddenColumnsKey = arrayUtil.not(colRowModel.colKeys, state.visibleColumnsKey);

    if(state.colWidthsModified === undefined) {
      state.colWidthsModified = initialState.colWidthsModified;
    }

    //行項目
    if (!state.visibleRowsKey || state.visibleRowsKey.length == 0) {
      state.visibleRowsKey = (initialState.visibleRowsKey.length == 0) ? colRowModel.rowKeys : initialState.visibleRowsKey;
    }
    //強制選択行
    state.visibleRowsKey = arrayUtil.union(arrayUtil.and(colRowModel.rowKeys, mustVisibleRowsKey), state.visibleRowsKey);

    if(state.last_type === undefined) {
      state.last_type = initialState.last_type;
    }
    if(state.result_type === undefined) {
      state.result_type = initialState.result_type;
    }
    if (state.optionFreshnessAlert === undefined) {
      state.optionFreshnessAlert = initialState.optionFreshnessAlert;
    }
    
    //表示項目
    if(!state.visibleAttributes || state.visibleAttributes.length < initialState.visibleAttributes.length) {
      state.visibleAttributes = initialState.visibleAttributes;
    }
    else {
      //9項目必要
      let visibleAttributes = [...state.visibleAttributes];
      let init = false;
      initialState.visibleAttributes.forEach((attrs, index) => {
        if(visibleAttributes[index].length != attrs.length) {
          init = true;
          visibleAttributes[index] = attrs;
        }
      });
      if(init) {
        state.visibleAttributes = visibleAttributes;
      }
    }
    if(state.alertSetting === undefined) {
      state.alertSetting = initialState.alertSetting;
    }
    if(state.alertAfterSetting === undefined) {
      state.alertAfterSetting = initialState.alertAfterSetting;
    }
    if(state.pagingUnit === undefined) {
      state.pagingUnit = initialState.pagingUnit;
    }
  
  },
  //componentWillUnmount
  resetOnWillUnmount(state:MainPageSaveState) {
    //初期表示列0配列は、常に全項目表示とするため、記憶しないpagingUnit
    if(initialState.visibleColumnsKey.length == 0) {
      state.visibleColumnsKey = [];
    }
  },
  clearOption(state: MainPageSaveState, action: PayloadAction<string>) {
    const key = action.payload;
    if (!key) {
      // state.optionBumons = initialMainPageState.optionBumons;
      state.optionZaikoTanaban = initialMainPageState.optionZaikoTanaban;
      state.optionZaikoFresh = initialMainPageState.optionZaikoFresh;
      state.optionZaikoSuryo = initialMainPageState.optionZaikoSuryo;
      state.optionTodayType = initialMainPageState.optionTodayType;
      state.optionNyukaYotei = initialMainPageState.optionNyukaYotei;
      state.optionTeiban = initialMainPageState.optionTeiban;
    }
    if (!key || key === 'term') {
      state.optionTermType = initialMainPageState.optionTermType;
      state.optionTermDate = initialMainPageState.optionTermDate;
      state.optionTermDiv = initialMainPageState.optionTermDiv;
      state.optionTermPast = initialMainPageState.optionTermPast;
      state.optionTermFuture = initialMainPageState.optionTermFuture;
      state.optionTermPastDays = initialMainPageState.optionTermPastDays;
      state.optionTermShipping = initialMainPageState.optionTermShipping;
    }
    if (!key || key === 'item') {
      state.optionMakers = initialMainPageState.optionMakers;
      state.optionMakerCode = initialMainPageState.optionMakerCode;
      state.optionItemCode = initialMainPageState.optionItemCode;
      state.optionItemKeyword = initialMainPageState.optionItemKeyword;
      state.optionItemTags = initialMainPageState.optionItemTags;
      state.optionTermShubai = initialMainPageState.optionTermShubai;
      state.optionOrderUsers = initialMainPageState.optionOrderUsers;
      state.optionTags = initialMainPageState.optionTags;
    }
    if (!key || key === 'center') {
      state.optionAreas = initialMainPageState.optionAreas;
      state.optionCenters = initialMainPageState.optionCenters;
      state.optionCenterCode = initialMainPageState.optionCenterCode;
    }
    if (!key || key === 'alert') {
      state.optionAlertStockMinusAfterNdays = initialMainPageState.optionAlertStockMinusAfterNdays;
      state.optionAlertStockMdaysOrMore = initialMainPageState.optionAlertStockMdaysOrMore;
      state.optionAlertStoreOrderNover = initialMainPageState.optionAlertStoreOrderNover;
      state.optionAlertStoreOrderNtimes = initialMainPageState.optionAlertStoreOrderNtimes;
      state.optionFreshnessAlert = initialMainPageState.optionFreshnessAlert;
    }
    if (!key || key === 'alertAfter') {
      state.alertAfterSetting = initialState.alertAfterSetting;
    }
    if (!key || key === 'sej') {
      state.optionSejOdttl7Times = initialMainPageState.optionSejOdttl7Times;
    }
  },
  clearUnOrderableOption (state: MainPageSaveState) {
    // state.optionBumons = initialMainPageState.optionBumons;
    // state.optionTermPast = initialMainPageState.optionTermPast;
    // state.optionTermFuture = initialMainPageState.optionTermFuture;
    // state.optionZaikoTanaban = initialMainPageState.optionZaikoTanaban;
    // state.optionZaikoFresh = initialMainPageState.optionZaikoFresh;
    // state.optionZaikoSuryo = initialMainPageState.optionZaikoSuryo;
    // state.optionNyukaYotei = initialMainPageState.optionNyukaYotei;

    // state.optionMakers = initialMainPageState.optionMakers;
    // state.optionMakerCode = initialMainPageState.optionMakerCode;
    // state.optionItemCode = initialMainPageState.optionItemCode;
    // state.optionItemKeyword = initialMainPageState.optionItemKeyword;
    state.optionItemTags = initialMainPageState.optionItemTags;

    // state.optionAreas = initialMainPageState.optionAreas;
    // state.optionCenters = initialMainPageState.optionCenters;
    // state.optionCenterCode = initialMainPageState.optionCenterCode;

    state.optionAlertStockMinusAfterNdays = initialMainPageState.optionAlertStockMinusAfterNdays;
    state.optionAlertStockMdaysOrMore = initialMainPageState.optionAlertStockMdaysOrMore;
    state.optionAlertStoreOrderNover = initialMainPageState.optionAlertStoreOrderNover;
    state.optionAlertStoreOrderNtimes = initialMainPageState.optionAlertStoreOrderNtimes;
  },
  refreshOptions (state: MainPageSaveState) {
    state.refreshOptions_ver = state.refreshOptions_ver + 1;
  },
  setOptionBumons (state:MainPageSaveState, action: PayloadAction<CodeName[]>) {
    state.optionBumons = action.payload;
  },
  setOptionMakers (state:MainPageSaveState, action: PayloadAction<CodeName[]>) {
    state.optionMakers = action.payload;
  },
  setOptionMakerCode (state:MainPageSaveState, action: PayloadAction<string>) {
    state.optionMakerCode = action.payload;
  },
  setOptionAreas (state:MainPageSaveState, action: PayloadAction<CodeName[]>) {
    state.optionAreas = action.payload;
  },
  setOptionItemCode (state:MainPageSaveState, action: PayloadAction<string>) {
    state.optionItemCode = action.payload;
  },
  setOptionItemKeyword (state:MainPageSaveState, action: PayloadAction<string>) {
    state.optionItemKeyword = action.payload;
  },
  setOptionItemTags (state:MainPageSaveState, action: PayloadAction<OptionItemTag[]>) {
    state.optionItemTags = action.payload;
  },
  setOptionCenters (state:MainPageSaveState, action: PayloadAction<CodeName[]>) {
    state.optionCenters = action.payload;
  },
  setOptionCenterCode (state:MainPageSaveState, action: PayloadAction<string>) {
    state.optionCenterCode = action.payload;
  },
  setOptionTermType(state:MainPageSaveState, action: PayloadAction<string>) {
    state.optionTermType = action.payload;
  },
  setOptionTermDate(state:MainPageSaveState, action: PayloadAction<OptionTerm>) {
    state.optionTermDate = action.payload;
  },
  setOptionTermDiv(state:MainPageSaveState, action: PayloadAction<string>) {
    state.optionTermDiv = action.payload;
  },
  setOptionTermPast (state:MainPageSaveState, action: PayloadAction<number>) {
    state.optionTermPast = action.payload;
  },
  setOptionTermFuture (state:MainPageSaveState, action: PayloadAction<number>) {
    state.optionTermFuture = action.payload;
  },
  setOptionTermPastDays (state:MainPageSaveState, action: PayloadAction<number>) {
    state.optionTermPastDays = action.payload;
  },
  setOptionTermShipping (state:MainPageSaveState, action: PayloadAction<number>) {
    state.optionTermShipping = action.payload;
  },
  setOptionZaikoTanaban(state:MainPageSaveState, action: PayloadAction<string>) {
    state.optionZaikoTanaban = action.payload;
  },
  setOptionZaikoSuryo(state:MainPageSaveState, action: PayloadAction<string>) {
    state.optionZaikoSuryo = action.payload;
  },
  setOptionZaikoFresh(state:MainPageSaveState, action: PayloadAction<string>) {
    state.optionZaikoFresh = action.payload;
  },
  setOptionTodayType(state:MainPageSaveState, action: PayloadAction<string>) {
    state.optionTodayType = action.payload;
  },
  setOptionNyukaYotei(state:MainPageSaveState, action: PayloadAction<string>) {
    state.optionNyukaYotei = action.payload;
  },
  setOptionTermShubai(state: MainPageSaveState, action: PayloadAction<OptionTerm>) {
    state.optionTermShubai = action.payload;
  },
  setOptionOrderUsers(state: MainPageSaveState, action: PayloadAction<CodeName[]>) {
    state.optionOrderUsers = action.payload;
  },
  setOptionAlertStockMinusAfterNdays(state:MainPageSaveState, action: PayloadAction<number|"">) {
    state.optionAlertStockMinusAfterNdays = action.payload;
  },
  setOptionAlertStockMdaysOrMore(state:MainPageSaveState, action: PayloadAction<number|"">) {
    state.optionAlertStockMdaysOrMore = action.payload;
  },
  setOptionAlertStoreOrderNover(state:MainPageSaveState, action: PayloadAction<number|"">) {
    state.optionAlertStoreOrderNover = action.payload;
  },
  setOptionAlertStoreOrderNtimes(state:MainPageSaveState, action: PayloadAction<number|"">) {
    state.optionAlertStoreOrderNtimes = action.payload;
  },
  setOptionSejOdttl7Times(state:MainPageSaveState, action: PayloadAction<number|"">) {
    state.optionSejOdttl7Times = action.payload;
  },
  setOptionTeiban(state: MainPageSaveState, action: PayloadAction<string>) {
    state.optionTeiban = action.payload;
  },
  setOptionTags(state: MainPageSaveState, action: PayloadAction<CodeName[]>) {
    state.optionTags = action.payload;
  },

  setFavoriteOptions(state:MainPageSaveState, action: PayloadAction<{ title: CodeName, params: MainPageOptions }[]>) {
    state.favoriteOptions = action.payload;
  },
  setFavoriteDisplaySettings(state:MainPageSaveState, action: PayloadAction<{ title: CodeName, params: DisplaySettings }[]>) {
    state.favoriteDisplaySettings = action.payload;
  },
  setListSortOrder (state:MainPageSaveState, action: PayloadAction<CodeName>) {
    state.listSortOrder = action.payload;
  },
  setListSortOrderDesc (state:MainPageSaveState, action: PayloadAction<boolean>) {
    state.listSortOrderDesc = action.payload;
  },
  setShowSubTotal (state:MainPageSaveState, action: PayloadAction<boolean>) {
    state.showSubTotal = action.payload;
  },
  setShowGroupRow (state:MainPageSaveState, action: PayloadAction<boolean>) {
    state.showGroupRow = action.payload;
  },
  setNumUnit (state:MainPageSaveState, action: PayloadAction<'CS'|'BL'|'PS'|string>) {
    state.numUnit = action.payload;
  },

  columnWidthChange(state:MainPageSaveState, action: PayloadAction<{colKey:string, width:number}>){
    console.log('store.columnWidthChange');
    const colKey = action.payload.colKey;
    const width = action.payload.width;
    if(colKey && width) {
      state.colWidthsModified[colKey] = width;
    }
  },
  hideColumnKeysChange(state:MainPageSaveState, action: PayloadAction<string[]>){
    console.log('store.hideColumnKeysChange');
    //store更新
    state.hiddenColumns = colRowModel.colFromKeys(action.payload);
    state.hiddenColumnsKey = action.payload;
    state.visibleColumnsKey = arrayUtil.not(colRowModel.colKeys, action.payload);
  },
  visibleRowKeysChange(state:MainPageSaveState, action: PayloadAction<string[]>){
    console.log('store.visibleRowKeysChange');
    //store更新
    state.visibleRowsKey = action.payload;
  },
  last_typeChange(state:MainPageSaveState, action: PayloadAction<'date'|'week'|string>){
    console.log('store.last_typeChange');
    //store更新
    state.last_type = action.payload;
  },
  result_typeChange(state:MainPageSaveState, action: PayloadAction<'general_teiban'|'general'|'general_yoyaku'|'general_tokubai'|'all'|string>){
    console.log('store.result_typeChange');
    //store更新
    state.result_type = action.payload;
  },
  setOptionFreshnessAlert(state: MainPageSaveState, action: PayloadAction<boolean>) {
    state.optionFreshnessAlert = action.payload;
  },
  visibleAttributesChange(state:MainPageSaveState, action: PayloadAction<string[][]>){
    console.log('store.visibleAttributesChange');
    //store更新
    state.visibleAttributes = action.payload;
  },
  setAlertSetting (state:MainPageSaveState, action: PayloadAction<AlertSettings>){
    console.log('store.setAlertParam');
    //store更新
    state.alertSetting = action.payload;
    state.alertAfterSetting = action.payload;
  },
  setAlertAfterSetting(state: MainPageSaveState, action: PayloadAction<AlertSettings>) {
    console.log('store.setAlertAfterSetting');
    //store更新
    state.alertAfterSetting = action.payload;
  },
  setPagingUnit (state:MainPageSaveState, action: PayloadAction<string>){
    console.log('store.setPagingUnit');
    //store更新
    state.pagingUnit = action.payload;
  },

}};

const createSliceContent = (name:string, colRowModel:MainPageColRowModel, initialState: MainPageSaveState) => createSlice({
  name: name,
  initialState: initialState,
  reducers: createReducerContent(name, colRowModel, initialState),
});

//Page Slice Export
//mainPageSaveSlice
export const mainPageSaveSlice = createSliceContent("mainPageSave", mainPageColRowModelDefault, initialMainPageState);
