import moment from 'moment';

export const plusDays = (moment:moment.Moment, n:number):moment.Moment => {
  if(!n || !moment) {
    return moment;
  }

  if(!moment.isValid()) {
    return moment;
  }

  return moment.add(n, 'days');
}

export const minusDays = (moment:moment.Moment, n:number):moment.Moment => {
  if(!n || !moment) {
    return moment;
  }

  if(!moment.isValid()) {
    return moment;
  }

  return moment.subtract(n, 'days');
}

/*
  営業日計算
*/

export const enableInBusiness = (moment:moment.Moment, allowMonDay:boolean, allowTueDay:boolean, allowWedDay:boolean, allowThrDay:boolean, allowFriDay:boolean, allowSatDay:boolean, allowSunDay:boolean, allowHoliday:boolean, holidays:any, disableddays?:any):boolean => {
  if(!moment) {
    return false;
  }

  if(!moment.isValid()) {
    return false;
  }

  //１〜７（月〜日）
  const w = moment.isoWeekday(); 

  const b =
    (w != 1 || allowMonDay) &&  //月
    (w != 2 || allowTueDay) &&  //火
    (w != 3 || allowWedDay) &&  //水
    (w != 4 || allowThrDay) &&  //木
    (w != 5 || allowFriDay) &&  //金
    (w != 6 || allowSatDay) &&  //土
    (w != 7 || allowSunDay) &&  //日
    //祝日
    (allowHoliday || !holidays?.[moment.format("YYYY-MM-DD")]) &&
    //不可日
    (!disableddays || !disableddays?.[moment.format("YYYY-MM-DD")])
  ;
  return b;
}

export const plusDaysInBusiness = (moment:moment.Moment, n:number, allowMonDay:boolean, allowTueDay:boolean, allowWedDay:boolean, allowThrDay:boolean, allowFriDay:boolean, allowSatDay:boolean, allowSunDay:boolean, allowHoliday:boolean, holidays:any, disableddays?:any):moment.Moment => {
  if(!n || !moment) {
    return moment;
  }

  if(!moment.isValid()) {
    return moment;
  }

  let t:number = n;
  while(t > 0) {
    moment = moment.add(1, 'days');
    if(enableInBusiness(
      moment,
      allowMonDay,
      allowTueDay,
      allowWedDay,
      allowThrDay,
      allowFriDay,
      allowSatDay,
      allowSunDay,
      allowHoliday,
      holidays,
      disableddays
    )) {
      t--;
    }
  }

  return moment;
}
export const plusDaysInBusinessLeadTime = (moment:moment.Moment, n:number, allowMonDay:boolean, allowTueDay:boolean, allowWedDay:boolean, allowThrDay:boolean, allowFriDay:boolean, allowSatDay:boolean, allowSunDay:boolean, allowHoliday:boolean, holidays:any, disableddays?:any):moment.Moment => {
  if(!n || !moment) {
    return moment;
  }

  if(!moment.isValid()) {
    return moment;
  }

  let t:number = n;
  while(t > 0) {
    //moment = moment.add(1, 'days');
    if(enableInBusiness(
      moment,
      allowMonDay,
      allowTueDay,
      allowWedDay,
      allowThrDay,
      allowFriDay,
      allowSatDay,
      allowSunDay,
      allowHoliday,
      holidays,
      disableddays
    )) {
      t--;
    }
    moment = moment.add(1, 'days');
  }

  return moment;
}

export const minusDaysInBusiness = (moment:moment.Moment, n:number, allowMonDay:boolean, allowTueDay:boolean, allowWedDay:boolean, allowThrDay:boolean, allowFriDay:boolean, allowSatDay:boolean, allowSunDay:boolean, allowHoliday:boolean, holidays:any, disableddays?:any):moment.Moment => {
  if(!n || !moment) {
    return moment;
  }

  if(!moment.isValid()) {
    return moment;
  }

  let t:number = n;
  while(t > 0) {
    moment = moment.subtract(1, 'days');

    //１〜７（月〜日）
    const w = moment.isoWeekday(); 

    if(
      (w != 1 || allowMonDay) &&  //月
      (w != 2 || allowTueDay) &&  //火
      (w != 3 || allowWedDay) &&  //水
      (w != 4 || allowThrDay) &&  //木
      (w != 5 || allowFriDay) &&  //金
      (w != 6 || allowSatDay) &&  //土
      (w != 7 || allowSunDay) &&  //日
      //祝日
      (allowHoliday || !holidays[moment.format("YYYY-MM-DD")]) &&
      //不可日
      (!disableddays || !disableddays?.[moment.format("YYYY-MM-DD")])
    ) {
      t--;
    }
    
  }

  return moment;
}

