import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CodeName } from "@/assets/commontype/CodeName";

import { CenterMaker, InfoData, ErrorInfoData } from '@/store/settingmaker/settingMakerCommon'
import * as settingMakerRetrieve from "@/assets/apitype/settingMakerRetrieve";
import { SettingMakerColRowModel, rowKeys, headersRow, colDataType, rowDataType, fractionRoundingIdFromLabel } from "@/components/settingmaker/SettingMakerTableModel";
import * as compareUtil from "@/util/compareUtil";
import * as arrayutil from "@/util/arrayUtil";
import * as editorUtil from "@/util/editorUtil";

//0.00フォーマット
const formatterP00 = new Intl.NumberFormat('ja-JP', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 });
//0,0フォーマット
const formatterN0 = new Intl.NumberFormat('ja-JP', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0 });

//編集中判定
export const isEditedRowData = (data: InfoData): boolean => {
  if (!data) {
    return false;
  }

  const blInput =
    (
      data.oldBlInputFlg != data.blInputFlg );
  const leadTime = 
    (
      data.oldLeadTimeFlg != data.leadTimeFlg ||
      data.oldLeadTimeDays != data.leadTimeDays ||
      data.oldLeadTimeExcludeSaturdayFlg != data.leadTimeExcludeSaturdayFlg ||
      data.oldLeadTimeContainHolidayFlg != data.leadTimeContainHolidayFlg);
  const specifiedDate = 
    (
      data.oldSpecifiedDateFlg != data.specifiedDateFlg ||
      data.oldSpecifiedDateSunFlg != data.specifiedDateSunFlg ||
      data.oldSpecifiedDateMonFlg != data.specifiedDateMonFlg ||
      data.oldSpecifiedDateTueFlg != data.specifiedDateTueFlg ||
      data.oldSpecifiedDateWedFlg != data.specifiedDateWedFlg ||
      data.oldSpecifiedDateThrFlg != data.specifiedDateThrFlg ||
      data.oldSpecifiedDateFriFlg != data.specifiedDateFriFlg ||
      data.oldSpecifiedDateSatFlg != data.specifiedDateSatFlg ||
      data.oldSpecifiedDateHolFlg != data.specifiedDateHolFlg);
  const minQty = 
    (
      data.oldMinQtyFlg != data.minQtyFlg ||
      data.oldMinQtyCs != data.minQtyCs);
  const maxQty = 
    (
      data.oldMaxQtyFlg != data.maxQtyFlg ||
      data.oldMaxQtyCs != data.maxQtyCs);
    const close = 
    (
      data.oldCloseFlg != data.closeFlg ||
      data.oldCloseTime != data.closeTime);
    const autoOrder = 
    (
      data.oldAutoOrderFlg != data.autoOrderFlg ||
      data.oldAutoOrderExecDays != data.autoOrderExecDays ||
      data.oldAutoOrderDays != data.autoOrderDays ||
      data.oldAutoOrderContainSundayFlg != data.autoOrderContainSundayFlg);
    const fractionRounding = 
    (
      data.oldFractionRounding != data.fractionRounding);
    const makerCenterName = 
    (
      data.oldMakerCenterName != data.makerCenterName);

  return (
    blInput ||
    leadTime ||
    specifiedDate ||
    minQty ||
    maxQty ||
    close ||
    autoOrder ||
    fractionRounding ||
    makerCenterName
  );
}

export type RowDataGroup = {
  centerMaker: CenterMaker,
  target?: boolean,
  hasChildTarget?: boolean,
}

export type RowInfo = {
  TP: "maker" | "center" | "info" | "bulk",
  no?: string,
  subno?: string,
  dataGroup:RowDataGroup,
  rowKey?: string,

  rowIndex?:number,  //同一集計行内でのrowKeyのindex
  rowIndexLast?:boolean, //同一集計行内での最後のrowKeyフラグ
  row:number,  //行番号

  infoData? :InfoData,
  infoDataIndex?:number,
}
export interface EditCellInfo {
  row:number, 
  col:number, 
  rowKey:string, 
  value:string|number|object|null, 
  relatedValues?: {key:string, value:string|number|object|null}[],
}

//Page State
export type SettingMakerTmpState = {
  // bumonList: CodeName[],  //部門
  makerList: CodeName[],  //メーカー
  areaList: CodeName[], //地域
  centerList: CodeName[], //倉庫

  progress: Record<string, unknown>,
  retrievedParam: settingMakerRetrieve.RequestParam,  //検索条件(検索済み)

  dataGroups: RowDataGroup[],
  rowInfos: RowInfo[],
  fixedRowsTop :number,
  infoDatas: InfoData[],
  rows: any[][],
  mergeCells: {row: number, col: number, rowspan: number, colspan: number}[]
  tableVer_updateData: number,
  tableVer_updateData_rows: number[],
  tableVer_updateSettting: number,

  errorMessage: string | null,
  infoMessage: string | null,

  editing: boolean,
  functionKeys: string[],
  // openDialog: boolean,
};

export const initialState: SettingMakerTmpState = {
  // bumonList: [],
  makerList: [],
  areaList: [],
  centerList: [],

  progress: {},
  retrievedParam: null,

  dataGroups: [],
  rowInfos: [],
  fixedRowsTop :0,
  infoDatas: [],
  rows: [],
  mergeCells: null,
  tableVer_updateData: 0,
  tableVer_updateData_rows: [],
  tableVer_updateSettting: 0,

  errorMessage: null,
  infoMessage: null,

  editing: false,
  functionKeys: [],
  // openDialog: boolean,
};

//Page Slice
export type SettingMakerTmpReducer = {
  putProgress: (state:SettingMakerTmpState, action: PayloadAction<string>) => void,
  removeProgress: (state:SettingMakerTmpState, action: PayloadAction<string>) => void,
  setErrorMessage: (state:SettingMakerTmpState, action: PayloadAction<string>) => void,
  setInfoMessage: (state:SettingMakerTmpState, action: PayloadAction<string>) => void,
  setEditingStart: (state:SettingMakerTmpState, action: PayloadAction<string>) => void,
  setEditingEnd: (state:SettingMakerTmpState, action: PayloadAction<string>) => void,
  // setBumonList: (state:SettingMakerTmpState, action: PayloadAction<CodeName[]>) => void,
  setMakerList: (state:SettingMakerTmpState, action: PayloadAction<CodeName[]>) => void,
  setAreaList: (state:SettingMakerTmpState, action: PayloadAction<CodeName[]>) => void,
  setCenterList: (state:SettingMakerTmpState, action: PayloadAction<CodeName[]>) => void,
  setRetrievedParam: (state:SettingMakerTmpState, action: PayloadAction<settingMakerRetrieve.RequestParam>) => void,

  searched: (state:SettingMakerTmpState, action: PayloadAction<{param: settingMakerRetrieve.RequestParam, centerMakers: CenterMaker[], infos: InfoData[], colRowModel:SettingMakerColRowModel, listSortOrder:CodeName, listSortOrderDesc:boolean, visibleRowsKey:string[]}>) => void,
  refreshTable: (state:SettingMakerTmpState, action: PayloadAction<{colRowModel:SettingMakerColRowModel, listSortOrder:CodeName, listSortOrderDesc:boolean, visibleRowsKey:string[]}>) => void,
  editRowDatas: (state:SettingMakerTmpState, action: PayloadAction<{colRowModel:SettingMakerColRowModel, listSortOrder:CodeName, listSortOrderDesc:boolean, visibleRowsKey:string[], editDatas:EditCellInfo[]}>) => void,
  setFunctionKey: (state:SettingMakerTmpState, action: PayloadAction<string>) => void,
}

const createReducerContent = ():SettingMakerTmpReducer => {return {
    putProgress(state:SettingMakerTmpState, action: PayloadAction<string>) {
      const key = action.payload;
      const progressNew = {...state.progress};
      progressNew[key] = true;
      state.progress = progressNew;
    },
    removeProgress(state:SettingMakerTmpState, action: PayloadAction<string>) {
      const key = action.payload;
      const progressNew = {};
      Object.keys(state.progress).forEach(k => {
        if(key != k) {
          progressNew[k] = true;
        }
      })
      state.progress = progressNew;
    },
    setErrorMessage(state:SettingMakerTmpState, action: PayloadAction<string>) {
      state.errorMessage = action.payload;
    },
    setInfoMessage(state:SettingMakerTmpState, action: PayloadAction<string>) {
      state.infoMessage = action.payload;
    },
    setEditingStart(state:SettingMakerTmpState) {
      console.log('store.setEditingStart');
      state.editing = true;
    },
    setEditingEnd(state:SettingMakerTmpState) {
      console.log('store.setEditingEnd');

      state.editing = false;

      state.progress= {};
      state.retrievedParam= null;
      
      state.dataGroups= [];
      state.rowInfos= [];
      state.fixedRowsTop =0;
      state.infoDatas= [];
      state.rows= [];
      state.mergeCells= null;

      state.tableVer_updateSettting = state.tableVer_updateSettting+1;  //カウントアップしテーブルを再構築対象とする (updateSetting)
    
      
    },
    // setBumonList(state:SettingMakerTmpState, action: PayloadAction<CodeName[]>) {
    //   state.bumonList = action.payload;
    // },
    setMakerList(state:SettingMakerTmpState, action: PayloadAction<CodeName[]>) {
      state.makerList = action.payload;
    },
    setAreaList(state:SettingMakerTmpState, action: PayloadAction<CodeName[]>) {
      state.areaList = action.payload;
    },
    setCenterList(state:SettingMakerTmpState, action: PayloadAction<CodeName[]>) {
      state.centerList = action.payload;
    },
    setRetrievedParam(state:SettingMakerTmpState, action: PayloadAction<settingMakerRetrieve.RequestParam>) {
      state.retrievedParam = action.payload;
    },

    searched(state:SettingMakerTmpState, action: PayloadAction<{param: settingMakerRetrieve.RequestParam, centerMakers: CenterMaker[], infos: InfoData[], colRowModel:SettingMakerColRowModel, listSortOrder:CodeName, listSortOrderDesc:boolean, visibleRowsKey:string[]}>) {
      const setOldData = (data: InfoData) => {
        data.oldBlInputFlg = data.blInputFlg ? data.blInputFlg : false;
        data.oldLeadTimeFlg = data.leadTimeFlg ? data.leadTimeFlg : false;
        data.oldLeadTimeDays = data.leadTimeDays ? data.leadTimeDays : null;
        data.oldLeadTimeExcludeSaturdayFlg = data.leadTimeExcludeSaturdayFlg ? data.leadTimeExcludeSaturdayFlg : false;
        data.oldLeadTimeContainHolidayFlg = data.leadTimeContainHolidayFlg ? data.leadTimeContainHolidayFlg : false;
        data.oldSpecifiedDateFlg = data.specifiedDateFlg ? data.specifiedDateFlg : false;
        data.oldSpecifiedDateSunFlg = data.specifiedDateSunFlg ? data.specifiedDateSunFlg : false;
        data.oldSpecifiedDateMonFlg = data.specifiedDateMonFlg ? data.specifiedDateMonFlg : false;
        data.oldSpecifiedDateTueFlg = data.specifiedDateTueFlg ? data.specifiedDateTueFlg : false;
        data.oldSpecifiedDateWedFlg = data.specifiedDateWedFlg ? data.specifiedDateWedFlg : false;
        data.oldSpecifiedDateThrFlg = data.specifiedDateThrFlg ? data.specifiedDateThrFlg : false;
        data.oldSpecifiedDateFriFlg = data.specifiedDateFriFlg ? data.specifiedDateFriFlg : false;
        data.oldSpecifiedDateSatFlg = data.specifiedDateSatFlg ? data.specifiedDateSatFlg : false;
        data.oldSpecifiedDateHolFlg = data.specifiedDateHolFlg ? data.specifiedDateHolFlg : false;
        data.oldMinQtyFlg = data.minQtyFlg ? data.minQtyFlg : false;
        data.oldMinQtyCs = data.minQtyCs ? data.minQtyCs : null;
        data.oldMaxQtyFlg = data.maxQtyFlg ? data.maxQtyFlg : false;
        data.oldMaxQtyCs = data.maxQtyCs ? data.maxQtyCs : null;
        data.oldCloseFlg = data.closeFlg ? data.closeFlg : false;
        data.oldCloseTime = data.closeTime ? data.closeTime : null;
        data.oldAutoOrderFlg = data.autoOrderFlg ? data.autoOrderFlg : false;
        data.oldAutoOrderExecDays = data.autoOrderExecDays ? data.autoOrderExecDays : null;
        data.oldAutoOrderDays = data.autoOrderDays ? data.autoOrderDays : null;
        data.oldAutoOrderContainSundayFlg = data.autoOrderContainSundayFlg ? data.autoOrderContainSundayFlg : false;
        data.oldFractionRounding = data.fractionRounding ? data.fractionRounding : null;
        data.oldMakerCenterName = data.makerCenterName ? data.makerCenterName : null;
      }
      const colRowModel = action.payload.colRowModel;
      const param = action.payload.param;

      let centerMakers = parseDataCenterMaker(action.payload.centerMakers);
      let infos = parseDataInfoData(action.payload.infos);
      //編集前のデータをセット
      infos.forEach(data => setOldData(data));
      infos = checkDatas(infos); //データチェック

      const listSortOrder = action.payload.listSortOrder;
      const listSortOrderDesc = action.payload.listSortOrderDesc;
      const visibleRowsKey = action.payload.visibleRowsKey;
      
      let dataGroups = convertDataGroups(centerMakers);
      dataGroups = sortDataGroups(dataGroups, listSortOrder, listSortOrderDesc);
      const [rowInfos, fixedRowsTop, newinfos] = convertRowInfos(dataGroups, listSortOrder, listSortOrderDesc, visibleRowsKey, infos);
      //新規も編集前のデータをセットをセット
      newinfos.forEach(data => setOldData(data));

      //store更新
      state.dataGroups = dataGroups;
      state.rowInfos = rowInfos;
      state.fixedRowsTop = fixedRowsTop;
      state.infoDatas = newinfos;
      state.rows = convertRows(rowInfos, colRowModel, listSortOrder, listSortOrderDesc);
      state.mergeCells = createMergeCells(rowInfos, colRowModel);

      state.tableVer_updateSettting = state.tableVer_updateSettting+1;  //カウントアップしテーブルを再構築対象とする (updateSetting)
    },
    refreshTable(state:SettingMakerTmpState, action: PayloadAction<{colRowModel:SettingMakerColRowModel, listSortOrder:CodeName, listSortOrderDesc:boolean, visibleRowsKey:string[]}>){
      console.log('refreshTable');
      const listSortOrder = action.payload.listSortOrder;
      const listSortOrderDesc = action.payload.listSortOrderDesc;
      const visibleRowsKey = action.payload.visibleRowsKey;
      const colRowModel = action.payload.colRowModel;
      let dataGroups = state.dataGroups;
      dataGroups = sortDataGroups(dataGroups, listSortOrder, listSortOrderDesc);
      const [rowInfos, fixedRowsTop, newinfos] = convertRowInfos(dataGroups, listSortOrder, listSortOrderDesc, visibleRowsKey, state.infoDatas);
      //store更新
      state.dataGroups = dataGroups;
      state.rowInfos = rowInfos;
      state.fixedRowsTop = fixedRowsTop;
      state.infoDatas = newinfos;
      state.rows = convertRows(rowInfos, colRowModel, listSortOrder, listSortOrderDesc);
      state.mergeCells = createMergeCells(rowInfos, colRowModel);

      state.tableVer_updateSettting = state.tableVer_updateSettting+1;  //カウントアップしテーブルを再構築対象とする (updateSetting)
    },

    editRowDatas(state:SettingMakerTmpState, action: PayloadAction<{colRowModel:SettingMakerColRowModel, listSortOrder:CodeName, listSortOrderDesc:boolean, visibleRowsKey:string[], editDatas:EditCellInfo[]}>) {
      console.log('store.editRowDatas');
      const listSortOrder = action.payload.listSortOrder;
      const listSortOrderDesc = action.payload.listSortOrderDesc;
      const visibleRowsKey = action.payload.visibleRowsKey;
      const colRowModel = action.payload.colRowModel;
      const editDatas = action.payload.editDatas;

      // const newInfoDatas = [...state.infoDatas];
      const newInfoDatas = state.infoDatas;
      const newRows = state.rows;

      let targetChange = false;

      let editedRows:Set<number> = new Set<number>();
      editDatas.forEach((editData)=>{
        const rowInfo:RowInfo = state.rowInfos[editData.row];
        if(!rowInfo) {
          return;
        }
        const dataGroup = rowInfo.dataGroup;
        const infoData = rowInfo.infoData;
        const infoDataIndex = rowInfo.infoDataIndex;
        if(!dataGroup || !infoData) {
          return;
        }

        const colKey:string = colRowModel.keyFromCol(editData.col);
        const rowKey:string = rowInfo.rowKey;

        let dataType = rowDataType[rowKey];
        if(!dataType) {
          dataType = colDataType[colKey];
        }

        const value = 
          colKey === 'fractionRounding' ? fractionRoundingIdFromLabel(editData.value) :
          editorUtil.parseValue(editData.value, dataType.type,
            dataType.type == 'numeric' ? dataType.numericFormat.pattern :
            dataType.type == 'date' ? dataType.dateFormat :
            dataType.type == 'time' ? dataType.timeFormat :
            null);

        if(colKey == 'target') {
          const rowTop = rowInfo.row - rowInfo.rowIndex;
          const rowInfoTop = state.rowInfos[rowTop];
          const dataGroupTop = rowInfoTop.dataGroup;
          dataGroupTop.target = value == true;
          editedRows.add(rowTop);
          rowInfoTop.dataGroup = dataGroupTop;
          state.rowInfos[rowTop] = rowInfoTop;

          targetChange = true;
        }
        else {
          newInfoDatas[infoDataIndex][colKey] = value;
          editedRows.add(editData.row);
          newInfoDatas[infoDataIndex] = checkData(newInfoDatas[infoDataIndex]);
          state.rowInfos[editData.row].infoData = newInfoDatas[infoDataIndex];
        }  
      });

      //選択有無を記録する
      if(targetChange) {
        const rowInfoBulk = state.rowInfos[0];
        const dataGroupBulk = rowInfoBulk.dataGroup;
        dataGroupBulk.hasChildTarget = !(!(state.rowInfos.find(rowInfo => rowInfo.dataGroup?.target)));
        rowInfoBulk.dataGroup = dataGroupBulk;
      }

      if(editedRows.size > 0) {
        const tableVer_updateData_rows = [];
        editedRows.forEach(row => {
          tableVer_updateData_rows.push(row);
          newRows[row] = convertRow(state.rowInfos[row], colRowModel, listSortOrder, listSortOrderDesc);
        });
        // state.rows = newRows;

        /*
        const [rowInfos, fixedRowsTop, newinfos] = convertRowInfos(state.dataGroups, listSortOrder, listSortOrderDesc, visibleRowsKey, newInfoDatas);
        
        // //store更新
        state.rowInfos = rowInfos;
        state.fixedRowsTop = fixedRowsTop;
        state.infoDatas = newinfos;
        state.rows = convertRows(rowInfos, colRowModel);
        state.mergeCells = createMergeCells(rowInfos, colRowModel);
        */

        state.tableVer_updateData_rows = tableVer_updateData_rows;
        state.tableVer_updateData = state.tableVer_updateData+1;  //カウントアップしテーブルを再描写対象とする (updateData)
      }
    },
    setFunctionKey(state:SettingMakerTmpState, action: PayloadAction<string>) {
      state.functionKeys = action.payload ? [action.payload] : [];
    },
}};

const parseDataCenterMaker = (datas:CenterMaker[]): CenterMaker[] => {
  datas = [...datas];
  datas.forEach((data) => {
  });
  return datas;
}
const parseDataInfoData = (datas:InfoData[]): InfoData[] => {
  datas = [...datas];
  datas.forEach((data) => {
    if(typeof data.leadTimeDays === 'string') data.leadTimeDays = parseInt(data.leadTimeDays);
    if(typeof data.minQtyCs === 'string') data.minQtyCs = parseInt(data.minQtyCs);
    if(typeof data.maxQtyCs === 'string') data.maxQtyCs = parseInt(data.maxQtyCs);
    if(typeof data.autoOrderDays === 'string') data.autoOrderDays = parseInt(data.autoOrderDays);
    if(typeof data.autoOrderExecDays === 'string') data.autoOrderExecDays = parseInt(data.autoOrderExecDays);
  });
  return datas;
}

//チェック
const checkDatas = (datas:InfoData[]): InfoData[] => {
  console.log('store.checkDatas');
  return datas.map(data => checkData(data));
}
const checkData = (data: InfoData): InfoData => {
  //編集状態をセットする
  // Object.keys(data).forEach(key => {
    data.edited = isEditedRowData(data);
  // });


  const errorInfo: ErrorInfoData = {};
  if (!data.errorInfo) {
    data.errorInfo = {};
  }

  if (data.leadTimeFlg) {
    if ((data.leadTimeDays === undefined || data.leadTimeDays === null)) {
      errorInfo.leadTimeDays = '入力してください';
    }
    else if (data.leadTimeDays < 1) {
      errorInfo.leadTimeDays = '1以上を入力してください';
    }
  }

  if (data.specifiedDateFlg) {
    if (
      !data.specifiedDateSunFlg &&
      !data.specifiedDateMonFlg &&
      !data.specifiedDateTueFlg &&
      !data.specifiedDateWedFlg &&
      !data.specifiedDateThrFlg &&
      !data.specifiedDateFriFlg &&
      !data.specifiedDateSatFlg
      // !data.specifiedDateHolFlg
    ) {
      errorInfo.specifiedDateSunFlg = 'いずれかを指定してください';
      errorInfo.specifiedDateMonFlg = 'いずれかを指定してください';
      errorInfo.specifiedDateTueFlg = 'いずれかを指定してください';
      errorInfo.specifiedDateWedFlg = 'いずれかを指定してください';
      errorInfo.specifiedDateThrFlg = 'いずれかを指定してください';
      errorInfo.specifiedDateFriFlg = 'いずれかを指定してください';
      errorInfo.specifiedDateSatFlg = 'いずれかを指定してください';
      // errorInfo.specifiedDateHolFlg = 'いずれかを指定してください';
    }
  }

  if(data.minQtyFlg) {
    if ((data.minQtyCs === undefined || data.minQtyCs === null)) {
      errorInfo.minQtyCs = '入力してください';
    }
    else if (data.minQtyCs < 1) {
      errorInfo.minQtyCs = '1以上を入力してください';
    }
  }

  if(data.maxQtyFlg) {
    if ((data.maxQtyCs === undefined || data.maxQtyCs === null)) {
      errorInfo.maxQtyCs = '入力してください';
    }
    else if (data.maxQtyCs < 1) {
      errorInfo.maxQtyCs = '1以上を入力してください';
    }
  }

  if(data.closeFlg) {
    if ((data.closeTime === undefined || data.closeTime === null)) {
      errorInfo.closeTime = '入力してください';
    }
  }

  if(data.autoOrderFlg) {
    if ((data.autoOrderExecDays === undefined || data.autoOrderExecDays === null)) {
      errorInfo.autoOrderExecDays = '入力してください';
    }
    else if (data.autoOrderExecDays < 0) {
      errorInfo.autoOrderExecDays = '0以上を入力してください';
    }
    else if (data.autoOrderExecDays > 21) {
      errorInfo.autoOrderExecDays = '21以下を入力してください';
    }
    // else if (data.autoOrderExecDays > data.autoOrderDays) {
    //   errorInfo.autoOrderExecDays = '発注在庫日数以下を指定してください';
    // }

    if ((data.autoOrderDays === undefined || data.autoOrderDays === null)) {
      errorInfo.autoOrderDays = '入力してください';
    }
    else if (data.autoOrderDays < 1) {
      errorInfo.autoOrderDays = '1以上を入力してください';
    }
    else if (data.autoOrderDays > 21) {
      errorInfo.autoOrderDays = '21以下を入力してください';
    }
  }

  data.errorInfo = errorInfo;

  return data;
}

//RowDataGroupに変換
const convertDataGroups = (centerMakers: CenterMaker[]): RowDataGroup[] => {
  //メーカー、センター順でソート
  centerMakers = centerMakers.sort((a,b) => {
    const objA = a;
    const objB = b;
    let comp = 0;
    comp = compareUtil.compareString(a.makerCD, b.makerCD, true)
    if(comp == 0) {
      comp = compareUtil.compareString(a.centerCD, b.centerCD, true)
    }
    return comp;
  });

  return centerMakers.map(centerMaker => {
    return {
      centerMaker: centerMaker,
    };
  });
}
//並び順変更
const sortDataGroups = (dataGroups:RowDataGroup[], listSortOrder:CodeName, listSortOrderDesc:boolean): RowDataGroup[] => {
  if(!dataGroups) {
    return dataGroups;
  }
  let asc = !listSortOrderDesc;
  let getSortKey1 = (o:RowDataGroup):string|number => 
    !o || !o.centerMaker ? null : 
    !listSortOrder ? o.centerMaker.makerCD :
    listSortOrder.code == 'makerCD' ? o.centerMaker.makerCD :
    listSortOrder.code == 'makerNM' ? o.centerMaker.makerNM :
    listSortOrder.code == 'makerCD_area' ? o.centerMaker.makerCD :
    listSortOrder.code == 'makerNM_area' ? o.centerMaker.makerNM :
    listSortOrder.code == 'makerCD_pref' ? o.centerMaker.makerCD :
    listSortOrder.code == 'makerNM_pref' ? o.centerMaker.makerNM :
    listSortOrder.code == 'center' ? o.centerMaker.centerCD :
    listSortOrder.code == 'area' ? o.centerMaker.areaCD :
    listSortOrder.code == 'pref' ? o.centerMaker.prefCD :
    o.centerMaker.makerCD
  ;
  let getSortKey2 = (o:RowDataGroup):string|number => 
    !o || !o.centerMaker ? null : 
    !listSortOrder ? o.centerMaker.makerCD :
    listSortOrder.group_code == 'maker' ? o.centerMaker.makerCD :
    listSortOrder.group_code == 'center' ? o.centerMaker.centerCD :
    o.centerMaker.makerCD
  ;
  let getSortKey3 = (o:RowDataGroup):string|number => 
    !o || !o.centerMaker ? null : 
    !listSortOrder ? o.centerMaker.makerCD :
    listSortOrder.code == 'makerCD' ? o.centerMaker.centerCD :
    listSortOrder.code == 'makerNM' ? o.centerMaker.centerCD :
    listSortOrder.code == 'makerCD_area' ? o.centerMaker.areaCD :
    listSortOrder.code == 'makerNM_area' ? o.centerMaker.areaCD :
    listSortOrder.code == 'makerCD_pref' ? o.centerMaker.prefCD :
    listSortOrder.code == 'makerNM_pref' ? o.centerMaker.prefCD :
    listSortOrder.code == 'center' ? o.centerMaker.makerCD :
    listSortOrder.code == 'area' ? o.centerMaker.makerCD :
    listSortOrder.code == 'pref' ? o.centerMaker.makerCD :
    o.centerMaker.centerCD
  ;
  dataGroups.sort((a, b) => {
    //第1弾ソート項目
    let va = getSortKey1(a);
    let vb = getSortKey1(b);
    let comp = compareUtil.compareAny(va, vb, asc);
    //第2弾ソート項目
    if(comp == 0) {
      va = getSortKey2(a);
      vb = getSortKey2(b);
      comp = compareUtil.compareAny(va, vb, asc);
    }
    //第3弾ソート項目
    if(comp == 0) {
      va = getSortKey3(a);
      vb = getSortKey3(b);
      comp = compareUtil.compareAny(va, vb, asc);
    }
    return comp;
  });
  return dataGroups;
}
  //行情報に変換
const convertRowInfos = (dataGroups:RowDataGroup[], listSortOrder:CodeName, listSortOrderDesc:boolean, visibleRowsKey:string[], infoDatas: InfoData[]): [RowInfo[], number, InfoData[]] => {
  let newInfoDatas: InfoData[] = [...infoDatas];
  //map作成
  const infoDataMap = {};
  const infoDataIndexMap = {};
  newInfoDatas.forEach((infoData, index) => {
    const key = `${infoData.makerCD} ${infoData.centerCD} ${infoData.tagCD}`;
    infoDataMap[key] = infoData;
    infoDataIndexMap[key] = index;
  });

  const targetRowsKeys = arrayutil.and(rowKeys, visibleRowsKey);

  const rowKeyInfos:{
    rowKey: string,
    rowIndex:number,
    rowIndexLast:boolean,
  }[] = [];
  targetRowsKeys.forEach((visibleRowKey, index) => {
    rowKeyInfos.push({
      rowKey: visibleRowKey,
      rowIndex:index,
      rowIndexLast: targetRowsKeys.length - 1 == index,
    });
  });

  const rowInfos:RowInfo[] = [];
  let fixedRowsTop:number = 0;


  //一番上に一括入力欄を用意する
  const dataGroupBulk:RowDataGroup = {
    centerMaker:{
      makerCD:"bulk",
      centerCD:"bulk",
    }
  };
  rowKeyInfos.forEach((rowKeyInfo) => {
    const centerMaker = dataGroupBulk.centerMaker;
    const key = `${centerMaker.makerCD} ${centerMaker.centerCD} ${rowKeyInfo.rowKey}`;
    let infoData:InfoData = {
      makerCD: centerMaker.makerCD,
      centerCD: centerMaker.centerCD,
      tagCD: rowKeyInfo.rowKey,
    };
    let infoDataIndex = newInfoDatas.length;

    newInfoDatas.push(infoData);
    infoDataMap[key] = newInfoDatas;
    infoDataIndexMap[key] = infoDataIndex;

    rowInfos.push({
      ...{
        TP: "bulk",
        no: `${no}`,
        subno: `${subno}`,
        dataGroup: dataGroupBulk,
        infoData: infoData,
        infoDataIndex: infoDataIndex,
        row: rowInfos.length,
      }, 
      ...rowKeyInfo
    });
  })





  //明細行
  fixedRowsTop = rowInfos.length;


  let beforeDataGroup:RowDataGroup;
  const grouping = (!listSortOrder || listSortOrder.group_code == 'maker') ? 'maker' : 'center';
  let no = 0;
  let subno = 0;
  dataGroups.forEach(dataGroup => {
    // グルーピング
    if(grouping == 'maker' && (!beforeDataGroup || beforeDataGroup.centerMaker.makerCD != dataGroup.centerMaker.makerCD)) {
      no++;
      subno = 0;
      rowInfos.push({
        TP: "maker",
        no: `${no}`,
        dataGroup: dataGroup,
        row: rowInfos.length,
      });
    }
    else if(grouping == 'center' && (!beforeDataGroup || beforeDataGroup.centerMaker.centerCD != dataGroup.centerMaker.centerCD)) {
      no++;
      subno = 0;
      rowInfos.push({
        TP: "center",
        no: `${no}`,
        dataGroup: dataGroup,
        row: rowInfos.length,
      });
    }

    //明細行
    subno++;
    rowKeyInfos.forEach((rowKeyInfo) => {
      const centerMaker = dataGroup.centerMaker;
      const key = `${centerMaker.makerCD} ${centerMaker.centerCD} ${rowKeyInfo.rowKey}`;
      let infoData:InfoData = infoDataMap[key];
      let infoDataIndex:number = infoDataIndexMap[key];
      //未登録レコードは新規作成
      if(!infoData) {
        infoData = {
          makerCD: centerMaker.makerCD,
          centerCD: centerMaker.centerCD,
          tagCD: rowKeyInfo.rowKey,
        };
        infoDataIndex = newInfoDatas.length;

        newInfoDatas.push(infoData);
        infoDataMap[key] = newInfoDatas;
        infoDataIndexMap[key] = infoDataIndex;
      }

      rowInfos.push({
        ...{
          TP: "info",
          no: `${no}`,
          subno: `${subno}`,
          dataGroup: dataGroup,
          infoData: infoData,
          infoDataIndex: infoDataIndex,
          row: rowInfos.length,
        }, 
        ...rowKeyInfo
      });
    })

    beforeDataGroup = dataGroup;
  });
  return [rowInfos, fixedRowsTop, newInfoDatas];
}
//配列データに変換
const convertRows = (rowInfos:RowInfo[], colRowModel:SettingMakerColRowModel, listSortOrder:CodeName, listSortOrderDesc:boolean): any[][] => {
  return rowInfos.map((rowInfo) => convertRow(rowInfo, colRowModel, listSortOrder, listSortOrderDesc));
}
//配列データに変換
const convertRow = (rowInfo:RowInfo, colRowModel:SettingMakerColRowModel, listSortOrder:CodeName, listSortOrderDesc:boolean): any[] => {
  //set No.
  const dataGroup:RowDataGroup = rowInfo.dataGroup;
  return colRowModel.colKeys.map(colKey => valueFromKey(colKey, rowInfo, dataGroup, listSortOrder));
}
export const valueFromKey = (colKey: string, rowInfo: RowInfo, dataGroup: RowDataGroup, listSortOrder: CodeName): any => {
  //一括
  if (rowInfo.TP == "bulk") {

    //明細行
    switch (colKey) {
      case "target":
        return rowInfo.dataGroup?.target;
      case "no":
        return "";
      case "code":
      case "name":
      case "area":
      case "pref":
        return null;
      case "rowHeader":
        return headersRow[rowInfo.rowKey];

      case "blInputFlg":
        return rowInfo.infoData.blInputFlg;

      case "leadTimeFlg":
        return rowInfo.infoData.leadTimeFlg;
      case "leadTimeDays":
        return rowInfo.infoData.leadTimeDays;
      case "leadTimeExcludeSaturdayFlg":
          return rowInfo.infoData.leadTimeExcludeSaturdayFlg;
      case "leadTimeContainHolidayFlg":
        return rowInfo.infoData.leadTimeContainHolidayFlg;

      case "specifiedDateFlg":
        return rowInfo.infoData.specifiedDateFlg;
      case "specifiedDateSunFlg":
        return rowInfo.infoData.specifiedDateSunFlg;
      case "specifiedDateMonFlg":
        return rowInfo.infoData.specifiedDateMonFlg;
      case "specifiedDateTueFlg":
        return rowInfo.infoData.specifiedDateTueFlg;
      case "specifiedDateWedFlg":
        return rowInfo.infoData.specifiedDateWedFlg;
      case "specifiedDateThrFlg":
        return rowInfo.infoData.specifiedDateThrFlg;
      case "specifiedDateFriFlg":
        return rowInfo.infoData.specifiedDateFriFlg;
      case "specifiedDateSatFlg":
        return rowInfo.infoData.specifiedDateSatFlg;
      case "specifiedDateHolFlg":
        return rowInfo.infoData.specifiedDateHolFlg;
  
      case "minQtyFlg":
        return rowInfo.infoData.minQtyFlg;
      case "minQtyCs":
        return rowInfo.infoData.minQtyCs;
      case "maxQtyFlg":
        return rowInfo.infoData.maxQtyFlg;
      case "maxQtyCs":
        return rowInfo.infoData.maxQtyCs;
      case "closeFlg":
        return rowInfo.infoData.closeFlg;
      case "closeTime":
        return rowInfo.infoData.closeTime;
  
      case "autoOrderFlg":
        return rowInfo.infoData.autoOrderFlg;
      case "autoOrderExecDays":
        return rowInfo.infoData.autoOrderExecDays;
      case "autoOrderDays":
        return rowInfo.infoData.autoOrderDays;
      case "autoOrderContainSundayFlg":
        return rowInfo.infoData.autoOrderContainSundayFlg;

      case "fractionRounding":
        return rowInfo.infoData.fractionRounding;

      case "makerCenterName":
        return rowInfo.infoData.makerCenterName;
          
      default: {
        return dataGroup[colKey];
      }
    }
  }


  //グループ行
  if (rowInfo.TP == "center" || rowInfo.TP == "maker") {
    switch (colKey) {
      case "no":
        return rowInfo.no;
      case "target":
        return "";
      case "code":
        return rowInfo.TP == "center" ? dataGroup.centerMaker.centerCD2 : rowInfo.TP == "maker" ? dataGroup.centerMaker.makerCD : null;
      case "name":
        return rowInfo.TP == "center" ? dataGroup.centerMaker.centerNM : rowInfo.TP == "maker" ? dataGroup.centerMaker.makerNM : null;
      case "area":
        return rowInfo.TP == "center" ? dataGroup.centerMaker.areaNM : null;
      case "pref":
        return rowInfo.TP == "center" ? dataGroup.centerMaker.prefNM : null;
      case "rowHeader":
        return null;
      default: {
        return null;
      }
    }
  }
  if (rowInfo.TP == "info") {
    //明細行
    switch (colKey) {
      case "target":
        return rowInfo.dataGroup?.target;
      case "no":
        return `${rowInfo.no}-${rowInfo.subno}`;
      case "code":
        return listSortOrder.group_code == "center" ? dataGroup.centerMaker.makerCD : listSortOrder.group_code == "maker" ? dataGroup.centerMaker.centerCD2 : null;
      case "name":
        return listSortOrder.group_code == "center" ? dataGroup.centerMaker.makerNM : listSortOrder.group_code == "maker" ? dataGroup.centerMaker.centerNM : null;
      case "area":
        return listSortOrder.group_code == "maker" ? dataGroup.centerMaker.areaNM : null;
      case "pref":
        return listSortOrder.group_code == "maker" ? dataGroup.centerMaker.prefNM : null;
      case "rowHeader":
        return headersRow[rowInfo.rowKey];

      case "blInputFlg":
        return rowInfo.infoData.blInputFlg;

      case "leadTimeFlg":
        return rowInfo.infoData.leadTimeFlg;
      case "leadTimeDays":
        return rowInfo.infoData.leadTimeDays;
      case "leadTimeExcludeSaturdayFlg":
        return rowInfo.infoData.leadTimeExcludeSaturdayFlg;
      case "leadTimeContainHolidayFlg":
        return rowInfo.infoData.leadTimeContainHolidayFlg;

      case "specifiedDateFlg":
        return rowInfo.infoData.specifiedDateFlg;
      case "specifiedDateSunFlg":
        return rowInfo.infoData.specifiedDateSunFlg;
      case "specifiedDateMonFlg":
        return rowInfo.infoData.specifiedDateMonFlg;
      case "specifiedDateTueFlg":
        return rowInfo.infoData.specifiedDateTueFlg;
      case "specifiedDateWedFlg":
        return rowInfo.infoData.specifiedDateWedFlg;
      case "specifiedDateThrFlg":
        return rowInfo.infoData.specifiedDateThrFlg;
      case "specifiedDateFriFlg":
        return rowInfo.infoData.specifiedDateFriFlg;
      case "specifiedDateSatFlg":
        return rowInfo.infoData.specifiedDateSatFlg;
      case "specifiedDateHolFlg":
        return rowInfo.infoData.specifiedDateHolFlg;
  
      case "minQtyFlg":
        return rowInfo.infoData.minQtyFlg;
      case "minQtyCs":
        return rowInfo.infoData.minQtyCs;
      case "maxQtyFlg":
        return rowInfo.infoData.maxQtyFlg;
      case "maxQtyCs":
        return rowInfo.infoData.maxQtyCs;
      case "closeFlg":
        return rowInfo.infoData.closeFlg;
      case "closeTime":
        return rowInfo.infoData.closeTime;
  
      case "autoOrderFlg":
        return rowInfo.infoData.autoOrderFlg;
      case "autoOrderExecDays":
        return rowInfo.infoData.autoOrderExecDays;
      case "autoOrderDays":
        return rowInfo.infoData.autoOrderDays;
      case "autoOrderContainSundayFlg":
        return rowInfo.infoData.autoOrderContainSundayFlg;

      case "fractionRounding":
        return rowInfo.infoData.fractionRounding;
  
      case "makerCenterName":
        return rowInfo.infoData.makerCenterName;
          
      default: {
        return dataGroup[colKey];
      }
    }
  }
  return null;
}

//マージを作成
const createMergeCells = (rowInfos:RowInfo[], colRowModel:SettingMakerColRowModel): {row: number, col: number, rowspan: number, colspan: number}[] => {
  let mergeCells:{row: number, col: number, rowspan: number, colspan: number}[] = [];
  return mergeCells;
}


export const getOptionLabel = (option: CodeName) => {
  return option && option.name ? (option.code + ' ' + option.name) : "";
}

const createSliceContent = (name:string) => createSlice({
  name: name,
  initialState,
  reducers: createReducerContent(),
});

//Page Slice Export
//settingMakerTmp
export const settingMakerTmpSlice = createSliceContent("settingMakerTmp");
