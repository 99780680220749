import moment from "moment";
moment.updateLocale("ja", {
  months: ["1月","2月","3月","4月","5月","6月","7月","8月","9月","10月","11月","12月",],
  weekdays: ["日曜日", "月曜日", "火曜日", "水曜日", "木曜日", "金曜日", "土曜日"],
  weekdaysShort: ["日", "月", "火", "水", "木", "金", "土"]
});

//ヘッダー列
export const headerColKeys = [
  'no',
  'targetFlg', 
  'center',
  'itemCD',
];
//行ヘッダー列
export const rowHeaderColKeys = [
  // 'rowHeader', 
];
//Body列
export const bodyColKeys = [
  'tokuisakiItemCD',

  'area',
  'pref',
  'itemNM',
  'janCD',
  'makerCD',
  'makerNM',
  'shiireFAX',
  'categoryCD',
  'categoryNM',
  'blIrisu',
  'csIrisu',
  // 'lot',
  // 'csIrisu_lot',
  'capacity',
  'bestBefore',
  'teika',
  'tatene',
  'loadedQty',

  'status', //ステータス
  'nouhinDate', //納品日
  'numCS', //発注CS数
  'numBL', //発注BL数
  'numCSTotal', //発注CS数
  'minQtyCs',
  'maxQtyCs',
  'closeTime',
  'leadTimeDays',
  'leadTimeExcludeSaturdayFlg',
  'leadTimeContainHolidayFlg',

  'editDate', //編集日
  'editUser', //編集者

  'ifAdmsSendDate',
  'ifAdmsErrorMessage',
];
//ヘッダー列（発注不可）
export const colKeysUnOrderableHidden = [
];

export const rowKeys = ['dummy'];

export const headersCol = [
  {
    no : "No.",
    targetFlg: "対象",

    rowHeader: "項目", 

    center : "倉庫",
    itemCD : "商品CD",

    tokuisakiItemCD : "得意先",

    area : "倉庫属性",
    pref : "倉庫属性",
       
    itemNM : "商品属性",
    janCD : "商品属性",
    makerCD : "商品属性",
    makerNM : "商品属性",
    shiireFAX : "商品属性",
    categoryCD : "商品属性",
    categoryNM : "商品属性",
    blIrisu : "商品属性",
    csIrisu : "商品属性",
    lot : "商品属性",
    capacity : "商品属性",
    bestBefore : "商品属性",
    teika : "商品属性",
    tatene : "商品属性",
    loadedQty : "商品属性",


    status : "発注情報", //ステータス
    nouhinDate : "発注情報", //納品日
    numCS : "発注情報", //発注CS数
    numBL : "発注情報", //発注BL数
    numCSTotal : "発注情報", //発注CS数
    minQtyCs : "ﾒｰｶｰ発注設定", //
    maxQtyCs : "ﾒｰｶｰ発注設定", //
    closeTime : "ﾒｰｶｰ発注設定", //
    leadTimeDays: "リードタイム",
    leadTimeExcludeSaturdayFlg: "リードタイム",
    leadTimeContainHolidayFlg: "リードタイム",
    editDate : "最終編集情報", //編集日
    editUser : "最終編集情報", //編集者
    ifAdmsSendDate : "ADMS連携", //編集者
    ifAdmsErrorMessage : "ADMS連携",
  },
  {
    no : "",
    targetFlg: "",
    rowHeader: "項目", 

    center : "",
    itemCD : "",

    tokuisakiItemCD : "商品CD",

    area : "地域",
    pref : "都道府県",
   
    itemNM : "商品名",
    janCD : "JAN",
    makerCD : "ﾒｰｶｰCD",
    makerNM : "ﾒｰｶｰ名",
    shiireFAX : "仕入先FAX",
    categoryCD : "ｶﾃｺﾞﾘCD",
    categoryNM : "ｶﾃｺﾞﾘ名",
    blIrisu : "BL入数",
    csIrisu : "CS入数",
    lot : "発注単位",
    capacity : "容量",
    bestBefore : "賞味期間",
    teika : "定価",
    tatene : "建値",
    loadedQty : "積付面",

    status : "ｽﾃｰﾀｽ", //ステータス
    nouhinDate : "倉入日", //納品日
    numCS : "発注CS数", //発注CS数
    numBL : "発注BL数", //発注BL数
    numCSTotal : "累計発注CS数", //発注CS数
    minQtyCs : "最小CS数", //
    maxQtyCs : "最大CS数", //
    closeTime : "締め時刻", //
    leadTimeDays: "日数",
    leadTimeExcludeSaturdayFlg: "土曜除く",
    leadTimeContainHolidayFlg: "祝日含む",
    editDate : "最終編集日時", //編集日
    editUser : "最終編集者", //編集者
    ifAdmsSendDate : "ADMS連携日時", //編集者
    ifAdmsErrorMessage : "ADMSエラー情報",
  
  },
];

export const headersRow = {
  dummy: "dummy"
};

export const selectableCol =
{
  no : false,

  targetFlg: false,
  rowHeader: false, 

  center : false,
  itemCD : false,

  tokuisakiItemCD : true,

  area : true,
  pref : true,
     
  itemNM : true,
  janCD : true,
  makerCD : true,
  makerNM : true,
  shiireFAX : true,
  categoryCD : true,
  categoryNM : true,
  blIrisu : true,
  csIrisu : true,
  lot : true,
  capacity : true,
  bestBefore : true,
  teika : true,
  tatene : true,
  loadedQty : true,

  status : true,
  nouhinDate : false,
  numCS : false,
  numBL : false,
  numCSTotal : false,
  minQtyCs : true,
  maxQtyCs : true,
  closeTime : true,
  leadTimeDays : true,
  leadTimeExcludeSaturdayFlg : true,
  leadTimeContainHolidayFlg : true,
  editDate : true,
  editUser : true,
  ifAdmsSendDate : true,
  ifAdmsErrorMessage : true,
}
export const selectableRow = {
  dummy: false,
};

export const headerColClasses = [
  {
    no : "header_noBorder_no",
    targetFlg: "header_noBorder_target",

    rowHeader: "header_noBorder",
  
    center : "header_noBorder",
    itemCD : "header_noBorder",

    tokuisakiItemCD : "header_noBorder",

    area : "header",
    pref : "header",
       
    itemNM : "header",
    janCD : "header",
    makerCD : "header",
    makerNM : "header",
    shiireFAX : "header",
    categoryCD : "header",
    categoryNM : "header",
    blIrisu : "header",
    csIrisu : "header",
    lot : "header",
    capacity : "header",
    bestBefore : "header",
    teika : "header",
    tatene : "header",
    loadedQty : "header",

    status : "header",
    nouhinDate : "header",
    numCS : "header",
    numBL : "header",
    numCSTotal : "header",
    minQtyCs : "header",
    maxQtyCs : "header",
    closeTime : "header",
    leadTimeDays : "header",
    leadTimeExcludeSaturdayFlg : "header",
    leadTimeContainHolidayFlg : "header",
    editDate : "header",
    editUser : "header",
    ifAdmsSendDate : "header",
    ifAdmsErrorMessage : "header",
  },
  {
    no : "header_no",
    targetFlg: "header_target",

    rowHeader: "header",
  
    center : "header",
    itemCD : "header",

    tokuisakiItemCD : "header",
  
    area : "header",
    pref : "header",
       
    itemNM : "header",
    janCD : "header",
    makerCD : "header",
    makerNM : "header",
    shiireFAX : "header",
    categoryCD : "header",
    categoryNM : "header",
    blIrisu : "header",
    csIrisu : "header",
    lot : "header",
    capacity : "header",
    bestBefore : "header",
    teika : "header",
    tatene : "header",
    loadedQty : "header",

    status : "header",
    nouhinDate : "header",
    numCS : "header",
    numBL : "header",
    numCSTotal : "header",
    minQtyCs : "header",
    maxQtyCs : "header",
    closeTime : "header",
    leadTimeDays : "header",
    leadTimeExcludeSaturdayFlg : "header",
    leadTimeContainHolidayFlg : "header",
    editDate : "header",
    editUser : "header",
    ifAdmsSendDate : "header",
    ifAdmsErrorMessage : "header",
    },
];
export const headerRowClasses = {
  dummy: 'rowHeader',
};

export const colWidths =
{
  no : 40,
  targetFlg: 40,

  rowHeader: 100, 

  center : 110,
  itemCD : 95,

  tokuisakiItemCD : 120,

  area : 50,
  pref : 70,
     
  itemNM : 110,
  janCD : 120,
  makerCD : 60,
  makerNM : 100,
  shiireFAX : 100,
  categoryCD : 40,
  categoryNM : 110,
  blIrisu : 40,
  csIrisu : 40,
  lot : 30,
  capacity : 50,
  bestBefore : 40,
  teika : 50,
  tatene : 55,
  loadedQty : 55,

  status : 70,
  nouhinDate : 70,
  numCS : 70,
  numBL : 70,
  numCSTotal : 80,
  minQtyCs : 60,
  maxQtyCs : 60,
  closeTime : 60,
	leadTimeDays: 40,
  leadTimeExcludeSaturdayFlg: 50,
	leadTimeContainHolidayFlg: 50,
  editDate : 120,
  editUser : 100,
  ifAdmsSendDate : 120,
  ifAdmsErrorMessage : 120,

};

export const colDataType =
{
  no : {type: 'text', readOnly: true,},
  targetFlg: {type: 'checkbox', checkedTemplate: true, uncheckedTemplate: false, readOnly: false, className: 'htCenter'},

  rowHeader: {type: 'text', readOnly: true,}, 

  center : {type: 'text', readOnly: true,}, 
  itemCD : {type: 'text', readOnly: true, className: 'htCenter',}, 

  tokuisakiItemCD : {type: 'text', readOnly: true, className: 'htCenter',}, 

  area : {type: 'text', readOnly: true,}, 
  pref : {type: 'text', readOnly: true,}, 
     
  itemNM : {type: 'text', readOnly: true,}, 
  janCD : {type: 'text', readOnly: true,}, 
  makerCD : {type: 'text', readOnly: true, className: 'htCenter',}, 
  makerNM : {type: 'text', readOnly: true,}, 
  shiireFAX : {type: 'text', readOnly: true,}, 
  categoryCD : {type: 'text', readOnly: true, className: 'htCenter',}, 
  categoryNM : {type: 'text', readOnly: true,}, 
  blIrisu : {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  csIrisu : {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  lot : {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  capacity : {type: 'text', readOnly: true, className: 'htRight',}, 
  bestBefore : {type: 'text', readOnly: true, className: 'htRight',}, 
  teika : {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},}, 
  tatene : {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0.00',},},
  loadedQty : {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},}, 

  status : {type: 'text', readOnly: true, className: 'htCenter',}, 
  nouhinDate : {type: 'text', readOnly: true, className: 'htCenter',}, 
  numCS : {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0.0',},}, 
  numBL : {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0.0',},}, 
  numCSTotal : {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0.0',},}, 
  minQtyCs : {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0.0',},}, 
  maxQtyCs : {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0.0',},}, 
  closeTime : {type: 'text', readOnly: true, className: 'htCenter',}, 
  leadTimeDays : {type: 'text', readOnly: true, className: 'htRight',}, 
  leadTimeExcludeSaturdayFlg : {type: 'text', readOnly: true, className: 'htCenter',}, 
	leadTimeContainHolidayFlg : {type: 'text', readOnly: true, className: 'htCenter',}, 
  editDate : {type: 'text', readOnly: true, className: 'htCenter',}, 
  editUser : {type: 'text', readOnly: true,}, 
  ifAdmsSendDate : {type: 'text', readOnly: true, className: 'htCenter',}, 
  ifAdmsErrorMessage : {type: 'text', readOnly: true,}, 

};

export const rowDataType = {};

//列定義情報（動的パラメータ）
interface OrderInstructionColRowModelInterface {
  //列固定
  fixedColumnsLeft: number,

  //テーブルの各列のキー
  colKeys: string[],
  //発注不可時非表示列
  colKeysUnOrderableHidden: string[];
  //テーブルの各行のキー
  rowKeys: string[],
}

export class OrderInstructionColRowModel implements OrderInstructionColRowModelInterface {
  //列固定
  fixedColumnsLeft: number;
  //テーブルの各列のキー
  colKeys: string[];
  //発注不可時非表示列
  colKeysUnOrderableHidden: string[];
  //テーブルの各列のキー
  rowKeys: string[];
  //ヘッダー
  nestedHeaders; //any[][]
  nestedHeadersBackup; //any[][]
  //ヘッダー区切り
  nestedHeadersGroupIndex: number[];
  //テーブルの各列の逆引き用マップ
  colKeysMap;
  //テーブルの各行の逆引き用マップ
  rowKeysMap;

  constructor(param: OrderInstructionColRowModelInterface) {
    this.fixedColumnsLeft = param.fixedColumnsLeft;
    this.colKeys = param.colKeys;
    this.rowKeys = param.rowKeys;
    this.colKeysUnOrderableHidden = param.colKeysUnOrderableHidden;

    //テーブルの各列のキー逆引き
    const colKeysMap = {};
    param.colKeys.forEach((colKey, index) => {
      colKeysMap[colKey] = index;
    });
    this.colKeysMap = colKeysMap;
    //テーブルの各行のキー逆引き
    const rowKeysMap = {};
    param.rowKeys.forEach((rowKey, index) => {
      rowKeysMap[rowKey] = index;
    });
    this.rowKeysMap = rowKeysMap;

    //nestedHeaders初期化
    this.initNestedHeaders();
  }

  //nestedHeaders初期化
  initNestedHeaders() {
    //nestedHeaders
    const nestedHeaders = [];
    const nestedHeadersGroupIndex = [];
    // const customBorders = [];
    headersCol.forEach((header, index) => {
      const lastHeader = index == headersCol.length - 1;
      const nestedHeader = [];

      let colspan=0;
      let preHeaderVal = null;
      this.colKeys.forEach((colKey) => {
        const headerVal = header[colKey];
        if(lastHeader) {
          nestedHeader.push(headerVal);
        }
        else {
          if(preHeaderVal !== null && preHeaderVal != headerVal){
            nestedHeader.push(colspan == 1 ? preHeaderVal : {label: preHeaderVal, colspan: colspan});
            colspan=0;
          }
          colspan++;
          preHeaderVal = headerVal;
        }
        //0行目でグループ
        if(index == 0) {
          nestedHeadersGroupIndex.push(colspan-1);
        }
      });
      if(!lastHeader && colspan > 0) {
        nestedHeader.push(colspan == 1 ? preHeaderVal : {label: preHeaderVal, colspan: colspan});
      }

      nestedHeaders.push(nestedHeader);
    });

    this.nestedHeaders = nestedHeaders;
    this.nestedHeadersBackup = nestedHeaders.map(nestedHeadersSub => [...nestedHeadersSub]);
    this.nestedHeadersGroupIndex = nestedHeadersGroupIndex;
  }

  //function
  keyFromCol(column:number):string {
    return this.colKeys[column];
  }
  keyFromCols(columns:number[]):string[] {
    const vals:string[] = [];
    columns.forEach(column => vals.push(this.colKeys[column]))
    return vals;
  }
  colFromKey(colKey:string):number {
    return this.colKeysMap[colKey];
  }
  colFromKeys(colKeys:string[]):number[] {
    const vals:number[] = [];
    colKeys.forEach(colKey => vals.push(this.colKeysMap[colKey]))
    return vals;
  }
  //function
  keyFromRow(row:number):string {
    const rowKeys = this.rowKeys;
    return rowKeys[row];
  }
  keyFromRows(rows:number[]):string[] {
    const rowKeys = this.rowKeys;
    const vals:string[] = [];
    rows.forEach(row => vals.push(rowKeys[row]))
    return vals;
  }
  rowFromKey(rowKey:string):number {
    const rowKeysMap = this.rowKeysMap;
    return rowKeysMap[rowKey];
  }
  rowFromKeys(rowKeys:string[]):number[] {
    const rowKeysMap = this.rowKeysMap;
    const vals:number[] = [];
    rowKeys.forEach(rowKey => vals.push(rowKeysMap[rowKey]))
    return vals;
  }
}

//OrderInstruction用の列モデル
export const orderInstructionColRowModelDefault:OrderInstructionColRowModel = new OrderInstructionColRowModel({
  //列固定
  fixedColumnsLeft: headerColKeys.length + rowHeaderColKeys.length,

  //テーブルの各列のキー
  colKeys: [
    ...headerColKeys,
    ...rowHeaderColKeys,
    ...bodyColKeys,
  ],
  colKeysUnOrderableHidden: colKeysUnOrderableHidden, 
  rowKeys: rowKeys,
});
