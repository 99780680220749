import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CodeName } from "@/assets/commontype/CodeName";
import * as arrayUtil from "@/util/arrayUtil";

export type CommonOptions = {
  bumonList?: CodeName[],    //部門
};
//Page State
export type CommonTmpState = {
  //検索条件
  bumonList:CodeName[],    //部門
  bumonDisabled:boolean,    //部門
};

export const initialCommonState: CommonTmpState = {
  bumonList: [],
  bumonDisabled: false,
};

//Page Slice
export type CommonTmpReducer = {
  initOnDidMount: (state:CommonTmpState) => void,
  resetOnWillUnmount: (state:CommonTmpState) => void,
  setBumonList: (state:CommonTmpState, action: PayloadAction<CodeName[]>) => void,
  setBumonDisabled: (state:CommonTmpState, action: PayloadAction<boolean>) => void,
}

const createReducerContent = (name:string, initialState: CommonTmpState):CommonTmpReducer => {return {
  //componentDidMount
  initOnDidMount(state:CommonTmpState) {
    state.bumonDisabled = false;
  },
  //componentWillUnmount
  resetOnWillUnmount(state:CommonTmpState) {
    console.log('----resetOnWillUnmount----');
    state.bumonDisabled = false;
  },
  setBumonList (state:CommonTmpState, action: PayloadAction<CodeName[]>) {
    console.log('commonTmpStore.setBumonList');
    state.bumonList = action.payload;
  },
  setBumonDisabled (state:CommonTmpState, action: PayloadAction<boolean>) {
    console.log('----setBumonDisabled----' + action.payload);
    state.bumonDisabled = action.payload;
  },
}};

const createSliceContent = (name:string, initialState: CommonTmpState) => createSlice({
  name: name,
  initialState: initialState,
  reducers: createReducerContent(name, initialState),
});

//Page Slice Export
//commonTmpSlice
export const commonTmpSlice = createSliceContent("commonTmp", initialCommonState);
