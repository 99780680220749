import { CodeName } from "@/assets/commontype/CodeName";

//倉庫・商品
export type CenterItem = {
  itemCD?: string | null, //商品CD
  itemNM?: string | null, //商品名
  janCD?: string | null,   //JAN
  makerCD?: string | null, //メーカー
  makerNM?: string | null, //メーカー
  categoryCD?: string | null, //ｶﾃｺﾞﾘ
  categoryNM?: string | null, //ｶﾃｺﾞﾘ
  blIrisu?: number | null,  //容量1(BL入数) 
  csIrisu?: number | null,  //容量2(CS入数)
  // lot?: number | null,  //発注単位
  // csIrisu_lot?: number | null,  //CS入数×発注単位
  capacity?: string | null, //内容量
  bestBefore?: string | null, //賞味期間
  teika?: number | null, //定価
  tatene?: number | null, //建値

  bumonCD?: string, //--部門
  makerCenterName?: string | null, //倉庫(4桁コード)
}

export type ErrorInfoDataCommon = {
  stockLimitDate?: string | null,
	stockLimitQty?: string | null,
}

//倉庫・商品・タグ
export type InfoDataCommon = {
  uuid?: string,  //データベースに登録済みのUUID。更新時にDB上の値とずれていれば登録エラーとする

  itemCD?: string | null, //商品CD
  bumonCD?: string, //--部門
  makerCenterName?: string | null, //倉庫(4桁コード)

	stockLimitDate?: string,  //メーカー残在庫日付
	stockLimitQty?: number, //メーカー残在庫CS数

  errorInfo?: ErrorInfoDataCommon,

  edited?: boolean, //編集
  visible?: boolean, //表示状態

  oldStockLimitDate?: string,
	oldStockLimitQty?: number,
}

//並び順
export const listSortOrders:CodeName[] = [
  new CodeName({group_code:"item", group_name:"商品→メーカー倉庫", code: "itemCD", name: "商品コード順", }),
  new CodeName({group_code:"item", group_name:"商品→メーカー倉庫", code: "itemNM", name: "商品名順", }),
  new CodeName({group_code:"item", group_name:"商品→メーカー倉庫", code: "janCD", name: "JANコード順", }),
  new CodeName({group_code:"item", group_name:"商品→メーカー倉庫", code: "maker", name: "メーカー順", }),
  new CodeName({group_code:"item", group_name:"商品→メーカー倉庫", code: "category", name: "商品カテゴリ順", }),
  new CodeName({group_code:"item", group_name:"商品→メーカー倉庫", code: "bestBefore", name: "賞味期間順", }),
  new CodeName({group_code:"item", group_name:"商品→メーカー倉庫", code: "teika", name: "定価順", }),
  new CodeName({group_code:"item", group_name:"商品→メーカー倉庫", code: "tatene", name: "建値順", }),

  new CodeName({group_code:"center", group_name:"メーカー倉庫→商品", code: "center", name: "メーカー倉庫", }),

];