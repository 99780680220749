import { saijiKbnList } from "@/store/settingtransfercenter/settingTransferCenterCommon";
import moment from "moment";
moment.updateLocale("ja", {
  months: ["1月","2月","3月","4月","5月","6月","7月","8月","9月","10月","11月","12月",],
  weekdays: ["日曜日", "月曜日", "火曜日", "水曜日", "木曜日", "金曜日", "土曜日"],
  weekdaysShort: ["日", "月", "火", "水", "木", "金", "土"]
});

//ヘッダー列
export const headerColKeys = [
  'no',
  'centerCD',
  'centerNM',
  'itemCD',
  'itemNM',
];
//行ヘッダー列
export const rowHeaderColKeys = [
  // 'rowHeader', 
];
//Body列
export const bodyColKeys = [
  'saijiKbn', //
  'fromDate', //
  'toDate', //
  'aiteCenterCD',
  'aiteCenterNM',

  'janCD',
  'makerCD',
  'makerNM',
  'categoryCD',
  'categoryNM',
  'blIrisu',
  'csIrisu',
  // 'lot',
  // 'csIrisu_lot',
  'capacity',
  'bestBefore',
  'teika',
  'tatene',

  'area',
  'pref',

  'aiteArea',
  'aitePref',

  'editDate', //編集日
  'editUser', //編集者

];
//ヘッダー列（発注不可）
export const colKeysUnOrderableHidden = [
];

export const rowKeys = ['dummy'];

export const headersCol = [
  {
    no : "No.",

    rowHeader: "項目", 

    itemCD : "対象商品",
    itemNM : "対象商品",

    centerCD : "子センター",
    centerNM : "子センター",
    area : "子センター属性",
    pref : "子センター属性",

    aiteCenterCD : "親センター",
    aiteCenterNM : "親センター",
    aiteArea : "親センター属性",
    aitePref : "親センター属性",
       
    janCD : "商品属性",
    makerCD : "商品属性",
    makerNM : "商品属性",
    categoryCD : "商品属性",
    categoryNM : "商品属性",
    blIrisu : "商品属性",
    csIrisu : "商品属性",
    lot : "商品属性",
    capacity : "商品属性",
    bestBefore : "商品属性",
    teika : "商品属性",
    tatene : "商品属性",
    
    saijiKbn : "催事区分", //
    fromDate : "転送期間", //
    toDate : "転送期間", //
    editDate : "最終編集情報", //編集日
    editUser : "最終編集情報", //編集者
  },
  {
    no : "",
    rowHeader: "項目", 

    itemCD : "商品CD",
    itemNM : "商品名",

    centerCD : "倉庫CD",
    centerNM : "倉庫名",
    area : "地域",
    pref : "都道府県",
   
    aiteCenterCD : "倉庫CD",
    aiteCenterNM : "倉庫名",
    aiteArea : "地域",
    aitePref : "都道府県",

    janCD : "JAN",
    makerCD : "ﾒｰｶｰCD",
    makerNM : "ﾒｰｶｰ名",
    categoryCD : "ｶﾃｺﾞﾘCD",
    categoryNM : "ｶﾃｺﾞﾘ名",
    blIrisu : "BL入数",
    csIrisu : "CS入数",
    lot : "発注単位",
    capacity : "容量",
    bestBefore : "賞味期間",
    teika : "定価",
    tatene : "建値",

    saijiKbn : "", //
    fromDate : "開始日", //
    toDate : "終了日", //

    editDate : "最終編集日時", //編集日
    editUser : "最終編集者", //編集者
  },
];

export const headersRow = {
  dummy: "dummy"
};

export const selectableCol =
{
  no : false,
  rowHeader: false, 
  itemCD : false,

  centerCD : false,
  centerNM : true,
  area : true,
  pref : true,

  aiteCenterCD : false,
  aiteCenterNM : true,
  aiteArea : true,
  aitePref : true,
     
  itemNM : true,
  janCD : true,
  makerCD : true,
  makerNM : true,
  categoryCD : true,
  categoryNM : true,
  blIrisu : true,
  csIrisu : true,
  lot : true,
  capacity : true,
  bestBefore : true,
  teika : true,
  tatene : true,

  saijiKbn : false,
  fromDate : false,
  toDate : false,

  editDate : true,
  editUser : true,
}
export const selectableRow = {
  dummy: false,
};

export const headerColClasses = [
  {
    no : "header_noBorder_no",
    rowHeader: "header_noBorder",
  
    itemCD : "header",

    centerCD : "header",
    centerNM : "header",
    area : "header",
    pref : "header",

    aiteCenterCD : "header",
    aiteCenterNM : "header",
    aiteArea : "header",
    aitePref : "header",
  
       
    itemNM : "header",
    janCD : "header",
    makerCD : "header",
    makerNM : "header",
    categoryCD : "header",
    categoryNM : "header",
    blIrisu : "header",
    csIrisu : "header",
    lot : "header",
    capacity : "header",
    bestBefore : "header",
    teika : "header",
    tatene : "header",

    saijiKbn : "header_noBorder",
    fromDate : "header",
    toDate : "header",
    editDate : "header",
    editUser : "header",
  },
  {
    no : "header_no",
    rowHeader: "header",
  
    itemCD : "header",

    centerCD : "header",
    centerNM : "header",
    area : "header",
    pref : "header",

    aiteCenterCD : "header",
    aiteCenterNM : "header",
    aiteArea : "header",
    aitePref : "header",
  
        
    itemNM : "header",
    janCD : "header",
    makerCD : "header",
    makerNM : "header",
    categoryCD : "header",
    categoryNM : "header",
    blIrisu : "header",
    csIrisu : "header",
    lot : "header",
    capacity : "header",
    bestBefore : "header",
    teika : "header",
    tatene : "header",

    saijiKbn : "header",
    fromDate : "header",
    toDate : "header",
    editDate : "header",
    editUser : "header",
  }
];
export const headerRowClasses = {
  dummy: 'rowHeader',
};

export const colWidths =
{
  no : 40,
  rowHeader: 100, 

  itemCD : 100,

  centerCD : 60,
  centerNM : 80,
  area : 50,
  pref : 70,

  aiteCenterCD : 60,
  aiteCenterNM : 80,
  aiteArea : 50,
  aitePref : 70,

  itemNM : 110,
  janCD : 120,
  makerCD : 60,
  makerNM : 100,
  categoryCD : 40,
  categoryNM : 110,
  blIrisu : 40,
  csIrisu : 40,
  lot : 30,
  capacity : 50,
  bestBefore : 40,
  teika : 50,
  tatene : 55,

  saijiKbn : 70,
  fromDate : 100,
  toDate : 100,

  editDate : 120,
  editUser : 100,
};

export const colDataType =
{
  no : {type: 'text', readOnly: true,},

  rowHeader: {type: 'text', readOnly: true,}, 

  itemCD : {type: 'text', readOnly: false, className: 'htCenter',}, 

  centerCD : {type: 'text', readOnly: false, className: 'htCenter',}, 
  centerNM : {type: 'text', readOnly: true,}, 
  area : {type: 'text', readOnly: true,}, 
  pref : {type: 'text', readOnly: true,}, 

  aiteCenterCD : {type: 'text', readOnly: false, className: 'htCenter',}, 
  aiteCenterNM : {type: 'text', readOnly: true,}, 
  aiteArea : {type: 'text', readOnly: true,}, 
  aitePref : {type: 'text', readOnly: true,}, 

  itemNM : {type: 'text', readOnly: true,}, 
  janCD : {type: 'text', readOnly: true,}, 
  makerCD : {type: 'text', readOnly: true, className: 'htCenter',}, 
  makerNM : {type: 'text', readOnly: true,}, 
  categoryCD : {type: 'text', readOnly: true, className: 'htCenter',}, 
  categoryNM : {type: 'text', readOnly: true,}, 
  blIrisu : {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  csIrisu : {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  lot : {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  capacity : {type: 'text', readOnly: true, className: 'htRight',}, 
  bestBefore : {type: 'text', readOnly: true, className: 'htRight',}, 
  teika : {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},}, 
  tatene : {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0.00',},},

  saijiKbn: { type: 'text', source: saijiKbnList.map(data => data.name), readOnly: false, className: 'htLeft' },

  fromDate: { type: 'date', dateFormat: 'YY/MM/DD', datePickerConfig: {yearSuffix: '年', showMonthAfterYear: true, showDaysInNextAndPreviousMonths: true, format: 'YY/MM/DD', formatStrict: true, i18n: { previousMonth: '前月', nextMonth: '次月', months: moment.months(), weekdays: moment.weekdays(), weekdaysShort: moment.weekdaysShort() } }, readOnly: false, className: 'htCenter' },
  toDate: { type: 'date', dateFormat: 'YY/MM/DD', datePickerConfig: {yearSuffix: '年', showMonthAfterYear: true, showDaysInNextAndPreviousMonths: true, format: 'YY/MM/DD', formatStrict: true, i18n: { previousMonth: '前月', nextMonth: '次月', months: moment.months(), weekdays: moment.weekdays(), weekdaysShort: moment.weekdaysShort() } }, readOnly: false, className: 'htCenter' },

  editDate : {type: 'text', readOnly: true, className: 'htCenter',}, 
  editUser : {type: 'text', readOnly: true,}, 

};

export const rowDataType = {};

//列定義情報（動的パラメータ）
interface SettingTransferCenterColRowModelInterface {
  //列固定
  fixedColumnsLeft: number,

  //テーブルの各列のキー
  colKeys: string[],
  //発注不可時非表示列
  colKeysUnOrderableHidden: string[];
  //テーブルの各行のキー
  rowKeys: string[],
}

export class SettingTransferCenterColRowModel implements SettingTransferCenterColRowModelInterface {
  //列固定
  fixedColumnsLeft: number;
  //テーブルの各列のキー
  colKeys: string[];
  //発注不可時非表示列
  colKeysUnOrderableHidden: string[];
  //テーブルの各列のキー
  rowKeys: string[];
  //ヘッダー
  nestedHeaders; //any[][]
  nestedHeadersBackup; //any[][]
  //ヘッダー区切り
  nestedHeadersGroupIndex: number[];
  //テーブルの各列の逆引き用マップ
  colKeysMap;
  //テーブルの各行の逆引き用マップ
  rowKeysMap;

  constructor(param: SettingTransferCenterColRowModelInterface) {
    this.fixedColumnsLeft = param.fixedColumnsLeft;
    this.colKeys = param.colKeys;
    this.rowKeys = param.rowKeys;
    this.colKeysUnOrderableHidden = param.colKeysUnOrderableHidden;
    
    //テーブルの各列のキー逆引き
    const colKeysMap = {};
    param.colKeys.forEach((colKey, index) => {
      colKeysMap[colKey] = index;
    });
    this.colKeysMap = colKeysMap;
    //テーブルの各行のキー逆引き
    const rowKeysMap = {};
    param.rowKeys.forEach((rowKey, index) => {
      rowKeysMap[rowKey] = index;
    });
    this.rowKeysMap = rowKeysMap;

    //nestedHeaders初期化
    this.initNestedHeaders();
  }

  //nestedHeaders初期化
  initNestedHeaders() {
    //nestedHeaders
    const nestedHeaders = [];
    const nestedHeadersGroupIndex = [];
    // const customBorders = [];
    headersCol.forEach((header, index) => {
      const lastHeader = index == headersCol.length - 1;
      const nestedHeader = [];

      let colspan=0;
      let preHeaderVal = null;
      this.colKeys.forEach((colKey) => {
        const headerVal = header[colKey];
        if(lastHeader) {
          nestedHeader.push(headerVal);
        }
        else {
          if(preHeaderVal !== null && preHeaderVal != headerVal){
            nestedHeader.push(colspan == 1 ? preHeaderVal : {label: preHeaderVal, colspan: colspan});
            colspan=0;
          }
          colspan++;
          preHeaderVal = headerVal;
        }
        //0行目でグループ
        if(index == 0) {
          nestedHeadersGroupIndex.push(colspan-1);
        }
      });
      if(!lastHeader && colspan > 0) {
        nestedHeader.push(colspan == 1 ? preHeaderVal : {label: preHeaderVal, colspan: colspan});
      }

      nestedHeaders.push(nestedHeader);
    });

    this.nestedHeaders = nestedHeaders;
    this.nestedHeadersBackup = nestedHeaders.map(nestedHeadersSub => [...nestedHeadersSub]);
    this.nestedHeadersGroupIndex = nestedHeadersGroupIndex;
  }

  //function
  keyFromCol(column:number):string {
    return this.colKeys[column];
  }
  keyFromCols(columns:number[]):string[] {
    const vals:string[] = [];
    columns.forEach(column => vals.push(this.colKeys[column]))
    return vals;
  }
  colFromKey(colKey:string):number {
    return this.colKeysMap[colKey];
  }
  colFromKeys(colKeys:string[]):number[] {
    const vals:number[] = [];
    colKeys.forEach(colKey => vals.push(this.colKeysMap[colKey]))
    return vals;
  }
  //function
  keyFromRow(row:number):string {
    const rowKeys = this.rowKeys;
    return rowKeys[row];
  }
  keyFromRows(rows:number[]):string[] {
    const rowKeys = this.rowKeys;
    const vals:string[] = [];
    rows.forEach(row => vals.push(rowKeys[row]))
    return vals;
  }
  rowFromKey(rowKey:string):number {
    const rowKeysMap = this.rowKeysMap;
    return rowKeysMap[rowKey];
  }
  rowFromKeys(rowKeys:string[]):number[] {
    const rowKeysMap = this.rowKeysMap;
    const vals:number[] = [];
    rowKeys.forEach(rowKey => vals.push(rowKeysMap[rowKey]))
    return vals;
  }
}

//SettingTransferCenter用の列モデル
export const settingTransferCenterColRowModelDefault:SettingTransferCenterColRowModel = new SettingTransferCenterColRowModel({
  //列固定
  fixedColumnsLeft: headerColKeys.length + rowHeaderColKeys.length,

  //テーブルの各列のキー
  colKeys: [
    ...headerColKeys,
    ...rowHeaderColKeys,
    ...bodyColKeys,
  ],
  colKeysUnOrderableHidden: colKeysUnOrderableHidden, 
  rowKeys: rowKeys,
});
