import { CodeName } from "@/assets/commontype/CodeName";

export const equals = (a1, a2, checkOrder:boolean) => {
  if(checkOrder) {
    //並び順まで含めて一致を確認する
    return JSON.stringify(a1) == JSON.stringify(a2);
  }
  else {
    //内容があってればOK
    return JSON.stringify([...a1].sort()) == JSON.stringify([...a2].sort());
  }
}


//A not B;
export const not = (a, b) => {
  if(!a || !b) return a;
  return a.filter((value) => b.indexOf(value) === -1);
}

//A and B;
export const and = (a, b) => {
  if(!a) return a;
  if(!b) return b;
  return a.filter((value) => b.indexOf(value) !== -1);
}

//A + B
export const union = (a, b) => {
  if(!a) return b;
  if(!b) return a;
  return [...a, ...not(b, a)];
}

export const stringify = (arr) => {
  var r;
  var rLen;
  var c;
  var cLen;
  var str = '';
  var val;

  for (r = 0, rLen = arr.length; r < rLen; r += 1) {
    cLen = arr[r].length;

    for (c = 0; c < cLen; c += 1) {
      if (c > 0) {
        str += '\t';
      }

      val = arr[r][c];

      if (typeof val === 'string') {
        if (val.indexOf('\n') > -1) {
          str += "\"".concat(val.replace(/"/g, '""'), "\"");
        } else {
          str += val;
        }
      } else if (val === null || val === void 0) {
        // void 0 resolves to undefined
        str += '';
      } else {
        str += val;
      }
    }

    if (r !== rLen - 1) {
      str += '\n';
    }
  }

  return str;
}

export const matchOrFirst = (selected:CodeName, list:CodeName[]):CodeName => {
  let notSelected = !selected?.code;
  if(!notSelected) {
    const obj = list.find(obj => obj.code == selected?.code);
    if(obj) {
      return obj;
    }
    else {
      notSelected = true;
    }
  }
  if(notSelected) {
    return list[0];
  }

  return null;
};
export const matches = (selected:CodeName[], list:CodeName[]):CodeName[] => {
  if(!selected?.length) {
    return [];
  }
  const set = new Set<string>(selected.map(obj => obj.code));
  return list.filter(obj => set.has(obj.code));
};