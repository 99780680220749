import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CodeName } from "@/assets/commontype/CodeName";
import * as arrayUtil from "@/util/arrayUtil";
import { OrderInstructionColRowModel, orderInstructionColRowModelDefault } from "@/components/orderinstruction/OrderInstructionTableModel";
import { listSortOrders } from "@/store/orderinstruction/orderInstructionCommon";

//強制表示列
export const mustVisibleColumnsKey:string[] = [
  "no", 
  "targetFlg",
  'center',
  'itemCD',

  'nouhinDate',
  'numCS',
  'numBL',
  'numCSTotal',
];
//デフォルト表示列
export const defaultVisibleColumnsKey:string[] = [
  //以下に貼り付け
  // 'area',
  // 'pref',
  'itemNM',
  // 'janCD',
  // 'makerCD',
  'makerNM',
  // 'categoryCD',
  // 'categoryNM',
  'blIrisu',
  'csIrisu',
  // 'lot',
  'capacity',
  // 'bestBefore',
  'teika',
  // 'tatene',

  'status',

  'minQtyCs',
  'maxQtyCs',

  'closeTime',
  'leadTimeDays',
  'leadTimeExcludeSaturdayFlg',
	'leadTimeContainHolidayFlg',

  'editDate',
  'editUser',
  'ifAdmsSendDate',
  'ifAdmsErrorMessage',
];

//強制表示行
export const mustVisibleRowsKey:string[] = [
  "dummy"
];
//デフォルト表示行
export const defaultVisibleRowsKey:string[] = [
  "dummy"
];

export const optionsNameList = [
  'optionUsers',
  'optionBumons',
  'optionMakers',
  'optionMakerCode',
  'optionAreas',
  'optionCenters',
  'optionCenterCode',
  'optionItemCode',
  'optionItemKeyword',
  'optionStatus',
];
export type OrderInstructionOptions = {
  optionUsers?: CodeName[],         //ユーザー
  optionBumons?: CodeName[],        //部門
  optionMakers?: CodeName[],        //メーカー
  optionMakerCode?: string,         //メーカーCD
  optionAreas?: CodeName[],         //地域
  optionCenters?: CodeName[],       //倉庫
  optionCenterCode?: string,        //倉庫CD
  optionItemCode?: string,          //商品CD
  optionItemKeyword?: string,            //商品キーワード
  optionStatus?:''|'0'|'9'|string,   //ステータス
};
export const displaySettingsNameList = [
  'checkedRowKey',
  'checkedColKey',
];
export type DisplaySettings = {
  checkedRowKey?: string[], //行項目選択
  checkedColKey?: string[],  //列項目選択
};

//Page State
export type OrderInstructionSaveState = {
  //検索条件
  refreshOptions_ver: number,
  optionUsers:CodeName[],     //ユーザー
  optionBumons:CodeName[],    //部門
  optionMakers:CodeName[],    //メーカー
  optionMakerCode:string,            //メーカーCD
  optionAreas:CodeName[],     //地域
  optionCenters:CodeName[],   //倉庫
  optionCenterCode:string,            //倉庫CD
  optionItemCode:string,            //商品CD
  optionItemKeyword:string,            //商品キーワード
  optionStatus:''|'0'|'9'|string,   //ステータス

  favoriteOptions: { title: CodeName, params: OrderInstructionOptions }[] | null,
  favoriteDisplaySettings: { title: CodeName, params: DisplaySettings }[] | null,

  listSortOrder:CodeName,   //並び順
  listSortOrderDesc:boolean,   //昇順

  hiddenColumns: number[],
  hiddenColumnsKey: string[],
  visibleColumnsKey: string[],
  visibleRowsKey: string[],

  colWidthsModified: {},

  narrowAlert:'all'|'none'|'has'|string,   //アラート有無

};

export const initialOrderInstructionState: OrderInstructionSaveState = {
  refreshOptions_ver: 0,
  optionUsers:[],     //ユーザー
  optionBumons:[],    //部門
  optionMakers:[],    //メーカー
  optionMakerCode:'',            //メーカーCD
  optionAreas:[],     //地域
  optionCenters:[],   //倉庫
  optionCenterCode:'',            //倉庫CD
  optionItemCode:'',            //商品CD
  optionItemKeyword:'',            //商品キーワード
  optionStatus:'',   //ステータス

  favoriteOptions: [],
  favoriteDisplaySettings: [],

  listSortOrder: listSortOrders.find(listSortOrder => listSortOrder.code == 'itemCD'),   //並び順
  listSortOrderDesc: false,   //並び順

  hiddenColumns: [],
  hiddenColumnsKey: [],
  visibleColumnsKey: defaultVisibleColumnsKey,
  visibleRowsKey: defaultVisibleRowsKey,

  colWidthsModified: {},

  narrowAlert:'all',   //アラート有無

};
export const initialOrderInstructionDialogState: OrderInstructionSaveState = {
  refreshOptions_ver: 0,
  optionUsers:[],     //ユーザー
  optionBumons:[],    //部門
  optionMakers:[],    //メーカー
  optionMakerCode:'',            //メーカーCD
  optionAreas:[],     //地域
  optionCenters:[],   //倉庫
  optionCenterCode:'',            //倉庫CD
  optionItemCode:'',            //商品CD
  optionItemKeyword:'',            //商品キーワード
  optionStatus:'',   //ステータス

  favoriteOptions: [],
  favoriteDisplaySettings: [],

  listSortOrder: listSortOrders.find(listSortOrder => listSortOrder.code == 'itemCD'),   //並び順
  listSortOrderDesc: false,   //並び順

  hiddenColumns: [],
  hiddenColumnsKey: [],
  visibleColumnsKey: defaultVisibleColumnsKey,
  visibleRowsKey: defaultVisibleRowsKey,

  colWidthsModified: {},

  narrowAlert:'all',   //アラート有無

};

//Page Slice
export type OrderInstructionSaveReducer = {
  initOnDidMount: (state:OrderInstructionSaveState) => void,
  resetOnWillUnmount: (state:OrderInstructionSaveState) => void,
  clearOption: (state:OrderInstructionSaveState, action: PayloadAction<{key:string, user:CodeName}>) => void,
  refreshOptions: (state: OrderInstructionSaveState) => void,
  setOptionUsers: (state:OrderInstructionSaveState, action: PayloadAction<CodeName[]>) => void,
  setOptionBumons: (state:OrderInstructionSaveState, action: PayloadAction<CodeName[]>) => void,
  setOptionMakers: (state:OrderInstructionSaveState, action: PayloadAction<CodeName[]>) => void,
  setOptionMakerCode: (state:OrderInstructionSaveState, action: PayloadAction<string>) => void,
  setOptionAreas: (state:OrderInstructionSaveState, action: PayloadAction<CodeName[]>) => void,
  setOptionCenters: (state:OrderInstructionSaveState, action: PayloadAction<CodeName[]>) => void,
  setOptionCenterCode: (state:OrderInstructionSaveState, action: PayloadAction<string>) => void,
  setOptionItemCode: (state:OrderInstructionSaveState, action: PayloadAction<string>) => void,
  setOptionItemKeyword: (state:OrderInstructionSaveState, action: PayloadAction<string>) => void,
  setOptionStatus: (state:OrderInstructionSaveState, action: PayloadAction<string>) => void,
  setFavoriteOptions: (state:OrderInstructionSaveState, action: PayloadAction<{ title: CodeName, params: OrderInstructionOptions }[]>) => void,
  setFavoriteDisplaySettings: (state:OrderInstructionSaveState, action: PayloadAction<{ title: CodeName, params: DisplaySettings }[]>) => void,
  setListSortOrder: (state:OrderInstructionSaveState, action: PayloadAction<CodeName>) => void,
  setListSortOrderDesc: (state:OrderInstructionSaveState, action: PayloadAction<boolean>) => void,
  setNarrowAlert: (state:OrderInstructionSaveState, action: PayloadAction<'all'|'none'|'has'|string>) => void,
  columnWidthChange: (state:OrderInstructionSaveState, action: PayloadAction<{colKey:string, width:number}>) => void,
  hideColumnKeysChange: (state:OrderInstructionSaveState, action: PayloadAction<string[]>) => void,
  visibleRowKeysChange: (state:OrderInstructionSaveState, action: PayloadAction<string[]>) => void,
}

const createReducerContent = (name:string, colRowModel:OrderInstructionColRowModel, initialState: OrderInstructionSaveState, mustVisibleColumnsKey: string[]):OrderInstructionSaveReducer => {return {
  //componentDidMount
  initOnDidMount(state:OrderInstructionSaveState) {
    if(state.optionBumons === undefined) {
      state.optionBumons = initialState.optionBumons;
    }
    
    if(state.optionUsers === undefined) {
      state.optionUsers = initialState.optionUsers;
    }
    if(state.optionMakers === undefined) {
      state.optionMakers = initialState.optionMakers;
    }
    if(state.optionMakerCode === undefined) {
      state.optionMakerCode = initialState.optionMakerCode;
    }
    if(state.optionAreas === undefined) {
      state.optionAreas = initialState.optionAreas;
    }
    if(state.optionCenters === undefined) {
      state.optionCenters = initialState.optionCenters;
    }
    if(state.optionCenterCode === undefined) {
      state.optionCenterCode = initialState.optionCenterCode;
    }
    if(state.optionItemCode === undefined) {
      state.optionItemCode = initialState.optionItemCode;
    }
    if(state.optionItemKeyword === undefined) {
      state.optionItemKeyword = initialState.optionItemKeyword;
    }
    if(state.optionStatus === undefined) {
      state.optionStatus = initialState.optionStatus;
    }
    if(state.favoriteOptions === undefined) {
      state.favoriteOptions = initialState.favoriteOptions;
    }
    if(state.favoriteDisplaySettings === undefined) {
      state.favoriteDisplaySettings = initialState.favoriteDisplaySettings;
    }
    if(!state.listSortOrder) {
      state.listSortOrder = initialState.listSortOrder;
    }
    else if(state.listSortOrder.code) { //属性値を再選択する
      const code = state.listSortOrder.code;
      state.listSortOrder = listSortOrders.find(listSortOrder => listSortOrder.code == code);   //並び順
    }

    if(state.listSortOrderDesc === undefined) {
      state.listSortOrderDesc = initialState.listSortOrderDesc;
    }

    if (name === 'orderInstructionDialogSave') {
      state.visibleColumnsKey = [];
    } else {
      //初期表示列0配列は、常に全項目表示とする
      if(initialState.visibleColumnsKey.length == 0) {
        state.visibleColumnsKey = colRowModel.colKeys;
      }
      else if(!state.visibleColumnsKey) {
        state.visibleColumnsKey = initialState.visibleColumnsKey;
      }
    }
    //強制選択列
    state.visibleColumnsKey = arrayUtil.union(arrayUtil.and(colRowModel.colKeys, mustVisibleColumnsKey), state.visibleColumnsKey);
    //非表示列
    state.hiddenColumnsKey = arrayUtil.not(colRowModel.colKeys, state.visibleColumnsKey);

    //行項目
    if (!state.visibleRowsKey || state.visibleRowsKey.length == 0) {
      state.visibleRowsKey = (initialState.visibleRowsKey.length == 0) ? colRowModel.rowKeys : initialState.visibleRowsKey;
    }
    //強制選択行
    state.visibleRowsKey = arrayUtil.union(arrayUtil.and(colRowModel.rowKeys, mustVisibleRowsKey), state.visibleRowsKey);

    if(state.colWidthsModified === undefined) {
      state.colWidthsModified = initialState.colWidthsModified;
    }

    //常に初期化
    // if(state.narrowAlert === undefined) {
      state.narrowAlert = initialState.narrowAlert;
    // }

  },
  //componentWillUnmount
  resetOnWillUnmount(state:OrderInstructionSaveState) {
    //初期表示列0配列は、常に全項目表示とするため、記憶しない
    if(initialState.visibleColumnsKey.length == 0) {
      state.visibleColumnsKey = [];
    }
  },
  clearOption(state: OrderInstructionSaveState, action: PayloadAction<{key:string, user:CodeName}>) {
    const key = action?.payload?.key;
    const user = action?.payload?.user;
    if (!key) {
      // state.optionBumons = initialState.optionBumons;
      state.optionStatus = initialState.optionStatus;
      state.optionUsers = user?.code ? [user] : initialState.optionUsers;
      state.narrowAlert = initialState.narrowAlert;
    }
    if (!key || key === 'item') {
      state.optionMakers = initialState.optionMakers;
      state.optionMakerCode = initialState.optionMakerCode;
      state.optionItemCode = initialState.optionItemCode;
      state.optionItemKeyword = initialState.optionItemKeyword;
    }
    if (!key || key === 'center') {
      state.optionAreas = initialState.optionAreas;
      state.optionCenters = initialState.optionCenters;
      state.optionCenterCode = initialState.optionCenterCode;
    }
  },
  refreshOptions (state: OrderInstructionSaveState) {
    state.refreshOptions_ver = state.refreshOptions_ver + 1;
  },
  setOptionUsers (state:OrderInstructionSaveState, action: PayloadAction<CodeName[]>) {
    state.optionUsers = action.payload;
  },
  setOptionBumons (state:OrderInstructionSaveState, action: PayloadAction<CodeName[]>) {
    state.optionBumons = action.payload;
  },
  setOptionMakers (state:OrderInstructionSaveState, action: PayloadAction<CodeName[]>) {
    state.optionMakers = action.payload;
  },
  setOptionMakerCode (state:OrderInstructionSaveState, action: PayloadAction<string>) {
    state.optionMakerCode = action.payload;
  },
  setOptionAreas (state:OrderInstructionSaveState, action: PayloadAction<CodeName[]>) {
    state.optionAreas = action.payload;
  },
  setOptionItemCode (state:OrderInstructionSaveState, action: PayloadAction<string>) {
    state.optionItemCode = action.payload;
  },
  setOptionItemKeyword (state:OrderInstructionSaveState, action: PayloadAction<string>) {
    state.optionItemKeyword = action.payload;
  },
  setOptionStatus(state:OrderInstructionSaveState, action: PayloadAction<string>) {
    state.optionStatus = action.payload;
  },
  setFavoriteOptions(state:OrderInstructionSaveState, action: PayloadAction<{ title: CodeName, params: OrderInstructionOptions }[]>) {
    state.favoriteOptions = action.payload;
  },
  setFavoriteDisplaySettings(state:OrderInstructionSaveState, action: PayloadAction<{ title: CodeName, params: DisplaySettings }[]>) {
    state.favoriteDisplaySettings = action.payload;
  },
  setOptionCenters (state:OrderInstructionSaveState, action: PayloadAction<CodeName[]>) {
    state.optionCenters = action.payload;
  },
  setOptionCenterCode (state:OrderInstructionSaveState, action: PayloadAction<string>) {
    state.optionCenterCode = action.payload;
  },
  setListSortOrder (state:OrderInstructionSaveState, action: PayloadAction<CodeName>) {
    state.listSortOrder = action.payload;
  },
  setListSortOrderDesc (state:OrderInstructionSaveState, action: PayloadAction<boolean>) {
    state.listSortOrderDesc = action.payload;
  },
  setNarrowAlert (state:OrderInstructionSaveState, action: PayloadAction<'all'|'none'|'has'|string>) {
    state.narrowAlert = action.payload;
  },
  columnWidthChange(state:OrderInstructionSaveState, action: PayloadAction<{colKey:string, width:number}>){
    console.log('store.columnWidthChange');
    const colKey = action.payload.colKey;
    const width = action.payload.width;
    if(colKey && width) {
      state.colWidthsModified[colKey] = width;
    }
  },
  hideColumnKeysChange(state:OrderInstructionSaveState, action: PayloadAction<string[]>){
    console.log('store.hideColumnKeysChange');
    //store更新
    state.hiddenColumns = colRowModel.colFromKeys(action.payload);
    state.hiddenColumnsKey = action.payload;
    state.visibleColumnsKey = arrayUtil.not(colRowModel.colKeys, action.payload);
  },
  visibleRowKeysChange(state:OrderInstructionSaveState, action: PayloadAction<string[]>){
    console.log('store.visibleRowKeysChange');
    //store更新
    state.visibleRowsKey = action.payload;
  },
  
}};

const createSliceContent = (name:string, colRowModel:OrderInstructionColRowModel, initialState: OrderInstructionSaveState, mustVisibleColumnsKey: string[]) => createSlice({
  name: name,
  initialState: initialState,
  reducers: createReducerContent(name, colRowModel, initialState, mustVisibleColumnsKey),
});

//Page Slice Export
//orderInstructionSaveSlice
export const orderInstructionSaveSlice = createSliceContent("orderInstructionSave", orderInstructionColRowModelDefault, initialOrderInstructionState, mustVisibleColumnsKey);
export const orderInstructionDialogSaveSlice = createSliceContent("orderInstructionDialogSave", orderInstructionColRowModelDefault, initialOrderInstructionDialogState, mustVisibleColumnsKey);

