import { CodeName } from "@/assets/commontype/CodeName";

//倉庫・メーカー
export type CenterMaker = {
  makerCD?: string | null, //メーカー
  makerNM?: string | null, //メーカー

  bumonCD?: string, //--部門
  centerCD?: string | null, //倉庫(4桁コード)
  centerCD2?: string | null, //倉庫(2桁コード)
  centerNM?: string | null, //倉庫
  areaCD?: string | null, //地域
  areaNM?: string | null, //地域
  prefCD?: string | null, //都道府県
  prefNM?: string | null, //都道府県
  
  centerSortInput?: number | null, //倉庫並び順
  centerSortOutput?: number | null, //倉庫並び順
}

export interface ErrorInfoData {
	leadTimeDays?: string | null,
	specifiedDateSunFlg?: string | null,
	specifiedDateMonFlg?: string | null,
	specifiedDateTueFlg?: string | null,
	specifiedDateWedFlg?: string | null,
	specifiedDateThrFlg?: string | null,
	specifiedDateFriFlg?: string | null,
	specifiedDateSatFlg?: string | null,
	specifiedDateHolFlg?: string | null,
	minQtyCs?: string | null,
	maxQtyCs?: string | null,
	closeTime?: string | null,
	autoOrderExecDays?: string | null,
	autoOrderDays?: string | null,
	fractionRounding?: string | null,
	makerCenterName?: string | null,
}
export interface InfoData {
  uuid?: string,  //データベースに登録済みのUUID。更新時にDB上の値とずれていれば登録エラーとする

  makerCD?: string | null, //メーカー
  centerCD?: string | null, //倉庫

  tagCD?: ''|string, //タグ属性　

  blInputFlg?: boolean,	//BL入力可不可

	leadTimeFlg?: boolean,	//リードタイム有無
	leadTimeDays?: number,	//リードタイム日数
	leadTimeExcludeSaturdayFlg?: boolean,	//リードタイム(土曜を除く)
	leadTimeContainHolidayFlg?: boolean,	//リードタイム(祝日を含む)

	specifiedDateFlg?: boolean,	//指定日有無
	specifiedDateSunFlg?: boolean,	//指定日：曜日：日
	specifiedDateMonFlg?: boolean,	//指定日：曜日：月
	specifiedDateTueFlg?: boolean,	//指定日：曜日：火
	specifiedDateWedFlg?: boolean,	//指定日：曜日：水
	specifiedDateThrFlg?: boolean,	//指定日：曜日：木
	specifiedDateFriFlg?: boolean,	//指定日：曜日：金
	specifiedDateSatFlg?: boolean,	//指定日：曜日：土
	specifiedDateHolFlg?: boolean,	//指定日：曜日：祝日

	minQtyFlg?: boolean,	//最小発注CS有無
	minQtyCs?: number,	//最小発注CS数
	maxQtyFlg?: boolean,	//最大発注CS有無
	maxQtyCs?: number,	//最大発注CS数

	closeFlg?: boolean,	//最小発注CS有無
	closeTime?: string,	//最小発注CS数

	autoOrderFlg?: boolean,	//自動発注フラグ
	autoOrderExecDays?: number,	//自動発注日数	・自動発注を実行するための基準在庫日数：N日分の在庫を切ったら
	autoOrderDays?: number,	//自動発注日数
	autoOrderContainSundayFlg?: boolean,	//自動発注(日曜を含む)

	fractionRounding?: '0'|'1'|'2'|'3', //端数処理 (0:無し 1:切捨て 2:四捨五入 3:切り上げ)

	makerCenterName?: string, //メーカー倉庫名

	//エラー情報
	errorInfo?: ErrorInfoData,

	edited?: boolean, //編集

	oldBlInputFlg?: boolean,	//BL入力可不可

	oldLeadTimeFlg?: boolean,	//リードタイム有無
	oldLeadTimeDays?: number,	//リードタイム日数
	oldLeadTimeExcludeSaturdayFlg?: boolean,	//リードタイム(土曜を除く)
	oldLeadTimeContainHolidayFlg?: boolean,	//リードタイム(祝日を含む)

	oldSpecifiedDateFlg?: boolean,	//指定日有無
	oldSpecifiedDateSunFlg?: boolean,	//指定日：曜日：日
	oldSpecifiedDateMonFlg?: boolean,	//指定日：曜日：月
	oldSpecifiedDateTueFlg?: boolean,	//指定日：曜日：火
	oldSpecifiedDateWedFlg?: boolean,	//指定日：曜日：水
	oldSpecifiedDateThrFlg?: boolean,	//指定日：曜日：木
	oldSpecifiedDateFriFlg?: boolean,	//指定日：曜日：金
	oldSpecifiedDateSatFlg?: boolean,	//指定日：曜日：土
	oldSpecifiedDateHolFlg?: boolean,	//指定日：曜日：祝日

	oldMinQtyFlg?: boolean,	//最小発注CS有無
	oldMinQtyCs?: number,	//最小発注CS数
	oldMaxQtyFlg?: boolean,	//最大発注CS有無
	oldMaxQtyCs?: number,	//最大発注CS数

	oldCloseFlg?: boolean,	//最大発注CS有無
	oldCloseTime?: string,	//最大発注CS数

	oldAutoOrderFlg?: boolean,	//自動発注フラグ
	oldAutoOrderExecDays?: number,	//自動発注日数
	oldAutoOrderDays?: number,	//自動発注日数
	oldAutoOrderContainSundayFlg?: boolean,	//自動発注(日曜を含む)

	oldFractionRounding?: '0' | '1' | '2' | '3', //端数処理 (0:無し 1:切捨て 2:四捨五入 3:切り上げ)

	oldMakerCenterName?: string, //メーカー倉庫名

}

export const centerMakerAttributes:CodeName[][] = [
  [
    new CodeName({group_code:"center", group_name:"センター属性", code: "center", name: "倉庫", }),
    new CodeName({group_code:"center", group_name:"センター属性", code: "area", name: "地域", }),
    new CodeName({group_code:"center", group_name:"センター属性", code: "pref", name: "都道府県", }),
    new CodeName({group_code:"maker", group_name:"メーカー属性", code: "makerCD", name: "メーカーCD", }),
    new CodeName({group_code:"maker", group_name:"メーカー属性", code: "makerNM", name: "メーカー名", }),
  ],
  [
    new CodeName({group_code:"center", group_name:"センター属性", code: "center", name: "倉庫", }),
    new CodeName({group_code:"center", group_name:"センター属性", code: "area", name: "地域", }),
    new CodeName({group_code:"center", group_name:"センター属性", code: "pref", name: "都道府県", }),
    new CodeName({group_code:"maker", group_name:"メーカー属性", code: "makerCD", name: "メーカーCD", }),
    new CodeName({group_code:"maker", group_name:"メーカー属性", code: "makerNM", name: "メーカー名", }),
  ]
];

//並び順
export const listSortOrders:CodeName[] = [
  new CodeName({group_code:"maker", group_name:"メーカー→センター", code: "makerCD", name: "メーカーコード→倉庫順", }),
  new CodeName({group_code:"maker", group_name:"メーカー→センター", code: "makerNM", name: "メーカー名→倉庫順", }),
  new CodeName({group_code:"maker", group_name:"メーカー→センター", code: "makerCD_area", name: "メーカーコード→地域順", }),
  new CodeName({group_code:"maker", group_name:"メーカー→センター", code: "makerNM_area", name: "メーカー名→地域順", }),
  new CodeName({group_code:"maker", group_name:"メーカー→センター", code: "makerCD_pref", name: "メーカーコード→都道府県順", }),
  new CodeName({group_code:"maker", group_name:"メーカー→センター", code: "makerNM_pref", name: "メーカー名→都道府県順", }),

  new CodeName({group_code:"center", group_name:"センター→メーカー", code: "center", name: "倉庫", }),
  new CodeName({group_code:"center", group_name:"センター→メーカー", code: "area", name: "地域", }),
  new CodeName({group_code:"center", group_name:"センター→メーカー", code: "pref", name: "都道府県", }),

];