import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CodeName } from "@/assets/commontype/CodeName";
import { OptionItemTag } from "@/assets/commontype/ItemTag";
import * as arrayUtil from "@/util/arrayUtil";
import { SettingLimitColRowModel, settingLimitColRowModelDefault } from "@/components/settinglimit/SettingLimitTableModel";
import { listSortOrders } from "@/store/settinglimit/settingLimitCommon";

//強制表示列
export const mustVisibleColumnsKey:string[] = [
  "target", 
  "no", 
  // "rowHeader", 

  'center',
  'itemCD',
  
	'stockLimitDate',
	'stockLimitQty',
];
//デフォルト表示列
export const defaultVisibleColumnsKey:string[] = [
  //以下に貼り付け
  'itemNM',
  // 'janCD',
  // 'makerCD',
  'makerNM',
  // 'categoryCD',
  // 'categoryNM',
  'blIrisu',
  'csIrisu',
  // 'lot',
  // 'csIrisu_lot',
  'capacity',
  // 'bestBefore',
  'teika',
  // 'tatene',
];

//強制表示列
export const mustVisibleDialogColumnsKey:string[] = [
  "rowHeader", 
	'stockLimitDate',
	'stockLimitQty',
];

//強制表示行
export const mustVisibleRowsKey:string[] = [
  "dummy"
];
//デフォルト表示行
export const defaultVisibleRowsKey:string[] = [
  "dummy"
];

export const optionsNameList = [
  'optionBumons',
  'optionMakers',
  'optionMakerCode',
  'optionAreas',
  'optionCenters',
  'optionCenterCode',
  'optionItemCode',
  'optionItemKeyword',
  'optionItemTags',
  'optionZaikoTanaban',
  'optionZaikoSuryo',
  'optionZaikoFresh',
  'optionMakerCenterName',
];
export type SettingLimitOptions = {
  optionBumons?: CodeName[],        //部門
  optionMakers?: CodeName[],        //メーカー
  optionMakerCode?: string,         //メーカーCD
  optionAreas?: CodeName[],         //地域
  optionCenters?: CodeName[],       //倉庫
  optionCenterCode?: string,        //倉庫CD
  optionItemCode?: string,          //商品CD
  optionItemKeyword?: string,            //商品キーワード
  optionItemTags?: OptionItemTag[], //商品タグ
  optionZaikoTanaban?: '' | 'has' | 'none' | string,   //棚番
  optionZaikoSuryo?: '' | 'has' | 'none' | string,   //在庫数
  optionZaikoFresh?: '' | 'morning' | 'real' | string,   //在庫鮮度
  optionMakerCenterName?: string,
};
export const displaySettingsNameList = [
  'checkedRowKey',
  'checkedColKey',
];
export type DisplaySettings = {
  checkedRowKey?: string[], //行項目選択
  checkedColKey?: string[],  //列項目選択
};

//Page State
export type SettingLimitSaveState = {
  //検索条件
  refreshOptions_ver: number,
  optionBumons:CodeName[],    //部門
  optionMakers:CodeName[],    //メーカー
  optionMakerCode:string,            //メーカーCD
  optionAreas:CodeName[],     //地域
  optionCenters:CodeName[],   //倉庫
  optionCenterCode:string,            //倉庫CD
  optionItemCode:string,            //商品CD
  optionItemKeyword:string,            //商品キーワード
  optionItemTags:OptionItemTag[],            //商品タグ
  optionZaikoTanaban:''|'has'|'none'|string,   //棚番
  optionZaikoSuryo:''|'has'|'none'|string,   //在庫数
  optionZaikoFresh:''|'morning'|'real'|string,   //在庫鮮度
  optionMakerCenterName?: string,

  favoriteOptions: { title: CodeName, params: SettingLimitOptions }[] | null,
  favoriteDisplaySettings: { title: CodeName, params: DisplaySettings }[] | null,

  listSortOrder:CodeName,   //並び順
  listSortOrderDesc:boolean,   //昇順

  hiddenColumns: number[],
  hiddenColumnsKey: string[],
  visibleColumnsKey: string[],
  visibleRowsKey: string[],

  colWidthsModified: {},

};

export const initialSettingLimitState: SettingLimitSaveState = {
  refreshOptions_ver: 0,
  optionBumons:[],    //部門
  optionMakers:[],    //メーカー
  optionMakerCode:'',            //メーカーCD
  optionAreas:[],     //地域
  optionCenters:[],   //倉庫
  optionCenterCode:'',            //倉庫CD
  optionItemCode:'',            //商品CD
  optionItemKeyword:'',            //商品キーワード
  optionItemTags:[],            //商品タグ
  optionZaikoTanaban:'has',   //棚番
  optionZaikoSuryo:'has',   //在庫数
  optionZaikoFresh:'morning',   //在庫鮮度
  optionMakerCenterName: '',

  favoriteOptions: [],
  favoriteDisplaySettings: [],

  listSortOrder: listSortOrders.find(listSortOrder => listSortOrder.code == 'itemCD'),   //並び順
  listSortOrderDesc: false,   //並び順

  hiddenColumns: [],
  hiddenColumnsKey: [],
  visibleColumnsKey: defaultVisibleColumnsKey,
  visibleRowsKey: defaultVisibleRowsKey,

  colWidthsModified: {},
};

//Page Slice
export type SettingLimitSaveReducer = {
  initOnDidMount: (state:SettingLimitSaveState) => void,
  resetOnWillUnmount: (state:SettingLimitSaveState) => void,
  clearOption: (state:SettingLimitSaveState, action: PayloadAction<string>) => void,
  refreshOptions: (state: SettingLimitSaveState) => void,
  setOptionBumons: (state:SettingLimitSaveState, action: PayloadAction<CodeName[]>) => void,
  setOptionMakers: (state:SettingLimitSaveState, action: PayloadAction<CodeName[]>) => void,
  setOptionMakerCode: (state:SettingLimitSaveState, action: PayloadAction<string>) => void,
  setOptionAreas: (state:SettingLimitSaveState, action: PayloadAction<CodeName[]>) => void,
  setOptionCenters: (state:SettingLimitSaveState, action: PayloadAction<CodeName[]>) => void,
  setOptionCenterCode: (state:SettingLimitSaveState, action: PayloadAction<string>) => void,
  setOptionItemCode: (state:SettingLimitSaveState, action: PayloadAction<string>) => void,
  setOptionItemKeyword: (state:SettingLimitSaveState, action: PayloadAction<string>) => void,
  setOptionItemTags: (state:SettingLimitSaveState, action: PayloadAction<OptionItemTag[]>) => void,
  setOptionZaikoTanaban: (state:SettingLimitSaveState, action: PayloadAction<string>) => void,
  setOptionZaikoSuryo: (state:SettingLimitSaveState, action: PayloadAction<string>) => void,
  setOptionZaikoFresh: (state:SettingLimitSaveState, action: PayloadAction<string>) => void,
  setOptionMakerCenterName: (state:SettingLimitSaveState, action: PayloadAction<string>) => void,
  setFavoriteOptions: (state:SettingLimitSaveState, action: PayloadAction<{ title: CodeName, params: SettingLimitOptions }[]>) => void,
  setFavoriteDisplaySettings: (state:SettingLimitSaveState, action: PayloadAction<{ title: CodeName, params: DisplaySettings }[]>) => void,
  setListSortOrder: (state:SettingLimitSaveState, action: PayloadAction<CodeName>) => void,
  setListSortOrderDesc: (state:SettingLimitSaveState, action: PayloadAction<boolean>) => void,
  columnWidthChange: (state:SettingLimitSaveState, action: PayloadAction<{colKey:string, width:number}>) => void,
  hideColumnKeysChange: (state:SettingLimitSaveState, action: PayloadAction<string[]>) => void,
  visibleRowKeysChange: (state:SettingLimitSaveState, action: PayloadAction<string[]>) => void,
}

const createReducerContent = (name:string, colRowModel:SettingLimitColRowModel, initialState: SettingLimitSaveState, mustVisibleColumnsKey: string[]):SettingLimitSaveReducer => {return {
  //componentDidMount
  initOnDidMount(state:SettingLimitSaveState) {
    if(state.optionBumons === undefined) {
      state.optionBumons = initialState.optionBumons;
    }
    
    if(state.optionMakers === undefined) {
      state.optionMakers = initialState.optionMakers;
    }
    if(state.optionMakerCode === undefined) {
      state.optionMakerCode = initialState.optionMakerCode;
    }
    if(state.optionAreas === undefined) {
      state.optionAreas = initialState.optionAreas;
    }
    if(state.optionCenters === undefined) {
      state.optionCenters = initialState.optionCenters;
    }
    if(state.optionCenterCode === undefined) {
      state.optionCenterCode = initialState.optionCenterCode;
    }
    if(state.optionItemCode === undefined) {
      state.optionItemCode = initialState.optionItemCode;
    }
    if(state.optionItemKeyword === undefined) {
      state.optionItemKeyword = initialState.optionItemKeyword;
    }
    if(state.optionItemTags === undefined) {
      state.optionItemTags = initialState.optionItemTags;
    }
    if(state.optionZaikoTanaban === undefined) {
      state.optionZaikoTanaban = initialState.optionZaikoTanaban;
    }
    if(state.optionZaikoFresh === undefined) {
      state.optionZaikoFresh = initialState.optionZaikoFresh;
    }
    if(state.optionZaikoSuryo === undefined) {
      state.optionZaikoSuryo = initialState.optionZaikoSuryo;
    }
    if(state.optionMakerCenterName === undefined) {
      state.optionMakerCenterName = initialState.optionMakerCenterName;
    }

    if(state.favoriteOptions === undefined) {
      state.favoriteOptions = initialState.favoriteOptions;
    }
    if(state.favoriteDisplaySettings === undefined) {
      state.favoriteDisplaySettings = initialState.favoriteDisplaySettings;
    }
    if(!state.listSortOrder) {
      state.listSortOrder = initialState.listSortOrder;
    }
    else if(state.listSortOrder.code) { //属性値を再選択する
      const code = state.listSortOrder.code;
      state.listSortOrder = listSortOrders.find(listSortOrder => listSortOrder.code == code);   //並び順
    }

    if(state.listSortOrderDesc === undefined) {
      state.listSortOrderDesc = initialState.listSortOrderDesc;
    }

    if (name === 'settingLimitDialogSave') {
      state.visibleColumnsKey = [];
    } else {
      //初期表示列0配列は、常に全項目表示とする
      if(initialState.visibleColumnsKey.length == 0) {
        state.visibleColumnsKey = colRowModel.colKeys;
      }
      else if(!state.visibleColumnsKey) {
        state.visibleColumnsKey = initialState.visibleColumnsKey;
      }
    }
    //強制選択列
    state.visibleColumnsKey = arrayUtil.union(arrayUtil.and(colRowModel.colKeys, mustVisibleColumnsKey), state.visibleColumnsKey);
    //非表示列
    state.hiddenColumnsKey = arrayUtil.not(colRowModel.colKeys, state.visibleColumnsKey);

    //行項目
    if (!state.visibleRowsKey || state.visibleRowsKey.length == 0) {
      state.visibleRowsKey = (initialState.visibleRowsKey.length == 0) ? colRowModel.rowKeys : initialState.visibleRowsKey;
    }
    //強制選択行
    state.visibleRowsKey = arrayUtil.union(arrayUtil.and(colRowModel.rowKeys, mustVisibleRowsKey), state.visibleRowsKey);

    if(state.colWidthsModified === undefined) {
      state.colWidthsModified = initialState.colWidthsModified;
    }

  },
  //componentWillUnmount
  resetOnWillUnmount(state:SettingLimitSaveState) {
    //初期表示列0配列は、常に全項目表示とするため、記憶しない
    if(initialState.visibleColumnsKey.length == 0) {
      state.visibleColumnsKey = [];
    }
  },
  clearOption(state: SettingLimitSaveState, action: PayloadAction<string>) {
    const key = action.payload;
    if (!key) {
      // state.optionBumons = initialState.optionBumons;
      state.optionZaikoTanaban = initialState.optionZaikoTanaban;
      state.optionZaikoFresh = initialState.optionZaikoFresh;
      state.optionZaikoSuryo = initialState.optionZaikoSuryo;
      state.optionMakerCenterName = initialState.optionMakerCenterName;
    }
    if (!key || key === 'item') {
      state.optionMakers = initialState.optionMakers;
      state.optionMakerCode = initialState.optionMakerCode;
      state.optionItemCode = initialState.optionItemCode;
      state.optionItemKeyword = initialState.optionItemKeyword;
      state.optionItemTags = initialState.optionItemTags;
    }
    if (!key || key === 'center') {
      state.optionAreas = initialState.optionAreas;
      state.optionCenters = initialState.optionCenters;
      state.optionCenterCode = initialState.optionCenterCode;
    }
  },
  refreshOptions (state: SettingLimitSaveState) {
    state.refreshOptions_ver = state.refreshOptions_ver + 1;
  },
  setOptionBumons (state:SettingLimitSaveState, action: PayloadAction<CodeName[]>) {
    state.optionBumons = action.payload;
  },
  setOptionMakers (state:SettingLimitSaveState, action: PayloadAction<CodeName[]>) {
    state.optionMakers = action.payload;
  },
  setOptionMakerCode (state:SettingLimitSaveState, action: PayloadAction<string>) {
    state.optionMakerCode = action.payload;
  },
  setOptionAreas (state:SettingLimitSaveState, action: PayloadAction<CodeName[]>) {
    state.optionAreas = action.payload;
  },
  setOptionItemCode (state:SettingLimitSaveState, action: PayloadAction<string>) {
    state.optionItemCode = action.payload;
  },
  setOptionItemKeyword (state:SettingLimitSaveState, action: PayloadAction<string>) {
    state.optionItemKeyword = action.payload;
  },
  setOptionItemTags (state:SettingLimitSaveState, action: PayloadAction<OptionItemTag[]>) {
    state.optionItemTags = action.payload;
  },
  setOptionZaikoTanaban(state:SettingLimitSaveState, action: PayloadAction<string>) {
    state.optionZaikoTanaban = action.payload;
  },
  setOptionZaikoSuryo(state:SettingLimitSaveState, action: PayloadAction<string>) {
    state.optionZaikoSuryo = action.payload;
  },
  setOptionZaikoFresh(state:SettingLimitSaveState, action: PayloadAction<string>) {
    state.optionZaikoFresh = action.payload;
  },
  setOptionMakerCenterName (state:SettingLimitSaveState, action: PayloadAction<string>) {
    state.optionMakerCenterName = action.payload;
  },

  setFavoriteOptions(state:SettingLimitSaveState, action: PayloadAction<{ title: CodeName, params: SettingLimitOptions }[]>) {
    state.favoriteOptions = action.payload;
  },
  setFavoriteDisplaySettings(state:SettingLimitSaveState, action: PayloadAction<{ title: CodeName, params: DisplaySettings }[]>) {
    state.favoriteDisplaySettings = action.payload;
  },
  setOptionCenters (state:SettingLimitSaveState, action: PayloadAction<CodeName[]>) {
    state.optionCenters = action.payload;
  },
  setOptionCenterCode (state:SettingLimitSaveState, action: PayloadAction<string>) {
    state.optionCenterCode = action.payload;
  },
  setListSortOrder (state:SettingLimitSaveState, action: PayloadAction<CodeName>) {
    state.listSortOrder = action.payload;
  },
  setListSortOrderDesc (state:SettingLimitSaveState, action: PayloadAction<boolean>) {
    state.listSortOrderDesc = action.payload;
  },

  columnWidthChange(state:SettingLimitSaveState, action: PayloadAction<{colKey:string, width:number}>){
    console.log('store.columnWidthChange');
    const colKey = action.payload.colKey;
    const width = action.payload.width;
    if(colKey && width) {
      state.colWidthsModified[colKey] = width;
    }
  },
  hideColumnKeysChange(state:SettingLimitSaveState, action: PayloadAction<string[]>){
    console.log('store.hideColumnKeysChange');
    //store更新
    state.hiddenColumns = colRowModel.colFromKeys(action.payload);
    state.hiddenColumnsKey = action.payload;
    state.visibleColumnsKey = arrayUtil.not(colRowModel.colKeys, action.payload);
  },
  visibleRowKeysChange(state:SettingLimitSaveState, action: PayloadAction<string[]>){
    console.log('store.visibleRowKeysChange');
    //store更新
    state.visibleRowsKey = action.payload;
  },
  
}};

const createSliceContent = (name:string, colRowModel:SettingLimitColRowModel, initialState: SettingLimitSaveState, mustVisibleColumnsKey: string[]) => createSlice({
  name: name,
  initialState: initialState,
  reducers: createReducerContent(name, colRowModel, initialState, mustVisibleColumnsKey),
});

//Page Slice Export
//settingLimitSaveSlice
export const settingLimitSaveSlice = createSliceContent("settingLimitSave", settingLimitColRowModelDefault, initialSettingLimitState, mustVisibleColumnsKey);
