import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CodeName } from "@/assets/commontype/CodeName";

import { CenterData, InfoData, ErrorInfoData } from '@/store/settingcenter/settingCenterCommon'
import * as settingCenterRetrieve from "@/assets/apitype/settingCenterRetrieve";
import { SettingCenterColRowModel, rowKeys, headersRow, colDataType, rowDataType } from "@/components/settingcenter/SettingCenterTableModel";
import * as compareUtil from "@/util/compareUtil";
import * as arrayutil from "@/util/arrayUtil";
import * as editorUtil from "@/util/editorUtil";

//0.00フォーマット
const formatterP00 = new Intl.NumberFormat('ja-JP', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 });
//0,0フォーマット
const formatterN0 = new Intl.NumberFormat('ja-JP', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0 });

//編集中判定
export const isEditedRowData = (data: InfoData): boolean => {
  if (!data) {
    return false;
  }

  const freshness = 
    (
      (data.oldAllowablePeriodMaster ?? false) != (data.allowablePeriodMaster ?? false) ||
      data.oldFreshnessMinDaysOfHalfBestBefore != data.freshnessMinDaysOfHalfBestBefore ||
      data.oldFreshnessDaysOfAlert != data.freshnessDaysOfAlert    
      );

  const shubai =
    (
      data.oldShubaiLimitAlert != data.shubaiLimitAlert 
    );

  const todayUseFlg =
    (
      (data.oldTodayUseFlg ?? false) != (data.todayUseFlg ?? false)
    );

  const weekdayWarnFlg =
    (
      (data.oldWeekdayWarnFlg ?? false) != (data.weekdayWarnFlg ?? false)
    );

  const shippingJuchuOrInput =
    (
      (data.oldShippingJuchuOrInput ?? false) != (data.shippingJuchuOrInput ?? false)
    );

  const sejZantei = 
  (
    data.oldSejZanteiOrderLeadNew != data.sejZanteiOrderLeadNew ||
    data.oldSejZanteiOrderLead != data.sejZanteiOrderLead    
    );
  
  return (
    freshness || shubai || todayUseFlg || weekdayWarnFlg || shippingJuchuOrInput || sejZantei
  );
}

export type RowDataGroup = {
  centerData: CenterData,
  target?: boolean,
  hasChildTarget?: boolean,
}

export type RowInfo = {
  TP: "info" | "bulk",
  no?: string,
  subno?: string,
  dataGroup:RowDataGroup,
  rowKey?: string,

  rowIndex?:number,  //同一集計行内でのrowKeyのindex
  rowIndexLast?:boolean, //同一集計行内での最後のrowKeyフラグ
  row:number,  //行番号

  infoData? :InfoData,
  infoDataIndex?:number,
}
export interface EditCellInfo {
  row:number, 
  col:number, 
  rowKey:string, 
  value:string|number|object|null, 
  relatedValues?: {key:string, value:string|number|object|null}[],
}

//Page State
export type SettingCenterTmpState = {
  // bumonList: CodeName[],  //部門
  areaList: CodeName[], //地域
  centerList: CodeName[], //倉庫

  progress: Record<string, unknown>,
  retrievedParam: settingCenterRetrieve.RequestParam,  //検索条件(検索済み)

  dataGroups: RowDataGroup[],
  rowInfos: RowInfo[],
  fixedRowsTop :number,
  infoDatas: InfoData[],
  rows: any[][],
  mergeCells: {row: number, col: number, rowspan: number, colspan: number}[]
  tableVer_updateData: number,
  tableVer_updateData_rows: number[],
  tableVer_updateSettting: number,

  errorMessage: string | null,
  infoMessage: string | null,

  editing: boolean,
  functionKeys: string[],
  // openDialog: boolean,
};

export const initialState: SettingCenterTmpState = {
  // bumonList: [],
  areaList: [],
  centerList: [],

  progress: {},
  retrievedParam: null,

  dataGroups: [],
  rowInfos: [],
  fixedRowsTop :0,
  infoDatas: [],
  rows: [],
  mergeCells: null,
  tableVer_updateData: 0,
  tableVer_updateData_rows: [],
  tableVer_updateSettting: 0,

  errorMessage: null,
  infoMessage: null,

  editing: false,
  functionKeys: [],
  // openDialog: boolean,
};

//Page Slice
export type SettingCenterTmpReducer = {
  putProgress: (state:SettingCenterTmpState, action: PayloadAction<string>) => void,
  removeProgress: (state:SettingCenterTmpState, action: PayloadAction<string>) => void,
  setErrorMessage: (state:SettingCenterTmpState, action: PayloadAction<string>) => void,
  setInfoMessage: (state:SettingCenterTmpState, action: PayloadAction<string>) => void,
  setEditingStart: (state:SettingCenterTmpState, action: PayloadAction<string>) => void,
  setEditingEnd: (state:SettingCenterTmpState, action: PayloadAction<string>) => void,
  // setBumonList: (state:SettingCenterTmpState, action: PayloadAction<CodeName[]>) => void,
  setAreaList: (state:SettingCenterTmpState, action: PayloadAction<CodeName[]>) => void,
  setCenterList: (state:SettingCenterTmpState, action: PayloadAction<CodeName[]>) => void,
  setRetrievedParam: (state:SettingCenterTmpState, action: PayloadAction<settingCenterRetrieve.RequestParam>) => void,

  searched: (state:SettingCenterTmpState, action: PayloadAction<{param: settingCenterRetrieve.RequestParam, centerDatas: CenterData[], infos: InfoData[], colRowModel:SettingCenterColRowModel, listSortOrder:CodeName, listSortOrderDesc:boolean, visibleRowsKey:string[]}>) => void,
  refreshTable: (state:SettingCenterTmpState, action: PayloadAction<{colRowModel:SettingCenterColRowModel, listSortOrder:CodeName, listSortOrderDesc:boolean, visibleRowsKey:string[]}>) => void,
  editRowDatas: (state:SettingCenterTmpState, action: PayloadAction<{colRowModel:SettingCenterColRowModel, listSortOrder:CodeName, listSortOrderDesc:boolean, visibleRowsKey:string[], editDatas:EditCellInfo[]}>) => void,
  setFunctionKey: (state:SettingCenterTmpState, action: PayloadAction<string>) => void,
}

const createReducerContent = ():SettingCenterTmpReducer => {return {
    putProgress(state:SettingCenterTmpState, action: PayloadAction<string>) {
      const key = action.payload;
      const progressNew = {...state.progress};
      progressNew[key] = true;
      state.progress = progressNew;
    },
    removeProgress(state:SettingCenterTmpState, action: PayloadAction<string>) {
      const key = action.payload;
      const progressNew = {};
      Object.keys(state.progress).forEach(k => {
        if(key != k) {
          progressNew[k] = true;
        }
      })
      state.progress = progressNew;
    },
    setErrorMessage(state:SettingCenterTmpState, action: PayloadAction<string>) {
      state.errorMessage = action.payload;
    },
    setInfoMessage(state:SettingCenterTmpState, action: PayloadAction<string>) {
      state.infoMessage = action.payload;
    },
    setEditingStart(state:SettingCenterTmpState) {
      console.log('store.setEditingStart');
      state.editing = true;
    },
    setEditingEnd(state:SettingCenterTmpState) {
      console.log('store.setEditingEnd');

      state.editing = false;

      state.progress= {};
      state.retrievedParam= null;
      
      state.dataGroups= [];
      state.rowInfos= [];
      state.fixedRowsTop =0;
      state.infoDatas= [];
      state.rows= [];
      state.mergeCells= null;

      state.tableVer_updateSettting = state.tableVer_updateSettting+1;  //カウントアップしテーブルを再構築対象とする (updateSetting)
    
      
    },
    // setBumonList(state:SettingCenterTmpState, action: PayloadAction<CodeName[]>) {
    //   state.bumonList = action.payload;
    // },
    setAreaList(state:SettingCenterTmpState, action: PayloadAction<CodeName[]>) {
      state.areaList = action.payload;
    },
    setCenterList(state:SettingCenterTmpState, action: PayloadAction<CodeName[]>) {
      state.centerList = action.payload;
    },
    setRetrievedParam(state:SettingCenterTmpState, action: PayloadAction<settingCenterRetrieve.RequestParam>) {
      state.retrievedParam = action.payload;
    },

    searched(state:SettingCenterTmpState, action: PayloadAction<{param: settingCenterRetrieve.RequestParam, centerDatas: CenterData[], infos: InfoData[], colRowModel:SettingCenterColRowModel, listSortOrder:CodeName, listSortOrderDesc:boolean, visibleRowsKey:string[]}>) {
      const setOldData = (data: InfoData) => {
        data.oldAllowablePeriodMaster = data.allowablePeriodMaster ? data.allowablePeriodMaster : null;
        data.oldFreshnessMinDaysOfHalfBestBefore = data.freshnessMinDaysOfHalfBestBefore ? data.freshnessMinDaysOfHalfBestBefore : null;
        data.oldFreshnessDaysOfAlert = data.freshnessDaysOfAlert ? data.freshnessDaysOfAlert : null;
        data.oldShubaiLimitAlert = data.shubaiLimitAlert ? data.shubaiLimitAlert : null;
        data.oldTodayUseFlg = data.todayUseFlg ? data.todayUseFlg : null;
        data.oldWeekdayWarnFlg = data.weekdayWarnFlg ? data.weekdayWarnFlg : null;
        data.oldShippingJuchuOrInput = data.shippingJuchuOrInput ? data.shippingJuchuOrInput : null;
        data.oldSejZanteiOrderLeadNew = data.sejZanteiOrderLeadNew ? data.sejZanteiOrderLeadNew : null;
        data.oldSejZanteiOrderLead = data.sejZanteiOrderLead ? data.sejZanteiOrderLead : null;
      }
      const colRowModel = action.payload.colRowModel;
      const param = action.payload.param;

      let centerDatas = parseDataCenterData(action.payload.centerDatas);
      let infos = parseDataInfoData(action.payload.infos);
      //編集前のデータをセット
      infos.forEach(data => setOldData(data));
      infos = checkDatas(infos); //データチェック

      const listSortOrder = action.payload.listSortOrder;
      const listSortOrderDesc = action.payload.listSortOrderDesc;
      const visibleRowsKey = action.payload.visibleRowsKey;
      
      let dataGroups = convertDataGroups(centerDatas);
      dataGroups = sortDataGroups(dataGroups, listSortOrder, listSortOrderDesc);
      const [rowInfos, fixedRowsTop, newinfos] = convertRowInfos(dataGroups, listSortOrder, listSortOrderDesc, visibleRowsKey, infos);
      //新規も編集前のデータをセットをセット
      newinfos.forEach(data => setOldData(data));

      //store更新
      state.dataGroups = dataGroups;
      state.rowInfos = rowInfos;
      state.fixedRowsTop = fixedRowsTop;
      state.infoDatas = newinfos;
      state.rows = convertRows(rowInfos, colRowModel, listSortOrder, listSortOrderDesc);
      state.mergeCells = createMergeCells(rowInfos, colRowModel);

      state.tableVer_updateSettting = state.tableVer_updateSettting+1;  //カウントアップしテーブルを再構築対象とする (updateSetting)
    },
    refreshTable(state:SettingCenterTmpState, action: PayloadAction<{colRowModel:SettingCenterColRowModel, listSortOrder:CodeName, listSortOrderDesc:boolean, visibleRowsKey:string[]}>){
      console.log('refreshTable');
      const listSortOrder = action.payload.listSortOrder;
      const listSortOrderDesc = action.payload.listSortOrderDesc;
      const visibleRowsKey = action.payload.visibleRowsKey;
      const colRowModel = action.payload.colRowModel;
      let dataGroups = state.dataGroups;
      dataGroups = sortDataGroups(dataGroups, listSortOrder, listSortOrderDesc);
      const [rowInfos, fixedRowsTop, newinfos] = convertRowInfos(dataGroups, listSortOrder, listSortOrderDesc, visibleRowsKey, state.infoDatas);
      //store更新
      state.dataGroups = dataGroups;
      state.rowInfos = rowInfos;
      state.fixedRowsTop = fixedRowsTop;
      state.infoDatas = newinfos;
      state.rows = convertRows(rowInfos, colRowModel, listSortOrder, listSortOrderDesc);
      state.mergeCells = createMergeCells(rowInfos, colRowModel);

      state.tableVer_updateSettting = state.tableVer_updateSettting+1;  //カウントアップしテーブルを再構築対象とする (updateSetting)
    },

    editRowDatas(state:SettingCenterTmpState, action: PayloadAction<{colRowModel:SettingCenterColRowModel, listSortOrder:CodeName, listSortOrderDesc:boolean, visibleRowsKey:string[], editDatas:EditCellInfo[]}>) {
      console.log('store.editRowDatas');
      const listSortOrder = action.payload.listSortOrder;
      const listSortOrderDesc = action.payload.listSortOrderDesc;
      const visibleRowsKey = action.payload.visibleRowsKey;
      const colRowModel = action.payload.colRowModel;
      const editDatas = action.payload.editDatas;

      // const newInfoDatas = [...state.infoDatas];
      const newInfoDatas = state.infoDatas;
      const newRows = state.rows;

      let targetChange = false;

      let editedRows:Set<number> = new Set<number>();
      editDatas.forEach((editData)=>{
        const rowInfo:RowInfo = state.rowInfos[editData.row];
        if(!rowInfo) {
          return;
        }
        const dataGroup = rowInfo.dataGroup;
        const infoData = rowInfo.infoData;
        const infoDataIndex = rowInfo.infoDataIndex;
        if(!dataGroup || !infoData) {
          return;
        }

        const colKey:string = colRowModel.keyFromCol(editData.col);
        const rowKey:string = rowInfo.rowKey;

        let dataType = rowDataType[rowKey];
        if(!dataType) {
          dataType = colDataType[colKey];
        }

        const value = 
          editorUtil.parseValue(editData.value, dataType.type,
            dataType.type == 'numeric' ? dataType.numericFormat.pattern :
            dataType.type == 'date' ? dataType.dateFormat :
            dataType.type == 'time' ? dataType.timeFormat :
            null);

        if(colKey == 'target') {
          const rowTop = rowInfo.row - rowInfo.rowIndex;
          const rowInfoTop = state.rowInfos[rowTop];
          const dataGroupTop = rowInfoTop.dataGroup;
          dataGroupTop.target = value == true;
          editedRows.add(rowTop);
          rowInfoTop.dataGroup = dataGroupTop;
          state.rowInfos[rowTop] = rowInfoTop;

          targetChange = true;
        }
        else {
          newInfoDatas[infoDataIndex][colKey] = value;
          editedRows.add(editData.row);
          newInfoDatas[infoDataIndex] = checkData(newInfoDatas[infoDataIndex]);
          state.rowInfos[editData.row].infoData = newInfoDatas[infoDataIndex];
        }  
      });

      //選択有無を記録する
      if(targetChange) {
        const rowInfoBulk = state.rowInfos[0];
        const dataGroupBulk = rowInfoBulk.dataGroup;
        dataGroupBulk.hasChildTarget = !(!(state.rowInfos.find(rowInfo => rowInfo.dataGroup?.target)));
        rowInfoBulk.dataGroup = dataGroupBulk;
      }

      if(editedRows.size > 0) {
        const tableVer_updateData_rows = [];
        editedRows.forEach(row => {
          tableVer_updateData_rows.push(row);
          newRows[row] = convertRow(state.rowInfos[row], colRowModel, listSortOrder, listSortOrderDesc);
        });
        // state.rows = newRows;

        /*
        const [rowInfos, fixedRowsTop, newinfos] = convertRowInfos(state.dataGroups, listSortOrder, listSortOrderDesc, visibleRowsKey, newInfoDatas);
        
        // //store更新
        state.rowInfos = rowInfos;
        state.fixedRowsTop = fixedRowsTop;
        state.infoDatas = newinfos;
        state.rows = convertRows(rowInfos, colRowModel);
        state.mergeCells = createMergeCells(rowInfos, colRowModel);
        */

        state.tableVer_updateData_rows = tableVer_updateData_rows;
        state.tableVer_updateData = state.tableVer_updateData+1;  //カウントアップしテーブルを再描写対象とする (updateData)
      }
    },
    setFunctionKey(state:SettingCenterTmpState, action: PayloadAction<string>) {
      state.functionKeys = action.payload ? [action.payload] : [];
    },
}};

const parseDataCenterData = (datas:CenterData[]): CenterData[] => {
  datas = [...datas];
  datas.forEach((data) => {
  });
  return datas;
}
const parseDataInfoData = (datas:InfoData[]): InfoData[] => {
  datas = [...datas];
  datas.forEach((data) => {
    if(typeof data.freshnessMinDaysOfHalfBestBefore === 'string') data.freshnessMinDaysOfHalfBestBefore = parseInt(data.freshnessMinDaysOfHalfBestBefore);
    if(typeof data.freshnessDaysOfAlert === 'string') data.freshnessDaysOfAlert = parseInt(data.freshnessDaysOfAlert);
    if(typeof data.shubaiLimitAlert === 'string') data.shubaiLimitAlert = parseInt(data.shubaiLimitAlert);
    if(typeof data.sejZanteiOrderLeadNew === 'string') data.sejZanteiOrderLeadNew = parseInt(data.sejZanteiOrderLeadNew);
    if(typeof data.sejZanteiOrderLead === 'string') data.sejZanteiOrderLead = parseInt(data.sejZanteiOrderLead);
  });
  return datas;
}

//チェック
const checkDatas = (datas:InfoData[]): InfoData[] => {
  console.log('store.checkDatas');
  return datas.map(data => checkData(data));
}
const checkData = (data: InfoData): InfoData => {
  //編集状態をセットする
  // Object.keys(data).forEach(key => {
    data.edited = isEditedRowData(data);
  // });

  const errorInfo: ErrorInfoData = {};
  if (!data.errorInfo) {
    data.errorInfo = {};
  }

  if(data.freshnessDaysOfAlert && !data.freshnessMinDaysOfHalfBestBefore){
    errorInfo.freshnessMinDaysOfHalfBestBefore = '入力してください';
  }
  if(!data.freshnessDaysOfAlert && data.freshnessMinDaysOfHalfBestBefore)
  {
    errorInfo.freshnessDaysOfAlert = '入力してください';
  }

  if (data.freshnessMinDaysOfHalfBestBefore) {
    if (data.freshnessMinDaysOfHalfBestBefore < 1) {
      errorInfo.freshnessMinDaysOfHalfBestBefore = '1以上で入力してください';
    }
    else if (data.freshnessMinDaysOfHalfBestBefore > 9999) {
      errorInfo.freshnessMinDaysOfHalfBestBefore = '9999以下で入力してください';
    }
  }
  if (data.freshnessDaysOfAlert) {
    if (data.freshnessDaysOfAlert < 1) {
      errorInfo.freshnessDaysOfAlert = '1以上で入力してください';
    }
    else if (data.freshnessDaysOfAlert > 99) {
      errorInfo.freshnessDaysOfAlert = '99以下で入力してください';
    }
  }
  if (data.shubaiLimitAlert) {
    if (data.shubaiLimitAlert < 1) {
      errorInfo.shubaiLimitAlert = '1以上で入力してください';
    }
    else if (data.shubaiLimitAlert > 99) {
      errorInfo.shubaiLimitAlert = '99以下で入力してください';
    }
  }

  if((data.sejZanteiOrderLeadNew || data.sejZanteiOrderLeadNew === 0) && !(data.sejZanteiOrderLead || data.sejZanteiOrderLead === 0)){
    errorInfo.sejZanteiOrderLead = '入力してください';
  }
  if(!(data.sejZanteiOrderLeadNew || data.sejZanteiOrderLeadNew === 0) && (data.sejZanteiOrderLead || data.sejZanteiOrderLead === 0))
  {
    errorInfo.sejZanteiOrderLeadNew = '入力してください';
  }


  if (data.sejZanteiOrderLeadNew || data.sejZanteiOrderLeadNew === 0) {
    if (data.sejZanteiOrderLeadNew < 0) {
      errorInfo.sejZanteiOrderLeadNew = '0以上で入力してください';
    }
    else if (data.sejZanteiOrderLeadNew > 9) {
      errorInfo.sejZanteiOrderLeadNew = '9以下で入力してください';
    }
  }
  if (data.sejZanteiOrderLead || data.sejZanteiOrderLead === 0) {
    if (data.sejZanteiOrderLead < 0) {
      errorInfo.sejZanteiOrderLead = '0以上で入力してください';
    }
    else if (data.sejZanteiOrderLead > 9) {
      errorInfo.sejZanteiOrderLead = '9以下で入力してください';
    }
  }

  data.errorInfo = errorInfo;

  return data;
}

//RowDataGroupに変換
const convertDataGroups = (centerDatas: CenterData[]): RowDataGroup[] => {
  //センター順でソート
  centerDatas = centerDatas.sort((a,b) => {
    const objA = a;
    const objB = b;
    let comp = 0;
    comp = compareUtil.compareString(a.centerCD, b.centerCD, true)
    return comp;
  });

  return centerDatas.map(centerData => {
    return {
      centerData: centerData,
    };
  });
}
//並び順変更
const sortDataGroups = (dataGroups:RowDataGroup[], listSortOrder:CodeName, listSortOrderDesc:boolean): RowDataGroup[] => {
  if(!dataGroups) {
    return dataGroups;
  }
  let asc = !listSortOrderDesc;
  let getSortKey1 = (o:RowDataGroup):string|number => 
    !o || !o.centerData ? null : 
    !listSortOrder ? o.centerData.centerCD :
    listSortOrder.code == 'center' ? o.centerData.centerSortInput :
    listSortOrder.code == 'area' ? o.centerData.areaCD :
    listSortOrder.code == 'pref' ? o.centerData.prefCD :
    o.centerData.centerCD
  ;
  let getSortKey2 = (o:RowDataGroup):string|number => 
    !o || !o.centerData ? null : 
    o.centerData.centerSortInput
  ;
  let getSortKey3 = (o:RowDataGroup):string|number => 
    !o || !o.centerData ? null : 
    o.centerData.centerCD
  ;
  dataGroups.sort((a, b) => {
    //第1弾ソート項目
    let va = getSortKey1(a);
    let vb = getSortKey1(b);
    let comp = compareUtil.compareAny(va, vb, asc);
    //第2弾ソート項目
    if(comp == 0) {
      va = getSortKey2(a);
      vb = getSortKey2(b);
      comp = compareUtil.compareAny(va, vb, asc);
    }
    //第3弾ソート項目
    if(comp == 0) {
      va = getSortKey3(a);
      vb = getSortKey3(b);
      comp = compareUtil.compareAny(va, vb, asc);
    }
    return comp;
  });
  return dataGroups;
}
  //行情報に変換
const convertRowInfos = (dataGroups:RowDataGroup[], listSortOrder:CodeName, listSortOrderDesc:boolean, visibleRowsKey:string[], infoDatas: InfoData[]): [RowInfo[], number, InfoData[]] => {
  let newInfoDatas: InfoData[] = [...infoDatas];
  //map作成
  const infoDataMap = {};
  const infoDataIndexMap = {};
  newInfoDatas.forEach((infoData, index) => {
    const key = `${infoData.centerCD} ${infoData.tagCD}`;
    infoDataMap[key] = infoData;
    infoDataIndexMap[key] = index;
  });

  const targetRowsKeys = arrayutil.and(rowKeys, visibleRowsKey);

  const rowKeyInfos:{
    rowKey: string,
    rowIndex:number,
    rowIndexLast:boolean,
  }[] = [];
  targetRowsKeys.forEach((visibleRowKey, index) => {
    rowKeyInfos.push({
      rowKey: visibleRowKey,
      rowIndex:index,
      rowIndexLast: targetRowsKeys.length - 1 == index,
    });
  });

  const rowInfos:RowInfo[] = [];
  let fixedRowsTop:number = 0;


  //一番上に一括入力欄を用意する
  const dataGroupBulk:RowDataGroup = {
    centerData:{
      centerCD:"bulk",
    }
  };
  rowKeyInfos.forEach((rowKeyInfo) => {
    const centerData = dataGroupBulk.centerData;
    const key = `${centerData.centerCD} ${rowKeyInfo.rowKey}`;
    let infoData:InfoData = {
      centerCD: centerData.centerCD,
      tagCD: rowKeyInfo.rowKey,
    };
    let infoDataIndex = newInfoDatas.length;

    newInfoDatas.push(infoData);
    infoDataMap[key] = newInfoDatas;
    infoDataIndexMap[key] = infoDataIndex;

    rowInfos.push({
      ...{
        TP: "bulk",
        no: `${no}`,
        subno: `${subno}`,
        dataGroup: dataGroupBulk,
        infoData: infoData,
        infoDataIndex: infoDataIndex,
        row: rowInfos.length,
      }, 
      ...rowKeyInfo
    });
  })





  //明細行
  fixedRowsTop = rowInfos.length;


  let beforeDataGroup:RowDataGroup;
  let no = 0;
  let subno = 0;
  dataGroups.forEach(dataGroup => {
    //明細行
    subno++;
    rowKeyInfos.forEach((rowKeyInfo) => {
      const centerData = dataGroup.centerData;
      const key = `${centerData.centerCD} ${rowKeyInfo.rowKey}`;
      let infoData:InfoData = infoDataMap[key];
      let infoDataIndex:number = infoDataIndexMap[key];
      //未登録レコードは新規作成
      if(!infoData) {
        infoData = {
          centerCD: centerData.centerCD,
          tagCD: rowKeyInfo.rowKey,
        };
        infoDataIndex = newInfoDatas.length;

        newInfoDatas.push(infoData);
        infoDataMap[key] = newInfoDatas;
        infoDataIndexMap[key] = infoDataIndex;
      }

      rowInfos.push({
        ...{
          TP: "info",
          no: `${no}`,
          subno: `${subno}`,
          dataGroup: dataGroup,
          infoData: infoData,
          infoDataIndex: infoDataIndex,
          row: rowInfos.length,
        }, 
        ...rowKeyInfo
      });
    })

    beforeDataGroup = dataGroup;
  });
  return [rowInfos, fixedRowsTop, newInfoDatas];
}
//配列データに変換
const convertRows = (rowInfos:RowInfo[], colRowModel:SettingCenterColRowModel, listSortOrder:CodeName, listSortOrderDesc:boolean): any[][] => {
  return rowInfos.map((rowInfo) => convertRow(rowInfo, colRowModel, listSortOrder, listSortOrderDesc));
}
//配列データに変換
const convertRow = (rowInfo:RowInfo, colRowModel:SettingCenterColRowModel, listSortOrder:CodeName, listSortOrderDesc:boolean): any[] => {
  //set No.
  const dataGroup:RowDataGroup = rowInfo.dataGroup;
  return colRowModel.colKeys.map(colKey => valueFromKey(colKey, rowInfo, dataGroup, listSortOrder));
}
export const valueFromKey = (colKey: string, rowInfo: RowInfo, dataGroup: RowDataGroup, listSortOrder: CodeName): any => {
  //一括
  if (rowInfo.TP == "bulk") {

    //明細行
    switch (colKey) {
      case "target":
        return rowInfo.dataGroup?.target;
      case "no":
        return "";
      case "code":
      case "name":
      case "area":
      case "pref":
        return null;
      case "rowHeader":
        return headersRow[rowInfo.rowKey];

      case "allowablePeriodMaster":
        return rowInfo.infoData.allowablePeriodMaster;
      case "freshnessMinDaysOfHalfBestBefore":
        return rowInfo.infoData.freshnessMinDaysOfHalfBestBefore;
      case "freshnessDaysOfAlert":
        return rowInfo.infoData.freshnessDaysOfAlert;
      case "shubaiLimitAlert":
        return rowInfo.infoData.shubaiLimitAlert;
      case "todayUseFlg":
        return rowInfo.infoData.todayUseFlg;
      case "weekdayWarnFlg":
        return rowInfo.infoData.weekdayWarnFlg;
      case "shippingJuchuOrInput":
        return rowInfo.infoData.shippingJuchuOrInput;
      case "sejZanteiOrderLeadNew":
        return rowInfo.infoData.sejZanteiOrderLeadNew;
      case "sejZanteiOrderLead":
        return rowInfo.infoData.sejZanteiOrderLead;
          
      default: {
        return dataGroup[colKey];
      }
    }
  }

  if (rowInfo.TP == "info") {
    //明細行
    switch (colKey) {
      case "target":
        return rowInfo.dataGroup?.target;
      case "no":
        return `${rowInfo.subno}`;
      case "code":
        return dataGroup.centerData.centerCD2;
      case "name":
        return dataGroup.centerData.centerNM;
      case "area":
        return dataGroup.centerData.areaNM;
      case "pref":
        return dataGroup.centerData.prefNM;
      case "rowHeader":
        return headersRow[rowInfo.rowKey];

      case "allowablePeriodMaster":
        return rowInfo.infoData.allowablePeriodMaster;
      case "freshnessMinDaysOfHalfBestBefore":
        return rowInfo.infoData.freshnessMinDaysOfHalfBestBefore;
      case "freshnessDaysOfAlert":
        return rowInfo.infoData.freshnessDaysOfAlert;
      case "shubaiLimitAlert":
        return rowInfo.infoData.shubaiLimitAlert;
      case "todayUseFlg":
        return rowInfo.infoData.todayUseFlg;
      case "weekdayWarnFlg":
        return rowInfo.infoData.weekdayWarnFlg;
        case "shippingJuchuOrInput":
          return rowInfo.infoData.shippingJuchuOrInput;
      case "sejZanteiOrderLeadNew":
        return rowInfo.infoData.sejZanteiOrderLeadNew;
      case "sejZanteiOrderLead":
        return rowInfo.infoData.sejZanteiOrderLead;
            

      default: {
        return dataGroup[colKey];
      }
    }
  }
  return null;
}

//マージを作成
const createMergeCells = (rowInfos:RowInfo[], colRowModel:SettingCenterColRowModel): {row: number, col: number, rowspan: number, colspan: number}[] => {
  let mergeCells:{row: number, col: number, rowspan: number, colspan: number}[] = [];
  return mergeCells;
}


export const getOptionLabel = (option: CodeName) => {
  return option && option.name ? (option.code + ' ' + option.name) : "";
}

const createSliceContent = (name:string) => createSlice({
  name: name,
  initialState,
  reducers: createReducerContent(),
});

//Page Slice Export
//settingCenterTmp
export const settingCenterTmpSlice = createSliceContent("settingCenterTmp");
