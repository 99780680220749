import { CodeName } from "@/assets/commontype/CodeName";

//倉庫
export type CenterData = {
  bumonCD?: string, //--部門
  centerCD?: string | null, //倉庫(4桁コード)
  centerCD2?: string | null, //倉庫(2桁コード)
  centerNM?: string | null, //倉庫
  areaCD?: string | null, //地域
  areaNM?: string | null, //地域
  prefCD?: string | null, //都道府県
  prefNM?: string | null, //都道府県
  centerSortInput?: number | null, //倉庫並び順
  centerSortOutput?: number | null, //倉庫並び順
}

export interface ErrorInfoData {
	allowablePeriodMaster?: string | null,
	freshnessMinDaysOfHalfBestBefore?: string | null,
	freshnessDaysOfAlert?: string | null,

  shubaiLimitAlert?: string | null,

  sejZanteiOrderLeadNew?: string | null,
  sejZanteiOrderLead?: string | null,
}
export interface InfoData {
	uuid?: string,  //データベースに登録済みのUUID。更新時にDB上の値とずれていれば登録エラーとする

	centerCD?: string | null, //倉庫

	tagCD?: ''|string, //タグ属性　

  allowablePeriodMaster?: boolean,
	freshnessMinDaysOfHalfBestBefore?: number,	//--1 - 9999	1/2許容期限とする最低賞味期限日数
	freshnessDaysOfAlert?: number,	//--N日前にアラーム
	shubaiLimitAlert?: number,	//終売日N日前以降にアラート
  todayUseFlg?: boolean,
  weekdayWarnFlg?: boolean,
  shippingJuchuOrInput?: boolean,

  sejZanteiOrderLeadNew?: number,
  sejZanteiOrderLead?: number,

	//エラー情報
	errorInfo?: ErrorInfoData,

	edited?: boolean, //編集

  oldAllowablePeriodMaster?: boolean,
	oldFreshnessMinDaysOfHalfBestBefore?: number,	//--1 - 9999	1/2許容期限とする最低賞味期限日数
	oldFreshnessDaysOfAlert?: number,	//--N日前にアラーム
	oldShubaiLimitAlert?: number,	//終売日N日前以降にアラート
  oldTodayUseFlg?: boolean,
  oldWeekdayWarnFlg?: boolean,
  oldShippingJuchuOrInput?: boolean,
  oldSejZanteiOrderLeadNew?: number,
  oldSejZanteiOrderLead?: number,

}

export const centerAttributes:CodeName[][] = [
  [
    new CodeName({group_code:"center", group_name:"センター属性", code: "center", name: "倉庫", }),
    new CodeName({group_code:"center", group_name:"センター属性", code: "area", name: "地域", }),
    new CodeName({group_code:"center", group_name:"センター属性", code: "pref", name: "都道府県", }),
  ],
  [
    new CodeName({group_code:"center", group_name:"センター属性", code: "center", name: "倉庫", }),
    new CodeName({group_code:"center", group_name:"センター属性", code: "area", name: "地域", }),
    new CodeName({group_code:"center", group_name:"センター属性", code: "pref", name: "都道府県", }),
  ]
];

//並び順
export const listSortOrders:CodeName[] = [
  new CodeName({group_code:"", group_name:"", code: "centerCD", name: "倉庫CD", }),
  new CodeName({group_code:"", group_name:"", code: "centerNM", name: "倉庫名", }),
  new CodeName({group_code:"", group_name:"", code: "area", name: "地域", }),
  new CodeName({group_code:"", group_name:"", code: "pref", name: "都道府県", }),
];