import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CodeName } from "@/assets/commontype/CodeName";
import * as arrayUtil from "@/util/arrayUtil";
import { SettingMakerColRowModel, settingMakerColRowModelDefault } from "@/components/settingmaker/SettingMakerTableModel";
import { centerMakerAttributes, listSortOrders } from "@/store/settingmaker/settingMakerCommon";

//強制表示列
export const mustVisibleColumnsKey:string[] = [
  "target", 
  "no", 
  "code", "name", 
  //入力項目
	'blInputFlg',
	'leadTimeFlg',
	'leadTimeDays',
  'leadTimeExcludeSaturdayFlg',
	'leadTimeContainHolidayFlg',
	'specifiedDateFlg',
	'specifiedDateSunFlg',
	'specifiedDateMonFlg',
	'specifiedDateTueFlg',
	'specifiedDateWedFlg',
	'specifiedDateThrFlg',
	'specifiedDateFriFlg',
	'specifiedDateSatFlg',
	'specifiedDateHolFlg',
	'minQtyFlg',
	'minQtyCs',
	'maxQtyFlg',
	'maxQtyCs',
	'closeFlg',
	'closeTime',
	'autoOrderFlg',
  'autoOrderExecDays',
	'autoOrderDays',
	'autoOrderContainSundayFlg',
	'fractionRounding',
  'makerCenterName',

];
//デフォルト表示列
export const defaultVisibleColumnsKey:string[] = [
  //以下に貼り付け
  "area", 
  "pref",
];
//強制表示行
export const mustVisibleRowsKey:string[] = [
  'const'
];
//デフォルト表示行
export const defaultVisibleRowsKey:string[] = [
];

export const optionsNameList = [
  'optionBumons',
  'optionMakers',
  'optionMakerCode',
  'optionAreas',
  'optionCenters',
  'optionCenterCode',
];
export type SettingMakerOptions = {
  optionBumons?: CodeName[],    //部門
  optionMakers?: CodeName[],    //メーカー
  optionMakerCode?: string,     //メーカーCD
  optionAreas?: CodeName[],     //地域
  optionCenters?: CodeName[],   //倉庫
  optionCenterCode?: string,    //倉庫CD
};

//Page State
export type SettingMakerSaveState = {
  //検索条件
  refreshOptions_ver: number,
  optionBumons:CodeName[],    //部門
  optionMakers:CodeName[],    //メーカー
  optionMakerCode:string,            //メーカーCD
  optionAreas:CodeName[],     //地域
  optionCenters:CodeName[],   //倉庫
  optionCenterCode:string,            //倉庫CD

  favoriteOptions: { title: CodeName, params: SettingMakerOptions }[] | null,

  listSortOrder:CodeName,   //並び順
  listSortOrderDesc:boolean,   //昇順

  hiddenColumns: number[],
  hiddenColumnsKey: string[],
  visibleColumnsKey: string[],
  visibleRowsKey: string[],

  colWidthsModified: {},
};

export const initialSettingMakerState: SettingMakerSaveState = {
  refreshOptions_ver: 0,
  optionBumons:[],    //部門
  optionMakers:[],    //メーカー
  optionMakerCode:'',            //メーカーCD
  optionAreas:[],     //地域
  optionCenters:[],   //倉庫
  optionCenterCode:'',            //倉庫CD

  favoriteOptions: [],

  listSortOrder: listSortOrders.find(listSortOrder => listSortOrder.code == 'makerCD'),   //並び順
  listSortOrderDesc: false,   //並び順

  hiddenColumns: [],
  hiddenColumnsKey: [],
  visibleColumnsKey: defaultVisibleColumnsKey,
  visibleRowsKey: defaultVisibleRowsKey,

  colWidthsModified: {},

};

//Page Slice
export type SettingMakerSaveReducer = {
  initOnDidMount: (state:SettingMakerSaveState) => void,
  resetOnWillUnmount: (state:SettingMakerSaveState) => void,
  clearOption: (state:SettingMakerSaveState, action: PayloadAction<string>) => void,
  refreshOptions: (state: SettingMakerSaveState) => void,
  setOptionBumons: (state:SettingMakerSaveState, action: PayloadAction<CodeName[]>) => void,
  setOptionMakers: (state:SettingMakerSaveState, action: PayloadAction<CodeName[]>) => void,
  setOptionMakerCode: (state:SettingMakerSaveState, action: PayloadAction<string>) => void,
  setOptionAreas: (state:SettingMakerSaveState, action: PayloadAction<CodeName[]>) => void,
  setOptionCenters: (state:SettingMakerSaveState, action: PayloadAction<CodeName[]>) => void,
  setOptionCenterCode: (state:SettingMakerSaveState, action: PayloadAction<string>) => void,
  setFavoriteOptions: (state:SettingMakerSaveState, action: PayloadAction<{ title: CodeName, params: SettingMakerOptions }[]>) => void,
  setListSortOrder: (state:SettingMakerSaveState, action: PayloadAction<CodeName>) => void,
  setListSortOrderDesc: (state:SettingMakerSaveState, action: PayloadAction<boolean>) => void,
  columnWidthChange: (state:SettingMakerSaveState, action: PayloadAction<{colKey:string, width:number}>) => void,
  hideColumnKeysChange: (state:SettingMakerSaveState, action: PayloadAction<string[]>) => void,
  visibleRowKeysChange: (state:SettingMakerSaveState, action: PayloadAction<string[]>) => void,
}

const createReducerContent = (name:string, colRowModel:SettingMakerColRowModel, initialState: SettingMakerSaveState):SettingMakerSaveReducer => {return {
  //componentDidMount
  initOnDidMount(state:SettingMakerSaveState) {
    if(state.optionBumons === undefined) {
      state.optionBumons = initialSettingMakerState.optionBumons;
    }
    
    if(state.optionMakers === undefined) {
      state.optionMakers = initialSettingMakerState.optionMakers;
    }
    if(state.optionMakerCode === undefined) {
      state.optionMakerCode = initialSettingMakerState.optionMakerCode;
    }
    if(state.optionAreas === undefined) {
      state.optionAreas = initialSettingMakerState.optionAreas;
    }
    if(state.optionCenters === undefined) {
      state.optionCenters = initialSettingMakerState.optionCenters;
    }
    if(state.optionCenterCode === undefined) {
      state.optionCenterCode = initialSettingMakerState.optionCenterCode;
    }
    if(state.favoriteOptions === undefined) {
      state.favoriteOptions = initialSettingMakerState.favoriteOptions;
    }
    if(!state.listSortOrder) {
      state.listSortOrder = initialState.listSortOrder;
    }
    else if(state.listSortOrder.code) { //属性値を再選択する
      const code = state.listSortOrder.code;
      state.listSortOrder = listSortOrders.find(listSortOrder => listSortOrder.code == code);   //並び順
    }

    if(state.listSortOrderDesc === undefined) {
      state.listSortOrderDesc = initialState.listSortOrderDesc;
    }

    //初期表示列0配列は、常に全項目表示とする
    if(initialState.visibleColumnsKey.length == 0) {
      state.visibleColumnsKey = colRowModel.colKeys;
    }
    else if(!state.visibleColumnsKey) {
      state.visibleColumnsKey = initialState.visibleColumnsKey;
    }
    //強制選択列
    state.visibleColumnsKey = arrayUtil.union(arrayUtil.and(colRowModel.colKeys, mustVisibleColumnsKey), state.visibleColumnsKey);
    //非表示列
    state.hiddenColumnsKey = arrayUtil.not(colRowModel.colKeys, state.visibleColumnsKey);

    //行項目
    if (!state.visibleRowsKey || state.visibleRowsKey.length == 0) {
      state.visibleRowsKey = (initialState.visibleRowsKey.length == 0) ? colRowModel.rowKeys : initialState.visibleRowsKey;
    }
    //強制選択行
    state.visibleRowsKey = arrayUtil.union(arrayUtil.and(colRowModel.rowKeys, mustVisibleRowsKey), state.visibleRowsKey);

    if(state.colWidthsModified === undefined) {
      state.colWidthsModified = initialState.colWidthsModified;
    }

  },
  //componentWillUnmount
  resetOnWillUnmount(state:SettingMakerSaveState) {
    //初期表示列0配列は、常に全項目表示とするため、記憶しない
    if(initialState.visibleColumnsKey.length == 0) {
      state.visibleColumnsKey = [];
    }
  },
  clearOption(state: SettingMakerSaveState, action: PayloadAction<string>) {
    const key = action.payload;
    if (!key) {
      // state.optionBumons = initialSettingMakerState.optionBumons;
    }
    if (!key || key === 'item') {
      state.optionMakers = initialSettingMakerState.optionMakers;
      state.optionMakerCode = initialSettingMakerState.optionMakerCode;
    }
    if (!key || key === 'center') {
      state.optionAreas = initialSettingMakerState.optionAreas;
      state.optionCenters = initialSettingMakerState.optionCenters;
      state.optionCenterCode = initialSettingMakerState.optionCenterCode;
    }
  },
  refreshOptions (state: SettingMakerSaveState) {
    state.refreshOptions_ver = state.refreshOptions_ver + 1;
  },
  setOptionBumons (state:SettingMakerSaveState, action: PayloadAction<CodeName[]>) {
    state.optionBumons = action.payload;
  },
  setOptionMakers (state:SettingMakerSaveState, action: PayloadAction<CodeName[]>) {
    state.optionMakers = action.payload;
  },
  setOptionMakerCode (state:SettingMakerSaveState, action: PayloadAction<string>) {
    state.optionMakerCode = action.payload;
  },
  setOptionAreas (state:SettingMakerSaveState, action: PayloadAction<CodeName[]>) {
    state.optionAreas = action.payload;
  },
  setOptionCenters (state:SettingMakerSaveState, action: PayloadAction<CodeName[]>) {
    state.optionCenters = action.payload;
  },
  setOptionCenterCode (state:SettingMakerSaveState, action: PayloadAction<string>) {
    state.optionCenterCode = action.payload;
  },
  setFavoriteOptions(state:SettingMakerSaveState, action: PayloadAction<{ title: CodeName, params: SettingMakerOptions }[]>) {
    state.favoriteOptions = action.payload;
  },
  setListSortOrder (state:SettingMakerSaveState, action: PayloadAction<CodeName>) {
    state.listSortOrder = action.payload;
  },
  setListSortOrderDesc (state:SettingMakerSaveState, action: PayloadAction<boolean>) {
    state.listSortOrderDesc = action.payload;
  },

  columnWidthChange(state:SettingMakerSaveState, action: PayloadAction<{colKey:string, width:number}>){
    console.log('store.columnWidthChange');
    const colKey = action.payload.colKey;
    const width = action.payload.width;
    if(colKey && width) {
      state.colWidthsModified[colKey] = width;
    }
  },
  hideColumnKeysChange(state:SettingMakerSaveState, action: PayloadAction<string[]>){
    console.log('store.hideColumnKeysChange');
    //store更新
    state.hiddenColumns = colRowModel.colFromKeys(action.payload);
    state.hiddenColumnsKey = action.payload;
    state.visibleColumnsKey = arrayUtil.not(colRowModel.colKeys, action.payload);
  },
  visibleRowKeysChange(state:SettingMakerSaveState, action: PayloadAction<string[]>){
    console.log('store.visibleRowKeysChange');
    //store更新
    state.visibleRowsKey = action.payload;
  },
  
}};

const createSliceContent = (name:string, colRowModel:SettingMakerColRowModel, initialState: SettingMakerSaveState) => createSlice({
  name: name,
  initialState: initialState,
  reducers: createReducerContent(name, colRowModel, initialState),
});

//Page Slice Export
//settingMakerSaveSlice
export const settingMakerSaveSlice = createSliceContent("settingMakerSave", settingMakerColRowModelDefault, initialSettingMakerState);
