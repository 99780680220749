import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CodeName } from "@/assets/commontype/CodeName";

import { CenterItem, InfoDataCommon, ErrorInfoDataCommon } from '@/store/settinglimit/settingLimitCommon'
import * as settingLimitRetrieve from "@/assets/apitype/settingLimitRetrieve";
import { SettingLimitColRowModel, rowKeys, colDataType, rowDataType } from "@/components/settinglimit/SettingLimitTableModel";
import { ItemTag, itemTags } from "@/assets/commontype/ItemTag";
import * as compareUtil from "@/util/compareUtil";
import * as arrayutil from "@/util/arrayUtil";
import * as editorUtil from "@/util/editorUtil";
import moment from 'moment';

//0.00フォーマット
const formatterP00 = new Intl.NumberFormat('ja-JP', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 });
//0,0フォーマット
const formatterN0 = new Intl.NumberFormat('ja-JP', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0 });

//編集中判定
export const isEditedRowDataCommon = (data: InfoDataCommon): boolean => {
  if(!data) {
    return false;
  }
  return (
    (data.stockLimitDate != data.oldStockLimitDate) ||
    (data.stockLimitQty != data.oldStockLimitQty)
  );
}

export type RowDataGroup = {
  centerItem: CenterItem,
  target?: boolean,
  hasChildTarget?: boolean,
}

export type RowInfo = {
  TP: "item" | "center" | "info" | "bulk",

  no?: string,
  subno?: string,
  dataGroup:RowDataGroup,
  rowKey?: string,
  itemTag?: ItemTag,

  row:number,  
  rowIndex?:number,  //同一集計行内でのrowKeyのindex
  rowIndexLast?:boolean, //同一集計行内での最後のrowKeyフラグ

  infoDataCommon? :InfoDataCommon,
  infoDataCommonIndex?:number,
}
export interface EditCellInfo {
  row:number, 
  col:number, 
  rowKey:string, 
  value:string|number|object|null, 
  relatedValues?: {key:string, value:string|number|object|null}[],
}

//Page State
export type SettingLimitTmpState = {
  // bumonList: CodeName[],  //部門
  makerList: CodeName[],  //メーカー
  areaList: CodeName[], //地域
  centerList: CodeName[], //倉庫

  progress: Record<string, unknown>,
  retrieveParam: settingLimitRetrieve.RequestParam,  //検索条件
  retrievedParam: settingLimitRetrieve.RequestParam,  //検索条件(検索済み)

  dataGroups: RowDataGroup[],
  rowInfos: RowInfo[],
  fixedRowsTop :number,
  infoDatasCommon: InfoDataCommon[],

  tableVer_updateData: number,
  tableVer_updateData_rows: number[],
  tableVer_updateSettting: number,
  rows: any[][],
  mergeCells: {row: number, col: number, rowspan: number, colspan: number}[]

  errorMessage: string | null,
  infoMessage: string | null,

  editing: boolean,
  functionKeys: string[],
  // openDialog: boolean,
};

export const initialState: SettingLimitTmpState = {
  // bumonList: [],
  makerList: [],
  areaList: [],
  centerList: [],

  progress: {},
  retrieveParam: null,  //検索条件
  retrievedParam: null,

  dataGroups: [],
  rowInfos: [],
  fixedRowsTop :0,
  infoDatasCommon: [],
  tableVer_updateData: 0,
  tableVer_updateData_rows: [],
  tableVer_updateSettting: 0,
  rows: [],
  mergeCells: null,

  errorMessage: null,
  infoMessage: null,

  editing: false,
  functionKeys: [],
  // openDialog: false,
};

//Page Slice
export type SettingLimitTmpReducer = {
  putProgress: (state:SettingLimitTmpState, action: PayloadAction<string>) => void,
  removeProgress: (state:SettingLimitTmpState, action: PayloadAction<string>) => void,
  setErrorMessage: (state:SettingLimitTmpState, action: PayloadAction<string>) => void,
  setInfoMessage: (state:SettingLimitTmpState, action: PayloadAction<string>) => void,
  setEditingStart: (state:SettingLimitTmpState, action: PayloadAction<string>) => void,
  setEditingEnd: (state:SettingLimitTmpState, action: PayloadAction<string>) => void,
  // setBumonList: (state:SettingLimitTmpState, action: PayloadAction<CodeName[]>) => void,
  setMakerList: (state:SettingLimitTmpState, action: PayloadAction<CodeName[]>) => void,
  setAreaList: (state:SettingLimitTmpState, action: PayloadAction<CodeName[]>) => void,
  setCenterList: (state:SettingLimitTmpState, action: PayloadAction<CodeName[]>) => void,
  startRetrieve: (state:SettingLimitTmpState, action: PayloadAction<{
    retrieveParam: settingLimitRetrieve.RequestParam,  //検索条件
  }>) => void,
  setRetrievedParam: (state:SettingLimitTmpState, action: PayloadAction<settingLimitRetrieve.RequestParam>) => void,

  searched: (state:SettingLimitTmpState, action: PayloadAction<{param: settingLimitRetrieve.RequestParam, centerItems: CenterItem[], infosCommon: InfoDataCommon[], colRowModel:SettingLimitColRowModel, listSortOrder:CodeName, listSortOrderDesc:boolean, visibleRowsKey:string[], hasBulk:boolean}>) => void,
  refreshTable: (state:SettingLimitTmpState, action: PayloadAction<{colRowModel:SettingLimitColRowModel, listSortOrder:CodeName, listSortOrderDesc:boolean, visibleRowsKey:string[], hasBulk:boolean}>) => void,
  editRowDatas: (state:SettingLimitTmpState, action: PayloadAction<{colRowModel:SettingLimitColRowModel, listSortOrder:CodeName, listSortOrderDesc:boolean, visibleRowsKey:string[], editDatas:EditCellInfo[]}>) => void,
  setFunctionKey: (state:SettingLimitTmpState, action: PayloadAction<string>) => void,
}

const createReducerContent = ():SettingLimitTmpReducer => {return {
    putProgress(state:SettingLimitTmpState, action: PayloadAction<string>) {
      const key = action.payload;
      const progressNew = {...state.progress};
      progressNew[key] = true;
      state.progress = progressNew;
    },
    removeProgress(state:SettingLimitTmpState, action: PayloadAction<string>) {
      const key = action.payload;
      const progressNew = {};
      Object.keys(state.progress).forEach(k => {
        if(key != k) {
          progressNew[k] = true;
        }
      })
      state.progress = progressNew;
    },
    setErrorMessage(state:SettingLimitTmpState, action: PayloadAction<string>) {
      state.errorMessage = action.payload;
    },
    setInfoMessage(state:SettingLimitTmpState, action: PayloadAction<string>) {
      state.infoMessage = action.payload;
    },
    setEditingStart(state:SettingLimitTmpState) {
      console.log('store.setEditingStart');
      state.editing = true;
      state.retrieveParam = null;  //検索条件
    },
    setEditingEnd(state:SettingLimitTmpState) {
      console.log('store.setEditingEnd');

      state.editing= false;

      state.retrieveParam = null;  //検索条件

      state.progress= {};
      // state.retrieveParam= null;
      state.retrievedParam= null;
      
      state.dataGroups= [];
      state.rowInfos= [];
      state.fixedRowsTop =0
      state.infoDatasCommon = [];
      state.rows= [];
      state.mergeCells= null;
      
      state.tableVer_updateSettting = state.tableVer_updateSettting+1;  //カウントアップしテーブルを再構築対象とする (updateSetting)

    },
    // setBumonList(state:SettingLimitTmpState, action: PayloadAction<CodeName[]>) {
    //   state.bumonList = action.payload;
    // },
    setMakerList(state:SettingLimitTmpState, action: PayloadAction<CodeName[]>) {
      state.makerList = action.payload;
    },
    setAreaList(state:SettingLimitTmpState, action: PayloadAction<CodeName[]>) {
      state.areaList = action.payload;
    },
    setCenterList(state:SettingLimitTmpState, action: PayloadAction<CodeName[]>) {
      state.centerList = action.payload;
    },
    startRetrieve(state:SettingLimitTmpState, action: PayloadAction<{
      retrieveParam: settingLimitRetrieve.RequestParam,  //検索条件
    }>) {
      state.retrieveParam = action.payload.retrieveParam;
    },
    setRetrievedParam(state:SettingLimitTmpState, action: PayloadAction<settingLimitRetrieve.RequestParam>) {
      state.retrievedParam = action.payload;
    },

    searched(state:SettingLimitTmpState, action: PayloadAction<{param: settingLimitRetrieve.RequestParam, centerItems: CenterItem[], infosCommon: InfoDataCommon[], colRowModel:SettingLimitColRowModel, listSortOrder:CodeName, listSortOrderDesc:boolean, visibleRowsKey:string[], hasBulk:boolean}>) {
      const setOldDataCommon = (data: InfoDataCommon) => {
        data.oldStockLimitDate = data.stockLimitDate;
        data.oldStockLimitQty = data.stockLimitQty;
      }
      const colRowModel = action.payload.colRowModel;
      const param = action.payload.param;

      let centerItems = parseDataCenterItem(action.payload.centerItems);
      let infosCommon = parseDataInfoCommonData(action.payload.infosCommon);
      //編集前のデータをセット

      infosCommon.forEach(data => setOldDataCommon(data));
      infosCommon = checkDatasCommon(infosCommon); //データチェック
      const hasBulk = action.payload.hasBulk;
      const listSortOrder = action.payload.listSortOrder;
      const listSortOrderDesc = action.payload.listSortOrderDesc;
      const visibleRowsKey = action.payload.visibleRowsKey;

      let dataGroups = convertDataGroups(centerItems);
      dataGroups = sortDataGroups(dataGroups, listSortOrder, listSortOrderDesc);
      const [rowInfos, fixedRowsTop, newinfosCommon] = convertRowInfos(dataGroups, listSortOrder, listSortOrderDesc, visibleRowsKey, infosCommon, hasBulk);
      //新規も編集前のデータをセットをセット
      newinfosCommon.forEach(data => setOldDataCommon(data));

      //store更新
      state.dataGroups = dataGroups;
      state.rowInfos = rowInfos;
      state.fixedRowsTop = fixedRowsTop;
      state.infoDatasCommon = newinfosCommon;

      state.rows = convertRows(rowInfos, colRowModel);
      state.mergeCells = createMergeCells(rowInfos, colRowModel);

      state.tableVer_updateSettting = state.tableVer_updateSettting+1;  //カウントアップしテーブルを再構築対象とする (updateSetting)
    },
    refreshTable(state:SettingLimitTmpState, action: PayloadAction<{colRowModel:SettingLimitColRowModel, listSortOrder:CodeName, listSortOrderDesc:boolean, visibleRowsKey:string[], hasBulk:boolean}>){
      console.log('refreshTable');
      const hasBulk = action.payload.hasBulk;
      const listSortOrder = action.payload.listSortOrder;
      const listSortOrderDesc = action.payload.listSortOrderDesc;
      const visibleRowsKey = action.payload.visibleRowsKey;
      const colRowModel = action.payload.colRowModel;
      let dataGroups = state.dataGroups;
      dataGroups = sortDataGroups(dataGroups, listSortOrder, listSortOrderDesc);
      const [rowInfos, fixedRowsTop, newinfosCommon] = convertRowInfos(dataGroups, listSortOrder, listSortOrderDesc, visibleRowsKey, state.infoDatasCommon, hasBulk);
      //store更新
      state.dataGroups = dataGroups;
      state.rowInfos = rowInfos;
      state.fixedRowsTop = fixedRowsTop;
      state.infoDatasCommon = newinfosCommon;
      state.rows = convertRows(rowInfos, colRowModel);
      state.mergeCells = createMergeCells(rowInfos, colRowModel);

      state.tableVer_updateSettting = state.tableVer_updateSettting+1;  //カウントアップしテーブルを再構築対象とする (updateSetting)
    },

    editRowDatas(state:SettingLimitTmpState, action: PayloadAction<{colRowModel:SettingLimitColRowModel, listSortOrder:CodeName, listSortOrderDesc:boolean, visibleRowsKey:string[], editDatas:EditCellInfo[]}>) {
      console.log('store.editRowDatas');
      const listSortOrder = action.payload.listSortOrder;
      const listSortOrderDesc = action.payload.listSortOrderDesc;
      const visibleRowsKey = action.payload.visibleRowsKey;
      const colRowModel = action.payload.colRowModel;
      const editDatas = action.payload.editDatas;

      // const newInfoDatas = [...state.infoDatas];
      const newInfoDatasCommon = state.infoDatasCommon;
      const newRows = state.rows;

      let targetChange = false;

      let editedRows:Set<number> = new Set<number>();
      editDatas.forEach((editData)=>{
        const rowInfo:RowInfo = state.rowInfos[editData.row];
        if(!rowInfo) {
          return;
        }

        //
        const dataGroup = rowInfo.dataGroup;
        const infoDataCommon = rowInfo.infoDataCommon;
        const infoDataCommonIndex = rowInfo.infoDataCommonIndex;
        if(!dataGroup) {
          return;
        }
        
        const colKey:string = colRowModel.keyFromCol(editData.col);
        const rowKey:string = rowInfo.rowKey;

        let dataType = rowDataType[rowKey];
        if(!dataType) {
          dataType = colDataType[colKey];
        }

        const value = editorUtil.parseValue(editData.value, dataType.type,
          dataType.type == 'numeric' ? dataType.numericFormat.pattern :
          dataType.type == 'date' ? dataType.dateFormat :
          null);

        if(colKey == 'target') {
          const rowTop = rowInfo.row - rowInfo.rowIndex;
          const rowInfoTop = state.rowInfos[rowTop];
          const dataGroupTop = rowInfoTop.dataGroup;
          dataGroupTop.target = value == true;
          editedRows.add(rowTop);
          rowInfoTop.dataGroup = dataGroupTop;
          state.rowInfos[rowTop] = rowInfoTop;

          targetChange = true;
        }
        else {
          newInfoDatasCommon[infoDataCommonIndex][colKey] = value;
          editedRows.add(rowInfo.row);
          newInfoDatasCommon[infoDataCommonIndex] = checkDataCommon(newInfoDatasCommon[infoDataCommonIndex]);
          state.rowInfos[rowInfo.row].infoDataCommon = newInfoDatasCommon[infoDataCommonIndex];
        }

      });

      //選択有無を記録する
      if(targetChange) {
        const rowInfoBulk = state.rowInfos[0];
        const dataGroupBulk = rowInfoBulk.dataGroup;
        dataGroupBulk.hasChildTarget = !(!(state.rowInfos.find(rowInfo => rowInfo.dataGroup?.target)));
        rowInfoBulk.dataGroup = dataGroupBulk;
      }

      if(editedRows.size > 0) {
        const tableVer_updateData_rows = [];
        editedRows.forEach(row => {
          tableVer_updateData_rows.push(row);
          newRows[row] = convertRow(state.rowInfos[row], colRowModel);
        });
        // state.rows = newRows;

        /*
        const [rowInfos, fixedRowsTop, newinfos] = convertRowInfos(state.dataGroups, listSortOrder, listSortOrderDesc, visibleRowsKey, newInfoDatas);
        
        // //store更新
        state.rowInfos = rowInfos;
        state.fixedRowsTop = fixedRowsTop;
        state.infoDatas = newinfos;
        state.rows = convertRows(rowInfos, colRowModel);
        state.mergeCells = createMergeCells(rowInfos, colRowModel);
        */

        state.tableVer_updateData_rows = tableVer_updateData_rows;
        state.tableVer_updateData = state.tableVer_updateData+1;  //カウントアップしテーブルを再描写対象とする (updateData)
      }

    },
    setFunctionKey(state:SettingLimitTmpState, action: PayloadAction<string>) {
      state.functionKeys = action.payload ? [action.payload] : [];
    },
}};

const parseDataCenterItem = (datas:CenterItem[]): CenterItem[] => {
  datas = [...datas];
  datas.forEach((data) => {
    // if(typeof data.termStartDate === 'string') data.blIrisu = parseInt(data.blIrisu);
    // if(typeof data.termEndDate === 'string') data.blIrisu = parseInt(data.blIrisu);

    if(typeof data.blIrisu === 'string') data.blIrisu = parseInt(data.blIrisu);
    if(typeof data.csIrisu === 'string') data.csIrisu = parseInt(data.csIrisu);
    // if(typeof data.lot === 'string') data.lot = parseInt(data.lot);
    // if(typeof data.csIrisu_lot === 'string') data.csIrisu_lot = parseInt(data.csIrisu_lot);
    if(typeof data.teika === 'string') data.teika = parseInt(data.teika);
    if(typeof data.tatene === 'string') data.tatene = parseFloat(data.tatene);
  });
  return datas;
}
const parseDataInfoCommonData = (datas:InfoDataCommon[]): InfoDataCommon[] => {
  datas = [...datas];
  datas.forEach((data) => {
    // if(typeof data.stockLimitDate === 'string') data.stockLimitDate = parseInt(data.stockLimitDate);
    if(typeof data.stockLimitQty === 'string') data.stockLimitQty = parseInt(data.stockLimitQty);
  });
  return datas;
}

//チェック
const checkDatasCommon = (datas: InfoDataCommon[]): InfoDataCommon[] => {
  console.log('store.checkDatasCommon');
  return datas.map(data => checkDataCommon(data));
}
const checkDataCommon = (data: InfoDataCommon): InfoDataCommon => {
  //編集状態をセットする
  // Object.keys(data).forEach(key => {
    data.edited = isEditedRowDataCommon(data);
  // });

  const errorInfo: ErrorInfoDataCommon = {};
  if(!data.errorInfo) {
    data.errorInfo = {};
  }

  const empty_stockLimitDate = !data.stockLimitDate;
  const empty_stockLimitQty = (data.stockLimitQty === null || data.stockLimitQty === undefined);
  if(!empty_stockLimitDate) {
    if(empty_stockLimitQty) {
      errorInfo.stockLimitQty = '入力してください';
    }
    const moment_stockLimitDate = moment('20' + data.stockLimitDate);
    if(!moment_stockLimitDate.isValid()) {
      errorInfo.stockLimitDate = '有効な日付を入力してください';
    }
    else if(moment_stockLimitDate.isAfter(moment(new Date()))) {
      errorInfo.stockLimitDate = '今日以前の日付を入力してください';
    }
  }
  if(!empty_stockLimitQty) {
    if(empty_stockLimitDate) {
      errorInfo.stockLimitDate = '入力してください';
    }
    if(data.stockLimitQty < 0) {
      errorInfo.stockLimitQty = '0以上の数値を入力してください';
    }
  }

  data.errorInfo = errorInfo;
  return data;
}

//RowDataGroupに変換
const convertDataGroups = (centerItems: CenterItem[]): RowDataGroup[] => {
  //商品、センター順でソート
  centerItems = centerItems.sort((a,b) => {
    const objA = a;
    const objB = b;
    let comp = 0;
    comp = compareUtil.compareString(a.itemCD, b.itemCD, true)
    if(comp == 0) {
      comp = compareUtil.compareString(a.bumonCD, b.bumonCD, true)
    }
    if(comp == 0) {
      comp = compareUtil.compareString(a.makerCenterName, b.makerCenterName, true)
    }
    return comp;
  });

  return centerItems.map(centerItem => {
    return {
      centerItem: centerItem,
    };
  });
}
//並び順変更
const sortDataGroups = (dataGroups:RowDataGroup[], listSortOrder:CodeName, listSortOrderDesc:boolean): RowDataGroup[] => {
  if(!dataGroups) {
    return dataGroups;
  }
  let asc = !listSortOrderDesc;
  let getSortKey1 = (o:RowDataGroup):string|number => 
    !o || !o.centerItem ? null : 
    !listSortOrder ? o.centerItem.itemCD :
    listSortOrder.code == 'itemCD' ? o.centerItem.itemCD :
    listSortOrder.code == 'itemNM' ? o.centerItem.itemNM :
    listSortOrder.code == 'janCD' ? o.centerItem.janCD :
    listSortOrder.code == 'maker' ? o.centerItem.makerCD :
    listSortOrder.code == 'category' ? o.centerItem.categoryCD :
    listSortOrder.code == 'bestBefore' ? o.centerItem.bestBefore :
    listSortOrder.code == 'teika' ? o.centerItem.teika :
    listSortOrder.code == 'tatene' ? o.centerItem.tatene :
    listSortOrder.code == 'center' ? o.centerItem.makerCenterName :
    o.centerItem.itemCD
  ;
  let getSortKey2 = (o:RowDataGroup):string|number => 
    !o || !o.centerItem ? null : 
    !listSortOrder ? o.centerItem.itemCD :
    listSortOrder.group_code == 'item' ? o.centerItem.itemCD :
    listSortOrder.group_code == 'center' ? o.centerItem.makerCenterName :
    o.centerItem.itemCD
  ;
  let getSortKey3 = (o:RowDataGroup):string|number => 
    !o || !o.centerItem ? null : 
    !listSortOrder ? o.centerItem.makerCenterName :
    listSortOrder.group_code == 'item' ? o.centerItem.makerCenterName :
    listSortOrder.group_code == 'center' ? o.centerItem.itemCD :
    o.centerItem.makerCenterName
  ;
  dataGroups.sort((a, b) => {
    //第1弾ソート項目
    let va = getSortKey1(a);
    let vb = getSortKey1(b);
    let comp = compareUtil.compareAny(va, vb, asc);
    //第2弾ソート項目
    if(comp == 0) {
      va = getSortKey2(a);
      vb = getSortKey2(b);
      comp = compareUtil.compareAny(va, vb, asc);
    }
    //第3弾ソート項目
    if(comp == 0) {
      va = getSortKey3(a);
      vb = getSortKey3(b);
      comp = compareUtil.compareAny(va, vb, asc);
    }
    return comp;
  });
  return dataGroups;
}
  //行情報に変換
const convertRowInfos = (dataGroups:RowDataGroup[], listSortOrder:CodeName, listSortOrderDesc:boolean, visibleRowsKey:string[], infoDatasCommon: InfoDataCommon[], hasBulk:boolean): [RowInfo[], number, InfoDataCommon[]] => {
  let newInfoDatasCommon: InfoDataCommon[] = [...infoDatasCommon];

  //map作成
  const infoDataCommonMap = {};
  const infoDataCommonIndexMap = {};
  newInfoDatasCommon.forEach((infoData, index) => {
    const key = `${infoData.itemCD} ${infoData.bumonCD} ${infoData.makerCenterName}`;
    infoDataCommonMap[key] = infoData;
    infoDataCommonIndexMap[key] = index;
  });

  const targetRowsKeys = arrayutil.and(rowKeys, visibleRowsKey);

  let rowInfos:RowInfo[] = [];
  let fixedRowsTop:number = 0;

  const rowKeyInfos:{
    rowKey: string,
    itemTag: ItemTag,
    rowIndex:number,
    rowIndexLast:boolean,
  }[] = [];
  targetRowsKeys.forEach((visibleRowKey, index) => {
    rowKeyInfos.push({
      rowKey: visibleRowKey,
      itemTag: itemTags.find(itemTag => itemTag.code == visibleRowKey),
      rowIndex:index,
      rowIndexLast: targetRowsKeys.length - 1 == index,
    });
  });

  //一番上に一括入力欄を用意する
  if(hasBulk) {
    const dataGroupBulk:RowDataGroup = {
      centerItem:{
        itemCD:"bulk",
        bumonCD:"bulk",
        makerCenterName:"bulk",
      }
    };

    let infoDataCommon:InfoDataCommon = {
      itemCD: dataGroupBulk.centerItem.itemCD,
      bumonCD: dataGroupBulk.centerItem.bumonCD,
      makerCenterName: dataGroupBulk.centerItem.makerCenterName,
    };
    const keyCommon = `${dataGroupBulk.centerItem.itemCD} ${dataGroupBulk.centerItem.bumonCD} ${dataGroupBulk.centerItem.makerCenterName}`;
    let infoDataCommonIndex = newInfoDatasCommon.length;
    newInfoDatasCommon.push(infoDataCommon);
    infoDataCommonMap[keyCommon] = newInfoDatasCommon;
    infoDataCommonIndexMap[keyCommon] = infoDataCommonIndex;

    rowKeyInfos.forEach((rowKeyInfo) => {
      const centerItem = dataGroupBulk.centerItem;

      rowInfos.push({
        ...{
          TP: "bulk",
          no: `${no}`,
          subno: `${subno}`,
          dataGroup: dataGroupBulk,
          infoDataCommon: infoDataCommon,
          infoDataCommonIndex: infoDataCommonIndex,
          row: rowInfos.length,
        }, 
        ...rowKeyInfo
      });
    })
  }


  //明細行
  fixedRowsTop = rowInfos.length;

  let beforeDataGroup:RowDataGroup;
  const grouping = (!listSortOrder || listSortOrder.group_code == 'item') ? 'item' : 'center';
  let no = 0;
  let subno = 0;
  dataGroups.forEach(dataGroup => {
    if(hasBulk) {
        // グルーピング
      if(grouping == 'item' && (!beforeDataGroup || beforeDataGroup.centerItem.itemCD != dataGroup.centerItem.itemCD)) {
        no++;
        subno = 0;
        // rowInfos.push({
        //   TP: "item",
        //   no: `${no}`,
        //   dataGroup: dataGroup,
        //   row: rowInfos.length,
        // });
      }
      else if(grouping == 'center' && (!beforeDataGroup || beforeDataGroup.centerItem.bumonCD != dataGroup.centerItem.bumonCD || beforeDataGroup.centerItem.makerCenterName != dataGroup.centerItem.makerCenterName)) {
        no++;
        subno = 0;
        // rowInfos.push({
        //   TP: "center",
        //   no: `${no}`,
        //   dataGroup: dataGroup,
        //   row: rowInfos.length,
        // });
      }
    }

    //明細行
    subno++;
    rowKeyInfos.forEach((rowKeyInfo) => {
      const centerItem = dataGroup.centerItem;
      const keyCommon = `${centerItem.itemCD} ${centerItem.bumonCD} ${centerItem.makerCenterName}`;

      let infoDataCommon:InfoDataCommon = infoDataCommonMap[keyCommon];
      let infoDataCommonIndex:number = infoDataCommonIndexMap[keyCommon];
      //未登録レコードは新規作成
      if(!infoDataCommon) {
        infoDataCommon = {
          itemCD: centerItem.itemCD,
          bumonCD: centerItem.bumonCD,
          makerCenterName: centerItem.makerCenterName,
        };
        infoDataCommonIndex = newInfoDatasCommon.length;

        newInfoDatasCommon.push(infoDataCommon);
        infoDataCommonMap[keyCommon] = newInfoDatasCommon;
        infoDataCommonIndexMap[keyCommon] = infoDataCommonIndex;
      }

      rowInfos.push({
        ...{
          TP: "info",
          no: `${no}`,
          subno: `${subno}`,
          dataGroup: dataGroup,
          infoDataCommon: infoDataCommon,
          infoDataCommonIndex: infoDataCommonIndex,
          row: rowInfos.length,
        }, 
        ...rowKeyInfo
      });
    })

    beforeDataGroup = dataGroup;
  });
  return [rowInfos, fixedRowsTop, newInfoDatasCommon];
}
//配列データに変換
const convertRows = (rowInfos:RowInfo[], colRowModel:SettingLimitColRowModel): any[][] => {
  return rowInfos.map((rowInfo) => convertRow(rowInfo, colRowModel));
}
//配列データに変換
const convertRow = (rowInfo:RowInfo, colRowModel:SettingLimitColRowModel): any[] => {
  //set No.
  const dataGroup:RowDataGroup = rowInfo.dataGroup;
  return colRowModel.colKeys.map(colKey => valueFromKey(colKey, rowInfo, dataGroup));
}
export const valueFromKey = (colKey: string, rowInfo: RowInfo, dataGroup: RowDataGroup): any => {
  //一括
  if (rowInfo.TP == "bulk") {

    //明細行
    switch (colKey) {
      case "target":
        return rowInfo.dataGroup?.target;
      case "no":
        return "";

      case "center": //"倉庫"
      case "itemCD": //"商品CD"
      case "itemNM": //"商品名"
      case "janCD": //"JAN"
      case "makerCD": //"メーカー"
      case "makerNM": //"メーカー"
      case "categoryCD": //"商品カテゴリ"
      case "categoryNM": //"商品カテゴリ"
      case "blIrisu": //"BL入数"
      case "csIrisu": //"CS入数"
      case "csIrisu_lot": //"CS入数×発注単位"
      case "lot": //"発注単位"
      case "capacity": //"内容量"
      case "bestBefore": //"賞味期間"
      case "teika": //"定価"
      case "tatene": //"建値"
        return "";

      case "rowHeader":
        return "";

      case "stockLimitDate":
        return rowInfo.infoDataCommon.stockLimitDate;
      case "stockLimitQty":
        return rowInfo.infoDataCommon.stockLimitQty;
      
      default: {
        return dataGroup[colKey];
      }
    }
  }

  //グループ行
  if (rowInfo.TP == "center") {
    switch (colKey) {
      case "target":
        return "";
      case "no":
        return rowInfo.no;

      case "center": //"倉庫"
        return `${dataGroup.centerItem?.makerCenterName}`;
      case "itemCD": //"商品CD"
      case "itemNM": //"商品名"
      case "janCD": //"JAN"
      case "makerCD": //"メーカー"
      case "makerNM": //"メーカー"
      case "categoryCD": //"商品カテゴリ"
      case "categoryNM": //"商品カテゴリ"
      case "blIrisu": //"BL入数"
      case "csIrisu": //"CS入数"
      case "csIrisu_lot": //"CS入数×発注単位"
      case "lot": //"発注単位"
      case "capacity": //"内容量"
      case "bestBefore": //"賞味期間"
      case "teika": //"定価"
      case "tatene": //"建値"
        return "";
        
      case "rowHeader":
        return "";
      default: {
        return "";
      }
    }
  }

  //グループ行
  if (rowInfo.TP == "item") {
    switch (colKey) {
      case "target":
        return "";
      case "no":
        return rowInfo.no;

      case "center": //"倉庫"
        return "";
      case "itemCD": //"商品CD"
        return `${dataGroup.centerItem?.itemCD}`;
      case "itemNM": //"商品名"
        return `${dataGroup.centerItem?.itemNM}`;
      case "janCD": //"JAN"
        return `${dataGroup.centerItem?.janCD}`;
      case "makerCD": //"メーカー"
        return `${dataGroup.centerItem?.makerCD}`;
      case "makerNM": //"メーカー"
        return `${dataGroup.centerItem?.makerNM}`;
      case "categoryCD": //"商品カテゴリ"
        return `${dataGroup.centerItem?.categoryCD}`;
      case "categoryNM": //"商品カテゴリ"
        return `${dataGroup.centerItem?.categoryNM}`;
      case "blIrisu": //"BL入数"
        return `${formatterN0.format(dataGroup.centerItem?.blIrisu)}`;
      case "csIrisu": //"CS入数"
        return `${formatterN0.format(dataGroup.centerItem?.csIrisu)}`;
      // case "csIrisu_lot": //"CS入数"
      //   return `${formatterN0.format(dataGroup.centerItem?.csIrisu_lot)}`;
      // case "lot": //"発注単位"
      //   return `${formatterN0.format(dataGroup.centerItem?.lot)}`;
      case "capacity": //"内容量"
        return `${dataGroup.centerItem?.capacity}`;
      case "bestBefore": //"賞味期間"
        return `${dataGroup.centerItem?.bestBefore}日`;
      case "teika": //"定価"
        return dataGroup.centerItem?.teika;
      case "tatene": //"建値"
        return "";
        
      case "rowHeader":
        return "";
      default: {
        return "";
      }
    }
  }

  if (rowInfo.TP == "info") {

    //明細行
    switch (colKey) {
      case "target":
        return rowInfo.dataGroup?.target;
      case "no":
        return `${rowInfo.no}-${rowInfo.subno}`;

      case "center": //"倉庫"
        return `${dataGroup.centerItem?.makerCenterName}`;
      case "itemCD": //"商品CD"
        return `${dataGroup.centerItem?.itemCD}`;
      case "itemNM": //"商品名"
        return `${dataGroup.centerItem?.itemNM}`;
      case "janCD": //"JAN"
        return `${dataGroup.centerItem?.janCD}`;
      case "makerCD": //"メーカー"
        return `${dataGroup.centerItem?.makerCD}`;
      case "makerNM": //"メーカー"
        return `${dataGroup.centerItem?.makerNM}`;
      case "categoryCD": //"商品カテゴリ"
        return `${dataGroup.centerItem?.categoryCD}`;
      case "categoryNM": //"商品カテゴリ"
        return `${dataGroup.centerItem?.categoryNM}`;
      case "blIrisu": //"BL入数"
        return `${formatterN0.format(dataGroup.centerItem?.blIrisu)}`;
      case "csIrisu": //"CS入数"
        return `${formatterN0.format(dataGroup.centerItem?.csIrisu)}`;
      // case "csIrisu_lot": //"CS入数"
      //   return `${formatterN0.format(dataGroup.centerItem?.csIrisu_lot)}`;
      // case "lot": //"発注単位"
      //   return `${formatterN0.format(dataGroup.centerItem?.lot)}`;
      case "capacity": //"内容量"
        return `${dataGroup.centerItem?.capacity}`;
      case "bestBefore": //"賞味期間"
        return `${dataGroup.centerItem?.bestBefore}日`;
      case "teika": //"定価"
        return dataGroup.centerItem?.teika;
      case "tatene": //"建値"
        return dataGroup.centerItem?.tatene;
  
      case "rowHeader":
        return "";

      case "stockLimitDate":
        return rowInfo.infoDataCommon.stockLimitDate;
      case "stockLimitQty":
        return rowInfo.infoDataCommon.stockLimitQty;
  

      default: {
        return dataGroup[colKey];
      }
    }
  }
  return null;
}

//マージを作成
const createMergeCells = (rowInfos:RowInfo[], colRowModel:SettingLimitColRowModel): {row: number, col: number, rowspan: number, colspan: number}[] => {
  let mergeCells:{row: number, col: number, rowspan: number, colspan: number}[] = [];
  return mergeCells;
}


export const getOptionLabel = (option: CodeName) => {
  return option && option.name ? (option.code + ' ' + option.name) : "";
}

const createSliceContent = (name:string) => createSlice({
  name: name,
  initialState,
  reducers: createReducerContent(),
});

//Page Slice Export
//settingLimitTmp
export const settingLimitTmpSlice = createSliceContent("settingLimitTmp");
