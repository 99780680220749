import {
  combineReducers
} from "@reduxjs/toolkit";

import {
  createStore,
  applyMiddleware,
  compose,
} from "redux";

import {
  persistReducer
} from "redux-persist";

// import storage from "redux-persist/lib/storage";
import createWebStorage from 'redux-persist/lib/storage/createWebStorage'

// custom stores
import { userSlice } from "@/store/user";
import { userSaveSlice } from "@/store/userSave";
import { commonSaveSlice } from "@/store/common/commonSave";
import { commonTmpSlice } from "@/store/common/commonTmp";
import { mainPageSaveSlice } from "@/store/mainpage/mainPageSave";
import { mainPageTmpSlice } from "@/store/mainpage/mainPageTmp";
import { orderInstructionSaveSlice, orderInstructionDialogSaveSlice } from "@/store/orderinstruction/orderInstructionSave";
import { orderInstructionTmpSlice, orderInstructionDialogTmpSlice } from "@/store/orderinstruction/orderInstructionTmp";
import { settingMakerSaveSlice } from "@/store/settingmaker/settingMakerSave";
import { settingMakerTmpSlice } from "@/store/settingmaker/settingMakerTmp";
import { settingItemSaveSlice, settingItemDialogSaveSlice, settingItemDirectSaveSlice } from "@/store/settingitem/settingItemSave";
import { settingItemTmpSlice, settingItemDialogTmpSlice, settingItemDirectTmpSlice } from "@/store/settingitem/settingItemTmp";
import { settingLimitSaveSlice } from "@/store/settinglimit/settingLimitSave";
import { settingLimitTmpSlice } from "@/store/settinglimit/settingLimitTmp";
import { settingCenterSaveSlice } from "@/store/settingcenter/settingCenterSave";
import { settingCenterTmpSlice } from "@/store/settingcenter/settingCenterTmp";
import { settingTransferCenterSaveSlice } from "@/store/settingtransfercenter/settingTransferCenterSave";
import { settingTransferCenterTmpSlice } from "@/store/settingtransfercenter/settingTransferCenterTmp";
import { settingTagsSaveSlice } from "@/store/settingtags/settingTagsSave";
import { settingTagsTmpSlice } from "@/store/settingtags/settingTagsTmp";

const rootReducer = combineReducers({
  user: userSlice.reducer,
  userSave: userSaveSlice.reducer,
  commonSave: commonSaveSlice.reducer,
  commonTmp: commonTmpSlice.reducer,
  mainPageSave: mainPageSaveSlice.reducer,
  mainPageTmp: mainPageTmpSlice.reducer,
  orderInstructionSave: orderInstructionSaveSlice.reducer,
  orderInstructionTmp: orderInstructionTmpSlice.reducer,
  orderInstructionDialogSave: orderInstructionDialogSaveSlice.reducer,
  orderInstructionDialogTmp: orderInstructionDialogTmpSlice.reducer,
  settingMakerSave: settingMakerSaveSlice.reducer,
  settingMakerTmp: settingMakerTmpSlice.reducer,
  settingItemSave: settingItemSaveSlice.reducer,
  settingItemTmp: settingItemTmpSlice.reducer,
  settingItemDialogSave: settingItemDialogSaveSlice.reducer,
  settingItemDialogTmp: settingItemDialogTmpSlice.reducer,
  settingItemDirectSave: settingItemDirectSaveSlice.reducer,
  settingItemDirectTmp: settingItemDirectTmpSlice.reducer,
  settingLimitSave: settingLimitSaveSlice.reducer,
  settingLimitTmp: settingLimitTmpSlice.reducer,
  settingCenterSave: settingCenterSaveSlice.reducer,
  settingCenterTmp: settingCenterTmpSlice.reducer,
  settingTransferCenterSave: settingTransferCenterSaveSlice.reducer,
  settingTransferCenterTmp: settingTransferCenterTmpSlice.reducer,
  settingTagsSave: settingTagsSaveSlice.reducer,
  settingTagsTmp: settingTagsTmpSlice.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;

/*永続化保存設定 */

// // HACK: `redux-persist failed to create sync storage. falling back to noop storage.`の対応
// // https://github.com/vercel/next.js/discussions/15687#discussioncomment-45319
const createNoopStorage = () => {
  return {
    getItem() {
      return Promise.resolve(null)
    },
    setItem(_key, value) {
      return Promise.resolve(value)
    },
    removeItem() {
      return Promise.resolve()
    },
  }
}
const storage =
  typeof window !== 'undefined'
    ? createWebStorage('local')
    : createNoopStorage()
;
//通常時
const persistConfigDefault = {
  //localstorage key
  key: process.env.NEXT_PUBLIC_STORAGE_KEY,
  version: 1,
  //localstorage
  storage,
  //保存対象
  whitelist: [
    userSaveSlice.name,
    commonSaveSlice.name,
    mainPageSaveSlice.name,
    orderInstructionSaveSlice.name,
    orderInstructionDialogSaveSlice.name,
    settingItemSaveSlice.name,
    settingItemDialogSaveSlice.name,
    settingItemDirectSaveSlice.name,
    settingMakerSaveSlice.name,
    settingLimitSaveSlice.name,
    settingCenterSaveSlice.name,
    settingTransferCenterSaveSlice.name,
    settingTagsSaveSlice.name,
  ],
  timeout: 500, //500msec
};


interface ExtendedWindow extends Window {
  __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
}
declare let window: ExtendedWindow;
const composeReduxDevToolsEnhancers = typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const middlewares = [];
const makeStoreInner = () => {
  const persistedReducer = persistReducer(persistConfigDefault, rootReducer);

  return createStore(persistedReducer,composeReduxDevToolsEnhancers(applyMiddleware(...middlewares)));
};

// Reduxのstoreはsingletonが推奨されている
// https://redux.js.org/faq/store-setup#store-setup
// https://qiita.com/nouka/items/74a2784598e7bd5554cd#%E3%82%A2%E3%83%97%E3%83%AA%E3%81%AB%E5%AF%BE%E3%81%97%E3%81%A6-1-%E3%81%A4%E3%81%AE-redux-store-%E3%82%92%E6%8C%81%E3%81%A4%E3%81%93%E3%81%A8
let storeCache = null as null | { store: ReturnType<typeof makeStoreInner> };
export const makeStore = () => {
  if (storeCache !== null) {
    return storeCache.store;
  }
  const store = makeStoreInner();
  storeCache = {
    store,
  }
  return store;
}
