
//ヘッダー列
export const headerColKeys = [
  'no',
  'valid',
];
//ヘッダー列
export const bodyColKeys = [
  'tag',
  'foregroundColor',
  'backgroundColor',
  'preview',
];

export const headersCol = [
  {
    no: "No.",
    valid: "有効/無効",

    tag: 'タグ名',
    foregroundColor: '文字色',
    backgroundColor: '背景色',
    preview: 'プレビュー',
  },
];

export const selectableCol =
{
  no: false,

  tag: false,
  foregroundColor: false,
  backgroundColor: false,
  preview: false,
}

export const headerColClasses = [
  {
    no: "header",
    valid: "header",

    tag: "header",
    foregroundColor: "header",
    backgroundColor: "header",
    preview: "header",
  },
];

export const colWidths =
{
  no: 50,
  valid: 50,

  tag: 150,
  foregroundColor: 80,
  backgroundColor: 80,
  preview: 150,
};

export const colDataType =
{
  no: { type: 'text', readOnly: true, },
  valid: { type: 'checkbox', checkedTemplate: true, uncheckedTemplate: false, readOnly: false, className: 'htCenter' },
  tag: { type: 'text', readOnly: false, },
  foregroundColor: { type: 'text', readOnly: false, },
  backgroundColor: { type: 'text', readOnly: false, },

  preview: { type: 'text', readOnly: true, },
};

// export const rowDataType = {};

//列定義情報（動的パラメータ）
interface SettingTagsColRowModelInterface {
  //列固定
  fixedColumnsLeft: number,
  //行固定
  fixedRowsTop: number,

  //テーブルの各列のキー
  colKeys: string[],
}

export class SettingTagsColRowModel implements SettingTagsColRowModelInterface {
  //列固定
  fixedColumnsLeft: number;
  //行固定
  fixedRowsTop: number;
  //テーブルの各列のキー
  colKeys: string[];
  //ヘッダー
  nestedHeaders; //any[][]
  nestedHeadersBackup; //any[][]
  //ヘッダー区切り
  nestedHeadersGroupIndex: number[];
  //テーブルの各列の逆引き用マップ
  colKeysMap;

  constructor(param: SettingTagsColRowModelInterface) {
    this.fixedColumnsLeft = param.fixedColumnsLeft;
    this.colKeys = param.colKeys;

    //テーブルの各列のキー逆引き
    const colKeysMap = {};
    param.colKeys.forEach((colKey, index) => {
      colKeysMap[colKey] = index;
    });
    this.colKeysMap = colKeysMap;

    //nestedHeaders初期化
    this.initNestedHeaders();
  }

  //nestedHeaders初期化
  initNestedHeaders() {
    //nestedHeaders
    const nestedHeaders = [];
    const nestedHeadersGroupIndex = [];
    // const customBorders = [];
    headersCol.forEach((header, index) => {
      const lastHeader = index == headersCol.length - 1;
      const nestedHeader = [];

      let colspan = 0;
      let preHeaderVal = null;
      this.colKeys.forEach((colKey) => {
        const headerVal = header[colKey];
        if (lastHeader) {
          nestedHeader.push(headerVal);
        }
        else {
          if (preHeaderVal !== null && preHeaderVal != headerVal) {
            nestedHeader.push(colspan == 1 ? preHeaderVal : { label: preHeaderVal, colspan: colspan });
            colspan = 0;
          }
          colspan++;
          preHeaderVal = headerVal;
        }
        //0行目でグループ
        if (index == 0) {
          nestedHeadersGroupIndex.push(colspan - 1);
        }
      });
      if (!lastHeader && colspan > 0) {
        nestedHeader.push(colspan == 1 ? preHeaderVal : { label: preHeaderVal, colspan: colspan });
      }

      nestedHeaders.push(nestedHeader);
    });

    this.nestedHeaders = nestedHeaders;
    this.nestedHeadersBackup = nestedHeaders.map(nestedHeadersSub => [...nestedHeadersSub]);
    this.nestedHeadersGroupIndex = nestedHeadersGroupIndex;
  }

  //function
  keyFromCol(column: number): string {
    return this.colKeys[column];
  }
  keyFromCols(columns: number[]): string[] {
    const vals: string[] = [];
    columns.forEach(column => vals.push(this.colKeys[column]))
    return vals;
  }
  colFromKey(colKey: string): number {
    return this.colKeysMap[colKey];
  }
  colFromKeys(colKeys: string[]): number[] {
    const vals: number[] = [];
    colKeys.forEach(colKey => vals.push(this.colKeysMap[colKey]))
    return vals;
  }
}

//SettingTags用の列モデル
export const settingTagsColRowModelDefault: SettingTagsColRowModel = new SettingTagsColRowModel({
  //列固定
  fixedColumnsLeft: headerColKeys.length,//+ rowHeaderColKeys.length,
  //行固定
  fixedRowsTop: 0,

  //テーブルの各列のキー
  colKeys: [
    ...headerColKeys,
    // ...rowHeaderColKeys,
    ...bodyColKeys,
  ],
});
