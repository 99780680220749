import moment from "moment";
moment.updateLocale("ja", {
  months: ["1月","2月","3月","4月","5月","6月","7月","8月","9月","10月","11月","12月",],
  weekdays: ["日曜日", "月曜日", "火曜日", "水曜日", "木曜日", "金曜日", "土曜日"],
  weekdaysShort: ["日", "月", "火", "水", "木", "金", "土"]
});

//ヘッダー列
export const headerColKeys = [
  'no',
  'target',
  'itemCD',
  'center',
];
//行ヘッダー列
export const rowHeaderColKeys = [
  // 'rowHeader', 
];
//ヘッダー列
export const bodyColKeys = [
  'itemNM',
  'janCD',
  'makerCD',
  'makerNM',
  'categoryCD',
  'categoryNM',
  'blIrisu',
  'csIrisu',
  // 'lot',
  // 'csIrisu_lot',
  'capacity',
  'bestBefore',
  'teika',
  'tatene',

	'stockLimitDate',
	'stockLimitQty',
];
//ヘッダー列（発注不可）
export const colKeysUnOrderableHidden = [
];

export const rowKeys = ['dummy'];

export const headersCol = [
  {
    target : "一括",
    no : "No.",

    rowHeader: "項目", 

    center : "メーカー倉庫名",
    itemCD : "商品CD",
       
    itemNM : "商品属性",
    janCD : "商品属性",
    makerCD : "商品属性",
    makerNM : "商品属性",
    categoryCD : "商品属性",
    categoryNM : "商品属性",
    blIrisu : "商品属性",
    csIrisu : "商品属性",
    lot : "商品属性",
    capacity : "商品属性",
    bestBefore : "商品属性",
    teika : "商品属性",
    tatene : "商品属性",

    stockLimitDate: "残在庫",
    stockLimitQty: "残在庫",
  },
  {
    target : "",
    no : "",

    rowHeader : "<br/>名称", 

    center : "",
    itemCD : "",
   
    itemNM : "商品名",
    janCD : "JAN",
    makerCD : "ﾒｰｶｰCD",
    makerNM : "ﾒｰｶｰ名",
    categoryCD : "ｶﾃｺﾞﾘCD",
    categoryNM : "ｶﾃｺﾞﾘ名",
    blIrisu : "BL入数",
    csIrisu : "CS入数",
    lot : "発注単位",
    capacity : "容量",
    bestBefore : "賞味期間",
    teika : "定価",
    tatene : "建値",


    stockLimitDate: "状態日",
    stockLimitQty: "残在庫CS数",
  },
];

export const headersRow = {
  dummy: "dummy"
};

export const selectableCol =
{
  target : false,
  no : false,
  rowHeader : false, 

  center : false,
  itemCD : false,

  itemNM : true,
  janCD : true,
  makerCD : true,
  makerNM : true,
  categoryCD : true,
  categoryNM : true,
  blIrisu : true,
  csIrisu : true,
  lot : true,
  capacity : true,
  bestBefore : true,
  teika : true,
  tatene : true,

  stockLimitDate: false,
  stockLimitQty: false,

}
export const selectableRow = {
  dummy: false,
};

export const headerColClasses = [
  {
    target : "header_noBorder_bulk",
    no : "header_noBorder_no",

    rowHeader : "header",

    center : "header_noBorder_makercenter",
    itemCD : "header_noBorder",
  
    itemNM : "header",
    janCD : "header",
    makerCD : "header",
    makerNM : "header",
    categoryCD : "header",
    categoryNM : "header",
    blIrisu : "header",
    csIrisu : "header",
    lot : "header",
    capacity : "header",
    bestBefore : "header",
    teika : "header",
    tatene : "header",


    stockLimitDate: "header_makercenter",
    stockLimitQty: "header_makercenter",
  
  },
  {
    target : "header_bulk",
    no : "header_no",

    rowHeader : "header", 

    center : "header_makercenter",
    itemCD : "header",
       
    itemNM : "header",
    janCD : "header",
    makerCD : "header",
    makerNM : "header",
    categoryCD : "header",
    categoryNM : "header",
    blIrisu : "header",
    csIrisu : "header",
    lot : "header",
    capacity : "header",
    bestBefore : "header",
    teika : "header",
    tatene : "header",


    stockLimitDate: "header_makercenter",
    stockLimitQty: "header_makercenter",
    
  },
];
export const headerRowClasses = {
  dummy: 'rowHeader',
};

export const colWidths =
{
  target : 50,
  no : 50,

  rowHeader : 105, 

  center : 200,
  itemCD : 100,
     
  itemNM : 110,
  janCD : 120,
  makerCD : 60,
  makerNM : 100,
  categoryCD : 40,
  categoryNM : 110,
  blIrisu : 40,
  csIrisu : 40,
  lot : 30,
  capacity : 50,
  bestBefore : 40,
  teika : 50,
  tatene : 55,

  stockLimitDate: 100,
  stockLimitQty: 80,

};

export const colDataType =
{
  target: {type: 'checkbox', checkedTemplate: true, uncheckedTemplate: false, readOnly: false, className: 'htCenter'},
  no : {type: 'text', readOnly: true,},

  rowHeader : {type: 'text', readOnly: true,}, 

  center : {type: 'text', readOnly: true,}, 
  itemCD : {type: 'text', readOnly: true, className: 'htCenter',}, 

  itemNM : {type: 'text', readOnly: true,}, 
  janCD : {type: 'text', readOnly: true,}, 
  makerCD : {type: 'text', readOnly: true, className: 'htCenter',}, 
  makerNM : {type: 'text', readOnly: true,}, 
  categoryCD : {type: 'text', readOnly: true, className: 'htCenter',}, 
  categoryNM : {type: 'text', readOnly: true,}, 
  blIrisu : {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  csIrisu : {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  lot : {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  capacity : {type: 'text', readOnly: true, className: 'htRight',}, 
  bestBefore : {type: 'text', readOnly: true, className: 'htRight',}, 
  teika : {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},}, 
  tatene : {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0.00',},},

  stockLimitDate: { type: 'date', dateFormat: 'YY/MM/DD', datePickerConfig: {yearSuffix: '年', showMonthAfterYear: true, showDaysInNextAndPreviousMonths: true, format: 'YY/MM/DD', formatStrict: true, maxDate: new Date(), i18n: { previousMonth: '前月', nextMonth: '次月', months: moment.months(), weekdays: moment.weekdays(), weekdaysShort: moment.weekdaysShort() } }, readOnly: false, className: 'htCenter' },
  stockLimitQty: { type: 'numeric', numericFormat: { pattern: '0,0', }, readOnly: false, }, 

};

export const rowDataType = {};


//列定義情報（動的パラメータ）
interface SettingLimitColRowModelInterface {
  //列固定
  fixedColumnsLeft: number,

  //テーブルの各列のキー
  colKeys: string[],
  //発注不可時非表示列
  colKeysUnOrderableHidden: string[];
  //テーブルの各行のキー
  rowKeys: string[],
}

export class SettingLimitColRowModel implements SettingLimitColRowModelInterface {
  //列固定
  fixedColumnsLeft: number;
  //テーブルの各列のキー
  colKeys: string[];
  //発注不可時非表示列
  colKeysUnOrderableHidden: string[];
  //テーブルの各列のキー
  rowKeys: string[];
  //ヘッダー
  nestedHeaders; //any[][]
  nestedHeadersBackup; //any[][]
  //ヘッダー区切り
  nestedHeadersGroupIndex: number[];
  //テーブルの各列の逆引き用マップ
  colKeysMap;
  //テーブルの各行の逆引き用マップ
  rowKeysMap;

  constructor(param: SettingLimitColRowModelInterface) {
    this.fixedColumnsLeft = param.fixedColumnsLeft;
    this.colKeys = param.colKeys;
    this.rowKeys = param.rowKeys;
    this.colKeysUnOrderableHidden = param.colKeysUnOrderableHidden;

    //テーブルの各列のキー逆引き
    const colKeysMap = {};
    param.colKeys.forEach((colKey, index) => {
      colKeysMap[colKey] = index;
    });
    this.colKeysMap = colKeysMap;
    //テーブルの各行のキー逆引き
    const rowKeysMap = {};
    param.rowKeys.forEach((rowKey, index) => {
      rowKeysMap[rowKey] = index;
    });
    this.rowKeysMap = rowKeysMap;

    //nestedHeaders初期化
    this.initNestedHeaders();
  }

  //nestedHeaders初期化
  initNestedHeaders() {
    //nestedHeaders
    const nestedHeaders = [];
    const nestedHeadersGroupIndex = [];
    // const customBorders = [];
    headersCol.forEach((header, index) => {
      const lastHeader = index == headersCol.length - 1;
      const nestedHeader = [];

      let colspan=0;
      let preHeaderVal = null;
      this.colKeys.forEach((colKey) => {
        const headerVal = header[colKey];
        if(lastHeader) {
          nestedHeader.push(headerVal);
        }
        else {
          if(preHeaderVal !== null && preHeaderVal != headerVal){
            nestedHeader.push(colspan == 1 ? preHeaderVal : {label: preHeaderVal, colspan: colspan});
            colspan=0;
          }
          colspan++;
          preHeaderVal = headerVal;
        }
        //0行目でグループ
        if(index == 0) {
          nestedHeadersGroupIndex.push(colspan-1);
        }
      });
      if(!lastHeader && colspan > 0) {
        nestedHeader.push(colspan == 1 ? preHeaderVal : {label: preHeaderVal, colspan: colspan});
      }

      nestedHeaders.push(nestedHeader);
    });

    this.nestedHeaders = nestedHeaders;
    this.nestedHeadersBackup = nestedHeaders.map(nestedHeadersSub => [...nestedHeadersSub]);
    this.nestedHeadersGroupIndex = nestedHeadersGroupIndex;
  }

  //function
  keyFromCol(column:number):string {
    return this.colKeys[column];
  }
  keyFromCols(columns:number[]):string[] {
    const vals:string[] = [];
    columns.forEach(column => vals.push(this.colKeys[column]))
    return vals;
  }
  colFromKey(colKey:string):number {
    return this.colKeysMap[colKey];
  }
  colFromKeys(colKeys:string[]):number[] {
    const vals:number[] = [];
    colKeys.forEach(colKey => vals.push(this.colKeysMap[colKey]))
    return vals;
  }
  //function
  keyFromRow(row:number):string {
    const rowKeys = this.rowKeys;
    return rowKeys[row];
  }
  keyFromRows(rows:number[]):string[] {
    const rowKeys = this.rowKeys;
    const vals:string[] = [];
    rows.forEach(row => vals.push(rowKeys[row]))
    return vals;
  }
  rowFromKey(rowKey:string):number {
    const rowKeysMap = this.rowKeysMap;
    return rowKeysMap[rowKey];
  }
  rowFromKeys(rowKeys:string[]):number[] {
    const rowKeysMap = this.rowKeysMap;
    const vals:number[] = [];
    rowKeys.forEach(rowKey => vals.push(rowKeysMap[rowKey]))
    return vals;
  }
}

//SettingLimit用の列モデル
export const settingLimitColRowModelDefault:SettingLimitColRowModel = new SettingLimitColRowModel({
  //列固定
  fixedColumnsLeft: headerColKeys.length + rowHeaderColKeys.length,

  //テーブルの各列のキー
  colKeys: [
    ...headerColKeys,
    ...rowHeaderColKeys,
    ...bodyColKeys,
  ],
  colKeysUnOrderableHidden: colKeysUnOrderableHidden, 
  rowKeys: rowKeys,
});

