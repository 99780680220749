import { CodeName } from "@/assets/commontype/CodeName";

//倉庫・商品
export type CenterItem = {
  itemCD?: string | null, //商品CD
  itemNM?: string | null, //商品名
  janCD?: string | null,   //JAN
  makerCD?: string | null, //メーカー
  makerNM?: string | null, //メーカー
  categoryCD?: string | null, //ｶﾃｺﾞﾘ
  categoryNM?: string | null, //ｶﾃｺﾞﾘ
  blIrisu?: number | null,  //容量1(BL入数) 
  csIrisu?: number | null,  //容量2(CS入数)
  lot?: number | null,  //発注単位
  csIrisu_lot?: number | null,  //CS入数×発注単位
  blIrisu_csIrisu?: string | null,  //BL入数×CS入数
  capacity?: string | null, //内容量
  bestBefore?: string | null, //賞味期間
  teika?: number | null, //定価
  tatene?: number | null, //建値

  bumonCD?: string, //--部門
  centerCD?: string | null, //倉庫(4桁コード)
  centerCD2?: string | null, //倉庫(2桁コード)
  centerNM?: string | null, //倉庫
  centerSortInput?: number | null, //倉庫並び順
  centerSortOutput?: number | null, //倉庫並び順
  areaCD?: string | null, //地域
  areaNM?: string | null, //地域
  prefCD?: string | null, //都道府県
  prefNM?: string | null, //都道府県

  tanaban?: string | null, //棚番
  tokuisakiCD?: string | null, //得意先
  tokuisakiNM?: string | null, //得意名
  tokuisakiItemCD?: string | null, //得意先
}

export type ErrorInfoDataCommon = {
  loadedQty?: string | null,
}
export type ErrorInfoData = {
  termStartDate?: string | null,
	termEndDate?: string | null,
  retrieveDate?: string | null,
  similarItemCD?: string | null,
}

//倉庫・商品・タグ
export type InfoDataCommon = {
  uuid?: string,  //データベースに登録済みのUUID。更新時にDB上の値とずれていれば登録エラーとする

  itemCD?: string | null, //商品CD
  centerCD?: string | null, //倉庫

	loadedQty?: number,	//積付数(面)
	
  errorInfo?: ErrorInfoDataCommon,

  edited?: boolean, //編集
  visible?: boolean, //表示状態

  oldLoadedQty?: number,	//積付数(面)
}

//倉庫・商品・タグ
export type InfoData = {
  uuid?: string,  //データベースに登録済みのUUID。更新時にDB上の値とずれていれば登録エラーとする

  itemCD?: string | null, //商品CD
  centerCD?: string | null, //倉庫

	tagCD?: 'NEW'|'CPN'|'CUT'|'EMC'|'PKG'|'OTH'|string, //タグ属性　'NEW':'新規', 'CPN':'キャンペーン', 'CUT':'カット', 'EMC':'緊急カット', 'PKG':'パッケージ変更', 'OTH':'その他'

	validFlg?: boolean,	//有効フラグ

  termFlg?: boolean,	//表示期間指定フラグ
	termStartDate?: string,	//表示期間開始日
	termEndDate?: string,	//表示期間終了日

  retrieveDateFlg?: boolean,	//検索日フラグ
	retrieveDate?: string,	//検索日

	similarItemCD?: string, //類似商品
	comment?: string, //コメント

  errorInfo?: ErrorInfoData,

  edited?: boolean, //編集
  visible?: boolean, //表示状態

  oldValidFlg?: boolean,
  oldTermFlg?: boolean,
	oldTermStartDate?: string,
	oldTermEndDate?: string,
  oldRetrieveDateFlg?: boolean,	//検索日フラグ
	oldRetrieveDate?: string,	//検索日
	oldSimilarItemCD?: string,
	oldComment?: string,
}

export const centerItemAttributes:CodeName[][] = [
  [
    new CodeName({group_code:"", group_name:"", code: "", name: "(指定なし)", ryaku: "", }),
    new CodeName({group_code:"center", group_name:"センター属性", code: "center", name: "倉庫", ryaku: "倉庫", }),
    new CodeName({group_code:"center", group_name:"センター属性", code: "area", name: "地域", ryaku: "地域", }),
    new CodeName({group_code:"center", group_name:"センター属性", code: "pref", name: "都道府県", ryaku: "都道府県", }),
    new CodeName({group_code:"item", group_name:"商品属性", code: "itemCD", name: "商品CD", ryaku: "商品CD", }),
    new CodeName({group_code:"item", group_name:"商品属性", code: "itemNM", name: "商品名", ryaku: "商品名", }),
    new CodeName({group_code:"item", group_name:"商品属性", code: "janCD", name: "JAN", ryaku: "JAN", }),
    new CodeName({group_code:"item", group_name:"商品属性", code: "maker", name: "メーカー", ryaku: "メーカー", }),
    new CodeName({group_code:"item", group_name:"商品属性", code: "category", name: "商品カテゴリ", ryaku: "カテゴリ", }),
    new CodeName({group_code:"item", group_name:"商品属性", code: "blIrisu", name: "BL入数", ryaku: "BL入数", }),
    new CodeName({group_code:"item", group_name:"商品属性", code: "csIrisu", name: "CS入数", ryaku: "CS入数", }),
    new CodeName({group_code:"item", group_name:"商品属性", code: "csIrisu_lot", name: "CS入数×発注単位", ryaku: "CS入数×発注単位", }),
    new CodeName({group_code:"item", group_name:"商品属性", code: "blIrisu_csIrisu", name: "BL入数×CS入数", ryaku: "BL入数×CS入数", }),
    new CodeName({group_code:"item", group_name:"商品属性", code: "lot", name: "発注単位", ryaku: "発注単位", }),
    new CodeName({group_code:"item", group_name:"商品属性", code: "capacity", name: "内容量", ryaku: "内容量", }),
    new CodeName({group_code:"item", group_name:"商品属性", code: "bestBefore", name: "賞味期間", ryaku: "賞味期間", }),
    new CodeName({group_code:"item", group_name:"商品属性", code: "teika", name: "定価", ryaku: "定価", }),
    new CodeName({group_code:"item", group_name:"商品属性", code: "tatene", name: "建値", ryaku: "建値", }),
    new CodeName({group_code:"zaiko", group_name:"在庫属性", code: "tanaban", name: "棚番", ryaku: "棚番", option2:"55"}),  //SEJ非表示
    new CodeName({group_code:"teiban", group_name:"定番マスタ", code: "tokuisakiCD", name: "得意先CD", ryaku: "得意先CD", }),
    new CodeName({group_code:"teiban", group_name:"定番マスタ", code: "tokuisakiNM", name: "得意先名", ryaku: "得意先名", }),
    new CodeName({group_code:"teiban", group_name:"定番マスタ", code: "tokuisakiItemCD", name: "得意先商品CD", ryaku: "得意先商品CD", }),
  ],
  [
    new CodeName({group_code:"", group_name:"", code: "", name: "(指定なし)", ryaku: "", }),
    new CodeName({group_code:"center", group_name:"センター属性", code: "center", name: "倉庫", ryaku: "倉庫", }),
    new CodeName({group_code:"center", group_name:"センター属性", code: "area", name: "地域", ryaku: "地域", }),
    new CodeName({group_code:"center", group_name:"センター属性", code: "pref", name: "都道府県", ryaku: "都道府県", }),
    new CodeName({group_code:"item", group_name:"商品属性", code: "itemCD", name: "商品CD", ryaku: "商品CD", }),
    new CodeName({group_code:"item", group_name:"商品属性", code: "itemNM", name: "商品名", ryaku: "商品名", }),
    new CodeName({group_code:"item", group_name:"商品属性", code: "janCD", name: "JAN", ryaku: "JAN", }),
    new CodeName({group_code:"item", group_name:"商品属性", code: "maker", name: "メーカー", ryaku: "メーカー", }),
    new CodeName({group_code:"item", group_name:"商品属性", code: "category", name: "商品カテゴリ", ryaku: "カテゴリ", }),
    new CodeName({group_code:"item", group_name:"商品属性", code: "blIrisu", name: "BL入数", ryaku: "BL入数", }),
    new CodeName({group_code:"item", group_name:"商品属性", code: "csIrisu", name: "CS入数", ryaku: "CS入数", }),
    new CodeName({group_code:"item", group_name:"商品属性", code: "csIrisu_lot", name: "CS入数×発注単位", ryaku: "CS入数×発注単位", }),
    new CodeName({group_code:"item", group_name:"商品属性", code: "blIrisu_csIrisu", name: "BL入数×CS入数", ryaku: "BL入数×CS入数", }),
    new CodeName({group_code:"item", group_name:"商品属性", code: "lot", name: "発注単位", ryaku: "発注単位", }),
    new CodeName({group_code:"item", group_name:"商品属性", code: "capacity", name: "内容量", ryaku: "内容量", }),
    new CodeName({group_code:"item", group_name:"商品属性", code: "bestBefore", name: "賞味期間", ryaku: "賞味期間", }),
    new CodeName({group_code:"item", group_name:"商品属性", code: "teika", name: "定価", ryaku: "定価", }),
    new CodeName({group_code:"item", group_name:"商品属性", code: "tatene", name: "建値", ryaku: "建値", }),
    new CodeName({group_code:"zaiko", group_name:"在庫属性", code: "tanaban", name: "棚番", ryaku: "棚番", option2:"55"}),  //SEJ非表示
    new CodeName({group_code:"teiban", group_name:"定番マスタ", code: "tokuisakiCD", name: "得意先CD", ryaku: "得意先CD", }),
    new CodeName({group_code:"teiban", group_name:"定番マスタ", code: "tokuisakiNM", name: "得意先名", ryaku: "得意先名", }),
    new CodeName({group_code:"teiban", group_name:"定番マスタ", code: "tokuisakiItemCD", name: "得意先商品CD", ryaku: "得意先商品CD", }),
  ]
];

//並び順
export const listSortOrders:CodeName[] = [
  new CodeName({group_code:"item", group_name:"商品→センター", code: "itemCD", name: "商品コード順", }),
  new CodeName({group_code:"item", group_name:"商品→センター", code: "itemNM", name: "商品名順", }),
  new CodeName({group_code:"item", group_name:"商品→センター", code: "janCD", name: "JANコード順", }),
  new CodeName({group_code:"item", group_name:"商品→センター", code: "maker", name: "メーカー順", }),
  new CodeName({group_code:"item", group_name:"商品→センター", code: "category", name: "商品カテゴリ順", }),
  new CodeName({group_code:"item", group_name:"商品→センター", code: "bestBefore", name: "賞味期間順", }),
  new CodeName({group_code:"item", group_name:"商品→センター", code: "teika", name: "定価順", }),
  new CodeName({group_code:"item", group_name:"商品→センター", code: "tatene", name: "建値順", }),

  new CodeName({group_code:"center", group_name:"センター→商品", code: "center", name: "倉庫コード→商品コード", }),
  new CodeName({group_code:"center", group_name:"センター→商品", code: "tanaban", name: "倉庫コード→棚番", }),
  new CodeName({group_code:"center", group_name:"センター→商品", code: "area", name: "地域", }),
  new CodeName({group_code:"center", group_name:"センター→商品", code: "pref", name: "都道府県", }),

];