import moment from "moment";
moment.updateLocale("ja", {
  months: ["1月","2月","3月","4月","5月","6月","7月","8月","9月","10月","11月","12月",],
  weekdays: ["日曜日", "月曜日", "火曜日", "水曜日", "木曜日", "金曜日", "土曜日"],
  weekdaysShort: ["日", "月", "火", "水", "木", "金", "土"]
});

//ヘッダー列
export const headerColKeys = [
  'no',
  'target',
  'code', 'name', 
  'area', 'pref', 
];
//行ヘッダー列
export const rowHeaderColKeys = [
  // 'rowHeader', 
];
//ヘッダー列
export const bodyColKeys = [
	'blInputFlg',

	'leadTimeFlg',
	'leadTimeDays',
  'leadTimeExcludeSaturdayFlg',
	'leadTimeContainHolidayFlg',

	'specifiedDateFlg',
	'specifiedDateSunFlg',
	'specifiedDateMonFlg',
	'specifiedDateTueFlg',
	'specifiedDateWedFlg',
	'specifiedDateThrFlg',
	'specifiedDateFriFlg',
	'specifiedDateSatFlg',
  'specifiedDateHolFlg',

	'autoOrderFlg',
	'autoOrderExecDays',
  'autoOrderDays',
	'autoOrderContainSundayFlg',

  'minQtyFlg',
	'minQtyCs',
	'maxQtyFlg',
	'maxQtyCs',

  'closeFlg',
	'closeTime',

  'makerCenterName',
];
//ヘッダー列（発注不可）
export const colKeysUnOrderableHidden = [
  'minQtyFlg',
	'minQtyCs',
	'maxQtyFlg',
	'maxQtyCs',

  'closeFlg',
	'closeTime',

  'makerCenterName',
];

export const rowKeys = ['const'];

export const headersCol = [
  {
    no : "No.",
    target : "一括",
    code: "メーカー/倉庫", 
    name: "メーカー/倉庫", 
  
    area: "メーカー/倉庫", pref: "メーカー/倉庫", 

    rowHeader: "項目", 

    blInputFlg: "BL発注",

    leadTimeFlg: "リードタイム",
    leadTimeDays: "リードタイム",
    leadTimeExcludeSaturdayFlg: "リードタイム",
    leadTimeContainHolidayFlg: "リードタイム",
  
    specifiedDateFlg: "入庫可能日",
    specifiedDateSunFlg: "入庫可能日",
    specifiedDateMonFlg: "入庫可能日",
    specifiedDateTueFlg: "入庫可能日",
    specifiedDateWedFlg: "入庫可能日",
    specifiedDateThrFlg: "入庫可能日",
    specifiedDateFriFlg: "入庫可能日",
    specifiedDateSatFlg: "入庫可能日",
    specifiedDateHolFlg: "入庫可能日",
  
    minQtyFlg: "最小発注数",
    minQtyCs: "最小発注数",
    maxQtyFlg: "最大発注数",
    maxQtyCs: "最大発注数",

    closeFlg: "締め時刻(未指定は11:00)",
    closeTime: "締め時刻(未指定は11:00)",

    autoOrderFlg: "自動発注入力",
    autoOrderExecDays: "自動発注入力",
    autoOrderDays: "自動発注入力",
    autoOrderContainSundayFlg: "自動発注入力",
  
    fractionRounding: "自動発注入力",

    makerCenterName: "メーカー倉庫",
  },
  {
    no : "",
    target : "",
    code: "<br/>コード", 
    name: "<br/>名称", 
    area: "<br/>地域", pref: "<br/>都道府県", 

    rowHeader : "", 
    
    blInputFlg: "有効",

    leadTimeFlg: "有効",
    leadTimeDays: "日数",
    leadTimeExcludeSaturdayFlg: "土曜を<br/>除く",
    leadTimeContainHolidayFlg: "祝日を<br/>含む",
  
    specifiedDateFlg: "有効",
    specifiedDateSunFlg: "日",
    specifiedDateMonFlg: "月",
    specifiedDateTueFlg: "火",
    specifiedDateWedFlg: "水",
    specifiedDateThrFlg: "木",
    specifiedDateFriFlg: "金",
    specifiedDateSatFlg: "土",
    specifiedDateHolFlg: "祝日",
  
    minQtyFlg: "有効",
    minQtyCs: "CS数",
    maxQtyFlg: "有効",
    maxQtyCs: "CS数",
  
    closeFlg: "有効",
    closeTime: "時刻(HH:MM)",

    autoOrderFlg: "有効",
    autoOrderExecDays: "実行<br/>在庫日数",
    autoOrderDays: "発注<br/>在庫日数",
    autoOrderContainSundayFlg: "日曜を<br/>含む",
  
    fractionRounding: "端数処理",

    makerCenterName: "メーカー倉庫名",
  },
];

export const headersRow = {
  const:"ダミー"
};

export const selectableCol =
{
  no : false,
  code: false, 
  name: false, 

  area: true, pref: true, 
  rowHeader : false, 

	blInputFlg: false,

	leadTimeFlg: false,
	leadTimeDays: false,
  leadTimeExcludeSaturdayFlg: false,
	leadTimeContainHolidayFlg: false,

	specifiedDateFlg: false,
	specifiedDateSunFlg: false,
	specifiedDateMonFlg: false,
	specifiedDateTueFlg: false,
	specifiedDateWedFlg: false,
	specifiedDateThrFlg: false,
	specifiedDateFriFlg: false,
	specifiedDateSatFlg: false,
  specifiedDateHolFlg: false,

	minQtyFlg: false,
	minQtyCs: false,
	maxQtyFlg: false,
	maxQtyCs: false,

  closeFlg: false,
  closeTime: false,

	autoOrderFlg: false,
  autoOrderExecDays: false,
	autoOrderDays: false,
	autoOrderContainSundayFlg: false,

	fractionRounding: false,

  makerCenterName: false,

}
export const selectableRow = {
  const:false
};

export const headerColClasses = [
  {
    no : "header_noBorder_no",
    target : "header_noBorder_bulk",
    code: "header", 
    name: "header", 
    area: "header", pref: "header", 

    rowHeader : "header",

    blInputFlg: "header",

    leadTimeFlg: "header",
    leadTimeDays: "header",
    leadTimeExcludeSaturdayFlg: "header",
    leadTimeContainHolidayFlg: "header",
  
    specifiedDateFlg: "header",
    specifiedDateSunFlg: "header",
    specifiedDateMonFlg: "header",
    specifiedDateTueFlg: "header",
    specifiedDateWedFlg: "header",
    specifiedDateThrFlg: "header",
    specifiedDateFriFlg: "header",
    specifiedDateSatFlg: "header",
    specifiedDateHolFlg: "header",
  
    minQtyFlg: "header_order",
    minQtyCs: "header_order",
    maxQtyFlg: "header_order",
    maxQtyCs: "header_order",
  
    closeFlg: "header_order",
    closeTime: "header_order",
  
    autoOrderFlg: "header_auto",
    autoOrderExecDays: "header_auto",
    autoOrderDays: "header_auto",
    autoOrderContainSundayFlg: "header_auto",
  
    fractionRounding: "header",

    makerCenterName: "header_makercenter",
  },
  {
    no : "header_no",
    target : "header_bulk",
    code: "header", 
    name: "header", 
    area: "header", pref: "header", 

    rowHeader : "header", 

    blInputFlg: "header",

    leadTimeFlg: "header",
    leadTimeDays: "header",
    leadTimeExcludeSaturdayFlg: "header",
    leadTimeContainHolidayFlg: "header",
  
    specifiedDateFlg: "header",
    specifiedDateSunFlg: "header",
    specifiedDateMonFlg: "header",
    specifiedDateTueFlg: "header",
    specifiedDateWedFlg: "header",
    specifiedDateThrFlg: "header",
    specifiedDateFriFlg: "header",
    specifiedDateSatFlg: "header",
    specifiedDateHolFlg: "header",
  
    minQtyFlg: "header_order",
    minQtyCs: "header_order",
    maxQtyFlg: "header_order",
    maxQtyCs: "header_order",
  
    closeFlg: "header_order",
    closeTime: "header_order",

    autoOrderFlg: "header_auto",
    autoOrderDays: "header_auto",
    autoOrderExecDays: "header_auto",
    autoOrderContainSundayFlg: "header_auto",
  
    fractionRounding: "header",

    makerCenterName: "header_makercenter",
  },
];
export const headerRowClasses = {
  const:'rowHeader'
};

export const colWidths =
{
  no : 50,
  target : 50,
  code: 50, 
  name: 150, 
  area: 60, pref: 90, 
  rowHeader : 100, 

	blInputFlg: 40,

	leadTimeFlg: 40,
	leadTimeDays: 40,
  leadTimeExcludeSaturdayFlg: 50,
	leadTimeContainHolidayFlg: 50,

	specifiedDateFlg: 40,
	specifiedDateSunFlg: 40,
	specifiedDateMonFlg: 40,
	specifiedDateTueFlg: 40,
	specifiedDateWedFlg: 40,
	specifiedDateThrFlg: 40,
	specifiedDateFriFlg: 40,
	specifiedDateSatFlg: 40,
  specifiedDateHolFlg: 40,

	minQtyFlg: 40,
	minQtyCs: 50,
	maxQtyFlg: 40,
	maxQtyCs: 50,

  closeFlg: 40,
  closeTime: 70,

	autoOrderFlg: 40,
  autoOrderExecDays: 50,
	autoOrderDays: 50,
	autoOrderContainSundayFlg: 40,
	fractionRounding: 70,  

  makerCenterName: 200,  

};

const fractionRoundingList = [
  { id: 0, label: '無し' },
  { id: 1, label: '切り捨て' },
  { id: 2, label: '四捨五入' },
  { id: 3, label: '切り上げ' },
];
export const fractionRoundingIdFromLabel = (label: string | number | object | null): number => {
  const result = fractionRoundingList.find(data => data.label == label);
  return result ? result.id : fractionRoundingList[0].id;
}
export const fractionRoundingLabelFromId = (id: number, isDefault: boolean): string => {
  const result = fractionRoundingList.find(data => data.id == id);
  return result ? result.label : isDefault ? fractionRoundingList[0].label : null;
}

export const colDataType =
{
  no : {type: 'text', readOnly: true,},
  target: {type: 'checkbox', checkedTemplate: true, uncheckedTemplate: false, readOnly: false, className: 'htCenter'},
  code: {type: 'text', readOnly: true,}, 
  name: {type: 'text', readOnly: true,}, 
  area: {type: 'text', readOnly: true,}, 
  pref: {type: 'text', readOnly: true,}, 
  rowHeader : {type: 'text', readOnly: true,}, 

  blInputFlg: {type: 'checkbox', checkedTemplate: true, uncheckedTemplate: false, readOnly: false, className: 'htCenter'},

  leadTimeFlg: {type: 'checkbox', checkedTemplate: true, uncheckedTemplate: false, readOnly: false, className: 'htCenter'},
  leadTimeDays: { type: 'numeric', numericFormat: { pattern: '0,0', }, readOnly: false, }, 
  leadTimeExcludeSaturdayFlg: {type: 'checkbox', checkedTemplate: true, uncheckedTemplate: false, readOnly: false, className: 'htCenter'},
	leadTimeContainHolidayFlg: {type: 'checkbox', checkedTemplate: true, uncheckedTemplate: false, readOnly: false, className: 'htCenter'},

	specifiedDateFlg: {type: 'checkbox', checkedTemplate: true, uncheckedTemplate: false, readOnly: false, className: 'htCenter'},
	specifiedDateSunFlg: {type: 'checkbox', checkedTemplate: true, uncheckedTemplate: false, readOnly: false, className: 'htCenter'},
	specifiedDateMonFlg: {type: 'checkbox', checkedTemplate: true, uncheckedTemplate: false, readOnly: false, className: 'htCenter'},
	specifiedDateTueFlg: {type: 'checkbox', checkedTemplate: true, uncheckedTemplate: false, readOnly: false, className: 'htCenter'},
	specifiedDateWedFlg: {type: 'checkbox', checkedTemplate: true, uncheckedTemplate: false, readOnly: false, className: 'htCenter'},
	specifiedDateThrFlg: {type: 'checkbox', checkedTemplate: true, uncheckedTemplate: false, readOnly: false, className: 'htCenter'},
	specifiedDateFriFlg: {type: 'checkbox', checkedTemplate: true, uncheckedTemplate: false, readOnly: false, className: 'htCenter'},
	specifiedDateSatFlg: {type: 'checkbox', checkedTemplate: true, uncheckedTemplate: false, readOnly: false, className: 'htCenter'},
	specifiedDateHolFlg: {type: 'checkbox', checkedTemplate: true, uncheckedTemplate: false, readOnly: false, className: 'htCenter'},

	minQtyFlg: {type: 'checkbox', checkedTemplate: true, uncheckedTemplate: false, readOnly: false, className: 'htCenter'},
  minQtyCs: { type: 'numeric', numericFormat: { pattern: '0,0', }, readOnly: false, }, 
	maxQtyFlg: {type: 'checkbox', checkedTemplate: true, uncheckedTemplate: false, readOnly: false, className: 'htCenter'},
  maxQtyCs: { type: 'numeric', numericFormat: { pattern: '0,0', }, readOnly: false, }, 

	closeFlg: {type: 'checkbox', checkedTemplate: true, uncheckedTemplate: false, readOnly: false, className: 'htCenter'},
  closeTime: { type: 'time', readOnly: false, className: 'htCenter', timeFormat: 'H:mm', correctFormat: true},

	autoOrderFlg: {type: 'checkbox', checkedTemplate: true, uncheckedTemplate: false, readOnly: false, className: 'htCenter'},
  autoOrderExecDays: { type: 'numeric', numericFormat: { pattern: '0,0', }, readOnly: false, }, 
  autoOrderDays: { type: 'numeric', numericFormat: { pattern: '0,0', }, readOnly: false, }, 
	autoOrderContainSundayFlg: {type: 'checkbox', checkedTemplate: true, uncheckedTemplate: false, readOnly: false, className: 'htCenter'},

  fractionRounding: { type: 'text', source: fractionRoundingList.map(data => data.label), readOnly: false, className: 'htCenter' },

  makerCenterName: { type: 'text', readOnly: false, className: 'htLeft' },

};

export const rowDataType = {};

//列定義情報（動的パラメータ）
interface SettingMakerColRowModelInterface {
  //列固定
  fixedColumnsLeft: number,

  //テーブルの各列のキー
  colKeys: string[],
  //発注不可時非表示列
  colKeysUnOrderableHidden: string[];
  //テーブルの各行のキー
  rowKeys: string[],
}

export class SettingMakerColRowModel implements SettingMakerColRowModelInterface {
  //列固定
  fixedColumnsLeft: number;
  //テーブルの各列のキー
  colKeys: string[];
  //発注不可時非表示列
  colKeysUnOrderableHidden: string[];
  //テーブルの各列のキー
  rowKeys: string[];
  //ヘッダー
  nestedHeaders; //any[][]
  nestedHeadersBackup; //any[][]
  //ヘッダー区切り
  nestedHeadersGroupIndex: number[];
  //テーブルの各列の逆引き用マップ
  colKeysMap;
  //テーブルの各行の逆引き用マップ
  rowKeysMap;

  constructor(param: SettingMakerColRowModelInterface) {
    this.fixedColumnsLeft = param.fixedColumnsLeft;
    this.colKeys = param.colKeys;
    this.colKeysUnOrderableHidden = param.colKeysUnOrderableHidden;
    this.rowKeys = param.rowKeys;
    this.colKeysUnOrderableHidden = param.colKeysUnOrderableHidden;

    //テーブルの各列のキー逆引き
    const colKeysMap = {};
    param.colKeys.forEach((colKey, index) => {
      colKeysMap[colKey] = index;
    });
    this.colKeysMap = colKeysMap;
    //テーブルの各行のキー逆引き
    const rowKeysMap = {};
    param.rowKeys.forEach((rowKey, index) => {
      rowKeysMap[rowKey] = index;
    });
    this.rowKeysMap = rowKeysMap;

    //nestedHeaders初期化
    this.initNestedHeaders();
  }

  //nestedHeaders初期化
  initNestedHeaders() {
    //nestedHeaders
    const nestedHeaders = [];
    const nestedHeadersGroupIndex = [];
    // const customBorders = [];
    headersCol.forEach((header, index) => {
      const lastHeader = index == headersCol.length - 1;
      const nestedHeader = [];

      let colspan=0;
      let preHeaderVal = null;
      this.colKeys.forEach((colKey) => {
        const headerVal = header[colKey];
        if(lastHeader) {
          nestedHeader.push(headerVal);
        }
        else {
          if(preHeaderVal !== null && preHeaderVal != headerVal){
            nestedHeader.push(colspan == 1 ? preHeaderVal : {label: preHeaderVal, colspan: colspan});
            colspan=0;
          }
          colspan++;
          preHeaderVal = headerVal;
        }
        //0行目でグループ
        if(index == 0) {
          nestedHeadersGroupIndex.push(colspan-1);
        }
      });
      if(!lastHeader && colspan > 0) {
        nestedHeader.push(colspan == 1 ? preHeaderVal : {label: preHeaderVal, colspan: colspan});
      }

      nestedHeaders.push(nestedHeader);
    });

    this.nestedHeaders = nestedHeaders;
    this.nestedHeadersBackup = nestedHeaders.map(nestedHeadersSub => [...nestedHeadersSub]);
    this.nestedHeadersGroupIndex = nestedHeadersGroupIndex;
  }

  //function
  keyFromCol(column:number):string {
    return this.colKeys[column];
  }
  keyFromCols(columns:number[]):string[] {
    const vals:string[] = [];
    columns.forEach(column => vals.push(this.colKeys[column]))
    return vals;
  }
  colFromKey(colKey:string):number {
    return this.colKeysMap[colKey];
  }
  colFromKeys(colKeys:string[]):number[] {
    const vals:number[] = [];
    colKeys.forEach(colKey => vals.push(this.colKeysMap[colKey]))
    return vals;
  }
  //function
  keyFromRow(row:number):string {
    const rowKeys = this.rowKeys;
    return rowKeys[row];
  }
  keyFromRows(rows:number[]):string[] {
    const rowKeys = this.rowKeys;
    const vals:string[] = [];
    rows.forEach(row => vals.push(rowKeys[row]))
    return vals;
  }
  rowFromKey(rowKey:string):number {
    const rowKeysMap = this.rowKeysMap;
    return rowKeysMap[rowKey];
  }
  rowFromKeys(rowKeys:string[]):number[] {
    const rowKeysMap = this.rowKeysMap;
    const vals:number[] = [];
    rowKeys.forEach(rowKey => vals.push(rowKeysMap[rowKey]))
    return vals;
  }
}

//SettingMaker用の列モデル
export const settingMakerColRowModelDefault:SettingMakerColRowModel = new SettingMakerColRowModel({
  //列固定
  fixedColumnsLeft: headerColKeys.length + rowHeaderColKeys.length,

  //テーブルの各列のキー
  colKeys: [
    ...headerColKeys,
    ...rowHeaderColKeys,
    ...bodyColKeys,
  ],
  colKeysUnOrderableHidden: colKeysUnOrderableHidden, 
  rowKeys: rowKeys,
});
