import moment from "moment";
moment.updateLocale("ja", {
  months: ["1月","2月","3月","4月","5月","6月","7月","8月","9月","10月","11月","12月",],
  weekdays: ["日曜日", "月曜日", "火曜日", "水曜日", "木曜日", "金曜日", "土曜日"],
  weekdaysShort: ["日", "月", "火", "水", "木", "金", "土"]
});

//ヘッダー列
export const headerColKeys = [
  'no',
  'target',
  'code', 'name', 
  'area', 'pref', 
];
//行ヘッダー列
export const rowHeaderColKeys = [
  // 'rowHeader', 
];
//ヘッダー列
export const bodyColKeys = [
	'allowablePeriodMaster',
	'freshnessMinDaysOfHalfBestBefore',
	'freshnessDaysOfAlert',

  'shubaiLimitAlert',

  'todayUseFlg',
  'weekdayWarnFlg',

	'shippingJuchuOrInput',

  'sejZanteiOrderLeadNew',
  'sejZanteiOrderLead',
];
//ヘッダー列（発注不可）
export const colKeysUnOrderableHidden = [
];

//ヘッダー列（部門SEJのみ）
export const colKeysBumonSejOnlyShow = [
	'sejZanteiOrderLeadNew',
	'sejZanteiOrderLead',
];

export const rowKeys = ['const'];

export const headersCol = [
  {
    no : "No.",
    target : "一括",
    code: "倉庫", 
    name: "倉庫", 
  
    area: "倉庫", pref: "倉庫", 

    rowHeader: "項目", 

    allowablePeriodMaster: "得意先商品マスタの",
    freshnessMinDaysOfHalfBestBefore: "出荷鮮度切れアラーム",
    freshnessDaysOfAlert: "出荷鮮度切れアラーム",

    shubaiLimitAlert: '終売日アラーム',

    todayUseFlg: '当日入庫',
    weekdayWarnFlg: '入庫可能曜日',

    shippingJuchuOrInput: '当日出庫数に',

    sejZanteiOrderLeadNew: "SEJ仮締めファイル 発注確定日⇒出荷日 加算日数",
    sejZanteiOrderLead: "SEJ仮締めファイル 発注確定日⇒出荷日 加算日数",
  
  },
  {
    no : "",
    target : "",
    code: "<br/>コード", 
    name: "<br/>名称", 
    area: "<br/>地域", pref: "<br/>都道府県", 

    rowHeader : "", 
    
    allowablePeriodMaster: "許容期間を利用",
    freshnessMinDaysOfHalfBestBefore: "N日以上は1/2期限<br/>N日未満は2/3期限",
    freshnessDaysOfAlert: "M日前にアラート表示",

    shubaiLimitAlert: 'N日前以降にアラート表示',

    todayUseFlg: '未使用',
    weekdayWarnFlg: '以外警告',

    shippingJuchuOrInput: '入力した出庫数を<br/>使用する',

    sejZanteiOrderLeadNew: "新規商品<br/>(算定日が日曜の場合は翌月曜)",
    sejZanteiOrderLead: "通常<br/>(算定日が日曜の場合は翌月曜)",
  },
];

export const headersRow = {
  const:"ダミー"
};

export const selectableCol =
{
  no : false,
  code: false, 
  name: false, 

  area: true, pref: true, 
  rowHeader : false, 

	allowablePeriodMaster: false,
	freshnessMinDaysOfHalfBestBefore: false,
	freshnessDaysOfAlert: false,

  shubaiLimitAlert: false,

  todayUseFlg: false,
  weekdayWarnFlg: false,

	shippingJuchuOrInput: false,

  sejZanteiOrderLeadNew: false,
  sejZanteiOrderLead: false,
}
export const selectableRow = {
  const:false
};

export const headerColClasses = [
  {
    no : "header_noBorder_no",
    target : "header_noBorder_bulk",
    code: "header", 
    name: "header", 
    area: "header", pref: "header", 

    rowHeader : "header",

    allowablePeriodMaster: "header_noBorder",
    freshnessMinDaysOfHalfBestBefore: "header",
    freshnessDaysOfAlert: "header",

    shubaiLimitAlert: "header",

    todayUseFlg: "header_noBorder",
    weekdayWarnFlg: "header_noBorder",

    shippingJuchuOrInput: "header_noBorder",

    sejZanteiOrderLeadNew: "header",
    sejZanteiOrderLead: "header",
  
  },
  {
    no : "header_no",
    target : "header_bulk",
    code: "header", 
    name: "header", 
    area: "header", pref: "header", 

    rowHeader : "header", 

    allowablePeriodMaster: "header",
    freshnessMinDaysOfHalfBestBefore: "header",
    freshnessDaysOfAlert: "header",

    shubaiLimitAlert: "header",

    todayUseFlg: "header",
    weekdayWarnFlg: "header",

    shippingJuchuOrInput: "header",

    sejZanteiOrderLeadNew: "header",
    sejZanteiOrderLead: "header",

  },
];
export const headerRowClasses = {
  const:'rowHeader'
};

export const colWidths =
{
  no : 50,
  target : 50,
  code: 50, 
  name: 150, 
  area: 60, pref: 90, 
  rowHeader : 100, 

	allowablePeriodMaster: 40,
	freshnessMinDaysOfHalfBestBefore: 40,
  freshnessDaysOfAlert: 50,

  shubaiLimitAlert: 50,

  todayUseFlg: 50,
  weekdayWarnFlg: 50,

	shippingJuchuOrInput: 40,

  sejZanteiOrderLeadNew: 50,
  sejZanteiOrderLead: 50,
	
};

export const colDataType =
{
  no : {type: 'text', readOnly: true,},
  target: {type: 'checkbox', checkedTemplate: true, uncheckedTemplate: false, readOnly: false, className: 'htCenter'},
  code: {type: 'text', readOnly: true,}, 
  name: {type: 'text', readOnly: true,}, 
  area: {type: 'text', readOnly: true,}, 
  pref: {type: 'text', readOnly: true,}, 
  rowHeader : {type: 'text', readOnly: true,}, 

  allowablePeriodMaster: { type: 'checkbox', checkedTemplate: true, uncheckedTemplate: false, readOnly: false, className: 'htCenter' },
  freshnessMinDaysOfHalfBestBefore: { type: 'numeric', numericFormat: { pattern: '0,0', }, readOnly: false, }, 
  freshnessDaysOfAlert: { type: 'numeric', numericFormat: { pattern: '0,0', }, readOnly: false, }, 

  shubaiLimitAlert: { type: 'numeric', numericFormat: { pattern: '0,0', }, readOnly: false, }, 

  todayUseFlg: { type: 'checkbox', checkedTemplate: true, uncheckedTemplate: false, readOnly: false, className: 'htCenter' },
  weekdayWarnFlg: { type: 'checkbox', checkedTemplate: true, uncheckedTemplate: false, readOnly: false, className: 'htCenter' },

  shippingJuchuOrInput: { type: 'checkbox', checkedTemplate: true, uncheckedTemplate: false, readOnly: false, className: 'htCenter' },

  sejZanteiOrderLeadNew: { type: 'numeric', numericFormat: { pattern: '0,0', }, readOnly: false, }, 
  sejZanteiOrderLead: { type: 'numeric', numericFormat: { pattern: '0,0', }, readOnly: false, }, 

};

export const rowDataType = {};

//列定義情報（動的パラメータ）
interface SettingCenterColRowModelInterface {
  //列固定
  fixedColumnsLeft: number,

  //テーブルの各列のキー
  colKeys: string[],
  //発注不可時非表示列
  colKeysUnOrderableHidden: string[];
  //部門による非表示列
  colKeysBumonSejOnlyShow: string[];
  //テーブルの各行のキー
  rowKeys: string[],
}

export class SettingCenterColRowModel implements SettingCenterColRowModelInterface {
  //列固定
  fixedColumnsLeft: number;
  //テーブルの各列のキー
  colKeys: string[];
  //発注不可時非表示列
  colKeysUnOrderableHidden: string[];
  //部門による非表示列
  colKeysBumonSejOnlyShow: string[];
  //テーブルの各列のキー
  rowKeys: string[];
  //ヘッダー
  nestedHeaders; //any[][]
  nestedHeadersBackup; //any[][]
  //ヘッダー区切り
  nestedHeadersGroupIndex: number[];
  //テーブルの各列の逆引き用マップ
  colKeysMap;
  //テーブルの各行の逆引き用マップ
  rowKeysMap;

  constructor(param: SettingCenterColRowModelInterface) {
    this.fixedColumnsLeft = param.fixedColumnsLeft;
    this.colKeys = param.colKeys;
    this.rowKeys = param.rowKeys;
    this.colKeysUnOrderableHidden = param.colKeysUnOrderableHidden;
    this.colKeysBumonSejOnlyShow = param.colKeysBumonSejOnlyShow;

    //テーブルの各列のキー逆引き
    const colKeysMap = {};
    param.colKeys.forEach((colKey, index) => {
      colKeysMap[colKey] = index;
    });
    this.colKeysMap = colKeysMap;
    //テーブルの各行のキー逆引き
    const rowKeysMap = {};
    param.rowKeys.forEach((rowKey, index) => {
      rowKeysMap[rowKey] = index;
    });
    this.rowKeysMap = rowKeysMap;

    //nestedHeaders初期化
    this.initNestedHeaders();
  }

  //nestedHeaders初期化
  initNestedHeaders() {
    //nestedHeaders
    const nestedHeaders = [];
    const nestedHeadersGroupIndex = [];
    // const customBorders = [];
    headersCol.forEach((header, index) => {
      const lastHeader = index == headersCol.length - 1;
      const nestedHeader = [];

      let colspan=0;
      let preHeaderVal = null;
      this.colKeys.forEach((colKey) => {
        const headerVal = header[colKey];
        if(lastHeader) {
          nestedHeader.push(headerVal);
        }
        else {
          if(preHeaderVal !== null && preHeaderVal != headerVal){
            nestedHeader.push(colspan == 1 ? preHeaderVal : {label: preHeaderVal, colspan: colspan});
            colspan=0;
          }
          colspan++;
          preHeaderVal = headerVal;
        }
        //0行目でグループ
        if(index == 0) {
          nestedHeadersGroupIndex.push(colspan-1);
        }
      });
      if(!lastHeader && colspan > 0) {
        nestedHeader.push(colspan == 1 ? preHeaderVal : {label: preHeaderVal, colspan: colspan});
      }

      nestedHeaders.push(nestedHeader);
    });

    this.nestedHeaders = nestedHeaders;
    this.nestedHeadersBackup = nestedHeaders.map(nestedHeadersSub => [...nestedHeadersSub]);
    this.nestedHeadersGroupIndex = nestedHeadersGroupIndex;
  }

  //function
  keyFromCol(column:number):string {
    return this.colKeys[column];
  }
  keyFromCols(columns:number[]):string[] {
    const vals:string[] = [];
    columns.forEach(column => vals.push(this.colKeys[column]))
    return vals;
  }
  colFromKey(colKey:string):number {
    return this.colKeysMap[colKey];
  }
  colFromKeys(colKeys:string[]):number[] {
    const vals:number[] = [];
    colKeys.forEach(colKey => vals.push(this.colKeysMap[colKey]))
    return vals;
  }
  //function
  keyFromRow(row:number):string {
    const rowKeys = this.rowKeys;
    return rowKeys[row];
  }
  keyFromRows(rows:number[]):string[] {
    const rowKeys = this.rowKeys;
    const vals:string[] = [];
    rows.forEach(row => vals.push(rowKeys[row]))
    return vals;
  }
  rowFromKey(rowKey:string):number {
    const rowKeysMap = this.rowKeysMap;
    return rowKeysMap[rowKey];
  }
  rowFromKeys(rowKeys:string[]):number[] {
    const rowKeysMap = this.rowKeysMap;
    const vals:number[] = [];
    rowKeys.forEach(rowKey => vals.push(rowKeysMap[rowKey]))
    return vals;
  }
}

//SettingCenter用の列モデル
export const settingCenterColRowModelDefault:SettingCenterColRowModel = new SettingCenterColRowModel({
  //列固定
  fixedColumnsLeft: headerColKeys.length + rowHeaderColKeys.length,

  //テーブルの各列のキー
  colKeys: [
    ...headerColKeys,
    ...rowHeaderColKeys,
    ...bodyColKeys,
  ],
  colKeysUnOrderableHidden: colKeysUnOrderableHidden, 
  //部門による非表示列
  colKeysBumonSejOnlyShow: colKeysBumonSejOnlyShow, 
  rowKeys: rowKeys,
});

