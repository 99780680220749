import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CodeName } from "@/assets/commontype/CodeName";
import * as arrayUtil from "@/util/arrayUtil";

export type CommonOptions = {
  optionBumons?: CodeName[],    //部門
};
//Page State
export type CommonSaveState = {
  //検索条件
  optionBumon:CodeName|null,    //部門
};

export const initialCommonState: CommonSaveState = {
  optionBumon: null,
};

//Page Slice
export type CommonSaveReducer = {
  initOnDidMount: (state:CommonSaveState) => void,
  resetOnWillUnmount: (state:CommonSaveState) => void,
  setOptionBumon: (state:CommonSaveState, action: PayloadAction<CodeName|null>) => void,
  setBumonList: (state:CommonSaveState, action: PayloadAction<CodeName[]>) => void,
  }

const createReducerContent = (name:string, initialState: CommonSaveState):CommonSaveReducer => {return {
  //componentDidMount
  initOnDidMount(state:CommonSaveState) {
  },
  //componentWillUnmount
  resetOnWillUnmount(state:CommonSaveState) {
  },
  setOptionBumon (state:CommonSaveState, action: PayloadAction<CodeName|null>) {
    console.log('commonSaveStore.setOptionBumon ' + action.payload?.code);
    state.optionBumon = action.payload;
  },
  setBumonList (state:CommonSaveState, action: PayloadAction<CodeName[]>) {
    console.log('commonSaveStore.setBumonList');
    state.optionBumon = arrayUtil.matchOrFirst(state.optionBumon, action.payload);
  },
}};

const createSliceContent = (name:string, initialState: CommonSaveState) => createSlice({
  name: name,
  initialState: initialState,
  reducers: createReducerContent(name, initialState),
});

//Page Slice Export
//commonSaveSlice
export const commonSaveSlice = createSliceContent("commonSave", initialCommonState);
