import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CodeName } from "@/assets/commontype/CodeName";
import * as arrayUtil from "@/util/arrayUtil";
import { SettingCenterColRowModel, settingCenterColRowModelDefault } from "@/components/settingcenter/SettingCenterTableModel";
import { centerAttributes, listSortOrders } from "@/store/settingcenter/settingCenterCommon";

//強制表示列
export const mustVisibleColumnsKey:string[] = [
  "target", 
  "no", 
  "code", "name", 
  //入力項目
  'allowablePeriodMaster',
  'freshnessMinDaysOfHalfBestBefore',
  'freshnessDaysOfAlert',
  'shubaiLimitAlert',
  'todayUseFlg',
  'weekdayWarnFlg',
  'shippingJuchuOrInput',
  'sejZanteiOrderLeadNew',
  'sejZanteiOrderLead',

];
//デフォルト表示列
export const defaultVisibleColumnsKey:string[] = [
  //以下に貼り付け
  "area", 
  "pref",
];
//強制表示行
export const mustVisibleRowsKey:string[] = [
  'const'
];
//デフォルト表示行
export const defaultVisibleRowsKey:string[] = [
];

export const optionsNameList = [
  'optionBumons',
  'optionAreas',
  'optionCenters',
  'optionCenterCode',
];
export type SettingCenterOptions = {
  optionBumons?: CodeName[],    //部門
  optionAreas?: CodeName[],     //地域
  optionCenters?: CodeName[],   //倉庫
  optionCenterCode?: string,    //倉庫CD
};

//Page State
export type SettingCenterSaveState = {
  //検索条件
  refreshOptions_ver: number,
  optionBumons:CodeName[],    //部門
  optionAreas:CodeName[],     //地域
  optionCenters:CodeName[],   //倉庫
  optionCenterCode:string,            //倉庫CD

  favoriteOptions: { title: CodeName, params: SettingCenterOptions }[] | null,

  listSortOrder:CodeName,   //並び順
  listSortOrderDesc:boolean,   //昇順

  hiddenColumns: number[],
  hiddenColumnsKey: string[],
  visibleColumnsKey: string[],
  visibleRowsKey: string[],

  colWidthsModified: {},
};

export const initialSettingCenterState: SettingCenterSaveState = {
  refreshOptions_ver: 0,
  optionBumons:[],    //部門
  optionAreas:[],     //地域
  optionCenters:[],   //倉庫
  optionCenterCode:'',            //倉庫CD

  favoriteOptions: [],

  listSortOrder: listSortOrders.find(listSortOrder => listSortOrder.code == 'centerCD'),   //並び順
  listSortOrderDesc: false,   //並び順

  hiddenColumns: [],
  hiddenColumnsKey: [],
  visibleColumnsKey: defaultVisibleColumnsKey,
  visibleRowsKey: defaultVisibleRowsKey,

  colWidthsModified: {},

};

//Page Slice
export type SettingCenterSaveReducer = {
  initOnDidMount: (state:SettingCenterSaveState) => void,
  resetOnWillUnmount: (state:SettingCenterSaveState) => void,
  clearOption: (state:SettingCenterSaveState, action: PayloadAction<string>) => void,
  refreshOptions: (state: SettingCenterSaveState) => void,
  setOptionBumons: (state:SettingCenterSaveState, action: PayloadAction<CodeName[]>) => void,
  setOptionAreas: (state:SettingCenterSaveState, action: PayloadAction<CodeName[]>) => void,
  setOptionCenters: (state:SettingCenterSaveState, action: PayloadAction<CodeName[]>) => void,
  setOptionCenterCode: (state:SettingCenterSaveState, action: PayloadAction<string>) => void,
  setFavoriteOptions: (state:SettingCenterSaveState, action: PayloadAction<{ title: CodeName, params: SettingCenterOptions }[]>) => void,
  setListSortOrder: (state:SettingCenterSaveState, action: PayloadAction<CodeName>) => void,
  setListSortOrderDesc: (state:SettingCenterSaveState, action: PayloadAction<boolean>) => void,
  columnWidthChange: (state:SettingCenterSaveState, action: PayloadAction<{colKey:string, width:number}>) => void,
  hideColumnKeysChange: (state:SettingCenterSaveState, action: PayloadAction<string[]>) => void,
  visibleRowKeysChange: (state:SettingCenterSaveState, action: PayloadAction<string[]>) => void,
}

const createReducerContent = (name:string, colRowModel:SettingCenterColRowModel, initialState: SettingCenterSaveState):SettingCenterSaveReducer => {return {
  //componentDidMount
  initOnDidMount(state:SettingCenterSaveState) {
    if(state.optionBumons === undefined) {
      state.optionBumons = initialSettingCenterState.optionBumons;
    }
    
    if(state.optionAreas === undefined) {
      state.optionAreas = initialSettingCenterState.optionAreas;
    }
    if(state.optionCenters === undefined) {
      state.optionCenters = initialSettingCenterState.optionCenters;
    }
    if(state.optionCenterCode === undefined) {
      state.optionCenterCode = initialSettingCenterState.optionCenterCode;
    }
    if(state.favoriteOptions === undefined) {
      state.favoriteOptions = initialSettingCenterState.favoriteOptions;
    }
    if(!state.listSortOrder) {
      state.listSortOrder = initialState.listSortOrder;
    }
    else if(state.listSortOrder.code) { //属性値を再選択する
      const code = state.listSortOrder.code;
      state.listSortOrder = listSortOrders.find(listSortOrder => listSortOrder.code == code);   //並び順
    }

    if(state.listSortOrderDesc === undefined) {
      state.listSortOrderDesc = initialState.listSortOrderDesc;
    }

    //初期表示列0配列は、常に全項目表示とする
    if(initialState.visibleColumnsKey.length == 0) {
      state.visibleColumnsKey = colRowModel.colKeys;
    }
    else if(!state.visibleColumnsKey) {
      state.visibleColumnsKey = initialState.visibleColumnsKey;
    }
    //強制選択列
    state.visibleColumnsKey = arrayUtil.union(arrayUtil.and(colRowModel.colKeys, mustVisibleColumnsKey), state.visibleColumnsKey);
    //非表示列
    state.hiddenColumnsKey = arrayUtil.not(colRowModel.colKeys, state.visibleColumnsKey);

    //行項目
    if (!state.visibleRowsKey || state.visibleRowsKey.length == 0) {
      state.visibleRowsKey = (initialState.visibleRowsKey.length == 0) ? colRowModel.rowKeys : initialState.visibleRowsKey;
    }
    //強制選択行
    state.visibleRowsKey = arrayUtil.union(arrayUtil.and(colRowModel.rowKeys, mustVisibleRowsKey), state.visibleRowsKey);

    if(state.colWidthsModified === undefined) {
      state.colWidthsModified = initialState.colWidthsModified;
    }

  },
  //componentWillUnmount
  resetOnWillUnmount(state:SettingCenterSaveState) {
    //初期表示列0配列は、常に全項目表示とするため、記憶しない
    if(initialState.visibleColumnsKey.length == 0) {
      state.visibleColumnsKey = [];
    }
  },
  clearOption(state: SettingCenterSaveState, action: PayloadAction<string>) {
    const key = action.payload;
    if (!key) {
      // state.optionBumons = initialSettingCenterState.optionBumons;
    }
    if (!key || key === 'center') {
      state.optionAreas = initialSettingCenterState.optionAreas;
      state.optionCenters = initialSettingCenterState.optionCenters;
      state.optionCenterCode = initialSettingCenterState.optionCenterCode;
    }
  },
  refreshOptions (state: SettingCenterSaveState) {
    state.refreshOptions_ver = state.refreshOptions_ver + 1;
  },
  setOptionBumons (state:SettingCenterSaveState, action: PayloadAction<CodeName[]>) {
    state.optionBumons = action.payload;
  },
  setOptionAreas (state:SettingCenterSaveState, action: PayloadAction<CodeName[]>) {
    state.optionAreas = action.payload;
  },
  setOptionCenters (state:SettingCenterSaveState, action: PayloadAction<CodeName[]>) {
    state.optionCenters = action.payload;
  },
  setOptionCenterCode (state:SettingCenterSaveState, action: PayloadAction<string>) {
    state.optionCenterCode = action.payload;
  },
  setFavoriteOptions(state:SettingCenterSaveState, action: PayloadAction<{ title: CodeName, params: SettingCenterOptions }[]>) {
    state.favoriteOptions = action.payload;
  },
  setListSortOrder (state:SettingCenterSaveState, action: PayloadAction<CodeName>) {
    state.listSortOrder = action.payload;
  },
  setListSortOrderDesc (state:SettingCenterSaveState, action: PayloadAction<boolean>) {
    state.listSortOrderDesc = action.payload;
  },

  columnWidthChange(state:SettingCenterSaveState, action: PayloadAction<{colKey:string, width:number}>){
    console.log('store.columnWidthChange');
    const colKey = action.payload.colKey;
    const width = action.payload.width;
    if(colKey && width) {
      state.colWidthsModified[colKey] = width;
    }
  },
  hideColumnKeysChange(state:SettingCenterSaveState, action: PayloadAction<string[]>){
    console.log('store.hideColumnKeysChange');
    //store更新
    state.hiddenColumns = colRowModel.colFromKeys(action.payload);
    state.hiddenColumnsKey = action.payload;
    state.visibleColumnsKey = arrayUtil.not(colRowModel.colKeys, action.payload);
  },
  visibleRowKeysChange(state:SettingCenterSaveState, action: PayloadAction<string[]>){
    console.log('store.visibleRowKeysChange');
    //store更新
    state.visibleRowsKey = action.payload;
  },
  
}};

const createSliceContent = (name:string, colRowModel:SettingCenterColRowModel, initialState: SettingCenterSaveState) => createSlice({
  name: name,
  initialState: initialState,
  reducers: createReducerContent(name, colRowModel, initialState),
});

//Page Slice Export
//settingCenterSaveSlice
export const settingCenterSaveSlice = createSliceContent("settingCenterSave", settingCenterColRowModelDefault, initialSettingCenterState);
