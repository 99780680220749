import { CodeName } from "@/assets/commontype/CodeName";

export type ErrorInfoData = {
  nouhinDate?: string | null, //納品日
  // numCS?: string | null, //発注CS数]
  numCSTotal?: string | null, //発注CS数]
}

//倉庫・商品・タグ
export type InfoData = {
  uuid?: string,  //データベースに登録済みのUUID。更新時にDB上の値とずれていれば登録エラーとする

  status?: '0'|'9'|string, //ステータス 0:未連携 1:連携中 2:連携済 9:連携エラー

  itemCD?: string | null, //商品CD
  itemNM?: string | null, //商品名
  janCD?: string | null,   //JAN
  makerCD?: string | null, //メーカー
  makerNM?: string | null, //メーカー
  shiireFAX?: string | null, //仕入先
  categoryCD?: string | null, //ｶﾃｺﾞﾘ
  categoryNM?: string | null, //ｶﾃｺﾞﾘ
  blIrisu?: number | null,  //容量1(BL入数) 
  csIrisu?: number | null,  //容量2(CS入数)
  lot?: number | null,  //発注単位
  csIrisu_lot?: number | null,  //CS入数×発注単位
  capacity?: string | null, //内容量
  bestBefore?: string | null, //賞味期間
  teika?: number | null, //定価
  tatene?: number | null, //建値
  loadedQty?: number | null, //"積付面",
  tokuisakiItemCD?: string | null, //得意先商品CD

  bumonCD?: string, //--部門
  centerCD?: string | null, //倉庫(4桁コード)
  centerCD2?: string | null, //倉庫(2桁コード)
  centerNM?: string | null, //倉庫
  centerSortInput?: number | null, //倉庫並び順
  centerSortOutput?: number | null, //倉庫並び順
  areaCD?: string | null, //地域
  areaNM?: string | null, //地域
  prefCD?: string | null, //都道府県
  prefNM?: string | null, //都道府県

  nouhinDate?: number | null, //納品日
  numCS?: number | null, //発注CS数
  numBL?: number | null, //発注BL数
  editDate?: string | null, //編集日  //'YYYY-MM-DD HH24:mi:ss'
  editUser?: string | null, //編集者
  editSeq?: number | null, //編集Seq

  targetFlg?: boolean | null, //発注対象

  oldTargetFlg?: boolean,
  errorInfo?: ErrorInfoData,
  warnInfo?: ErrorInfoData,
  edited?: boolean, //編集
  visible?: boolean, //表示状態

	minQtyCs?: number,	//最小発注CS数
	maxQtyCs?: number,	//最大発注CS数

  closeTime?: string,

	leadTimeDays?: number,	//リードタイム日数
  leadTimeExcludeSaturdayFlg?: boolean,	//リードタイム(土曜を除く)
	leadTimeContainHolidayFlg?: boolean,	//リードタイム(祝日を含む)

  ifAdmsOrderIfseq?: number,
  ifAdmsOrderHsseq?: number,
  ifAdmsSendDate?: string,  //'YYYY-MM-DD HH24:mi:ss'
  ifAdmsErrorDate?: string,  //'YYYY-MM-DD HH24:mi:ss'
  ifAdmsErrorMessage?: string,
}
export type InfoMakerCenterData = {
  makerCD?: string | null, //メーカー
  makerNM?: string | null, //メーカー

  bumonCD?: string, //--部門
  centerCD?: string | null, //倉庫(4桁コード)
  centerCD2?: string | null, //倉庫(2桁コード)
  centerNM?: string | null, //倉庫
  areaCD?: string | null, //地域
  areaNM?: string | null, //地域
  prefCD?: string | null, //都道府県
  prefNM?: string | null, //都道府県

  nouhinDate?: number | null, //納品日
  numCS?: number | null, //発注CS数
  numBL?: number | null, //発注BL数
  numCS_result?: number | null, //発注CS数(発注済み)
  numBL_result?: number | null, //発注BL数(発注済み)

  errorInfo?: ErrorInfoData,
  warnInfo?: ErrorInfoData,

	minQtyCs?: number,	//最小発注CS数
	maxQtyCs?: number,	//最大発注CS数

  closeTime?: string,
}

//並び順
export const listSortOrders:CodeName[] = [
  new CodeName({group_code:"item", group_name:"商品→センター", code: "itemCD", name: "商品コード順", }),
  new CodeName({group_code:"item", group_name:"商品→センター", code: "itemNM", name: "商品名順", }),
  new CodeName({group_code:"item", group_name:"商品→センター", code: "janCD", name: "JANコード順", }),
  new CodeName({group_code:"item", group_name:"商品→センター", code: "maker", name: "メーカー順", }),
  new CodeName({group_code:"item", group_name:"商品→センター", code: "category", name: "商品カテゴリ順", }),
  new CodeName({group_code:"item", group_name:"商品→センター", code: "bestBefore", name: "賞味期間順", }),
  new CodeName({group_code:"item", group_name:"商品→センター", code: "teika", name: "定価順", }),
  new CodeName({group_code:"item", group_name:"商品→センター", code: "tatene", name: "建値順", }),

  new CodeName({group_code:"center", group_name:"センター→商品", code: "center", name: "倉庫", }),
  new CodeName({group_code:"center", group_name:"センター→商品", code: "area", name: "地域", }),
  new CodeName({group_code:"center", group_name:"センター→商品", code: "pref", name: "都道府県", }),

  new CodeName({group_code:"ymd", group_name:"倉入日", code: "ymd_center", name: "倉入日→センター順", }),
  new CodeName({group_code:"ymd", group_name:"倉入日", code: "ymd_item", name: "倉入日→商品コード順", }),

];