import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CodeName } from "@/assets/commontype/CodeName";

import { InfoData, ErrorInfoData, InfoMakerCenterData } from '@/store/orderinstruction/orderInstructionCommon'
import * as orderInstructionRetrieve from "@/assets/apitype/orderInstructionRetrieve";
import { OrderInstructionColRowModel, rowKeys, headersRow, colDataType, rowDataType } from "@/components/orderinstruction/OrderInstructionTableModel";
import * as compareUtil from "@/util/compareUtil";
import * as arrayutil from "@/util/arrayUtil";
import * as editorUtil from "@/util/editorUtil";
import * as calcUtil from "@/util/calcUtil";
import * as momentUtil from "@/util/momentUtil";
import moment, { Moment } from 'moment';

//0.00フォーマット
const formatterP00 = new Intl.NumberFormat('ja-JP', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 });
//0,0フォーマット
const formatterN0 = new Intl.NumberFormat('ja-JP', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0 });

//編集中判定
export const isEditedRowData = (data: InfoData): boolean => {
  if(!data) {
    return false;
  }

  return (
    compareUtil.compareBoolean(data.targetFlg, data.oldTargetFlg, true) != 0
  );
}

export type RowInfo = {
  no?: string,
  subno?: string,
  rowKey?: string,
  rowIndex?:number,  //同一集計行内でのrowKeyのindex
  rowIndexLast?:boolean, //同一集計行内での最後のrowKeyフラグ
  row:number,

  infoData? :InfoData,
  infoDataIndex?:number,
  infoMakerCenterDataIndex? :number,

  orderable:boolean,
}
export interface EditCellInfo {
  row:number, 
  col:number, 
  rowKey:string, 
  value:string|number|object|null, 
  relatedValues?: {key:string, value:string|number|object|null}[],
}

//Page State
export type OrderInstructionTmpState = {
  // bumonList: CodeName[],  //部門
  makerList: CodeName[],  //メーカー
  areaList: CodeName[], //地域
  centerList: CodeName[], //倉庫
  userList: CodeName[], //地域

  progress: Record<string, unknown>,
  retrieveParam: orderInstructionRetrieve.RequestParam,  //検索条件(検索済み)
  retrievedParam: orderInstructionRetrieve.RequestParam,  //検索条件(検索済み)

  rowInfos: RowInfo[],
  fixedRowsTop :number,
  infoDatas: InfoData[],
  infoMakerCenterDatas: InfoMakerCenterData[],

  tableVer_updateData: number,
  tableVer_updateData_rows: number[],
  tableVer_updateSettting: number,
  rows: any[][],
  mergeCells: {row: number, col: number, rowspan: number, colspan: number}[]

  errorMessage: string | null,
  infoMessage: string | null,

  editing: boolean,
  functionKeys: string[],
  // openDialog: boolean,

  selectedSubno: string[],
};

export const initialState: OrderInstructionTmpState = {
  // bumonList: [],
  makerList: [],
  areaList: [],
  centerList: [],
  userList: [],

  progress: {},
  retrieveParam: null,
  retrievedParam: null,

  rowInfos: [],
  fixedRowsTop :0,
  infoDatas: [],
  infoMakerCenterDatas: [],

  tableVer_updateData: 0,
  tableVer_updateData_rows: [],
  tableVer_updateSettting: 0,
  rows: [],
  mergeCells: null,

  errorMessage: null,
  infoMessage: null,

  editing: false,
  functionKeys: [],
  // openDialog: boolean,

  selectedSubno: [],
};

//Page Slice
export type OrderInstructionTmpReducer = {
  putProgress: (state:OrderInstructionTmpState, action: PayloadAction<string>) => void,
  removeProgress: (state:OrderInstructionTmpState, action: PayloadAction<string>) => void,
  setErrorMessage: (state:OrderInstructionTmpState, action: PayloadAction<string>) => void,
  setInfoMessage: (state:OrderInstructionTmpState, action: PayloadAction<string>) => void,
  setEditingStart: (state:OrderInstructionTmpState, action: PayloadAction<string>) => void,
  setEditingEnd: (state:OrderInstructionTmpState, action: PayloadAction<string>) => void,
  // setBumonList: (state:OrderInstructionTmpState, action: PayloadAction<CodeName[]>) => void,
  setMakerList: (state:OrderInstructionTmpState, action: PayloadAction<CodeName[]>) => void,
  setAreaList: (state:OrderInstructionTmpState, action: PayloadAction<CodeName[]>) => void,
  setCenterList: (state:OrderInstructionTmpState, action: PayloadAction<CodeName[]>) => void,
  setUserList: (state:OrderInstructionTmpState, action: PayloadAction<CodeName[]>) => void,
  setRetrieveParam: (state:OrderInstructionTmpState, action: PayloadAction<orderInstructionRetrieve.RequestParam>) => void,
  setRetrievedParam: (state:OrderInstructionTmpState, action: PayloadAction<orderInstructionRetrieve.RequestParam>) => void,

  searched: (state:OrderInstructionTmpState, action: PayloadAction<{param: orderInstructionRetrieve.RequestParam, infos: InfoData[], infoMakerCenterDatas: InfoMakerCenterData[], colRowModel:OrderInstructionColRowModel, listSortOrder:CodeName, listSortOrderDesc:boolean, visibleRowsKey:string[], narrowAlert:'all'|'none'|'has'|string, hidden?: boolean, holidays:any}>) => void,
  refreshTable: (state:OrderInstructionTmpState, action: PayloadAction<{colRowModel:OrderInstructionColRowModel, listSortOrder:CodeName, listSortOrderDesc:boolean, visibleRowsKey:string[], narrowAlert:'all'|'none'|'has'|string}>) => void,
  editRowDatas: (state:OrderInstructionTmpState, action: PayloadAction<{colRowModel:OrderInstructionColRowModel, listSortOrder:CodeName, listSortOrderDesc:boolean, visibleRowsKey:string[], editDatas:EditCellInfo[], holidays:any}>) => void,
  selectTarget: (state: OrderInstructionTmpState, action: PayloadAction<{ colRowModel: OrderInstructionColRowModel, type: 'all' | 'none' | 'reverse' | 'choice', holidays:any }>) => void,
  setFunctionKey: (state:OrderInstructionTmpState, action: PayloadAction<string>) => void,
  setSelectedSubno: (state: OrderInstructionTmpState, action: PayloadAction<string[]>) => void,
}

const createReducerContent = ():OrderInstructionTmpReducer => {return {
    putProgress(state:OrderInstructionTmpState, action: PayloadAction<string>) {
      const key = action.payload;
      const progressNew = {...state.progress};
      progressNew[key] = true;
      state.progress = progressNew;
    },
    removeProgress(state:OrderInstructionTmpState, action: PayloadAction<string>) {
      const key = action.payload;
      const progressNew = {};
      Object.keys(state.progress).forEach(k => {
        if(key != k) {
          progressNew[k] = true;
        }
      })
      state.progress = progressNew;
    },
    setErrorMessage(state:OrderInstructionTmpState, action: PayloadAction<string>) {
      state.errorMessage = action.payload;
    },
    setInfoMessage(state:OrderInstructionTmpState, action: PayloadAction<string>) {
      state.infoMessage = action.payload;
    },
    setEditingStart(state:OrderInstructionTmpState) {
      console.log('store.setEditingStart');
      state.editing = true;
    },
    setEditingEnd(state:OrderInstructionTmpState) {
      console.log('store.setEditingEnd');

      state.editing= false;

      state.progress= {};
      state.retrieveParam= null;
      state.retrievedParam= null;
      
      // state.dataGroups= [];
      state.rowInfos= [];
      state.fixedRowsTop =0;
      state.infoDatas= [];
      state.infoMakerCenterDatas= [];
      state.rows= [];
      state.mergeCells= null;
      
      state.tableVer_updateSettting = state.tableVer_updateSettting+1;  //カウントアップしテーブルを再構築対象とする (updateSetting)

    },
    // setBumonList(state:OrderInstructionTmpState, action: PayloadAction<CodeName[]>) {
    //   state.bumonList = action.payload;
    // },
    setMakerList(state:OrderInstructionTmpState, action: PayloadAction<CodeName[]>) {
      state.makerList = action.payload;
    },
    setAreaList(state:OrderInstructionTmpState, action: PayloadAction<CodeName[]>) {
      state.areaList = action.payload;
    },
    setCenterList(state:OrderInstructionTmpState, action: PayloadAction<CodeName[]>) {
      state.centerList = action.payload;
    },
    setUserList(state:OrderInstructionTmpState, action: PayloadAction<CodeName[]>) {
      state.userList = action.payload;
    },
    setRetrieveParam(state:OrderInstructionTmpState, action: PayloadAction<orderInstructionRetrieve.RequestParam>) {
      state.retrieveParam = action.payload;
    },
    setRetrievedParam(state:OrderInstructionTmpState, action: PayloadAction<orderInstructionRetrieve.RequestParam>) {
      state.retrievedParam = action.payload;
    },

    searched(state:OrderInstructionTmpState, action: PayloadAction<{param: orderInstructionRetrieve.RequestParam, infos: InfoData[], infoMakerCenterDatas: InfoMakerCenterData[], colRowModel:OrderInstructionColRowModel, listSortOrder:CodeName, listSortOrderDesc:boolean, visibleRowsKey:string[], narrowAlert:'all'|'none'|'has'|string, hidden?: boolean, holidays:any}>) {
      const setOldData = (data: InfoData) => {
        data.oldTargetFlg = data.targetFlg ? data.targetFlg : false;
      }
      const colRowModel = action.payload.colRowModel;
      const param = action.payload.param;
      const hidden = action.payload.hidden;
      const holidays = action.payload.holidays;
      const narrowAlert = action.payload.narrowAlert;

      let infos = parseDataInfoData(action.payload.infos);
      let infoMakerCenterDatas = parseDataInfoMakerCenterData(action.payload.infoMakerCenterDatas);
      //編集前のデータをセット
      infos.forEach(data => setOldData(data));
      
      infos = checkDatas(infos, infoMakerCenterDatas, holidays); //データチェック

      const listSortOrder = action.payload.listSortOrder;
      const listSortOrderDesc = action.payload.listSortOrderDesc;
      const visibleRowsKey = action.payload.visibleRowsKey;

      infos = sortInfoDatas(infos, listSortOrder, listSortOrderDesc);
      const [rowInfos, fixedRowsTop] = convertRowInfos(listSortOrder, listSortOrderDesc, visibleRowsKey, infos, infoMakerCenterDatas, narrowAlert, hidden);
      //新規も編集前のデータをセットをセット
      infos.forEach(data => setOldData(data));

      //store更新
      state.rowInfos = rowInfos;
      state.fixedRowsTop = fixedRowsTop;
      state.infoDatas = infos;
      state.infoMakerCenterDatas = infoMakerCenterDatas;
      state.rows = convertRows(rowInfos, colRowModel, infoMakerCenterDatas);
      state.mergeCells = createMergeCells(rowInfos, colRowModel);

      state.tableVer_updateSettting = state.tableVer_updateSettting+1;  //カウントアップしテーブルを再構築対象とする (updateSetting)
    },
    refreshTable(state:OrderInstructionTmpState, action: PayloadAction<{colRowModel:OrderInstructionColRowModel, listSortOrder:CodeName, listSortOrderDesc:boolean, visibleRowsKey:string[], narrowAlert:'all'|'none'|'has'|string}>){
      console.log('refreshTable');
      const listSortOrder = action.payload.listSortOrder;
      const listSortOrderDesc = action.payload.listSortOrderDesc;
      const visibleRowsKey = action.payload.visibleRowsKey;
      const colRowModel = action.payload.colRowModel;
      let infos = state.infoDatas;
      const infoMakerCenterDatas = state.infoMakerCenterDatas;
      const narrowAlert = action.payload.narrowAlert;

      infos = sortInfoDatas(infos, listSortOrder, listSortOrderDesc);
      const [rowInfos, fixedRowsTop] = convertRowInfos(listSortOrder, listSortOrderDesc, visibleRowsKey, infos, infoMakerCenterDatas, narrowAlert);
      //store更新
      state.rowInfos = rowInfos;
      state.fixedRowsTop = fixedRowsTop;
      state.infoDatas = infos;
      state.rows = convertRows(rowInfos, colRowModel, infoMakerCenterDatas);
      state.mergeCells = createMergeCells(rowInfos, colRowModel);

      state.tableVer_updateSettting = state.tableVer_updateSettting+1;  //カウントアップしテーブルを再構築対象とする (updateSetting)
    },

    editRowDatas(state:OrderInstructionTmpState, action: PayloadAction<{colRowModel:OrderInstructionColRowModel, listSortOrder:CodeName, listSortOrderDesc:boolean, visibleRowsKey:string[], editDatas:EditCellInfo[], holidays:any}>) {
      console.log('store.editRowDatas');
      const listSortOrder = action.payload.listSortOrder;
      const listSortOrderDesc = action.payload.listSortOrderDesc;
      const visibleRowsKey = action.payload.visibleRowsKey;
      const colRowModel = action.payload.colRowModel;
      const editDatas = action.payload.editDatas;
      const holidays = action.payload.holidays;
      const infoMakerCenterDatas = state.infoMakerCenterDatas;

      // const newInfoDatas = [...state.infoDatas];
      const newInfoDatas = state.infoDatas;
      const newRows = state.rows;

      let editedRows:Set<number> = new Set<number>();
      let editedMakerCenterIndex:Set<number> = new Set<number>();
      editDatas.forEach((editData)=>{
        const rowInfo:RowInfo = state.rowInfos[editData.row];
        if(!rowInfo) {
          return;
        }
        const infoData = rowInfo.infoData;
        const infoDataIndex = rowInfo.infoDataIndex;
        if(!infoData) {
          return;
        }

        const colKey:string = colRowModel.keyFromCol(editData.col);
        const rowKey:string = rowInfo.rowKey;

        let dataType = rowDataType[rowKey];
        if(!dataType) {
          dataType = colDataType[colKey];
        }

        const value = editorUtil.parseValue(editData.value, dataType.type,
          dataType.type == 'numeric' ? dataType.numericFormat.pattern :
          dataType.type == 'date' ? dataType.dateFormat :
          null);

        newInfoDatas[infoDataIndex][colKey] = value;
        editedRows.add(editData.row);
        newInfoDatas[infoDataIndex] = checkData(newInfoDatas[infoDataIndex], infoMakerCenterDatas[rowInfo.infoMakerCenterDataIndex], holidays);
        state.rowInfos[editData.row].infoData = newInfoDatas[infoDataIndex];

        //発注累計の計算
        if(colKey == 'targetFlg') {
          editedMakerCenterIndex.add(state.rowInfos[editData.row]?.infoMakerCenterDataIndex);
        }

      });

      //累計を再計算
      if(editedMakerCenterIndex.size > 0) {
        let rowInfos = state.rowInfos.filter(rowInfo => rowInfo.rowIndex == 0);
        let infos = rowInfos.map(rowInfo => rowInfo.infoData);
        const [infoMakerCenterDatasTmp, editRowsTmp] = calcInfoMakerCenterDatas(rowInfos, state.infoMakerCenterDatas, Array.from(editedMakerCenterIndex));
        state.infoMakerCenterDatas = infoMakerCenterDatasTmp;
        editRowsTmp.forEach(editRow => {
          editedRows.add(editRow);
        });
        infos = checkDatas(infos, infoMakerCenterDatas, holidays); //データチェック
      }

      if(editedRows.size > 0) {
        const tableVer_updateData_rows = [];
        editedRows.forEach(row => {
          tableVer_updateData_rows.push(row);
          newRows[row] = convertRow(state.rowInfos[row], colRowModel, infoMakerCenterDatas);
        });
        // state.rows = newRows;

        /*
        const [rowInfos, fixedRowsTop, newinfos] = convertRowInfos(state.dataGroups, listSortOrder, listSortOrderDesc, visibleRowsKey, newInfoDatas);
        
        // //store更新
        state.rowInfos = rowInfos;
        state.fixedRowsTop = fixedRowsTop;
        state.infoDatas = newinfos;
        state.rows = convertRows(rowInfos, colRowModel);
        state.mergeCells = createMergeCells(rowInfos, colRowModel);
        */

        state.tableVer_updateData_rows = tableVer_updateData_rows;
        state.tableVer_updateData = state.tableVer_updateData+1;  //カウントアップしテーブルを再描写対象とする (updateData)
      }
    },
    selectTarget(state: OrderInstructionTmpState, action: PayloadAction<{ colRowModel: OrderInstructionColRowModel, type: 'all' | 'none' | 'reverse' | 'choice', holidays:any }>) {
      console.log('store.selectTarget ' + action.payload);
      const colRowModel = action.payload.colRowModel;
      const selectType = action.payload.type;
      const holidays = action.payload.holidays;
      let rowInfos:RowInfo[] = state.rowInfos;
      let infoDatas:InfoData[] = state.infoDatas;
      const rows = state.rows;
      const infoMakerCenterDatas = state.infoMakerCenterDatas;
      const selectedSubno = state.selectedSubno;

      let editedMakerCenterIndex:Set<number> = new Set<number>();

      rowInfos.filter(rowInfo => rowInfo.orderable).forEach(rowInfo => {
        let infoData:InfoData = infoDatas[rowInfo.infoDataIndex];
        switch (selectType) {
          case "all":
            infoData.targetFlg = true;
            break;
          case "none":
            infoData.targetFlg = false;
            break;
          case "reverse":
            infoData.targetFlg = !infoData.targetFlg;
            break;
          case "choice":
            infoData.targetFlg = infoData.targetFlg ? infoData.targetFlg : selectedSubno.some(subno => subno == rowInfo.subno);
            break;
          default:
            break;
        }
        infoData = checkData(infoData, infoMakerCenterDatas[rowInfo.infoMakerCenterDataIndex], holidays);
        state.rowInfos[rowInfo.row].infoData = infoData;
        editedMakerCenterIndex.add(state.rowInfos[rowInfo.row].infoMakerCenterDataIndex);
      });

      //累計を再計算
      if(editedMakerCenterIndex.size > 0) {
        let rowInfos = state.rowInfos.filter(rowInfo => rowInfo.rowIndex == 0);
        const [infoMakerCenterDatasTmp, editRowsTmp] = calcInfoMakerCenterDatas(rowInfos, state.infoMakerCenterDatas, Array.from(editedMakerCenterIndex));
        state.infoMakerCenterDatas = infoMakerCenterDatasTmp;
        let infos = rowInfos.map(rowInfo => rowInfo.infoData);
        infos = checkDatas(infos, infoMakerCenterDatas, holidays); //データチェック
      }

      const tableVer_updateData_rows = [];
      rowInfos.filter(rowInfo => rowInfo.orderable).forEach(rowInfo => {
        rows[rowInfo.row] = convertRow(state.rowInfos[rowInfo.row], colRowModel, infoMakerCenterDatas);
        tableVer_updateData_rows.push(rowInfo.row);
      });

      state.tableVer_updateData_rows = tableVer_updateData_rows;
      state.tableVer_updateData = state.tableVer_updateData+1;  //カウントアップしテーブルを再描写対象とする (updateData)
      // state.tableVer_updateSettting = state.tableVer_updateSettting+1;  //カウントアップしテーブルを再描写対象とする (updateData)
    },
    setFunctionKey(state:OrderInstructionTmpState, action: PayloadAction<string>) {
      state.functionKeys = action.payload ? [action.payload] : [];
    },
    setSelectedSubno(state: OrderInstructionTmpState, action: PayloadAction<string[]>) {
      state.selectedSubno = action.payload;
    },

}};

const parseDataInfoData = (datas:InfoData[]): InfoData[] => {
  datas = [...datas];
  datas.forEach((data) => {
    if(typeof data.blIrisu === 'string') data.blIrisu = parseInt(data.blIrisu);
    if(typeof data.csIrisu === 'string') data.csIrisu = parseInt(data.csIrisu);
    if(typeof data.lot === 'string') data.lot = parseInt(data.lot);
    if(typeof data.csIrisu_lot === 'string') data.csIrisu_lot = parseInt(data.csIrisu_lot);
    if(typeof data.teika === 'string') data.teika = parseInt(data.teika);
    if(typeof data.tatene === 'string') data.tatene = parseFloat(data.tatene);
    if(typeof data.loadedQty === 'string') data.loadedQty = parseFloat(data.loadedQty);
    if(typeof data.numCS === 'string') data.numCS = parseFloat(data.numCS);
    if(typeof data.numBL === 'string') data.numBL = parseFloat(data.numBL);
  });
  return datas;
}
const parseDataInfoMakerCenterData = (datas:InfoMakerCenterData[]): InfoMakerCenterData[] => {
  datas = [...datas];
  datas.forEach((data) => {
    if(typeof data.numCS === 'string') data.numCS = parseFloat(data.numCS);
    if(typeof data.numBL === 'string') data.numBL = parseFloat(data.numBL);
    if(typeof data.numCS_result === 'string') data.numCS_result = parseFloat(data.numCS_result);
    if(typeof data.numBL_result === 'string') data.numBL_result = parseFloat(data.numBL_result);
  });
  return datas;
}

//チェック
const checkDatas = (infos: InfoData[], makercenterDatas:InfoMakerCenterData[], holidays:any): InfoData[] => {
  console.log('store.checkDatas');
  const map = {};
  makercenterDatas.forEach(makercenterData => {
    const key = `${makercenterData.centerCD} ${makercenterData.makerCD} ${makercenterData.nouhinDate}`;
    map[key] = makercenterData;
  });

  return infos.map(info => {
    const key = `${info.centerCD} ${info.makerCD} ${info.nouhinDate}`;
    const makercenterDatas = map[key];
    return checkData(info, makercenterDatas, holidays);
  });
}
const calcInfoMakerCenterDatas = (rowInfos: RowInfo[], dataMakerCenters: InfoMakerCenterData[], dataMakerCenterIndexes:number[]): [InfoMakerCenterData[], number[]] => {
  if(!dataMakerCenterIndexes?.length) {
    return [dataMakerCenters, []];
  }
  const map = {};
  dataMakerCenterIndexes.forEach(index => {
    const makercenterData: InfoMakerCenterData = dataMakerCenters[index];
    makercenterData.numBL = null;
    makercenterData.numCS = null;

    const key = `${makercenterData.centerCD} ${makercenterData.makerCD} ${makercenterData.nouhinDate}`;
    map[key] = makercenterData;
  });
  const editRows = [];
  rowInfos.forEach(rowInfo => {
    const infoData = rowInfo.infoData;
    const key = `${infoData.centerCD} ${infoData.makerCD} ${infoData.nouhinDate}`;
    const makercenterData:InfoMakerCenterData = map[key];
    if(makercenterData) {
      if(infoData.targetFlg) {
        makercenterData.numBL = calcUtil.plus(makercenterData.numBL, infoData.numBL);
        makercenterData.numCS = calcUtil.plus(makercenterData.numCS, infoData.numCS);
      }
      editRows.push(rowInfo.row);
    }
  });
  return [dataMakerCenters, editRows];
}
const checkData = (data: InfoData, dataMakerCenter: InfoMakerCenterData, holidays:any): InfoData => {
  //編集状態をセットする
  // Object.keys(data).forEach(key => {
    data.edited = isEditedRowData(data);
  // });


  const errorInfo: ErrorInfoData = {};
  if(!data.errorInfo) {
    data.errorInfo = {};
  }
  const warnInfo: ErrorInfoData = {};
  if(!data.warnInfo) {
    data.warnInfo = {};
  }


  const numCSTotal = calcUtil.plus(dataMakerCenter?.numCS, dataMakerCenter?.numCS_result);
  if(dataMakerCenter && data.minQtyCs && numCSTotal && numCSTotal < data.minQtyCs) {
    errorInfo.numCSTotal = '最小発注数より少ない';
  }
  if(dataMakerCenter && data.maxQtyCs && numCSTotal && numCSTotal > data.maxQtyCs) {
    errorInfo.numCSTotal = '最大発注数より多い';
  }
  //納品日
  const newDate = new Date();
  const today = moment(newDate);
  const minNohinDate = momentUtil.plusDaysInBusinessLeadTime(
    today, data.leadTimeDays ? data.leadTimeDays : 1, true, true, true, true, true, !data.leadTimeExcludeSaturdayFlg, false, data.leadTimeContainHolidayFlg, holidays
  );
  const minNohinDateInt:number = parseInt(minNohinDate.format('YYYYMMDD'));

  if(minNohinDateInt > data.nouhinDate) {
    //納品日
    warnInfo.nouhinDate = '納品リードタイムを超過';
  }
  else if(minNohinDateInt == data.nouhinDate) {
    //締め時刻超過判定
    const close = moment(`${moment(newDate).format('YYYY-MM-DD')} ${data.closeTime}`);
    const now: Moment = moment(newDate);
    if(now.isAfter(close)) {
      //翌日の納品日判定
      const nextday = moment(newDate).add(1, 'day');
      const minNohinDateNext = momentUtil.plusDaysInBusinessLeadTime(
        nextday, data.leadTimeDays ? data.leadTimeDays : 1, true, true, true, true, true, !data.leadTimeExcludeSaturdayFlg, false, data.leadTimeContainHolidayFlg, holidays
      );
      const minNohinDateNextInt: number = parseInt(minNohinDateNext.format('YYYYMMDD'));
      //翌営業日の最短入庫日が、今日と異なる場合のみ
      if (minNohinDateInt != minNohinDateNextInt) {
        warnInfo.nouhinDate = '締め時刻を超過';
      }
    }
  }

  data.errorInfo = errorInfo;
  data.warnInfo = warnInfo;
  return data;
}

//並び順変更
const sortInfoDatas = (infoDatas:InfoData[], listSortOrder:CodeName, listSortOrderDesc:boolean): InfoData[] => {
  if(!infoDatas) {
    return infoDatas;
  }
  let asc = !listSortOrderDesc;
  let getSortKey1 = (o:InfoData):string|number => 
    !o ? null : 
    !listSortOrder ? o.itemCD :
    listSortOrder.code == 'itemCD' ? o.itemCD :
    listSortOrder.code == 'itemNM' ? o.itemNM :
    listSortOrder.code == 'janCD' ? o.janCD :
    listSortOrder.code == 'maker' ? o.makerCD :
    listSortOrder.code == 'category' ? o.categoryCD :
    listSortOrder.code == 'bestBefore' ? o.bestBefore :
    listSortOrder.code == 'teika' ? o.teika :
    listSortOrder.code == 'tatene' ? o.tatene :
    listSortOrder.code == 'center' ? o.centerSortOutput :
    listSortOrder.code == 'area' ? o.areaCD :
    listSortOrder.code == 'pref' ? o.prefCD :
    listSortOrder.code == 'ymd_center' ? o.nouhinDate :
    listSortOrder.code == 'ymd_item' ? o.nouhinDate :
    o.itemCD
  ;
  let getSortKey2 = (o:InfoData):string|number => 
    !o ? null : 
    !listSortOrder ? o.itemCD :
    listSortOrder.group_code == 'item' ? o.itemCD :
    listSortOrder.group_code == 'center' ? o.centerCD :
    listSortOrder.group_code == 'ymd' && listSortOrder.code == 'ymd_center' ? o.centerCD :
    listSortOrder.group_code == 'ymd' && listSortOrder.code == 'ymd_item' ? o.itemCD :
    o.itemCD
  ;
  let getSortKey3 = (o:InfoData):string|number => 
    !o ? null : 
    !listSortOrder ? o.centerCD :
    listSortOrder.group_code == 'item' ? o.centerSortOutput :
    listSortOrder.group_code == 'center' ? o.itemCD :
    listSortOrder.group_code == 'ymd' && listSortOrder.code == 'ymd_center' ? o.itemCD :
    listSortOrder.group_code == 'ymd' && listSortOrder.code == 'ymd_item' ? o.centerSortOutput :
    o.centerCD
  ;
  let getSortKey4 = (o:InfoData):string|number => 
    !o ? null : 
    !listSortOrder ? o.centerCD :
    listSortOrder.group_code == 'item' ? o.centerCD :
    listSortOrder.group_code == 'center' ? o.itemCD :
    listSortOrder.group_code == 'ymd' && listSortOrder.code == 'ymd_center' ? o.itemCD :
    listSortOrder.group_code == 'ymd' && listSortOrder.code == 'ymd_item' ? o.centerCD :
    o.centerCD
  ;
  infoDatas.sort((a, b) => {
    //第1弾ソート項目
    let va = getSortKey1(a);
    let vb = getSortKey1(b);
    let comp = compareUtil.compareAny(va, vb, asc);
    //第2弾ソート項目
    if(comp == 0) {
      va = getSortKey2(a);
      vb = getSortKey2(b);
      comp = compareUtil.compareAny(va, vb, asc);
    }
    //第3弾ソート項目
    if(comp == 0) {
      va = getSortKey3(a);
      vb = getSortKey3(b);
      comp = compareUtil.compareAny(va, vb, asc);
    }
    //第4弾ソート項目
    if(comp == 0) {
      va = getSortKey4(a);
      vb = getSortKey4(b);
      comp = compareUtil.compareAny(va, vb, asc);
    }
    return comp;
  });
  return infoDatas;
}
  //行情報に変換
const convertRowInfos = (listSortOrder:CodeName, listSortOrderDesc:boolean, visibleRowsKey:string[], infoDatas: InfoData[], infoMakerCenterDatas: InfoMakerCenterData[], narrowAlert:'all'|'none'|'has'|string, hidden?: boolean): [RowInfo[], number] => {

  // const infoMakerCenterDataMap = {};
  const infoMakerCenterDataIndexMap = {};
  if(infoMakerCenterDatas) {
    infoMakerCenterDatas.forEach((data, index) => {
      const key = `${data.centerCD} ${data.makerCD} ${data.nouhinDate}`;
      // infoMakerCenterDataMap[key] = data;
      infoMakerCenterDataIndexMap[key] = index;
    });
  }

  const targetRowsKeys = arrayutil.and(rowKeys, visibleRowsKey);

  let rowInfos:RowInfo[] = [];
  let fixedRowsTop:number = 0;

  //明細行
  fixedRowsTop = rowInfos.length;

  const rowKeyInfos:{
    rowKey: string,
    rowIndex:number,
    rowIndexLast:boolean,
  }[] = [];
  targetRowsKeys.forEach((visibleRowKey, index) => {
    rowKeyInfos.push({
      rowKey: visibleRowKey,
      rowIndex:index,
      rowIndexLast: targetRowsKeys.length - 1 == index,
    });
  });

  const grouping = (!listSortOrder || listSortOrder.group_code == 'item') ? 'item' : 'center';
  let no = 0;
  let subno = 0;
  let row = 0;

  infoDatas.forEach((infoData, index) => {
    const errorCount = Object.keys(infoData.errorInfo).length;
    const warnCount = Object.keys(infoData.warnInfo).length;
    if(!narrowAlert 
      || narrowAlert == "all"
      || (narrowAlert == "none" && (!errorCount && !warnCount))
      || (narrowAlert == "has" && !(!errorCount && !warnCount))
    ) {
  
      const key = `${infoData.centerCD} ${infoData.makerCD} ${infoData.nouhinDate}`;
      // const makerCenterData:InfoMakerCenterData = infoMakerCenterDataMap[key];
      const makerCenterDataIndex:number = infoMakerCenterDataIndexMap[key];
      //明細行
      subno++;
      rowKeyInfos.forEach((rowKeyInfo) => {

        rowInfos.push({
          ...{
            TP: "info",
            no: `${no}`,
            subno: `${subno}`,
            infoData: infoData,
            infoDataIndex: index,
            // infoMakerCenterData:makerCenterData,
            infoMakerCenterDataIndex:makerCenterDataIndex,
            row: row++,

            // orderable: !infoData.errorInfo.numCSTotal
            orderable: true
          }, 
          ...rowKeyInfo
        });
      })
    }

  });
  return [rowInfos, fixedRowsTop];
}
//配列データに変換
const convertRows = (rowInfos:RowInfo[], colRowModel:OrderInstructionColRowModel, infoMakerCenterDatas:InfoMakerCenterData[]): any[][] => {
  return rowInfos.map((rowInfo) => convertRow(rowInfo, colRowModel, infoMakerCenterDatas));
}
//配列データに変換
const convertRow = (rowInfo:RowInfo, colRowModel:OrderInstructionColRowModel, infoMakerCenterDatas:InfoMakerCenterData[]): any[] => {
  //set No.
  return colRowModel.colKeys.map(colKey => valueFromKey(colKey, rowInfo, infoMakerCenterDatas));
}
export const valueFromKey = (colKey: string, rowInfo: RowInfo, infoMakerCenterDatas:InfoMakerCenterData[]): any => {
  //明細行
  switch (colKey) {
    case "no":
      return `${rowInfo.subno}`;
    case "targetFlg":
      return rowInfo.infoData.targetFlg;
    case "rowHeader":
      return headersRow[rowInfo.rowKey];
    case "center": //"倉庫"
      return `${rowInfo.infoData?.centerCD2} ${rowInfo.infoData?.centerNM}`;
    case "area": //"地域"
      return `${rowInfo.infoData?.areaNM}`;
    case "pref": //"都道府県"
      return `${rowInfo.infoData?.prefNM}`;
    case "itemCD": //"商品CD"
      return `${rowInfo.infoData?.itemCD}`;
    case "itemNM": //"商品名"
      return `${rowInfo.infoData?.itemNM}`;
    case "janCD": //"JAN"
      return `${rowInfo.infoData?.janCD}`;
    case "makerCD": //"メーカー"
      return `${rowInfo.infoData?.makerCD}`;
    case "makerNM": //"メーカー"
      return `${rowInfo.infoData?.makerNM}`;
    case "shiireFAX": //"メーカー"
      return `${rowInfo.infoData?.shiireFAX}`;
    case "categoryCD": //"商品カテゴリ"
      return `${rowInfo.infoData?.categoryCD}`;
    case "categoryNM": //"商品カテゴリ"
      return `${rowInfo.infoData?.categoryNM}`;
    case "blIrisu": //"BL入数"
      return `${formatterN0.format(rowInfo.infoData?.blIrisu)}`;
    case "csIrisu": //"CS入数"
      return `${formatterN0.format(rowInfo.infoData?.csIrisu)}`;
    case "csIrisu_lot": //"CS入数×発注単位"
      return `${formatterN0.format(rowInfo.infoData.csIrisu_lot)}`;
    case "lot": //"発注単位"
      return `${formatterN0.format(rowInfo.infoData?.lot)}`;
    case "capacity": //"内容量"
      return `${rowInfo.infoData?.capacity}`;
    case "bestBefore": //"賞味期間"
      return `${rowInfo.infoData?.bestBefore}日`;
    case "teika": //"定価"
      return rowInfo.infoData?.teika;
    case "tatene": //"建値"
      return rowInfo.infoData?.tatene;
    case "loadedQty": //"積付面"
      return rowInfo.infoData?.loadedQty;

    case "status": //"ステータス"
      return ((status) => {
        switch (status) {
          case '0': return '未連携';
          case '1': return '連携中';
          case '2': return '連携済';
          case '9': return '連携エラー';
          default: return '';
        }
      })(rowInfo.infoData?.status)
    case "nouhinDate": //"建値"
      return rowInfo.infoData?.nouhinDate ? moment(rowInfo.infoData?.nouhinDate).format('YY/MM/DD') : null;
    case "numCS": //"建値"
      return rowInfo.infoData?.numCS;
    case "numBL": //"建値"
      return rowInfo.infoData?.numBL;
    case "numCSTotal": //"建値"
      return calcUtil.plus(infoMakerCenterDatas?.[rowInfo.infoMakerCenterDataIndex]?.numCS_result, infoMakerCenterDatas?.[rowInfo.infoMakerCenterDataIndex]?.numCS);
    case 'minQtyCs':
      return rowInfo.infoData?.minQtyCs;
    case 'maxQtyCs':
      return rowInfo.infoData?.maxQtyCs;
    case 'closeTime':
      return rowInfo.infoData?.closeTime;
    case 'leadTimeDays':
      return rowInfo.infoData?.leadTimeDays;
    case 'leadTimeExcludeSaturdayFlg':
      return rowInfo.infoData?.leadTimeExcludeSaturdayFlg ? '✓' : '';
    case 'leadTimeContainHolidayFlg':
      return rowInfo.infoData?.leadTimeContainHolidayFlg ? '✓' : '';
          
    case "editDate": //"建値"
      return rowInfo.infoData?.editDate ? moment(rowInfo.infoData?.editDate).format('YY/MM/DD HH:mm') : null;
    case "editUser": //"建値"
      return rowInfo.infoData?.editUser;
    case "ifAdmsSendDate":
      return rowInfo.infoData?.ifAdmsSendDate ? moment(rowInfo.infoData?.ifAdmsSendDate).format('YY/MM/DD HH:mm') : null;
    case "ifAdmsErrorMessage":
      return rowInfo.infoData?.ifAdmsErrorMessage;

    default: {
      return rowInfo.infoData?.[colKey];
    }
  }
}

//マージを作成
const createMergeCells = (rowInfos:RowInfo[], colRowModel:OrderInstructionColRowModel): {row: number, col: number, rowspan: number, colspan: number}[] => {
  return null;
}


export const getOptionLabel = (option: CodeName) => {
  return option && option.name ? (option.code + ' ' + option.name) : "";
}

const createSliceContent = (name:string) => createSlice({
  name: name,
  initialState,
  reducers: createReducerContent(),
});

//Page Slice Export
//orderInstructionTmp
export const orderInstructionTmpSlice = createSliceContent("orderInstructionTmp");
export const orderInstructionDialogTmpSlice = createSliceContent("orderInstructionDialogTmp");
