import { itemTags } from "@/assets/commontype/ItemTag";
import moment from "moment";
moment.updateLocale("ja", {
  months: ["1月","2月","3月","4月","5月","6月","7月","8月","9月","10月","11月","12月",],
  weekdays: ["日曜日", "月曜日", "火曜日", "水曜日", "木曜日", "金曜日", "土曜日"],
  weekdaysShort: ["日", "月", "火", "水", "木", "金", "土"]
});

//ヘッダー列
export const headerColKeys = [
  'no',
  'target',
  'attribute1Label', 
  'attribute1Value', 
  'attribute2Label', 
  'attribute2Value', 
];
//行ヘッダー列
export const rowHeaderColKeys = [
  'rowHeader', 
  'validFlg', 
];
//ヘッダー列
export const bodyColKeysTag = [
	'termFlg',	//期間指定フラグ
	'termStartDate',	//期間開始日
	'termEndDate',	//期間終了日

  'retrieveDateFlg',	//期間指定フラグ
	'retrieveDate',	//期間終了日

	'similarItemCD', //類似商品CD
	'comment', //コメント
];
export const bodyColKeysCommon = [
	'loadedQty',
];
//ヘッダー列（発注不可）
export const colKeysUnOrderableHidden = [
];

export const rowKeys = itemTags.filter(itemTag => itemTag.code !== 'none').map(itemTag => itemTag.code);

export const headersCol = [
  {
    target : "一括",
    no : "No.",
    attribute1Label: "商品/倉庫-属性1", attribute1Value: "商品/倉庫-属性1", 
    attribute2Label: "商品/倉庫-属性2", attribute2Value: "商品/倉庫-属性2", 

    rowHeader: "項目", 
    validFlg: "項目",

    termFlg: "表示期間指定",
    termStartDate: "表示期間指定",
    termEndDate: "表示期間指定",

    retrieveDateFlg: "検索対象日指定",
    retrieveDate: "検索対象日指定",
  
    similarItemCD: "類似",
    comment: "コメント",

    loadedQty: "積付面",
  },
  {
    target : "",
    no : "",
    attribute1Label: "<br/>項目名", attribute1Value: "<br/>値", 
    attribute2Label: "<br/>項目名", attribute2Value: "<br/>値", 

    rowHeader : "<br/>名称", 
    validFlg : "<br/>有効",
    
    termFlg: "あり",
    termStartDate: "開始日",
    termEndDate: "終了日",

    retrieveDateFlg: "あり",
    retrieveDate: "検索対象日",

    similarItemCD: "商品CD",
    comment: "",

    loadedQty: "(BL数)",
  },
];

export const headersRow = {};
itemTags.filter(itemTag => itemTag.code !== 'none').forEach(itemTag => headersRow[itemTag.code] = itemTag.name);

export const selectableCol =
{
  target : false,
  no : false,
  attribute1Label: true, 
  attribute1Value: false, 
  attribute2Label: true, 
  attribute2Value: true, 
  rowHeader : false, 
  validFlg : false, 
  termFlg: false,
  termStartDate: false,
  termEndDate: false,
  retrieveDateFlg: false,
  retrieveDate: false,
  similarItemCD: false,
  comment: false,

  loadedQty: false,

}
export const selectableRow = {};
itemTags.filter(itemTag => itemTag.code !== 'none').forEach(itemTag => selectableRow[itemTag.code] = true);

export const headerColClasses = [
  {
    target : "header_noBorder_bulk",
    no : "header_noBorder_no",
    attribute1Label: "header", 
    attribute1Value: "header", 
    attribute2Label: "header", 
    attribute2Value: "header", 

    rowHeader : "header",
    validFlg : "header",

    termFlg: "header",
    termStartDate: "header",
    termEndDate: "header",
    retrieveDateFlg: "header",
    retrieveDate: "header",
    similarItemCD: "header_noBorder",  //下線なし
    comment: "header_noBorder",  //下線なし

    loadedQty: "header_noBorder",
  },
  {
    target : "header_bulk",
    no : "header_no",
    attribute1Label: "header", 
    attribute1Value: "header", 
    attribute2Label: "header", 
    attribute2Value: "header", 

    rowHeader : "header", 
    validFlg : "header", 

    termFlg: "header",
    termStartDate: "header",
    termEndDate: "header",
    retrieveDateFlg: "header",
    retrieveDate: "header",
        
    similarItemCD: "header",
    comment: "header",  //下線なし

    loadedQty: "header",
  },
];
export const headerRowClasses = {};
itemTags.filter(itemTag => itemTag.code !== 'none').forEach(itemTag => headerRowClasses[itemTag.code] = 'rowHeader');

export const colWidths =
{
  target : 50,
  no : 50,
  attribute1Label: 60, 
  attribute1Value: 150, 
  attribute2Label: 60, 
  attribute2Value: 100, 

  rowHeader : 105, 
  validFlg : 40, 

  termFlg: 39,
  termStartDate: 100,
  termEndDate: 100,
  retrieveDateFlg: 39,
  retrieveDate: 100,
    
  similarItemCD: 100,
  comment: 400,

  loadedQty: 70,
};

export const colDataType =
{
  target: {type: 'checkbox', checkedTemplate: true, uncheckedTemplate: false, readOnly: false, className: 'htCenter'},
  no : {type: 'text', readOnly: true,},
  attribute1Label: {type: 'text', readOnly: true, className: 'htRight',}, 
  attribute1Value: {type: 'text', readOnly: true,}, 
  attribute2Label: {type: 'text', readOnly: true, className: 'htRight',}, 
  attribute2Value: {type: 'text', readOnly: true,}, 

  rowHeader : {type: 'text', readOnly: true,}, 
  validFlg: {type: 'checkbox', checkedTemplate: true, uncheckedTemplate: false, readOnly: false, className: 'htCenter'},

  termFlg: {type: 'checkbox', checkedTemplate: true, uncheckedTemplate: false, readOnly: false, className: 'htCenter'},
  termStartDate: { type: 'date', dateFormat: 'YY/MM/DD', datePickerConfig: { yearSuffix: '年', showMonthAfterYear: true, showDaysInNextAndPreviousMonths: true, format: 'YY/MM/DD', formatStrict: true, i18n: { previousMonth: '前月', nextMonth: '次月', months: moment.months(), weekdays: moment.weekdays(), weekdaysShort: moment.weekdaysShort()} }, readOnly: false, className: 'htCenter' },
  termEndDate: { type: 'date', dateFormat: 'YY/MM/DD', datePickerConfig: { yearSuffix: '年', showMonthAfterYear: true, showDaysInNextAndPreviousMonths: true, format: 'YY/MM/DD', formatStrict: true, i18n: { previousMonth: '前月', nextMonth: '次月', months: moment.months(), weekdays: moment.weekdays(), weekdaysShort: moment.weekdaysShort()} }, readOnly: false, className: 'htCenter' },
  retrieveDateFlg: {type: 'checkbox', checkedTemplate: true, uncheckedTemplate: false, readOnly: false, className: 'htCenter'},
  retrieveDate: { type: 'date', dateFormat: 'YY/MM/DD', datePickerConfig: { yearSuffix: '年', showMonthAfterYear: true, showDaysInNextAndPreviousMonths: true, format: 'YY/MM/DD', formatStrict: true, i18n: { previousMonth: '前月', nextMonth: '次月', months: moment.months(), weekdays: moment.weekdays(), weekdaysShort: moment.weekdaysShort()} }, readOnly: false, className: 'htCenter' },

  similarItemCD: {type: 'text', readOnly: false,}, 
  comment: {type: 'text', readOnly: false,}, 

  loadedQty: { type: 'numeric', numericFormat: { pattern: '0,0', }, readOnly: false, }, 
};

export const rowDataType = {};


//列定義情報（動的パラメータ）
interface SettingItemColRowModelInterface {
  //列固定
  fixedColumnsLeft: number,

  //テーブルの各列のキー
  colKeys: string[],
  //発注不可時非表示列
  colKeysUnOrderableHidden: string[];
  //テーブルの各行のキー
  rowKeys: string[],
}

export class SettingItemColRowModel implements SettingItemColRowModelInterface {
  //列固定
  fixedColumnsLeft: number;
  //テーブルの各列のキー
  colKeys: string[];
  //発注不可時非表示列
  colKeysUnOrderableHidden: string[];
  //テーブルの各列のキー
  rowKeys: string[];
  //ヘッダー
  nestedHeaders; //any[][]
  nestedHeadersBackup; //any[][]
  //ヘッダー区切り
  nestedHeadersGroupIndex: number[];
  //テーブルの各列の逆引き用マップ
  colKeysMap;
  //テーブルの各行の逆引き用マップ
  rowKeysMap;

  constructor(param: SettingItemColRowModelInterface) {
    this.fixedColumnsLeft = param.fixedColumnsLeft;
    this.colKeys = param.colKeys;
    this.rowKeys = param.rowKeys;
    this.colKeysUnOrderableHidden = param.colKeysUnOrderableHidden;

    //テーブルの各列のキー逆引き
    const colKeysMap = {};
    param.colKeys.forEach((colKey, index) => {
      colKeysMap[colKey] = index;
    });
    this.colKeysMap = colKeysMap;
    //テーブルの各行のキー逆引き
    const rowKeysMap = {};
    param.rowKeys.forEach((rowKey, index) => {
      rowKeysMap[rowKey] = index;
    });
    this.rowKeysMap = rowKeysMap;

    //nestedHeaders初期化
    this.initNestedHeaders();
  }

  //nestedHeaders初期化
  initNestedHeaders() {
    //nestedHeaders
    const nestedHeaders = [];
    const nestedHeadersGroupIndex = [];
    // const customBorders = [];
    headersCol.forEach((header, index) => {
      const lastHeader = index == headersCol.length - 1;
      const nestedHeader = [];

      let colspan=0;
      let preHeaderVal = null;
      this.colKeys.forEach((colKey) => {
        const headerVal = header[colKey];
        if(lastHeader) {
          nestedHeader.push(headerVal);
        }
        else {
          if(preHeaderVal !== null && preHeaderVal != headerVal){
            nestedHeader.push(colspan == 1 ? preHeaderVal : {label: preHeaderVal, colspan: colspan});
            colspan=0;
          }
          colspan++;
          preHeaderVal = headerVal;
        }
        //0行目でグループ
        if(index == 0) {
          nestedHeadersGroupIndex.push(colspan-1);
        }
      });
      if(!lastHeader && colspan > 0) {
        nestedHeader.push(colspan == 1 ? preHeaderVal : {label: preHeaderVal, colspan: colspan});
      }

      nestedHeaders.push(nestedHeader);
    });

    this.nestedHeaders = nestedHeaders;
    this.nestedHeadersBackup = nestedHeaders.map(nestedHeadersSub => [...nestedHeadersSub]);
    this.nestedHeadersGroupIndex = nestedHeadersGroupIndex;
  }

  //function
  keyFromCol(column:number):string {
    return this.colKeys[column];
  }
  keyFromCols(columns:number[]):string[] {
    const vals:string[] = [];
    columns.forEach(column => vals.push(this.colKeys[column]))
    return vals;
  }
  colFromKey(colKey:string):number {
    return this.colKeysMap[colKey];
  }
  colFromKeys(colKeys:string[]):number[] {
    const vals:number[] = [];
    colKeys.forEach(colKey => vals.push(this.colKeysMap[colKey]))
    return vals;
  }
  //function
  keyFromRow(row:number):string {
    const rowKeys = this.rowKeys;
    return rowKeys[row];
  }
  keyFromRows(rows:number[]):string[] {
    const rowKeys = this.rowKeys;
    const vals:string[] = [];
    rows.forEach(row => vals.push(rowKeys[row]))
    return vals;
  }
  rowFromKey(rowKey:string):number {
    const rowKeysMap = this.rowKeysMap;
    return rowKeysMap[rowKey];
  }
  rowFromKeys(rowKeys:string[]):number[] {
    const rowKeysMap = this.rowKeysMap;
    const vals:number[] = [];
    rowKeys.forEach(rowKey => vals.push(rowKeysMap[rowKey]))
    return vals;
  }
}

//SettingItem用の列モデル
export const settingItemColRowModelDefault:SettingItemColRowModel = new SettingItemColRowModel({
  //列固定
  fixedColumnsLeft: headerColKeys.length + rowHeaderColKeys.length,

  //テーブルの各列のキー
  colKeys: [
    ...headerColKeys,
    ...rowHeaderColKeys,
    ...bodyColKeysTag,
    ...bodyColKeysCommon,
  ],
  colKeysUnOrderableHidden: colKeysUnOrderableHidden, 
  rowKeys: itemTags.filter(itemTag => itemTag.code !== 'none').map(itemTag => itemTag.code),
});

//ダイアログSettingItem用の列モデル
export const settingItemColRowModelDialog:SettingItemColRowModel = new SettingItemColRowModel({
  //列固定
  fixedColumnsLeft: rowHeaderColKeys.length,

  //テーブルの各列のキー
  colKeys: [
    ...rowHeaderColKeys,
    ...bodyColKeysTag,
    ...bodyColKeysCommon,
  ],
  colKeysUnOrderableHidden: colKeysUnOrderableHidden, 
  rowKeys: itemTags.filter(itemTag => itemTag.code !== 'none').map(itemTag => itemTag.code),
});
