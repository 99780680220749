import { createSlice, isFulfilled, PayloadAction } from "@reduxjs/toolkit";
import { CodeName } from "@/assets/commontype/CodeName";

import { InfoData, ErrorInfoData, getSaijiKbnName, getSaijiKbnCD, InfoItemData, InfoCenterData } from '@/store/settingtransfercenter/settingTransferCenterCommon'
import * as settingTransferCenterRetrieve from "@/assets/apitype/settingTransferCenterRetrieve";
import * as settingTransferCenterInfoItem from "@/assets/apitype/settingTransferCenterInfoItem";
import * as settingTransferCenterInfoCenter from "@/assets/apitype/settingTransferCenterInfoCenter";
import { SettingTransferCenterColRowModel, rowKeys, headersRow, colDataType, rowDataType } from "@/components/settingtransfercenter/SettingTransferCenterTableModel";
import * as compareUtil from "@/util/compareUtil";
import * as arrayutil from "@/util/arrayUtil";
import * as editorUtil from "@/util/editorUtil";
import * as calcUtil from "@/util/calcUtil";
import moment, { Moment } from 'moment';

//0.00フォーマット
const formatterP00 = new Intl.NumberFormat('ja-JP', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 });
//0,0フォーマット
const formatterN0 = new Intl.NumberFormat('ja-JP', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0 });

//編集中判定
export const isEditedRowData = (data: InfoData): boolean => {
  if(!data) {
    return false;
  }

  // console.log([data.itemCD, data.oldItemCD, compareUtil.compareString(data.itemCD, data.oldItemCD, true)]);
  // console.log([data.centerCD, data.oldCenterCD, compareUtil.compareString(data.centerCD, data.oldCenterCD, true)]);
  // console.log([data.aiteCenterCD, data.oldAiteCenterCD, compareUtil.compareString(data.aiteCenterCD, data.oldAiteCenterCD, true)]);
  // console.log([data.saijiKbn, data.oldSaijiKbn, compareUtil.compareString(data.saijiKbn, data.oldSaijiKbn, true)]);
  // console.log([data.toDate, data.oldToDate, compareUtil.compareString(data.toDate, data.oldToDate, true)]);

  return (
    compareUtil.compareString(data.itemCD, data.oldItemCD, true) != 0 ||
    compareUtil.compareString(data.centerCD, data.oldCenterCD, true) != 0 ||
    compareUtil.compareString(data.aiteCenterCD, data.oldAiteCenterCD, true) != 0 ||
    compareUtil.compareString(data.saijiKbn, data.oldSaijiKbn, true) != 0 ||
    compareUtil.compareString(data.fromDate, data.oldFromDate, true) != 0 ||
    compareUtil.compareString(data.toDate, data.oldToDate, true) != 0
  );
}

//空白行判定
export const isEmptyRowData = (info: InfoData, checkWithOld:boolean): boolean => {
  if(!info) {
    return false;
  }

  return !(
    info.itemCD || info.centerCD || info.aiteCenterCD || info.fromDate || info.toDate || info.saijiKbn ||
    checkWithOld && (info.oldItemCD || info.oldCenterCD || info.oldAiteCenterCD || info.oldFromDate || info.oldToDate || info.oldSaijiKbn)
  );
}

export type RowInfo = {
  no?: string,
  subno?: string,
  rowKey?: string,
  rowIndex?:number,  //同一集計行内でのrowKeyのindex
  rowIndexLast?:boolean, //同一集計行内での最後のrowKeyフラグ
  row:number,

  infoData? :InfoData,
  infoDataIndex?:number,

  orderable:boolean,
}
export interface EditCellInfo {
  row:number, 
  col:number, 
  rowKey:string, 
  value:string|number|object|null, 
  relatedValues?: {key:string, value:string|number|object|null}[],
}

//Page State
export type SettingTransferCenterTmpState = {
  // bumonList: CodeName[],  //部門
  makerList: CodeName[],  //メーカー
  areaList: CodeName[], //地域
  centerList: CodeName[], //倉庫

  progress: Record<string, unknown>,
  retrieveParam: settingTransferCenterRetrieve.RequestParam,  //検索条件(検索済み)
  retrievedParam: settingTransferCenterRetrieve.RequestParam,  //検索条件(検索済み)

  rowInfos: RowInfo[],
  fixedRowsTop :number,
  infoDatas: InfoData[],
  infoItemDatasMap: {},
  infoCenterDatasMap: {},

  tableVer_updateData: number,
  tableVer_updateData_rows: number[],
  tableVer_updateSettting: number,
  rows: any[][],
  mergeCells: {row: number, col: number, rowspan: number, colspan: number}[]

  errorMessage: string | null,
  infoMessage: string | null,

  retrieveItemInfo_ver: number,
  retrieveCenterInfo_ver: number,

  editing: boolean,
  functionKeys: string[],
  // openDialog: boolean,
};

export const initialState: SettingTransferCenterTmpState = {
  // bumonList: [],
  makerList: [],
  areaList: [],
  centerList: [],

  progress: {},
  retrieveParam: null,
  retrievedParam: null,

  rowInfos: [],
  fixedRowsTop :0,
  infoDatas: [],
  infoItemDatasMap: {},
  infoCenterDatasMap: {},

  tableVer_updateData: 0,
  tableVer_updateData_rows: [],
  tableVer_updateSettting: 0,
  rows: [],
  mergeCells: null,

  errorMessage: null,
  infoMessage: null,

  retrieveItemInfo_ver: 0,
  retrieveCenterInfo_ver: 0,

  editing: false,
  functionKeys: [],
  // openDialog: boolean,
};

//Page Slice
export type SettingTransferCenterTmpReducer = {
  putProgress: (state:SettingTransferCenterTmpState, action: PayloadAction<string>) => void,
  removeProgress: (state:SettingTransferCenterTmpState, action: PayloadAction<string>) => void,
  setErrorMessage: (state:SettingTransferCenterTmpState, action: PayloadAction<string>) => void,
  setInfoMessage: (state:SettingTransferCenterTmpState, action: PayloadAction<string>) => void,
  setEditingStart: (state:SettingTransferCenterTmpState, action: PayloadAction<string>) => void,
  setEditingEnd: (state:SettingTransferCenterTmpState, action: PayloadAction<string>) => void,
  // setBumonList: (state:SettingTransferCenterTmpState, action: PayloadAction<CodeName[]>) => void,
  setMakerList: (state:SettingTransferCenterTmpState, action: PayloadAction<CodeName[]>) => void,
  setAreaList: (state:SettingTransferCenterTmpState, action: PayloadAction<CodeName[]>) => void,
  setCenterList: (state:SettingTransferCenterTmpState, action: PayloadAction<CodeName[]>) => void,
  setRetrieveParam: (state:SettingTransferCenterTmpState, action: PayloadAction<settingTransferCenterRetrieve.RequestParam>) => void,
  setRetrievedParam: (state:SettingTransferCenterTmpState, action: PayloadAction<settingTransferCenterRetrieve.RequestParam>) => void,

  searched: (state:SettingTransferCenterTmpState, action: PayloadAction<{param: settingTransferCenterRetrieve.RequestParam, infos: InfoData[], colRowModel:SettingTransferCenterColRowModel, listSortOrder:CodeName, listSortOrderDesc:boolean, visibleRowsKey:string[], hidden?: boolean}>) => void,
  searchedItemInfo: (state:SettingTransferCenterTmpState, action: PayloadAction<{param: settingTransferCenterInfoItem.RequestParam, infoItems: InfoItemData[], colRowModel:SettingTransferCenterColRowModel, listSortOrder:CodeName, listSortOrderDesc:boolean, visibleRowsKey:string[], hidden?: boolean}>) => void,
  searchedCenterInfo: (state:SettingTransferCenterTmpState, action: PayloadAction<{param: settingTransferCenterInfoCenter.RequestParam, infoCenters: InfoCenterData[], colRowModel:SettingTransferCenterColRowModel, listSortOrder:CodeName, listSortOrderDesc:boolean, visibleRowsKey:string[], hidden?: boolean}>) => void,
  addRow: (state:SettingTransferCenterTmpState, action: PayloadAction<{addRowCount:number, colRowModel:SettingTransferCenterColRowModel, listSortOrder:CodeName, listSortOrderDesc:boolean, visibleRowsKey:string[]}>) => void,
  removeEmptyRow: (state:SettingTransferCenterTmpState, action: PayloadAction<{colRowModel:SettingTransferCenterColRowModel, listSortOrder:CodeName, listSortOrderDesc:boolean, visibleRowsKey:string[]}>) => void,
  refreshTable: (state:SettingTransferCenterTmpState, action: PayloadAction<{colRowModel:SettingTransferCenterColRowModel, listSortOrder:CodeName, listSortOrderDesc:boolean, visibleRowsKey:string[]}>) => void,
  editRowDatas: (state:SettingTransferCenterTmpState, action: PayloadAction<{colRowModel:SettingTransferCenterColRowModel, listSortOrder:CodeName, listSortOrderDesc:boolean, visibleRowsKey:string[], editDatas:EditCellInfo[]}>) => void,
  setFunctionKey: (state:SettingTransferCenterTmpState, action: PayloadAction<string>) => void,
}

const createReducerContent = ():SettingTransferCenterTmpReducer => {return {
    putProgress(state:SettingTransferCenterTmpState, action: PayloadAction<string>) {
      const key = action.payload;
      const progressNew = {...state.progress};
      progressNew[key] = true;
      state.progress = progressNew;
    },
    removeProgress(state:SettingTransferCenterTmpState, action: PayloadAction<string>) {
      const key = action.payload;
      const progressNew = {};
      Object.keys(state.progress).forEach(k => {
        if(key != k) {
          progressNew[k] = true;
        }
      })
      state.progress = progressNew;
    },
    setErrorMessage(state:SettingTransferCenterTmpState, action: PayloadAction<string>) {
      state.errorMessage = action.payload;
    },
    setInfoMessage(state:SettingTransferCenterTmpState, action: PayloadAction<string>) {
      state.infoMessage = action.payload;
    },
    setEditingStart(state:SettingTransferCenterTmpState) {
      console.log('store.setEditingStart');
      state.editing = true;
    },
    setEditingEnd(state:SettingTransferCenterTmpState) {
      console.log('store.setEditingEnd');

      state.editing= false;

      state.progress= {};
      state.retrieveParam= null;
      state.retrievedParam= null;
      
      // state.dataGroups= [];
      state.rowInfos= [];
      state.fixedRowsTop =0;
      state.infoDatas= [];
      state.infoItemDatasMap = {};
      state.infoCenterDatasMap = {};
      state.rows= [];
      state.mergeCells= null;
      
      state.tableVer_updateSettting = state.tableVer_updateSettting+1;  //カウントアップしテーブルを再構築対象とする (updateSetting)

    },
    // setBumonList(state:SettingTransferCenterTmpState, action: PayloadAction<CodeName[]>) {
    //   state.bumonList = action.payload;
    // },
    setMakerList(state:SettingTransferCenterTmpState, action: PayloadAction<CodeName[]>) {
      state.makerList = action.payload;
    },
    setAreaList(state:SettingTransferCenterTmpState, action: PayloadAction<CodeName[]>) {
      state.areaList = action.payload;
    },
    setCenterList(state:SettingTransferCenterTmpState, action: PayloadAction<CodeName[]>) {
      state.centerList = action.payload;
    },
    setRetrieveParam(state:SettingTransferCenterTmpState, action: PayloadAction<settingTransferCenterRetrieve.RequestParam>) {
      state.retrieveParam = action.payload;
    },
    setRetrievedParam(state:SettingTransferCenterTmpState, action: PayloadAction<settingTransferCenterRetrieve.RequestParam>) {
      state.retrievedParam = action.payload;
    },

    searched(state:SettingTransferCenterTmpState, action: PayloadAction<{param: settingTransferCenterRetrieve.RequestParam, infos: InfoData[], colRowModel:SettingTransferCenterColRowModel, listSortOrder:CodeName, listSortOrderDesc:boolean, visibleRowsKey:string[], hidden?: boolean}>) {
      const colRowModel = action.payload.colRowModel;
      const param = action.payload.param;
      const hidden = action.payload.hidden;

      let infos = parseDataInfoData(action.payload.infos);
      //編集前のデータをセット
      infos.forEach(data => setOldData(data));
      
      //翌日納品日
      const tommorowInt:number = parseInt(moment(new Date()).add(1, 'days').format('YYYYMMDD'));
      infos = checkDatas(infos); //データチェック

      const listSortOrder = action.payload.listSortOrder;
      const listSortOrderDesc = action.payload.listSortOrderDesc;
      const visibleRowsKey = action.payload.visibleRowsKey;

      infos = sortInfoDatas(infos, listSortOrder, listSortOrderDesc);
      const [rowInfos, fixedRowsTop] = convertRowInfos(listSortOrder, listSortOrderDesc, visibleRowsKey, infos, hidden);
      //新規も編集前のデータをセットをセット
      infos.forEach(data => setOldData(data));

      //map
      const infoItemDatasMap = {};
      const infoCenterDatasMap = {};
      infos.forEach(infoData => {
        if(infoData.itemInfo) {
          infoItemDatasMap[infoData.itemCD] = infoData.itemInfo;
        }
        if(infoData.centerInfo) {
          infoCenterDatasMap[infoData.centerCD] = infoData.centerInfo;
        }
        if(infoData.aiteCenterInfo) {
          infoCenterDatasMap[infoData.aiteCenterCD] = infoData.aiteCenterInfo;
        }
      });

      //store更新
      state.rowInfos = rowInfos;
      state.fixedRowsTop = fixedRowsTop;
      state.infoDatas = infos;
      state.infoItemDatasMap = infoItemDatasMap;
      state.infoCenterDatasMap = infoCenterDatasMap;

      state.rows = convertRows(rowInfos, colRowModel);
      state.mergeCells = createMergeCells(rowInfos, colRowModel);

      state.tableVer_updateSettting = state.tableVer_updateSettting+1;  //カウントアップしテーブルを再構築対象とする (updateSetting)
    },
    searchedItemInfo(state:SettingTransferCenterTmpState, action: PayloadAction<{param: settingTransferCenterInfoItem.RequestParam, infoItems: InfoItemData[], colRowModel:SettingTransferCenterColRowModel, listSortOrder:CodeName, listSortOrderDesc:boolean, visibleRowsKey:string[], hidden?: boolean}>){
      console.log('searchedItemInfo');
      const colRowModel = action.payload.colRowModel;
      const param = action.payload.param;
      const hidden = action.payload.hidden;

      let infoItems = parseDataInfoItemDatas(action.payload.infoItems);
      //map
      const infoItemDatasMap = state.infoItemDatasMap;
      infoItems.forEach(infoItem => {
        if(infoItem.itemCD) {
          infoItemDatasMap[infoItem.itemCD] = infoItem;
        }
      });

      const rowInfos = state.rowInfos;
      
      //store更新
      state.infoItemDatasMap = infoItemDatasMap;

      const newRows = state.rows;
      const newInfoDatas = state.infoDatas;
      const tableVer_updateData_rowsSet = new Set<number>();
      rowInfos.forEach((rowInfo, row) => {
        let newInfoData = newInfoDatas[rowInfo.infoDataIndex];
        if(newInfoData.requestItemInfo) {
          const infoItem:InfoItemData = infoItemDatasMap[newInfoData.itemCD];
          if(infoItem) {
            newInfoData.itemInfo = infoItem;
          }
          newInfoData.requestItemInfo = false;
          newInfoData = checkData(newInfoData);
          tableVer_updateData_rowsSet.add(row);
          rowInfo.infoData = newInfoData;
        }
      });

      const tableVer_updateData_rows = Array.from(tableVer_updateData_rowsSet);
      if(tableVer_updateData_rows.length > 0) {
        tableVer_updateData_rows.forEach(row => {
          newRows[row] = convertRow(rowInfos[row], colRowModel);
        });

        state.tableVer_updateData_rows = tableVer_updateData_rows;
        state.tableVer_updateData = state.tableVer_updateData+1;  //カウントアップしテーブルを再描写対象とする (updateData)
      }
    },
    searchedCenterInfo(state:SettingTransferCenterTmpState, action: PayloadAction<{param: settingTransferCenterInfoCenter.RequestParam, infoCenters: InfoCenterData[], colRowModel:SettingTransferCenterColRowModel, listSortOrder:CodeName, listSortOrderDesc:boolean, visibleRowsKey:string[], hidden?: boolean}>){
      console.log('searchedCenterInfo');
      const colRowModel = action.payload.colRowModel;
      const param = action.payload.param;
      const hidden = action.payload.hidden;

      let infoCenters = parseDataInfoCenterDatas(action.payload.infoCenters);
      //map
      const infoCenterDatasMap = state.infoCenterDatasMap;
      infoCenters.forEach(infoCenter => {
        if(infoCenter.centerCD) {
          infoCenterDatasMap[infoCenter.centerCD] = infoCenter;
        }
      });

      const rowInfos = state.rowInfos;
      
      //store更新
      state.infoCenterDatasMap = infoCenterDatasMap;

      const newRows = state.rows;
      const newInfoDatas = state.infoDatas;
      const tableVer_updateData_rowsSet = new Set<number>();
      rowInfos.forEach((rowInfo, row) => {
        let newInfoData = newInfoDatas[rowInfo.infoDataIndex];
        if(newInfoData.requestCenterInfo) {
          const infoCener:InfoCenterData = infoCenterDatasMap[newInfoData.centerCD];
          if(infoCener) {
            newInfoData.centerInfo = infoCener;
          }
          newInfoData.requestCenterInfo = false;
          newInfoData = checkData(newInfoData);
          tableVer_updateData_rowsSet.add(row);
          rowInfo.infoData = newInfoData;
        }
        if(newInfoData.requestAiteCenterInfo) {
          let infoCener:InfoCenterData = infoCenterDatasMap[newInfoData.aiteCenterCD];
          if(infoCener) {
            newInfoData.aiteCenterInfo = infoCener;
          }
          newInfoData.requestAiteCenterInfo = false;
          newInfoData = checkData(newInfoData);
          tableVer_updateData_rowsSet.add(row);
          rowInfo.infoData = newInfoData;
      }
      });

      const tableVer_updateData_rows = Array.from(tableVer_updateData_rowsSet);
      if(tableVer_updateData_rows.length > 0) {
        tableVer_updateData_rows.forEach(row => {
          newRows[row] = convertRow(rowInfos[row], colRowModel);
        });

        state.tableVer_updateData_rows = tableVer_updateData_rows;
        state.tableVer_updateData = state.tableVer_updateData+1;  //カウントアップしテーブルを再描写対象とする (updateData)
      }
    },
  
    addRow (state:SettingTransferCenterTmpState, action: PayloadAction<{addRowCount:number, colRowModel:SettingTransferCenterColRowModel, listSortOrder:CodeName, listSortOrderDesc:boolean, visibleRowsKey:string[]}>) {
      console.log('addRow');
      const addRowCount = action.payload.addRowCount;

      const listSortOrder = action.payload.listSortOrder;
      const listSortOrderDesc = action.payload.listSortOrderDesc;
      const visibleRowsKey = action.payload.visibleRowsKey;
      const colRowModel = action.payload.colRowModel;
      let oldinfos:InfoData[] = state.infoDatas;
      let addinfos:InfoData[] = [];
      for (let i = 0; i < addRowCount; i++) {
        const infoData:InfoData = {
          itemCD: '',
          //子センター
          bumonCD: '',
          centerCD: '',
          centerCD2: '',
          //親センター
          aiteBumonCD: '',
          aiteCenterCD: '',
          aiteCenterCD2: '',
          
          saijiKbn: '',
          fromDate: null,
          toDate: null,
        };
        setOldData(infoData)
        addinfos.push(infoData);
      }

      let infos = [...oldinfos, ...addinfos];

      infos = sortInfoDatas(infos, listSortOrder, listSortOrderDesc);
      const [rowInfos, fixedRowsTop] = convertRowInfos(listSortOrder, listSortOrderDesc, visibleRowsKey, infos);
      //store更新
      state.rowInfos = rowInfos;
      state.fixedRowsTop = fixedRowsTop;
      state.infoDatas = infos;
      state.rows = convertRows(rowInfos, colRowModel);
      state.mergeCells = createMergeCells(rowInfos, colRowModel);

      state.tableVer_updateSettting = state.tableVer_updateSettting+1;  //カウントアップしテーブルを再構築対象とする (updateSetting)
    },
    removeEmptyRow (state:SettingTransferCenterTmpState, action: PayloadAction<{colRowModel:SettingTransferCenterColRowModel, listSortOrder:CodeName, listSortOrderDesc:boolean, visibleRowsKey:string[]}>) {
      console.log('removeEmptyRow');
      const listSortOrder = action.payload.listSortOrder;
      const listSortOrderDesc = action.payload.listSortOrderDesc;
      const visibleRowsKey = action.payload.visibleRowsKey;
      const colRowModel = action.payload.colRowModel;
      let infos:InfoData[] = state.infoDatas;
      infos = infos.filter(info => !isEmptyRowData(info, true));

      infos = sortInfoDatas(infos, listSortOrder, listSortOrderDesc);
      const [rowInfos, fixedRowsTop] = convertRowInfos(listSortOrder, listSortOrderDesc, visibleRowsKey, infos);
      //store更新
      state.rowInfos = rowInfos;
      state.fixedRowsTop = fixedRowsTop;
      state.infoDatas = infos;
      state.rows = convertRows(rowInfos, colRowModel);
      state.mergeCells = createMergeCells(rowInfos, colRowModel);

      state.tableVer_updateSettting = state.tableVer_updateSettting+1;  //カウントアップしテーブルを再構築対象とする (updateSetting)
    },

    refreshTable(state:SettingTransferCenterTmpState, action: PayloadAction<{colRowModel:SettingTransferCenterColRowModel, listSortOrder:CodeName, listSortOrderDesc:boolean, visibleRowsKey:string[]}>){
      console.log('refreshTable');
      const listSortOrder = action.payload.listSortOrder;
      const listSortOrderDesc = action.payload.listSortOrderDesc;
      const visibleRowsKey = action.payload.visibleRowsKey;
      const colRowModel = action.payload.colRowModel;
      let infos = state.infoDatas;

      infos = sortInfoDatas(infos, listSortOrder, listSortOrderDesc);
      const [rowInfos, fixedRowsTop] = convertRowInfos(listSortOrder, listSortOrderDesc, visibleRowsKey, infos);
      //store更新
      state.rowInfos = rowInfos;
      state.fixedRowsTop = fixedRowsTop;
      state.infoDatas = infos;
      state.rows = convertRows(rowInfos, colRowModel);
      state.mergeCells = createMergeCells(rowInfos, colRowModel);

      state.tableVer_updateSettting = state.tableVer_updateSettting+1;  //カウントアップしテーブルを再構築対象とする (updateSetting)
    },

    editRowDatas(state:SettingTransferCenterTmpState, action: PayloadAction<{colRowModel:SettingTransferCenterColRowModel, listSortOrder:CodeName, listSortOrderDesc:boolean, visibleRowsKey:string[], editDatas:EditCellInfo[]}>) {
      console.log('store.editRowDatas');
      const listSortOrder = action.payload.listSortOrder;
      const listSortOrderDesc = action.payload.listSortOrderDesc;
      const visibleRowsKey = action.payload.visibleRowsKey;
      const colRowModel = action.payload.colRowModel;
      const editDatas = action.payload.editDatas;

      // const newInfoDatas = [...state.infoDatas];
      const newInfoDatas = state.infoDatas;
      const newRows = state.rows;

      let countUp_retrieveItemInfo_ver = false;
      let countUp_retrieveCenterInfo_ver = false;

      let editedRows:Set<number> = new Set<number>();
      editDatas.forEach((editData)=>{
        const rowInfo:RowInfo = state.rowInfos[editData.row];
        if(!rowInfo) {
          return;
        }
        const infoData = rowInfo.infoData;
        const infoDataIndex = rowInfo.infoDataIndex;
        if(!infoData) {
          return;
        }

        const colKey:string = colRowModel.keyFromCol(editData.col);
        const rowKey:string = rowInfo.rowKey;

        let dataType = rowDataType[rowKey];
        if(!dataType) {
          dataType = colDataType[colKey];
        }

        const value = editorUtil.parseValue(editData.value, dataType.type,
          dataType.type == 'numeric' ? dataType.numericFormat.pattern :
          dataType.type == 'date' ? dataType.dateFormat :
          null);

        let newInfoData = newInfoDatas[infoDataIndex];
        switch (colKey) {
          case 'centerCD':
            {
              const bumonCD = !value ? '' : state.retrievedParam?.bumonCodes?.[0];
              const centerCD2 = !value ? '' : `${value}`;
              const centerCD = bumonCD + centerCD2;
              newInfoData.bumonCD = bumonCD;
              newInfoData.centerCD = centerCD;
              newInfoData.centerCD2 = centerCD2;
              //centerInfo更新
              const infoCenterData:InfoCenterData = state.infoCenterDatasMap[centerCD];
              if(infoCenterData) {
                newInfoData.centerInfo = infoCenterData;
                newInfoData.requestCenterInfo = false;
              }
              else if(centerCD.length == 4){
                newInfoData.centerInfo = {};
                newInfoData.requestCenterInfo = true;
                countUp_retrieveCenterInfo_ver = true;
              }
              else {
                newInfoData.centerInfo = {};
                newInfoData.requestCenterInfo = false;
              }
            }
            break;
          case 'aiteCenterCD':
            {
              const bumonCD = !value ? '' : state.retrievedParam?.bumonCodes?.[0];
              const centerCD2 = !value ? '' : `${value}`;
              const centerCD = bumonCD + centerCD2;
              newInfoData.aiteBumonCD = bumonCD;
              newInfoData.aiteCenterCD = centerCD;
              newInfoData.aiteCenterCD2 = centerCD2;
              //centerInfo更新
              const infoCenterData:InfoCenterData = state.infoCenterDatasMap[centerCD];
              if(infoCenterData) {
                newInfoData.aiteCenterInfo = infoCenterData;
                newInfoData.requestAiteCenterInfo = false;
              }
              else if(centerCD.length == 4){
                newInfoData.aiteCenterInfo = {};
                newInfoData.requestAiteCenterInfo = true;
                countUp_retrieveCenterInfo_ver = true;
              }
              else {
                newInfoData.aiteCenterInfo = {};
                newInfoData.requestAiteCenterInfo = false;
              }
              
            }
            break;
          case 'itemCD':
            {
              const itemCD = !value ? '' : `${value}`;
              newInfoData.itemCD = itemCD;

              //itemInfo更新
              const infoItemData:InfoItemData = state.infoCenterDatasMap[itemCD];
              if(infoItemData) {
                newInfoData.itemInfo = infoItemData;
                newInfoData.requestItemInfo = false;
              }
              else if(itemCD.length == 10){
                newInfoData.itemInfo = {};
                newInfoData.requestItemInfo = true;
                countUp_retrieveItemInfo_ver = true;
              }
              else {
                newInfoData.itemInfo = {};
                newInfoData.requestItemInfo = false;
              }
            }
            break;
          case 'saijiKbn':
            {
              const saijiKbn = getSaijiKbnCD(!value ? '' : `${value}`);
              newInfoData.saijiKbn = saijiKbn;
            }
            break;

          default:
            newInfoData[colKey] = value;
            break;
        }
        editedRows.add(editData.row);
        newInfoData = checkData(newInfoData);
        state.rowInfos[editData.row].infoData = newInfoData;

      });

      if(editedRows.size > 0) {
        const tableVer_updateData_rows = [];
        editedRows.forEach(row => {
          tableVer_updateData_rows.push(row);
          newRows[row] = convertRow(state.rowInfos[row], colRowModel);
        });
        // state.rows = newRows;

        /*
        const [rowInfos, fixedRowsTop, newinfos] = convertRowInfos(state.dataGroups, listSortOrder, listSortOrderDesc, visibleRowsKey, newInfoDatas);
        
        // //store更新
        state.rowInfos = rowInfos;
        state.fixedRowsTop = fixedRowsTop;
        state.infoDatas = newinfos;
        state.rows = convertRows(rowInfos, colRowModel);
        state.mergeCells = createMergeCells(rowInfos, colRowModel);
        */

        state.tableVer_updateData_rows = tableVer_updateData_rows;
        state.tableVer_updateData = state.tableVer_updateData+1;  //カウントアップしテーブルを再描写対象とする (updateData)
      }

      if(countUp_retrieveCenterInfo_ver) {
        state.retrieveCenterInfo_ver = state.retrieveCenterInfo_ver + 1;
      }
      if(countUp_retrieveItemInfo_ver) {
        state.retrieveItemInfo_ver = state.retrieveItemInfo_ver + 1;
      }
    },
    setFunctionKey(state:SettingTransferCenterTmpState, action: PayloadAction<string>) {
      state.functionKeys = action.payload ? [action.payload] : [];
    },

}};

const setOldData = (data: InfoData): InfoData => {
  data.oldItemCD = data.itemCD ? data.itemCD : '';
  data.oldCenterCD = data.centerCD ? data.centerCD : '';
  data.oldAiteCenterCD = data.aiteCenterCD ? data.aiteCenterCD : '';
  data.oldSaijiKbn = data.saijiKbn ? data.saijiKbn : '';
  data.oldFromDate = data.fromDate ? data.fromDate : null;
  data.oldToDate = data.toDate ? data.toDate : null;

  return data;
}


const parseDataInfoData = (datas:InfoData[]): InfoData[] => {
  datas = [...datas];
  datas.forEach((data) => {
    // if(typeof data.fromDate === 'string') data.fromDate = parseFloat(data.fromDate);
    // if(typeof data.toDate === 'string') data.toDate = parseFloat(data.toDate);
    if(data.itemInfo) {
      data.itemInfo = parseDataInfoItemData(data.itemInfo);
    }
    if(data.centerInfo) {
      data.centerInfo = parseDataInfoCenterData(data.centerInfo);
    }
    if(data.aiteCenterInfo) {
      data.aiteCenterInfo = parseDataInfoCenterData(data.aiteCenterInfo);
    }
  });
  return datas;
};

const parseDataInfoItemDatas = (datas:InfoItemData[]): InfoItemData[] => {
  return datas.map(data => parseDataInfoItemData(data));
};
const parseDataInfoItemData = (data:InfoItemData): InfoItemData => {
  if(typeof data.blIrisu === 'string') data.blIrisu = parseInt(data.blIrisu);
  if(typeof data.csIrisu === 'string') data.csIrisu = parseInt(data.csIrisu);
  if(typeof data.lot === 'string') data.lot = parseInt(data.lot);
  if(typeof data.csIrisu_lot === 'string') data.csIrisu_lot = parseInt(data.csIrisu_lot);
  if(typeof data.teika === 'string') data.teika = parseInt(data.teika);
  if(typeof data.tatene === 'string') data.tatene = parseFloat(data.tatene);

  // if(typeof data.fromDate === 'string') data.fromDate = parseFloat(data.fromDate);
  // if(typeof data.toDate === 'string') data.toDate = parseFloat(data.toDate);
  return data;
};

const parseDataInfoCenterDatas = (datas:InfoCenterData[]): InfoCenterData[] => {
  return datas.map(data => parseDataInfoCenterData(data));
};
const parseDataInfoCenterData = (data:InfoCenterData): InfoCenterData => {
  return data;
};

//チェック
const checkDatas = (infos: InfoData[]): InfoData[] => {
  console.log('store.checkDatas');

  return infos.map(info => {
    return checkData(info);
  });
}
const checkData = (data: InfoData): InfoData => {
  //編集状態をセットする
  // Object.keys(data).forEach(key => {
    data.edited = isEditedRowData(data);
  // });


  const errorInfo: ErrorInfoData = {};
  if(!data.errorInfo) {
    data.errorInfo = {};
  }
  const warnInfo: ErrorInfoData = {};
  if(!data.warnInfo) {
    data.warnInfo = {};
  }

  const empty = isEmptyRowData(data, false);
  if(!empty) {

    //itemCD
    if(!data.itemCD) {
      errorInfo.itemCD = '入力してください';
    }
    else if(data.itemCD.length != 10) {
      errorInfo.itemCD = '10桁で入力してください';
    }
    else if(data.itemCD && !data.itemInfo?.itemCD) {
      warnInfo.itemCD = '存在しない商品CDです';
    }
    //centerCD
    if(!data.centerCD) {
      errorInfo.centerCD = '入力してください';
    }
    else if(data.centerCD.length != 4) {
      errorInfo.centerCD = '2桁で入力してください';
    }
    else if(data.centerCD && !data.centerInfo?.centerCD) {
      warnInfo.centerCD = '存在しない倉庫CDです';
    }
    //aiteCenterCD
    if(!data.aiteCenterCD) {
      errorInfo.aiteCenterCD = '入力してください';
    }
    else if(data.aiteCenterCD.length != 4) {
      errorInfo.aiteCenterCD = '2桁で入力してください';
    }
    else if(data.aiteCenterCD && !data.aiteCenterInfo?.centerCD) {
      warnInfo.aiteCenterCD = '存在しない倉庫CDです';
    }
    //centerCD
    if(data.centerCD && data.aiteCenterCD && data.centerCD == data.aiteCenterCD) {
      errorInfo.centerCD = '親子センターは異なる倉庫を指定してください';
      errorInfo.aiteCenterCD = '親子センターは異なる倉庫を指定してください';
    }

    //saijiKbn
    if(!data.saijiKbn) {
      errorInfo.saijiKbn = '入力してください';
    }
    //fromDate
    if(!data.fromDate) {
      errorInfo.fromDate = '入力してください';
    }
    else if(!moment('20' + data.fromDate).isValid()) {
      errorInfo.fromDate = '無効な日付です';
    }
    else if(data.toDate && (data.toDate != '99/99/99' && !moment('20' + data.toDate).isValid()) && data.fromDate > data.toDate) {
      errorInfo.fromDate = '開始日＜終了日で入力してください';
    }
    //toDate
    if(!data.toDate) {
      errorInfo.toDate = '入力してください';
    }
    else if(data.toDate != '99/99/99' && !moment('20' + data.toDate).isValid()) {
      errorInfo.toDate = '無効な日付です';
    }
    else if(data.fromDate && moment('20' + data.fromDate).isValid() && data.fromDate > data.toDate) {
      errorInfo.toDate = '開始日＜終了日で入力してください';
    }
    
  }

  data.errorInfo = errorInfo;
  data.warnInfo = warnInfo;
  return data;
}

//並び順変更
const sortInfoDatas = (infoDatas:InfoData[], listSortOrder:CodeName, listSortOrderDesc:boolean): InfoData[] => {
  if(!infoDatas) {
    return infoDatas;
  }
  let asc = !listSortOrderDesc;
  let getSortKey1 = (o:InfoData):string|number => 
    !o ? null : 
    !listSortOrder ? o.itemCD :
    listSortOrder.code == 'itemCD' ? o.itemCD :
    listSortOrder.code == 'itemNM' ? o.itemInfo?.itemNM :
    listSortOrder.code == 'janCD' ? o.itemInfo?.janCD :
    listSortOrder.code == 'maker' ? o.itemInfo?.makerCD :
    listSortOrder.code == 'category' ? o.itemInfo?.categoryCD :
    listSortOrder.code == 'bestBefore' ? o.itemInfo?.bestBefore :
    listSortOrder.code == 'teika' ? o.itemInfo?.teika :
    listSortOrder.code == 'tatene' ? o.itemInfo?.tatene :
    listSortOrder.code == 'center' ? o.centerCD :
    listSortOrder.code == 'area' ? o.centerInfo?.areaCD :
    listSortOrder.code == 'pref' ? o.centerInfo?.prefCD :
    listSortOrder.code == 'aiteCenter' ? o.aiteCenterCD :
    listSortOrder.code == 'aiteArea' ? o.aiteCenterInfo?.areaCD :
    listSortOrder.code == 'aitePref' ? o.aiteCenterInfo?.prefCD :
    o.itemCD
  ;
  let getSortKey2 = (o:InfoData):string|number => 
    !o ? null : 
    !listSortOrder ? o.itemCD :
    listSortOrder.group_code == 'item' ? o.itemCD :
    listSortOrder.group_code == 'center' ? o.centerCD :
    listSortOrder.group_code == 'aiteCenter' ? o.aiteCenterCD :
    o.itemCD
  ;
  let getSortKey3 = (o:InfoData):string|number => 
    !o ? null : 
    !listSortOrder ? o.centerCD :
    listSortOrder.group_code == 'item' ? o.centerCD :
    listSortOrder.group_code == 'center' ? o.itemCD :
    listSortOrder.group_code == 'aiteCenter' ? o.itemCD :
    o.centerCD
  ;
  let getSortKey4 = (o:InfoData):string|number => 
    !o ? null : 
    o.fromDate
  ;
  infoDatas.sort((a, b) => {
    //第1弾ソート項目
    let va = getSortKey1(a);
    let vb = getSortKey1(b);
    let comp = compareUtil.compareAny(va, vb, asc, true); //nullは最下部
    //第2弾ソート項目
    if(comp == 0) {
      va = getSortKey2(a);
      vb = getSortKey2(b);
      comp = compareUtil.compareAny(va, vb, asc, true); //nullは最下部
    }
    //第3弾ソート項目
    if(comp == 0) {
      va = getSortKey3(a);
      vb = getSortKey3(b);
      comp = compareUtil.compareAny(va, vb, asc, true); //nullは最下部
    }
    //第4弾ソート項目
    if(comp == 0) {
      va = getSortKey4(a);
      vb = getSortKey4(b);
      comp = compareUtil.compareAny(va, vb, asc, true); //nullは最下部
    }
    return comp;
  });
  return infoDatas;
}
  //行情報に変換
const convertRowInfos = (listSortOrder:CodeName, listSortOrderDesc:boolean, visibleRowsKey:string[], infoDatas: InfoData[], hidden?: boolean): [RowInfo[], number] => {

  const targetRowsKeys = arrayutil.and(rowKeys, visibleRowsKey);

  let rowInfos:RowInfo[] = [];
  let fixedRowsTop:number = 0;

  //明細行
  fixedRowsTop = rowInfos.length;

  const rowKeyInfos:{
    rowKey: string,
    rowIndex:number,
    rowIndexLast:boolean,
  }[] = [];
  targetRowsKeys.forEach((visibleRowKey, index) => {
    rowKeyInfos.push({
      rowKey: visibleRowKey,
      rowIndex:index,
      rowIndexLast: targetRowsKeys.length - 1 == index,
    });
  });

  const grouping = (!listSortOrder || listSortOrder.group_code == 'item') ? 'item' : 'center';
  let no = 0;
  let subno = 0;
  infoDatas.forEach((infoData, index) => {
    //明細行
    subno++;
    rowKeyInfos.forEach((rowKeyInfo) => {

      rowInfos.push({
        ...{
          TP: "info",
          no: `${no}`,
          subno: `${subno}`,
          infoData: infoData,
          infoDataIndex: index,
          // infoMakerCenterData:makerCenterData,
          row: index,

          // orderable: !infoData.errorInfo.numCSTotal
          orderable: true
        }, 
        ...rowKeyInfo
      });
    })
  });
  return [rowInfos, fixedRowsTop];
}
//配列データに変換
const convertRows = (rowInfos:RowInfo[], colRowModel:SettingTransferCenterColRowModel): any[][] => {
  return rowInfos.map((rowInfo) => convertRow(rowInfo, colRowModel));
}
//配列データに変換
const convertRow = (rowInfo:RowInfo, colRowModel:SettingTransferCenterColRowModel): any[] => {
  //set No.
  return colRowModel.colKeys.map(colKey => valueFromKey(colKey, rowInfo));
}
export const valueFromKey = (colKey: string, rowInfo: RowInfo): any => {
  //明細行
  switch (colKey) {
    case "no":
      return `${rowInfo.subno}`;
    case "rowHeader":
      return headersRow[rowInfo.rowKey];

    case "centerCD": //"倉庫"
      return rowInfo.infoData?.centerCD2;
    case "centerNM": //"倉庫"
      return rowInfo.infoData?.centerInfo?.centerNM;
    case "area": //"地域"
      return rowInfo.infoData?.centerInfo?.areaNM;
    case "pref": //"都道府県"
      return rowInfo.infoData?.centerInfo?.prefNM;

    case "aiteCenterCD": //"倉庫"
      return rowInfo.infoData?.aiteCenterCD2;
    case "aiteCenterNM": //"倉庫"
      return rowInfo.infoData?.aiteCenterInfo?.centerNM;
    case "aiteArea": //"地域"
      return rowInfo.infoData?.aiteCenterInfo?.areaNM;
    case "aitePref": //"都道府県"
      return rowInfo.infoData?.aiteCenterInfo?.prefNM;

    case "itemCD": //"商品CD"
      return rowInfo.infoData?.itemCD;
    case "itemNM": //"商品名"
      return rowInfo.infoData?.itemInfo?.itemNM;
    case "janCD": //"JAN"
      return rowInfo.infoData?.itemInfo?.janCD;
    case "makerCD": //"メーカー"
      return rowInfo.infoData?.itemInfo?.makerCD;
    case "makerNM": //"メーカー"
      return rowInfo.infoData?.itemInfo?.makerNM;
    case "categoryCD": //"商品カテゴリ"
      return rowInfo.infoData?.itemInfo?.categoryCD;
    case "categoryNM": //"商品カテゴリ"
      return rowInfo.infoData?.itemInfo?.categoryNM;
    case "blIrisu": //"BL入数"
      return rowInfo.infoData?.itemInfo?.blIrisu ? formatterN0.format(rowInfo.infoData?.itemInfo?.blIrisu) : '';
    case "csIrisu": //"CS入数"
      return rowInfo.infoData?.itemInfo?.csIrisu ? formatterN0.format(rowInfo.infoData?.itemInfo?.csIrisu) : '';
    case "csIrisu_lot": //"CS入数×発注単位"
      return rowInfo.infoData.itemInfo?.csIrisu_lot ? formatterN0.format(rowInfo.infoData.itemInfo?.csIrisu_lot) : '';
    case "lot": //"発注単位"
      return rowInfo.infoData?.itemInfo?.lot ? formatterN0.format(rowInfo.infoData?.itemInfo?.lot) : '';
    case "capacity": //"内容量"
      return rowInfo.infoData?.itemInfo?.capacity;
    case "bestBefore": //"賞味期間"
      return rowInfo.infoData?.itemInfo?.bestBefore ? `${rowInfo.infoData?.itemInfo?.bestBefore}日` : '';
    case "teika": //"定価"
      return rowInfo.infoData?.itemInfo?.teika;
    case "tatene": //"建値"
      return rowInfo.infoData?.itemInfo?.tatene;

    case "saijiKbn": //"ステータス"
      return getSaijiKbnName(rowInfo.infoData?.saijiKbn, true);
    case "fromDate": //"開始日"
      return rowInfo.infoData?.fromDate;
    case "toDate": //"開始日"
      return rowInfo.infoData?.toDate;
      
    case "editDate": //"建値"
      return rowInfo.infoData?.editDate ? moment(rowInfo.infoData?.editDate).format('YY/MM/DD HH:mm') : null;
    case "editUser": //"建値"
      return rowInfo.infoData?.editUser;

    default: {
      return rowInfo.infoData?.[colKey];
    }
  }
}

//マージを作成
const createMergeCells = (rowInfos:RowInfo[], colRowModel:SettingTransferCenterColRowModel): {row: number, col: number, rowspan: number, colspan: number}[] => {
  return null;
}


export const getOptionLabel = (option: CodeName) => {
  return option && option.name ? (option.code + ' ' + option.name) : "";
}

const createSliceContent = (name:string) => createSlice({
  name: name,
  initialState,
  reducers: createReducerContent(),
});

//Page Slice Export
//settingTransferCenterTmp
export const settingTransferCenterTmpSlice = createSliceContent("settingTransferCenterTmp");
