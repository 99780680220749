//ItemTag
interface ItemTagInterface {
  code: string,
  name: string,
  ryaku: string;
  hasTerm?: boolean;
  hasSimular: boolean;
  fgColor?: string;
  bgColor?: string;
  sortOrder: number;
  border?: string;
}

export class ItemTag implements ItemTagInterface {
  code: string;
  name: string;
  ryaku: string;
  hasTerm: boolean;
  hasSimular: boolean;
  fgColor: string;
  bgColor: string;
  sortOrder: number;
  border: string;

  constructor(param: ItemTagInterface) {
    this.code = param.code;
    this.name = param.name;
    this.ryaku = param.ryaku;
    this.hasTerm = param.hasTerm;
    this.hasSimular = param.hasSimular;
    this.fgColor = param.fgColor;
    this.bgColor = param.bgColor;
    this.sortOrder = param.sortOrder;
    this.border = param.border;
  }
}

export const itemTags:ItemTag[] = [
  new ItemTag({sortOrder: 1, code:'NEW', name:'新規', ryaku:'新規', hasTerm:true, hasSimular:true, fgColor:"#000000", bgColor:"#00FFFF"}),
  new ItemTag({sortOrder: 2, code:'CPN', name:'ｷｬﾝﾍﾟｰﾝ', ryaku:'ｷｬﾝﾍﾟｰﾝ', hasTerm:true, hasSimular:false, fgColor:"#FFFFFF", bgColor:"#FF5050"}),
  new ItemTag({sortOrder: 3, code:'CUT', name:'カット', ryaku:'ｶｯﾄ', hasTerm:true, hasSimular:false, fgColor:"#000000", bgColor:"#FFFF00"}),
  new ItemTag({sortOrder: 4, code:'EMC', name:'緊急カット', ryaku:'緊急ｶｯﾄ', hasTerm:true, hasSimular:false, fgColor:"#FFFFFF", bgColor:"#831083"}),
  new ItemTag({sortOrder: 5, code:'PKG', name:'ﾊﾟｯｹｰｼﾞ変更', ryaku:'PKG変更', hasTerm:true, hasSimular:false, fgColor:"#FFFFFF", bgColor:"#0000FF"}),
  new ItemTag({sortOrder: 6, code:'OTH', name:'その他', ryaku:'その他', hasTerm:true, hasSimular:false, fgColor:"#000000", bgColor:"#FF8C55"}),
  new ItemTag({sortOrder: 7, code:'none', name:'指定なし', ryaku:'指定なし', hasTerm:false, hasSimular:false, fgColor:"#000000", bgColor:"#ffffff", border:"1px dashed #000"}),
];


//OptionItemTag
export type OptionItemTag = {
  code: string;
  name: string;
  selected?: boolean;
  term?: Date;
  termFrom?: Date;
  termTo?: Date;
}