import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CodeName } from "@/assets/commontype/CodeName";
import { OptionItemTag } from "@/assets/commontype/ItemTag";
import * as arrayUtil from "@/util/arrayUtil";
import { SettingItemColRowModel, settingItemColRowModelDefault } from "@/components/settingitem/SettingItemTableModel";
import { listSortOrders } from "@/store/settingitem/settingItemCommon";
import { ItemTag, itemTags } from "@/assets/commontype/ItemTag";

//強制表示列
export const mustVisibleColumnsKey:string[] = [
  "target", 
  "no", 
  "attribute1Value",
  "rowHeader", "validFlg",
	'termFlg',	//期間指定フラグ
	'termStartDate',	//期間開始日
	'termEndDate',	//期間終了日
  'retrieveDateFlg',
	'retrieveDate',
	'similarItemCD',	//類似商品CD
	'comment', //コメント
	'loadedQty',
];
//デフォルト表示列
export const defaultVisibleColumnsKey:string[] = [
  //以下に貼り付け
  "attribute1Label",
  "attribute2Label",
  "attribute2Value",
];

//強制表示列
export const mustVisibleDialogColumnsKey:string[] = [
  "rowHeader", "validFlg",
	'termFlg',	//期間指定フラグ
	'termStartDate',	//期間開始日
	'termEndDate',	//期間終了日
  'retrieveDateFlg',
	'retrieveDate',
	'similarItemCD',	//類似商品CD
	'comment', //コメント
	'loadedQty',
];

//強制表示行
export const mustVisibleRowsKey:string[] = [
  
];
//デフォルト表示行
export const defaultVisibleRowsKey:string[] = itemTags.map(itemTag => itemTag.code);

//デフォルト表示項目(6行必要)
export const defaultVisibleAttributes:string[][] = [
  [
    'center', // 倉庫
    'itemCD', // 商品CD
    'itemNM', // 商品名
    'janCD', // janCD
    '',
    '',
  ],
  [
    'blIrisu', // BL入数
    'csIrisu', // CS入数
    'lot',
    '',
    '',
    '',
  ],
];

export const optionsNameList = [
  'optionBumons',
  'optionMakers',
  'optionMakerCode',
  'optionAreas',
  'optionCenters',
  'optionCenterCode',
  'optionItemCode',
  'optionItemTags',
  'optionZaikoTanaban',
  'optionZaikoSuryo',
  'optionZaikoFresh',
];
export type SettingItemOptions = {
  optionBumons?: CodeName[],        //部門
  optionMakers?: CodeName[],        //メーカー
  optionMakerCode?: string,         //メーカーCD
  optionAreas?: CodeName[],         //地域
  optionCenters?: CodeName[],       //倉庫
  optionCenterCode?: string,        //倉庫CD
  optionItemCode?: string,          //商品CD
  optionItemKeyword?: string,            //商品キーワード
  optionItemTags?: OptionItemTag[], //商品タグ
  optionZaikoTanaban?: '' | 'has' | 'none' | string,   //棚番
  optionZaikoSuryo?: '' | 'has' | 'none' | string,   //在庫数
  optionZaikoFresh?: '' | 'morning' | 'real' | string,   //在庫鮮度
};
export const displaySettingsNameList = [
  'visibleAttributes',
  'checkedRowKey',
  'checkedColKey',
];
export type DisplaySettings = {
  visibleAttributes?: string[][],    //表示属性
  checkedRowKey?: string[], //行項目選択
  checkedColKey?: string[],  //列項目選択
};

//Page State
export type SettingItemSaveState = {
  //検索条件
  refreshOptions_ver: number,
  optionBumons:CodeName[],    //部門
  optionMakers:CodeName[],    //メーカー
  optionMakerCode:string,            //メーカーCD
  optionAreas:CodeName[],     //地域
  optionCenters:CodeName[],   //倉庫
  optionCenterCode:string,            //倉庫CD
  optionItemCode:string,            //商品CD
  optionItemKeyword:string,            //商品キーワード
  optionItemTags:OptionItemTag[],            //商品タグ
  optionZaikoTanaban:''|'has'|'none'|string,   //棚番
  optionZaikoSuryo:''|'has'|'none'|string,   //在庫数
  optionZaikoFresh:''|'morning'|'real'|string,   //在庫鮮度

  favoriteOptions: { title: CodeName, params: SettingItemOptions }[] | null,
  favoriteDisplaySettings: { title: CodeName, params: DisplaySettings }[] | null,

  listSortOrder:CodeName,   //並び順
  listSortOrderDesc:boolean,   //昇順

  hiddenColumns: number[],
  hiddenColumnsKey: string[],
  visibleColumnsKey: string[],
  visibleRowsKey: string[],

  visibleAttributes:string[][],    //表示属性

  colWidthsModified: {},

};

export const initialSettingItemState: SettingItemSaveState = {
  refreshOptions_ver: 0,
  optionBumons:[],    //部門
  optionMakers:[],    //メーカー
  optionMakerCode:'',            //メーカーCD
  optionAreas:[],     //地域
  optionCenters:[],   //倉庫
  optionCenterCode:'',            //倉庫CD
  optionItemCode:'',            //商品CD
  optionItemKeyword:'',            //商品キーワード
  optionItemTags:[],            //商品タグ
  optionZaikoTanaban:'has',   //棚番
  optionZaikoSuryo:'has',   //在庫数
  optionZaikoFresh:'morning',   //在庫鮮度

  favoriteOptions: [],
  favoriteDisplaySettings: [],

  listSortOrder: listSortOrders.find(listSortOrder => listSortOrder.code == 'itemCD'),   //並び順
  listSortOrderDesc: false,   //並び順

  hiddenColumns: [],
  hiddenColumnsKey: [],
  visibleColumnsKey: defaultVisibleColumnsKey,
  visibleRowsKey: defaultVisibleRowsKey,

  visibleAttributes: defaultVisibleAttributes,    //表示属性

  colWidthsModified: {},

};
export const initialSettingItemDialogState: SettingItemSaveState = {
  refreshOptions_ver: 0,
  optionBumons:[],    //部門
  optionMakers:[],    //メーカー
  optionMakerCode:'',            //メーカーCD
  optionAreas:[],     //地域
  optionCenters:[],   //倉庫
  optionCenterCode:'',            //倉庫CD
  optionItemCode:'',            //商品CD
  optionItemKeyword:'',            //商品キーワード
  optionItemTags:[],            //商品タグ
  optionZaikoTanaban:'',   //棚番
  optionZaikoSuryo:'',   //在庫数
  optionZaikoFresh:'',   //在庫鮮度

  favoriteOptions: [],
  favoriteDisplaySettings: [],

  listSortOrder: listSortOrders.find(listSortOrder => listSortOrder.code == 'itemCD'),   //並び順
  listSortOrderDesc: false,   //並び順

  hiddenColumns: [],
  hiddenColumnsKey: [],
  visibleColumnsKey: [],
  visibleRowsKey: defaultVisibleRowsKey,

  visibleAttributes: defaultVisibleAttributes,    //表示属性

  colWidthsModified: {},

};

//Page Slice
export type SettingItemSaveReducer = {
  initOnDidMount: (state:SettingItemSaveState) => void,
  resetOnWillUnmount: (state:SettingItemSaveState) => void,
  clearOption: (state:SettingItemSaveState, action: PayloadAction<string>) => void,
  refreshOptions: (state: SettingItemSaveState) => void,
  setOptionBumons: (state:SettingItemSaveState, action: PayloadAction<CodeName[]>) => void,
  setOptionMakers: (state:SettingItemSaveState, action: PayloadAction<CodeName[]>) => void,
  setOptionMakerCode: (state:SettingItemSaveState, action: PayloadAction<string>) => void,
  setOptionAreas: (state:SettingItemSaveState, action: PayloadAction<CodeName[]>) => void,
  setOptionCenters: (state:SettingItemSaveState, action: PayloadAction<CodeName[]>) => void,
  setOptionCenterCode: (state:SettingItemSaveState, action: PayloadAction<string>) => void,
  setOptionItemCode: (state:SettingItemSaveState, action: PayloadAction<string>) => void,
  setOptionItemKeyword: (state:SettingItemSaveState, action: PayloadAction<string>) => void,
  setOptionItemTags: (state:SettingItemSaveState, action: PayloadAction<OptionItemTag[]>) => void,
  setOptionZaikoTanaban: (state:SettingItemSaveState, action: PayloadAction<string>) => void,
  setOptionZaikoSuryo: (state:SettingItemSaveState, action: PayloadAction<string>) => void,
  setOptionZaikoFresh: (state:SettingItemSaveState, action: PayloadAction<string>) => void,
  setFavoriteOptions: (state:SettingItemSaveState, action: PayloadAction<{ title: CodeName, params: SettingItemOptions }[]>) => void,
  setFavoriteDisplaySettings: (state:SettingItemSaveState, action: PayloadAction<{ title: CodeName, params: DisplaySettings }[]>) => void,
  setListSortOrder: (state:SettingItemSaveState, action: PayloadAction<CodeName>) => void,
  setListSortOrderDesc: (state:SettingItemSaveState, action: PayloadAction<boolean>) => void,
  columnWidthChange: (state:SettingItemSaveState, action: PayloadAction<{colKey:string, width:number}>) => void,
  hideColumnKeysChange: (state:SettingItemSaveState, action: PayloadAction<string[]>) => void,
  visibleRowKeysChange: (state:SettingItemSaveState, action: PayloadAction<string[]>) => void,
  visibleAttributesChange: (state:SettingItemSaveState, action: PayloadAction<string[][]>) => void,
}

const createReducerContent = (name:string, colRowModel:SettingItemColRowModel, initialState: SettingItemSaveState, mustVisibleColumnsKey: string[]):SettingItemSaveReducer => {return {
  //componentDidMount
  initOnDidMount(state:SettingItemSaveState) {
    if(state.optionBumons === undefined) {
      state.optionBumons = initialState.optionBumons;
    }

    if(state.optionMakers === undefined) {
      state.optionMakers = initialState.optionMakers;
    }
    if(state.optionMakerCode === undefined) {
      state.optionMakerCode = initialState.optionMakerCode;
    }
    if(state.optionAreas === undefined) {
      state.optionAreas = initialState.optionAreas;
    }
    if(state.optionCenters === undefined) {
      state.optionCenters = initialState.optionCenters;
    }
    if(state.optionCenterCode === undefined) {
      state.optionCenterCode = initialState.optionCenterCode;
    }
    if(state.optionItemCode === undefined) {
      state.optionItemCode = initialState.optionItemCode;
    }
    if(state.optionItemKeyword === undefined) {
      state.optionItemKeyword = initialState.optionItemKeyword;
    }
    if(state.optionItemTags === undefined) {
      state.optionItemTags = initialState.optionItemTags;
    }
    if(state.optionZaikoTanaban === undefined) {
      state.optionZaikoTanaban = initialState.optionZaikoTanaban;
    }
    if(state.optionZaikoFresh === undefined) {
      state.optionZaikoFresh = initialState.optionZaikoFresh;
    }
    if(state.optionZaikoSuryo === undefined) {
      state.optionZaikoSuryo = initialState.optionZaikoSuryo;
    }

    if(state.favoriteOptions === undefined) {
      state.favoriteOptions = initialState.favoriteOptions;
    }
    if(state.favoriteDisplaySettings === undefined) {
      state.favoriteDisplaySettings = initialState.favoriteDisplaySettings;
    }
    if(!state.listSortOrder) {
      state.listSortOrder = initialState.listSortOrder;
    }
    else if(state.listSortOrder.code) { //属性値を再選択する
      const code = state.listSortOrder.code;
      state.listSortOrder = listSortOrders.find(listSortOrder => listSortOrder.code == code);   //並び順
    }

    if(state.listSortOrderDesc === undefined) {
      state.listSortOrderDesc = initialState.listSortOrderDesc;
    }

    if (name === 'settingItemDialogSave') {
      state.visibleColumnsKey = [];
    } else {
      //初期表示列0配列は、常に全項目表示とする
      if(initialState.visibleColumnsKey.length == 0) {
        state.visibleColumnsKey = colRowModel.colKeys;
      }
      else if(!state.visibleColumnsKey) {
        state.visibleColumnsKey = initialState.visibleColumnsKey;
      }
    }
    //強制選択列
    state.visibleColumnsKey = arrayUtil.union(arrayUtil.and(colRowModel.colKeys, mustVisibleColumnsKey), state.visibleColumnsKey);
    //非表示列
    state.hiddenColumnsKey = arrayUtil.not(colRowModel.colKeys, state.visibleColumnsKey);

    //行項目
    if (!state.visibleRowsKey || state.visibleRowsKey.length == 0) {
      state.visibleRowsKey = (initialState.visibleRowsKey.length == 0) ? colRowModel.rowKeys : initialState.visibleRowsKey;
    }
    //強制選択行
    state.visibleRowsKey = arrayUtil.union(arrayUtil.and(colRowModel.rowKeys, mustVisibleRowsKey), state.visibleRowsKey);

    //表示項目
    if(!state.visibleAttributes || state.visibleAttributes.length < initialState.visibleAttributes.length) {
      state.visibleAttributes = initialState.visibleAttributes;
    }
    else {
      //6項目必要
      let visibleAttributes = [...state.visibleAttributes];
      let init = false;
      initialState.visibleAttributes.forEach((attrs, index) => {
        if(visibleAttributes[index].length != attrs.length) {
          init = true;
          visibleAttributes[index] = attrs;
        }
      });
      if(init) {
        state.visibleAttributes = visibleAttributes;
      }
    }

    if(state.colWidthsModified === undefined) {
      state.colWidthsModified = initialState.colWidthsModified;
    }

  },
  //componentWillUnmount
  resetOnWillUnmount(state:SettingItemSaveState) {
    //初期表示列0配列は、常に全項目表示とするため、記憶しない
    if(initialState.visibleColumnsKey.length == 0) {
      state.visibleColumnsKey = [];
    }
  },
  clearOption(state: SettingItemSaveState, action: PayloadAction<string>) {
    const key = action.payload;
    if (!key) {
      // state.optionBumons = initialState.optionBumons;
      state.optionZaikoTanaban = initialState.optionZaikoTanaban;
      state.optionZaikoFresh = initialState.optionZaikoFresh;
      state.optionZaikoSuryo = initialState.optionZaikoSuryo;
    }
    if (!key || key === 'item') {
      state.optionMakers = initialState.optionMakers;
      state.optionMakerCode = initialState.optionMakerCode;
      state.optionItemCode = initialState.optionItemCode;
      state.optionItemKeyword = initialState.optionItemKeyword;
      state.optionItemTags = initialState.optionItemTags;
    }
    if (!key || key === 'center') {
      state.optionAreas = initialState.optionAreas;
      state.optionCenters = initialState.optionCenters;
      state.optionCenterCode = initialState.optionCenterCode;
    }
  },
  refreshOptions (state: SettingItemSaveState) {
    state.refreshOptions_ver = state.refreshOptions_ver + 1;
  },
  setOptionBumons (state:SettingItemSaveState, action: PayloadAction<CodeName[]>) {
    state.optionBumons = action.payload;
  },
  setOptionMakers (state:SettingItemSaveState, action: PayloadAction<CodeName[]>) {
    state.optionMakers = action.payload;
  },
  setOptionMakerCode (state:SettingItemSaveState, action: PayloadAction<string>) {
    state.optionMakerCode = action.payload;
  },
  setOptionAreas (state:SettingItemSaveState, action: PayloadAction<CodeName[]>) {
    state.optionAreas = action.payload;
  },
  setOptionItemCode (state:SettingItemSaveState, action: PayloadAction<string>) {
    state.optionItemCode = action.payload;
  },
  setOptionItemKeyword (state:SettingItemSaveState, action: PayloadAction<string>) {
    state.optionItemKeyword = action.payload;
  },
  setOptionItemTags (state:SettingItemSaveState, action: PayloadAction<OptionItemTag[]>) {
    state.optionItemTags = action.payload;
  },
  setOptionZaikoTanaban(state:SettingItemSaveState, action: PayloadAction<string>) {
    state.optionZaikoTanaban = action.payload;
  },
  setOptionZaikoSuryo(state:SettingItemSaveState, action: PayloadAction<string>) {
    state.optionZaikoSuryo = action.payload;
  },
  setOptionZaikoFresh(state:SettingItemSaveState, action: PayloadAction<string>) {
    state.optionZaikoFresh = action.payload;
  },

  setFavoriteOptions(state:SettingItemSaveState, action: PayloadAction<{ title: CodeName, params: SettingItemOptions }[]>) {
    state.favoriteOptions = action.payload;
  },
  setFavoriteDisplaySettings(state:SettingItemSaveState, action: PayloadAction<{ title: CodeName, params: DisplaySettings }[]>) {
    state.favoriteDisplaySettings = action.payload;
  },
  setOptionCenters (state:SettingItemSaveState, action: PayloadAction<CodeName[]>) {
    state.optionCenters = action.payload;
  },
  setOptionCenterCode (state:SettingItemSaveState, action: PayloadAction<string>) {
    state.optionCenterCode = action.payload;
  },
  setListSortOrder (state:SettingItemSaveState, action: PayloadAction<CodeName>) {
    state.listSortOrder = action.payload;
  },
  setListSortOrderDesc (state:SettingItemSaveState, action: PayloadAction<boolean>) {
    state.listSortOrderDesc = action.payload;
  },

  columnWidthChange(state:SettingItemSaveState, action: PayloadAction<{colKey:string, width:number}>){
    console.log('store.columnWidthChange');
    const colKey = action.payload.colKey;
    const width = action.payload.width;
    if(colKey && width) {
      state.colWidthsModified[colKey] = width;
    }
  },
  hideColumnKeysChange(state:SettingItemSaveState, action: PayloadAction<string[]>){
    console.log('store.hideColumnKeysChange');
    //store更新
    state.hiddenColumns = colRowModel.colFromKeys(action.payload);
    state.hiddenColumnsKey = action.payload;
    state.visibleColumnsKey = arrayUtil.not(colRowModel.colKeys, action.payload);
  },
  visibleRowKeysChange(state:SettingItemSaveState, action: PayloadAction<string[]>){
    console.log('store.visibleRowKeysChange');
    //store更新
    state.visibleRowsKey = action.payload;
  },
  visibleAttributesChange(state:SettingItemSaveState, action: PayloadAction<string[][]>){
    console.log('store.visibleAttributesChange');
    //store更新
    state.visibleAttributes = action.payload;
  },
  
}};

const createSliceContent = (name:string, colRowModel:SettingItemColRowModel, initialState: SettingItemSaveState, mustVisibleColumnsKey: string[]) => createSlice({
  name: name,
  initialState: initialState,
  reducers: createReducerContent(name, colRowModel, initialState, mustVisibleColumnsKey),
});

//Page Slice Export
//settingItemSaveSlice
export const settingItemSaveSlice = createSliceContent("settingItemSave", settingItemColRowModelDefault, initialSettingItemState, mustVisibleColumnsKey);
export const settingItemDialogSaveSlice = createSliceContent("settingItemDialogSave", settingItemColRowModelDefault, initialSettingItemDialogState, mustVisibleDialogColumnsKey);
export const settingItemDirectSaveSlice = createSliceContent("settingItemDirectSave", settingItemColRowModelDefault, initialSettingItemState, mustVisibleColumnsKey);
